import React, { useEffect, useState } from 'react'
import { Autocomplete, GoogleMap, LoadScript, Marker, MarkerF, useJsApiLoader, useLoadScript } from '@react-google-maps/api'
import { API_END_POINTS, GLOBAL_CONSTANTS, TUTORMAPCONSTANT } from '../../Constants/Constants'
import { Field, Formik } from 'formik';
import * as yup from "yup";
import { t } from 'i18next';
import { axiosInstance } from '../../Utilities/Interceptor/Interceptor';
import toast from 'react-hot-toast';
import "./AddAddress.scss"
import { WidthFull } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import DeleteIcon from ".././../Assets/ProfileSettings/deletebutton.svg"
const containerStyle = {
    width: '100%',
    height: '35vh',
}


function AddAddress() {

    const libraries: any = ['places'];
    const center = {
        lat: 25.2048,
        lng: 55.2708,
    }
    const [address, setAddress] = React.useState<any>([])
    const [autocomplete, setAutocomplete] = React.useState<any>(null);
    const inputRef: any = React.useRef(null);
    const formikRef: any = React.useRef(null);



    const InitialLocation = {
        lat: "",
        lng: ""
    }
    const [location, setLocation] = useState<any>(InitialLocation);

    useEffect(() => {
        if (address?.data?.length) {
            const activeLocation = address.data.find(
                (res: any) => res?.is_activated
                    === false && res?.latitude && res?.longitude
            );
            if (activeLocation) {
                setLocation({ lat: activeLocation.latitude, lng: activeLocation.longitude });
            }
        }
    }, [address]);



    useEffect(() => {
        getAddress();
    }, [])

    const setCurrentLatLng = (latitude: any, longitude: any) => {
        setCurrentLocation({ lat: latitude, lng: longitude });
    }
    const { isLoaded } = useJsApiLoader({
        id: TUTORMAPCONSTANT.MAP__ID || "",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "",
        libraries,
    })

    const onAutocompleteLoad = (autoC: any) => {
        setAutocomplete(autoC); // Save the autocomplete instance
    };

    const [currentLocation, setCurrentLocation] = React.useState(center)





    // api calls 
    const getAddress = () => {
        axiosInstance.get(API_END_POINTS.MANAGE_ADDRESS)
            .then((response) => {
                setAddress(response?.data?.result)
            }).catch(() => { })
    }

    const postAddress = (values: any, resetForm: any) => {
        axiosInstance.post(API_END_POINTS.MANAGE_ADDRESS, values)
            .then((response) => {
                if (response?.data?.status) {
                    getAddress();
                    toast.success(response?.data?.message, {
                        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                    });
                    resetForm()
                }
            }
            ).catch(() => { })
    }

    const deleteAddress = (id: any) => {
        axiosInstance.delete(`${API_END_POINTS.MANAGE_ADDRESS}`, { params: { id } })
            .then((response) => {
                if (response?.data?.status) {
                    getAddress();
                    toast.success(response?.data?.message, {
                        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                    });
                }
            }).catch(() => { })
    }

    const onPlaceChanged = (formik: any, check: boolean, user: any) => {
        console.log("🚀 ~ onPlaceChanged ~ user and formik:", user, formik)
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place?.geometry) {
                const location = place?.geometry?.location;
                const lat = !check ? location.lat() : +user.latitude;
                const lng = !check ? location.lng() : +user.longitude;
                // Set lat/lng after fetching
                setCurrentLatLng(lat, lng);
                if (inputRef.current) {
                    const inputValue = inputRef.current.value;
                    formik.setFieldValue("map_address", !check ? inputValue : user.map_address)
                }
                getFormattedAddress(lat, lng, formik, false);
            }
        }
    };

    const geocoder: any = isLoaded ? new window.google.maps.Geocoder() : "";

    const getFormattedAddress = (lat: any, lng: any, formik: any, check: boolean) => {
        geocoder.geocode({ location: { lat, lng } }, (results: any, status: any) => {
            if (status === "OK") {
                if (results[0]) {
                    let filterCountry = results[0]?.address_components?.filter((res: any) => {
                        return res.types[0] === 'country'
                    });
                    let filterState = results[0]?.address_components?.filter((res: any) => {
                        return res.types[0] === 'locality'
                    });
                    if (results[0]) {
                        if (check) {
                            formik.setFieldValue(
                                "map_address",
                                results[0]?.formatted_address ? results[0]?.formatted_address : "N/A"
                            )
                        }
                        formik.setFieldValue(
                            "country",
                            filterCountry[0]?.long_name ? filterCountry[0]?.long_name : "N/A"
                        )
                        formik.setFieldValue(
                            "latitude",
                            lat
                        )
                        formik.setFieldValue(
                            "longitude",
                            lng
                        )
                        formik.setFieldValue(
                            "city",
                            filterState[0]?.long_name ? filterState[0]?.long_name : "N/A"
                        )
                        formik.setFieldValue(
                            "state",
                            filterState[0]?.long_name ? filterState[0]?.long_name : "N/A"
                        )
                    }
                }
            }
        });
    }

    const mapOptions = {
        draggable: true,
        clickableIcons: false,
        disableDoubleClickZoom: true,
    };

    const initialValues = {
        map_address: "",
        address: "",
        latitude: "",
        longitude: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
    }

    const addAddressSchema = yup.object({
        ["map_address"]: yup.string().required("Map address is required"),
        ["address"]: yup.string().required("Address is required"),
    })

    const hnaldeSelectedAddress = (id: any, user: any) => {

        let payload = {
            id: user?.user,
            address_id: id
        }
        let loca = {
            lat: +user.latitude,
            lng: +user.longitude
        }
        setCurrentLocation(loca);
        if (formikRef.current) {
            formikRef.current.setFieldValue("map_address", user.map_address);
            formikRef.current.setFieldValue("address", user.address);
          }
        onPlaceChanged(formikRef.current, true, user)
        axiosInstance.post(`${API_END_POINTS.SELECT_ADDRESS}`, payload)
            .then((response) => {
                if (response?.data?.status) {
                    getAddress();
                    toast.success(response?.data?.message, {
                        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                    });
                }
            }).catch(() => { })
    }

    return (
        <div className='Addaddress-container'>
            <div className='row mt-3 '>
                <div className='col-lg-6 map-card'>
                    {isLoaded ? (
                        <>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={(values, { resetForm }) => {
                                    postAddress({ ...values }, resetForm)
                                }}
                                innerRef={formikRef}
                                validationSchema={addAddressSchema}
                            >
                                {(formik) => (
                                    <div>
                                        <div className="address_container">
                                            <label className="field_label">{t("Enter a place")}</label>
                                            {isLoaded && (
                                                <div>

                                                    <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={() => onPlaceChanged(formik, false, "")}>
                                                        <input
                                                            type="text"
                                                            className='Autocomplete-box'
                                                            style={{ "marginBottom": "10px", width: "100%" }}
                                                            placeholder="Enter a place"
                                                            ref={inputRef}
                                                            name={"map_address"}
                                                            value={formik.values["map_address"]}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </Autocomplete>
                                                    <GoogleMap
                                                        id={TUTORMAPCONSTANT.MAP__ID}
                                                        mapContainerStyle={containerStyle}
                                                        zoom={10}
                                                        center={currentLocation}
                                                        options={mapOptions}
                                                    >
                                                        <MarkerF position={currentLocation}
                                                            shape={{ coords: [10, 14, 19,], type: "circle" }}
                                                            title="Dubai"
                                                            draggable={true}
                                                            onDragEnd={(e: any) => {
                                                                const locationData: any = {
                                                                    lat: e.latLng.lat(),
                                                                    lng: e.latLng.lng()
                                                                };
                                                                setCurrentLocation(locationData);
                                                                getFormattedAddress(e?.latLng?.lat(), e?.latLng?.lng(), formik, true);
                                                            }}
                                                        />
                                                    </GoogleMap >
                                                </div>
                                            )}
                                            <div className="error-message-wrap">
                                                {formik.touched["map_address"] &&
                                                    formik.errors["map_address"] ? (
                                                    <div className="errorMessage">
                                                        {t(
                                                            `${formik.errors[
                                                            "map_address"
                                                            ]
                                                            }`
                                                        )}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="address_container mt-2">
                                            <label className="field_label">{t("Address")}</label>
                                            <Field
                                                placeholder={t("Enter Your Address")}
                                                name={"address"}
                                                style={{ WidthFull }}
                                            />
                                            <div className="error-message-wrap">
                                                {formik.touched["address"] &&
                                                    formik.errors["address"] ? (
                                                    <div className="errorMessage">
                                                        {t(
                                                            `${formik.errors[
                                                            "address"
                                                            ]
                                                            }`
                                                        )}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>



                                        <div className="submit_container mt-3">
                                            <label className="field_label"></label>
                                            <button
                                                style={{ borderRadius: "70px", backgroundColor: "#a18fe7", minHeight: "40px" }}
                                                type='submit'
                                                onClick={() => { formik.handleSubmit() }}
                                            >{"Submit"}</button>
                                        </div>

                                    </div>
                                )}

                            </Formik>
                        </>
                    ) : (
                        <></>
                    )
                    }
                </div>
                <div className="col-lg-6 allcard-container" >
                    <div className='card_div' style={{
                        maxHeight: "410px", overflow: "auto"
                    }}>
                        <div className='row mt-3 mt-2'>
                            {address?.data?.map((res: any, index: number) => (
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3 mb-3' key={index}>
                                    <div className='card_container' >
                                        <div className='row align-items-center mt-3'>
                                            <div className='col-lg-6 '>
                                                {
                                                    res?.map_address?.length > 10 ? (
                                                        <Tooltip title={res?.map_address} placement="top-start">
                                                            <p className="mb-0 font-style">{`${res?.map_address.slice(0, 10)}...`}</p>
                                                        </Tooltip>
                                                    ) : (
                                                        <p className="mb-0 font-style">{res?.map_address}</p>
                                                    )
                                                }
                                                {
                                                    res?.address?.length > 10 ? (
                                                        <Tooltip title={res?.address} placement="top-start">
                                                            <p className="mb-0 font-style">{`${res?.address.slice(0, 10)}...`}</p>
                                                        </Tooltip>
                                                    ) : (
                                                        <p className="mb-0 font-style">{res?.address}</p>
                                                    )
                                                }

                                                {/* <p className="mb-0 font-style">{res?.map_address}</p> */}
                                            </div>
                                            <div className='col-lg-6 selected_btn_container'>
                                                <button className={`${res?.is_activated ? "Selected-btn" : "not-activated"}`} onClick={() => { if (!res?.is_activated) { hnaldeSelectedAddress(res?.id, res) } }}>
                                                    {t("Selected")}
                                                </button>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 d-flex justify-content-end'><button className='Delete-btn' onClick={() => deleteAddress(res?.id)}><img className='red_icon' src={DeleteIcon} alt='DeleteIcon' width={16} height={16} /></button></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>


                </div>




            </div>
        </div>

    )
}

export default React.memo(AddAddress)
