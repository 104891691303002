import "./ThemeParkDasboard.scss";
import { useEffect, useState } from "react";
import search from "../../Assets/Tutor/searchIconGray.svg";
import themeParkIcon from "../../Assets/SideBar/themeParkActive.svg"
import { useLocation, useNavigate } from "react-router-dom";
import { API_END_POINTS, AUTHENTICATION, FILTER_CONSTANT, GLOBAL_CONSTANTS, ROUTES } from "../../Constants/Constants";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { setErrorMessage, setErrorNotification, setGuestSignupOpen, setLoginSignUpOpen } from "../../Redux/Reducer/Reducer";
import { GetSessionStorageData, SetSessionStorageData, encryptPassData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import StarIcon from '@mui/icons-material/Star';
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Share from "../../Assets/home/Share.svg";
import HeartOutline from "../../Assets/home/Heart_Outline.svg";
import HeartFilled from "../../Assets/home/Heart_Filled.svg";
import LikeFilled from "../../Assets/ThemePark/HeartFilled.svg";
// import HeartFilled from "../../../Assets/home/Heart_Filled.svg";
import axios from "axios";
import WebShareComponent from '../../Components/WebShare/WebShare';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Card, CardContent, CardMedia, Rating, styled, CardContentProps } from "@mui/material";
import Discount from "../../Assets/home/Discount.svg";
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useTranslation } from "react-i18next";
import themeParkImgsvg from "../../Assets/ThemePark/unsplashGird2-svg.png";
import { store } from "../../Redux/Store/Store";
// const StyledListCardContent = styled(CardContent)({
//     position: 'absolute',
//     bottom: 0,
//     width: '100%',
//     // background: 'rgba(0, 0, 0, 0.5)',
//     // .css-lozhc7-MuiCardContent-root
//     color: 'white',
//     borderRadius: '13px',
//     backdropFilter: 'blur(4px)',
//     background: 'rgba(0, 0, 0, 0.5)',
// });


const StyledListCardContent = styled(({ isHovered, ...rest }: CardContentProps & { isHovered?: boolean }) => (
    <CardContent {...rest} />
)) <{ isHovered?: boolean }>`
    position: absolute;
    bottom: 0;  
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 13px;
    // padding: 20px !important;
    padding: 15px 20px 10px 20px !important;

    backdropFilter: 'blur(4px)',
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    max-height: ${(props) => (props.isHovered ? '500px' : '200px')};
  `;

const ThemeParkDasboard = () => {
    const { t } = useTranslation();
    const themePark_search = GetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.THEMEPARK);
    const route = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [themeParkList, setThemeParkList] = useState([]);
    const [pageLimit, setPageLimit] = useState(9);
    const [page, setPage] = useState(1);
    const [themeParkCount, setThemeParkCount] = useState(1);

    const [hoveredStates, setHoveredStates] = useState(Array(themeParkList.length).fill(false));
    const [showMore, setShowMore] = useState(Array(themeParkList?.length).fill(false));
    const [searchTerm, setSearchTerm] = useState<string>(themePark_search ? themePark_search : '');
    const [searchActive, setSearchActive] = useState<boolean>(false);
    const [searchfilteredData, setSearchFilteredData] = useState<any[]>([]);

    const toggleDescription = (index: number) => {
        setShowMore((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = !newStates[index];
            return newStates;
        });
    };
    const handleMouseEnter = (index: number) => {
        setHoveredStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = true;
            return newStates;
        });
    };

    const handleMouseLeave = (index: number) => {
        setHoveredStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = false;
            return newStates;
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getThemeParkList(searchTerm);
    }, [route])

    const HeaderLoction: any = useSelector((state: any) => {
        return state.locationReducer.location;
    });

    useEffect(()=>{
        getThemeParkList()
    }, [HeaderLoction])

    const getThemeParkList = (search: any = "" , pageLimit: any = 9) => {
        var payload = {
            type: "web",
            screen: route?.state?.screen ? route?.state?.screen : "location",
            page: 1,
            limit: pageLimit,
            search: nullUndefinedEmptyCheck(search) ? search : route?.state.search || "",
        }
        axiosInstance
            .post(
                `${API_END_POINTS.THEMEPARK_HOMEPAGE}`, payload
            )
            .then((themePageHomeResponse: any) => {
                if (
                    themePageHomeResponse.data[AUTHENTICATION.SHARED.STATUS] ===
                    AUTHENTICATION.SHARED.TRUE
                ) {
                    const responseData = themePageHomeResponse.data?.result?.data;
                    setThemeParkList(responseData);
                    setThemeParkCount(themePageHomeResponse.data?.result?.total_count);
                    setSearchActive(false);
                }
            })
            .catch((err) => { });
    }

    const navigateThemeDetailedView = (value: any) => {
        navigate(ROUTES.THEMEPARK_DETAILE_VIEW + "?id=" + encryptPassData(value?.id), {
            state: { tutor_Detailes: value }
        });
    };

    const navigateCheckOut = (value: any) => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN) || GetSessionStorageData(AUTHENTICATION.SHARED.GUEST_ACCESS_TOKEN);
        if (value.ticket_type_list.length > 0) {
            if (nullUndefinedEmptyCheck(accessToken)) {
                navigate(ROUTES.THEMEPARK_CHECKOUT + "?id=" + value?.main_events[0]?.id + '&event=' + value?.id, { state: { event: value?.id, theme_park_id: value?.main_events[0]?.id, details: value } });
            } else {
                // dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
                dispatch(setGuestSignupOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));

            }
        } else {
            // toast.error("Tickets not Available", {
            //     position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            // });
            store.dispatch(setErrorNotification(true))
            store.dispatch(setErrorMessage("Tickets not Available"))
        }
    }

    const handleSearch = (value: string) => {
        setSearchTerm(value);
        SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.THEMEPARK, value);
        getThemeParkList(value)

    };

    const onInputChange = (e: any) => {
        e.preventDefault();
        let value = e.target.value;
        setSearchTerm(value);
        setSearchActive(true);
        SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.THEMEPARK, value);

        if (nullUndefinedEmptyCheck(value)) {
            axiosInstance.get(API_END_POINTS.THEMEPARK_SEARCH_KEYWORDS + value,
                //  {headers: {
                //     'Content-Type': 'application/json',
                // },}
        ).then((Response: any) => {
                setSearchFilteredData(Response?.data?.result?.data);
            })
                .catch(() => { });
        } else {
            getThemeParkList("");
            SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.THEMEPARK, '');
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        //  prevent page refresh
        event.preventDefault();
        getThemeParkList(searchTerm);
    };

    const handleChangeFavourites = (val: any, id: any) => {
        const accessToken = GetSessionStorageData(
            AUTHENTICATION.SHARED.ACCESS_TOKEN
        );
        if (nullUndefinedEmptyCheck(accessToken)) {
            var payload = {
                service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.SIX_INT,
                service_provider: id,
                is_favourite: !val,
            };
            axiosInstance
                .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
                .then((Response: any) => {
                    if (Response?.data?.status) {
                        getThemeParkList(searchTerm);
                        toast.success(Response?.data?.message, {
                            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
                        });
                    }
                })
                .catch((error) => {
                    // toast.error(error?.response?.data?.message, {
                    //     position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
                    // });
                });
        } else {
            dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
        }
    };

    const viewMore = () => {

        setPage(page + 1);
        getThemeParkList(searchTerm, (page + 1) * pageLimit);


    }

    const viewLess = () => {
        setPage(1);
        getThemeParkList(searchTerm, pageLimit);
    }

    /**Loader state*/
    const Loader: any = useSelector((state: any) => {
        return state.loaderReducer.loader;
    });

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });
    /*Below class name based on English Arabic*/

    const SearchBoxArab = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "search_box-english" : "search_box-arab";
    //Store text in Variable
    const PARKS_AND_EVENTS = t("Parks & Events");
    const SearchParksEvents = t("Search for Parks & Events");
    const NOITEM_FOUND = t("No items found");
    const NODATA_FOUND = t("No Data Found");
    const VIEW_MORE = t("View more");

    return (
        <>
            <div className="Themepark_list_page">
                <div className="background_linear_1"></div>
                <div className="ThemePark__dashboard__container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-10 col-sm-12">
                            <div className="header_wapper">
                                <div className="ThemeParkCard_Img_wapper" onClick={() => navigate(ROUTES.THEMEPARK__HOMEPAGE)}>
                                    <img
                                        draggable="false"
                                        src={themeParkIcon}
                                        alt="themeParkIcon"
                                        className=""
                                    />
                                    <div className="TutorImg__text"><span>{PARKS_AND_EVENTS}</span></div>
                                </div>
                                <div className="search_field_wrapper">
                                    {/* <div className="search__autocomplete">
                                        <form onSubmit={handleSubmit}>
                                            <Autocomplete
                                                freeSolo
                                                popupIcon
                                                id="free-solo-2-demo"
                                                disableClearable={false}
                                                clearIcon={false}
                                                value={searchTerm}
                                                onChange={(e, value: any) => {
                                                    handleSearch(value);
                                                }}
                                                options={searchfilteredData?.map((option: any) => option?.tag)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Search for Theme Park"
                                                        onChange={(inputChangeEvent: any) => {
                                                            onInputChange(inputChangeEvent?.target?.value.trim())
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'text',
                                                        }}
                                                    />
                                                )}
                                            />
                                            <img
                                                draggable="false"
                                                src={search}
                                                alt="search"
                                                className="search_button"
                                            />
                                        </form>
                                    </div> */}

                                    <div className="custom-searchbox">
                                        <div className="search_box_container">
                                            {/* <div className="search_box"> */}
                                            <div className={`${SearchBoxArab} search_box`}>
                                                <TextField
                                                    className="search-field"
                                                    variant="outlined"
                                                    value={searchTerm}
                                                    placeholder={SearchParksEvents}
                                                    onChange={(e) => onInputChange(e)}
                                                    InputProps={{
                                                        endAdornment: <SearchIcon onClick={() => handleSearch(searchTerm)} style={{ color: '#C0C0C0', cursor: 'pointer' }} />,
                                                        style: { borderRadius: '15px', height: '46px', backgroundColor: '#fff' }
                                                    }}
                                                    onKeyPress={(e: any) => {
                                                        if (e.code === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                                                            if (searchTerm.trim() === "") {
                                                                e.preventDefault();
                                                            } else {
                                                                handleSearch(searchTerm)
                                                                e.preventDefault();
                                                            }
                                                        }
                                                    }}
                                                />

                                            </div>

                                            {(searchTerm && searchActive) && (
                                                <Paper className="auto-complete-container" elevation={3}>
                                                    {searchfilteredData?.length > 0 ? (
                                                        <List>
                                                            {searchfilteredData?.map((item) => (
                                                                <ListItem
                                                                    key={item.id}
                                                                    button
                                                                    onClick={() => handleSearch(item.tag)}
                                                                >
                                                                    <ListItemText primary={item.tag} />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    ) : (
                                                        <Typography variant="body1" align="center">
                                                            <span>{NOITEM_FOUND}</span>
                                                        </Typography>
                                                    )}
                                                </Paper>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <div className="row">
                    {themeParkList?.map((item: any, i: any) => {
                        return (
                            <>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div className="ThemePark__list__wapper" key={i} onDoubleClick={() => navigateDetailedView(item)}>
                                        <div className="ThemePark__leftside" onClick={() => navigateListView(item)}>
                                            <img src={themeParkImg} alt="" />
                                        </div>
                                        <div className="ThemePark__rightside">
                                            <div className="ThemePark__name">
                                                <span className="name">{item?.themepark_name}</span>
                                                <div className="imageIcon">
                                                    <img src={shareIcon} alt="" className="mb-1" />
                                                    <img src={LikeIcon} alt="" />
                                                </div>
                                            </div>

                                            <div className="ThemePark__location">
                                                <img src={locationIcon} alt="" />
                                                <span className="location">{item?.address}</span>
                                            </div>

                                            <div className="ThemePark__description">
                                                <span className="text__black">{item?.description}</span>
                                            </div>

                                            <div className="ThemePark__rating">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="ticket_text">Tickets : </div>
                                                    {item?.available_ticket !== 0 ? (
                                                        <div className="available_text ms-2">Available</div>
                                                    ) : (
                                                        <div className="sold_text ms-2">Sold</div>
                                                    )}
                                                </div>
                                                <div className="d-flex flex-row align-items-center">
                                                    <StarIcon className="StarIcon" />
                                                    <span className="points ms-2">{item?.rating}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div> */}


                    {/* Theme Park Three Cart For List View*/}
                    <div className='theme-park-list-container mb-2'>
                        <div className="list-data__conatiner">
                            {themeParkList?.map((data: any, index: number) => {
                                const THEMEPARK_NAME = language === "English" ? data?.themepark_name : data?.arabic_themepark_name || data?.themepark_name
                                // const THEMEPARK_NAME = data?.themepark_name;
                                const ShareParams_Var = `${ROUTES.THEMEPARK_DETAILE_VIEW}?id=${encryptPassData(data?.id)}`;
                                const DataAddress = data?.address;
                                const DATA_RADING = data?.rating;
                                const AED_TEXT = t("AED");
                                const FROM_TXT = t("from");
                                const BOOK_TICKETS = t("Book Tickets");
                                const VIEW_LESS = t("view less");
                                return (
                                    <Card key={data.id}
                                        className="list-theme_three_card"
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={() => handleMouseLeave(index)}>
                                        {/* {!(index % 2) && index <= 4 ?
                                        <>
                                            <div className="discount" />
                                            <div className="discount-label">10<img src={Discount} alt="discount" /> <span>OFF</span></div>
                                        </> : ""
                                    } */}

                                        <CardMedia
                                            className="list-theme-three-card-img"
                                            component="img"
                                            src={data.themeparkcover_images[0]}
                                            onError={(e: any) => { e.target.src = themeParkImgsvg; }}
                                            style={{ width: '100%', height: '540px', cursor: 'pointer' }}
                                            alt="Paella dish"
                                            onClick={() => navigateThemeDetailedView(data)}
                                        />
                                        <StyledListCardContent className="list-card-content-container" isHovered={hoveredStates[index]}>

                                            <div className="list-theme_rating_inc">
                                                <div className="list-theme_card_head">
                                                    <span className="notranslate" translate="no" >{THEMEPARK_NAME}</span>
                                                </div>

                                                <div className="list-theme-share-like">
                                                    <WebShareComponent
                                                        params={ShareParams_Var}
                                                    >
                                                        <img src={Share} alt="" />
                                                    </WebShareComponent>

                                                    <img
                                                        src={data.is_favourite ? LikeFilled : HeartOutline}
                                                        alt=""
                                                        onClick={() =>
                                                            handleChangeFavourites(data?.is_favourite, data?.user)

                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <p className="list-card_map_icon">
                                                <div title={DataAddress}><span><LocationOnIcon />{DataAddress}</span></div>
                                            </p>

                                            <div className="list-theme_rating_inc">
                                                <div>
                                                    <Rating name="read-only" value={DATA_RADING} readOnly emptyIcon={<StarIcon style={{ opacity: 2 }} fontSize="inherit" />} /> <span className="rating_count">{DATA_RADING}/5</span>
                                                </div>

                                                <div>
                                                    <span>{FROM_TXT} {AED_TEXT} {data?.min_price?.replace(/(\.00|\.0)$/, '')}</span>
                                                </div>
                                            </div>

                                            {hoveredStates[index] &&
                                                <div className="hover_content" >
                                                    <span>{showMore[index] ? data?.description : `${data?.description?.substring(0, 100)}`}</span>
                                                    <div className="showmore" onClick={() => toggleDescription(index)} > {(data?.description.length > 100 && !showMore[index]) && <span style={{ cursor: 'pointer' }}>...</span>} {showMore[index] && <span style={{ color: '#01B4D3', cursor: 'pointer' }}>{VIEW_LESS}</span>}</div>

                                                    <button className="booking_button mt-2" onClick={() => navigateCheckOut(data)}>{BOOK_TICKETS}</button>
                                                </div>
                                            }

                                        </StyledListCardContent>
                                    </Card>
                                )
                            }
                            )}
                        </div>


                    </div>

                    {themeParkList?.length === 0 && !Loader && (
                        <>
                            <div className="No__data__contant">
                                <div className="text"><span>{NODATA_FOUND}</span></div>
                            </div>
                        </>
                    )}

                    <div className="action-container ">
                        {
                            themeParkCount > (page * pageLimit) ?
                                <button className="view_more" onClick={() => viewMore()}><span>{VIEW_MORE} <ArrowDownwardIcon /></span></button>
                                : ""}
                        {/* : page === Math.ceil(themeParkCount / pageLimit) ? <button className="view_more mt-2" onClick={() => viewLess()}>View Less  <ArrowUpwardIcon /></button> : ""} */}

                    </div>


                </div>
            </div>
        </>
    )
}

export default ThemeParkDasboard;