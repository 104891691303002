import "./ActivitesCategoryPopup.scss";
import { Dialog, DialogContent, } from "@mui/material";
import { nullUndefinedEmptyCheck, SetSessionStorageData } from "../../../Utilities/Helpers/Helper";
import { FILTER_CONSTANT, ROUTES } from "../../../Constants/Constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cricket from "../../../Assets/Activities/cricket.svg";

const ActivitesCategoryPopup = ({ PopOpen, onClosePop, CourseData, FilterRadiusvalue, currentLat, currentLang, FilterApplyData }: any) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    /**Coursecategory Data Navigation*/
    const handlePassCoursecategory = (value: any) => {
        if (nullUndefinedEmptyCheck(value)) {
            SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.ACTIVITY, value);
            navigate(ROUTES.ACTIVITY_LIST_VIEW, {
                state: {
                    activities_list: "",
                    search_text: value,
                    redius: FilterRadiusvalue[0],
                    lat: currentLat,
                    lang: currentLang,
                    map_show: false,
                    filter: FilterApplyData,
                },
            });
            onClosePop();
        }
    };

    return (
        <>
            <Dialog open={PopOpen}>
                <DialogContent>
                    <div className="TutorFilterPop_conatiner activity_container">
                        <div className="background_circle_1"></div>

                        <div className="Filter_header__wapper">
                            <div className="header_title">{t("Category List Data")}</div>
                            <span className="close__text" onClick={onClosePop}>{t("close")}</span>
                        </div>
                        <div className="Filter__body__contant">
                            <div className="Category_bobules__wapper">
                                {CourseData?.map((data: any, i: any) => {
                                    return (
                                        <>
                                            <div className="Category__activitiesSports_Img_wapper" key={i} onClick={() => handlePassCoursecategory(data?.category_name)}>
                                                <img
                                                    draggable="false"
                                                    src={nullUndefinedEmptyCheck(data?.display_image) ? data?.display_image : ""}
                                                    alt="cricket" onError={(e: any) => { e.target.src = cricket; }}
                                                    className=""
                                                />
                                                <span>{data?.category_name}</span>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ActivitesCategoryPopup;