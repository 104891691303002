import { useEffect, useRef } from 'react'
import { SetSessionStorageData } from '../../../Utilities/Helpers/Helper';
import { AUTHENTICATION, GLOBAL_CONSTANTS } from '../../../Constants/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../../Redux/Reducer/Reducer';
import { useTranslation } from 'react-i18next';
import './GTranslator.scss';

const GTranslator = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  // const [durections, setDropdownDirection] = useState<any>('ltr');

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  const googleDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const googleDiv = googleDivRef.current;
    if (!googleDiv) return; // Ensure element exists

    const cleanNodes = () => {
      googleDiv.childNodes.forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE && node.nodeValue?.trim() !== "") {
          node.nodeValue = ""; // Instead of removing, just clear the text
        }
      });
    };

    cleanNodes(); // Run cleanup on mount
  }, []);

  const getCurrentTranslateLanguage = (): string | null => {
    const selectElement = document.querySelector(".goog-te-combo") as HTMLSelectElement;
    return selectElement ? selectElement.value : null;
  };

  const handleTranslateLanguageChange = () => {
    const selectedLanguage = getCurrentTranslateLanguage();
    // Do something with the selected language
    if (selectedLanguage === 'ar') {
      SetSessionStorageData(AUTHENTICATION.SHARED.LANGUAGE_SWICTH, 'Arabic');
      dispatch(setLanguage('Arabic'));
      i18n.changeLanguage('Arabic');
      setDropdownDirection('rtl');
    } else if (selectedLanguage === 'en') {
      SetSessionStorageData(AUTHENTICATION.SHARED.LANGUAGE_SWICTH, 'English');
      dispatch(setLanguage('English'));
      i18n.changeLanguage('English');
      setDropdownDirection('ltr');
    }
  };

  const setDropdownDirection = (direction: 'rtl' | 'ltr') => {
    const selectElement = document.querySelector(".goog-te-combo") as HTMLSelectElement;
    if (selectElement) {
      selectElement.style.direction = direction;
    }
  };

  const updateArabicOptionText = () => {
    const selectElement = document.querySelector(".goog-te-combo") as HTMLSelectElement;
    const userLang = navigator?.language;
    if (selectElement && selectElement.options.length > 0) {
      const optionAr = selectElement.querySelector('option[value="ar"]');
      const optionEn = selectElement.querySelector('option[value="en"]');
      if (optionAr) {
        optionAr.textContent = "العربية";
        optionAr.setAttribute('translate', 'no');
      }
      if (optionEn && userLang !== 'en') {
        optionEn.textContent = "English";
        optionEn.setAttribute('translate', 'no');
      }
      // Add translate="no" attribute to all options
      Array.from(selectElement.options).forEach(option => {
        option.setAttribute('translate', 'no');
      });
    }
  };

  const googleTranslateElementInit = () => {
    if (!window.google || !window.google.translate) return; // Ensure Google Translate is loaded
  
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "ar",
        autoDisplay: true,
        includedLanguages: "en,ar",
        multilanguagePage: true,
      },
      "google_translate_element"
    );
  
    // Observer to ensure Arabic text updates properly
    const observer = new MutationObserver(() => {
      updateArabicOptionText();
    });
  
    observer.observe(document.body, { childList: true, subtree: true });
  
    // Remove unnecessary elements after translation loads
    setTimeout(() => {
      const googleDivChild = document.querySelector("#google_translate_element .skiptranslate div");
      if (googleDivChild?.nextElementSibling) {
        googleDivChild.nextElementSibling.remove();
      }
    }, 500);
  
    // Handle select dropdown changes correctly
    setTimeout(() => {
      const selectElement = document.querySelector(".goog-te-combo") as HTMLSelectElement;
      if (selectElement) {
        selectElement.value = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "en" : "ar";
  
        // Force the change event to trigger translation
        const event = new Event("change", { bubbles: true });
        selectElement.dispatchEvent(event);
  
        setDropdownDirection(language === "Arabic" ? "rtl" : "ltr"); // Set initial direction
        selectElement.addEventListener("change", handleTranslateLanguageChange);
      }
    }, 1000); // Delay to ensure Google Translate loads first
  };
  
  // Ensure this function is globally accessible
  window.googleTranslateElementInit = googleTranslateElementInit;
  

  useEffect(() => {
    const checkGoogleTranslate = () => {
      if (window.google && window.google.translate) {
        googleTranslateElementInit();
      } else {
        setTimeout(checkGoogleTranslate, 500); // Retry in case of delay
      }
    };
  
    if (!window.google) {
      const addScript = document.createElement("script");
      addScript.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      addScript.async = true;
      addScript.defer = true;
      // addScript.crossOrigin = "anonymous";
  
      addScript.onload = () => {
        console.log("Google Translate script loaded.");
        checkGoogleTranslate();
      };
  
      document.body.appendChild(addScript);
    } else {
      checkGoogleTranslate();
    }
  
    // Hide unwanted elements using CSS
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .goog-te-combo option[value=""] {
        display: none;
      }
    `;
    document.head.appendChild(styleElement);
  
    return () => {
      const selectElement = document.querySelector(".goog-te-combo") as HTMLSelectElement;
      selectElement?.removeEventListener("change", handleTranslateLanguageChange);
      
      if (document.head.contains(styleElement)) {
        document.head.removeChild(styleElement);
      }
    };
  }, []);
  

  return (
    <div id="google_translate_element" ref={googleDivRef} className="web_view" onChange={getCurrentTranslateLanguage}></div>
  )
}

export default GTranslator;