import tutor_icon from "../../../Assets/Tutor/TutorImg.svg";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import PhoneInput from "react-phone-input-2";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES
} from "../../../Constants/Constants";
import { isValidPhoneNumber } from "libphonenumber-js";
import "./TutorsCheckOut.scss";
import { useState, useEffect, useCallback, useMemo } from "react";
import { GetSessionStorageData } from "../../../Utilities/Helpers/Helper";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { nullUndefinedEmptyCheck } from "../../../Utilities/Helpers/Helper";
import defaultProfile from "../../../Assets/Authentication/defaultProfile.svg";
import toast from "react-hot-toast";
import Discount_symble_blue from "../../../Assets/Global/Discount_symble_blue.svg";
import Discount_Symbole from "../../../Assets/Global/Discount_Symbole.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TaCDialog from "../../../Components/TaCDialog/TaCDialog";
import { setTermsandConditions } from "../../../Redux/Reducer/Reducer";
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import locationIcon from "../../../Assets/Tutor/location.svg";
import { error } from "console";

const TutorsCheckOut = () => {
  const route = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [userProfileImg, setUserProfileImg] = useState<any>();
  const [userName, setUserName] = useState<any>();
  const [userEmail, setUserEmail] = useState<any>();
  const [userMobile, setUserMobile] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [orderDetailes, setOrderDetailes] = useState([]);
  const [parentProfileData, setParentProfileData] = useState<any>([]);
  const [childProfileData, setChildProfileData] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>([]);
  const [ProfileData, setProfileData] = useState<any>("");
  const [PaymentTotal, setPaymentTotal] = useState<any>("");
  const [DiscountList, setDiscountList] = useState([]);
  const [DiscountArray, setDiscountArray] = useState<any>([]);
  const [DiscountSelect, setDiscountSelect] = useState<any>(route?.state?.discount_id);
  const [TotalPrice, setTotalPrice] = useState<any>("");
  const [DiscountPrice, setDiscountPrice] = useState<any>("");
  const [FreeSlotCount, setFreeSlotCount] = useState<any>("");
  const [FreeSlotList, setFreeSlotList] = useState([]);
  const [CourseOpen, setCourseOpen] = useState(true);
  const [TaCopen, setTaCOpen] = useState<boolean>(false);
  const [aMOpen, setAMOpen] = useState<boolean>(false)
  const [addressMessage, setAddressMessage] = useState<string>("")
  const [courseIndex, setCourseIndex] = useState<any>(route?.state?.courseIndex);
  console.log("🚀 ~ TutorsCheckOut ~ courseIndex:", courseIndex)

  const dispatch = useDispatch();

  const handleClickOpen = (value: any) => {
    if (courseIndex?.course_mode == "Offline") {
      addressValidation(singleAddress, courseIndex?.state)
    } else {
      setOpen(true)
    }
    setViewMode(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAMClose = () => {
    setAMOpen(false)
  }

  const handleCloseDialog = () => {
    setTaCOpen(false)
  }


  const handleChecked = () => {
    dispatch(setTermsandConditions(true))
    handleTermsandConditions()
  }

  const navigatePaymentSuccess = () => {
    slotBookingApi();
  };

  const calculateTotal = useCallback(() => {
    // Use the reduce method to sum the array elements
    const total = route?.state?.tutor_checkOut?.reduce(
      (accumulator: any, currentValue: any) => {
        return accumulator + parseInt(currentValue.slot_price);
      },
      0
    ); // 0 is the initial value of the accumulator

    return total;
  }, [route?.state?.tutor_checkOut])

  const TotalAmount = useMemo(() => calculateTotal(), [route?.state?.tutor_checkOut])

  const calculate_vat = () => {
    return +(0.05 * (calculateTotal() - +(DiscountPrice || 0))).toFixed(0)
  }

  const addressValidation = (id: number, state: any) => {
    let payload = {
      address_id: id || null,
      location: state
    }
    axiosInstance.post(API_END_POINTS.ADDRESS_VALIDATION, payload)
      .then((res: any) => {
        if (res?.data?.status) {
          if (res?.data?.result?.data?.is_book) {
            setOpen(true)
          } else {
            setAddressMessage(res?.data?.message)
            setAMOpen(true)
          }
        }
      })
      .catch(() => { })
  }

  /** handle Discount*/
  const handleDiscount = (option: any) => {
    axiosInstance
      .get(`${API_END_POINTS.VALIDATE_DISCOUNT + option}`)
      .then((Response: any) => {
        if (Response?.data?.result?.is_discount) {
          DiscountSelect == option
            ? setDiscountSelect("")
            : setDiscountSelect(option);
        }
      })
      .catch((error) => {
      });
  };

  const handleTermsandConditions = () => {
    axiosInstance
      .post(API_END_POINTS.TERMS_AND_CONDITIONS)
      .then((response) => {
        if (response.data.status) {
        }
      })
      .catch(() => { })
  }


  useEffect(() => {
    if (!route?.state?.free_trial) {
      get_Discount_List();
    }
    if (courseIndex?.course_mode == "Offline") {
      axiosInstance.get(API_END_POINTS.MANAGE_ADDRESS)
        .then((response) => {
          setAllAddress(response?.data?.result?.data)
          setSingleAddress(response?.data?.result?.data[0].id)
        }).catch(() => { })
    }
    groupData();
    getProfileList();
    setProfileData(route?.state?.tutor_del);
    setUserProfileImg(route?.state?.childProfileData?.profile_image);
    setUserName(route?.state?.childProfileData?.name);
    setUserEmail(GetSessionStorageData(AUTHENTICATION.SHARED.EMAIL));
    setPaymentTotal(calculateTotal());
  }, []);

  useEffect(() => {
    if (!route?.state?.free_trial) {
      var oneSlot_price = route?.state?.tutor_checkOut[0]?.slot_price;
      var Original_price = calculateTotal();
      var Vat_price: number = calculate_vat()
      var Free_slot: any = FreeSlotList?.filter(
        (item: any) => item?.id === DiscountSelect
      );
      var discount_amount = Free_slot[0]?.free_slots * oneSlot_price;
      var Total_Amount = Original_price - discount_amount + Vat_price;
      setDiscountPrice(discount_amount);
      setTotalPrice(Total_Amount);
      setFreeSlotCount(Free_slot[0]?.free_slots);
    }
  }, [DiscountSelect, FreeSlotList]);

  /**Get Discount List*/
  const get_Discount_List = () => {
    axiosInstance
      .get(
        `${API_END_POINTS.DISCOUNT_LIST +
        "?tutor_subject_id=" +
        route?.state?.tutor_sesstion_id
        }`
      )
      .then((Response: any) => {
        if (Response?.data?.status) {
          setFreeSlotList(Response?.data?.result?.data);
          if (nullUndefinedEmptyCheck(route?.state?.discount_id)) {
            var Array = Response?.data?.result?.data?.filter(
              (item: any) => item?.id === route?.state?.discount_id
            );
            setDiscountArray(Array);
          } else {
            var Array = Response?.data?.result?.data?.filter(
              (item: any) =>
                item?.total_slots <= route?.state?.tutor_checkOut?.length
            );
            setDiscountList(Array);
          }
        }
      })
      .catch(() => { });
  };

  /**Get profile data */
  const getProfileList = () => {
    var selectArray: any = [];
    axiosInstance
      .get(`${API_END_POINTS.PROFILE_LIST}`)
      .then((profileListResponse: any) => {
        if (
          profileListResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          setParentProfileData(profileListResponse.data.result.data.parent);
          setChildProfileData(profileListResponse?.data?.result?.data?.child);
          profileListResponse.data.result.data.parent?.map((item: any) =>
            selectArray.push(item)
          );
          profileListResponse?.data?.result?.data?.child?.map((item: any) =>
            selectArray.push(item)
          );
          var SelectedArr = selectArray?.filter(
            (item: any) => item.id == route?.state?.childProfileData?.id
          );
          if (route?.state?.free_trial) {
            setSelectedOption(profileListResponse.data.result.data.parent[0]);
          } else {
            setSelectedOption(SelectedArr[0]);
          }
          setUserMobile(
            profileListResponse?.data?.result?.data?.parent[0]?.phone_number
          );
        }
      })
      .catch(() => { });
  };

  const payload = () => {
    var transformedData: any = [];
    // Iterate through the input response and transform each item
    route?.state?.tutor_checkOut.forEach((item: any) => {
      transformedData?.push({
        time_slot_id: item.id,
        start_time: item.start_time,
        end_time: item.end_time,
        slot_price: item.slot_price,
        start_date: moment(item.start).format(
          GLOBAL_CONSTANTS.INITIAL_VALUES.DATE_FORMAT
        ),
        session_slot_id: item.session_slot,
        session_id: item?.session_id,
      });
    });
    const transformedSlotIds = route?.state?.tutor_checkOut?.map((item: any) =>
      item.id.toString()
    );

    var Response = {
      child_id: selectedOption?.is_primary
        ? null
        : selectedOption?.id.toString(),
      // service_provider_id:
      //   route?.state?.tutor_checkOut[0]?.service_provider_id.toString(),
      teach_service_provider_id:
        route?.state?.tutor_checkOut[0]?.teach_service_provider_id.toString(),
      // session_title: route?.state?.tutor_checkOut[0]?.title,
      sp_user_id: route?.state?.tutor_checkOut[0]?.user_id,
      booked_email: userEmail,
      booked_phone_number: userMobile,
      time_slot_ids: transformedSlotIds,
      slot_count: route?.state?.tutor_checkOut?.length.toString(),
      payment_mode: GLOBAL_CONSTANTS.INITIAL_VALUES.CARD,
      payment_type: GLOBAL_CONSTANTS.INITIAL_VALUES.ONLINE,
      booked_slot_detail: transformedData,
      course_type:
        courseIndex?.course_mode == "Online"
          ? GLOBAL_CONSTANTS.INITIAL_VALUES.ONLINE
          : courseIndex?.course_mode,
      is_discount: nullUndefinedEmptyCheck(DiscountSelect) ? true : false,
      discount_id: nullUndefinedEmptyCheck(DiscountSelect)
        ? DiscountSelect
        : null,
      free_slots_count: nullUndefinedEmptyCheck(DiscountSelect)
        ? FreeSlotCount
        : "",
      total_amount: calculateTotal(),
      discount_amount: nullUndefinedEmptyCheck(DiscountSelect)
        ? DiscountPrice
        : 0.0,
      final_amount: nullUndefinedEmptyCheck(DiscountSelect)
        ? TotalPrice
        : calculateTotal(),
      free_session: GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE,
      is_primary: selectedOption?.is_primary ? true : false,
      parent_id: selectedOption?.is_primary ? selectedOption?.id : null,
      vat_percent: 5,
      vat_amount: calculate_vat() || 0,
      vat_total_amount: TotalPrice ? TotalPrice : calculateTotal() - +(DiscountPrice || 0) + calculate_vat() || 0,
      user_address: singleAddress || null,
      commission: courseIndex?.commission || null,
    };

    return Response;
  };

  const Free_trial_payload = () => {
    var Response = {
      child_id: null,
      service_provider_id:
        route?.state?.free_trial_data?.service_provider_id?.toString(),
      teach_service_provider_id:
        route?.state?.free_trial_data?.teach_service_provider_id?.toString(),
      session_title: route?.state?.free_trial_data?.title,
      booked_email: userEmail,
      booked_phone_number: userMobile,
      time_slot_ids: [route?.state?.free_trial_data?.id?.toString()],
      slot_count: "1",
      payment_mode: GLOBAL_CONSTANTS.INITIAL_VALUES.CARD,
      payment_type: GLOBAL_CONSTANTS.INITIAL_VALUES.ONLINE,
      booked_slot_detail: [
        {
          time_slot_id: route?.state?.free_trial_data?.id,
          start_time: route?.state?.free_trial_data?.start_time,
          end_time: route?.state?.free_trial_data?.end_time,
          slot_price: route?.state?.free_trial_data?.slot_price,
          start_date: route?.state?.free_trial_data?.session_slot_date,
          session_slot_id: route?.state?.free_trial_data?.session_slot,
          session_id: route?.state?.free_trial_data?.session_id,
        },
      ],
      tutor_id: route?.state?.free_trial_data?.tutor_id,
      course_type: GLOBAL_CONSTANTS.INITIAL_VALUES.ONLINE,
      is_discount: GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE,
      discount_id: null,
      free_slots_count: "1",
      total_amount: 0.0,
      discount_amount: 0.0,
      final_amount: 0.0,
      sp_user_id: route?.state?.free_trial_data?.user_id,
      free_session: GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE,
      is_primary: GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE,
      parent_id: selectedOption?.is_primary ? selectedOption?.id : null,
      vat_percent: 5,
      vat_amount: calculate_vat() || 0,
      vat_total_amount: TotalPrice ? TotalPrice : calculateTotal() - +(DiscountPrice || 0) + calculate_vat() || 0,
      commission: courseIndex?.commission || null,
    };

    return Response;
  };

  /**Post slot booking Api */
  const slotBookingApi = () => {
    var pay = route?.state?.free_trial ? Free_trial_payload() : payload();
    axiosInstance
      .post(`${API_END_POINTS.SLOT_BOOKING}`, pay)
      .then((Response: any) => {
        if (Response?.data?.status) {
          toast.success(Response?.data?.message, {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          });
          handleClose();
          navigate(ROUTES.TUTORS_PAYMENT_SUCCESS, {
            state: {
              calender_link: Response?.data?.result?.data,
              ProfileData: ProfileData,
              PaymentTotal: TotalPrice ? TotalPrice : calculateTotal() - +(DiscountPrice || 0) + calculate_vat(),
              orderDetailes: orderDetailes,
              free_trial_data: route?.state?.free_trial_data,
              free_trial: route?.state?.free_trial ? true : false,
            },
          });
        }
      })
      .catch((error) => {
        handleClose();
      });
  };

  /**onclick Function to get particular child data*/
  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  // Create a map to store groups
  const groupData = () => {
    const groupedData = new Map();

    route?.state?.tutor_checkOut?.forEach((item: any) => {
      const { title, start_time, end_time } = item;
      const groupKey = `${title}-${start_time}-${end_time}`;

      if (!groupedData.has(groupKey)) {
        groupedData.set(groupKey, { title, start_time, end_time, dates: [] });
      }

      const group = groupedData.get(groupKey);
      group.dates.push(item?.session_slot_date);
    });

    // Convert the map to an array of objects
    const result: any = Array.from(groupedData.values());
    setOrderDetailes(result);
  };

  // An array with one date is considered sorted and continuous.
  const isDateArray = (arr: any) => {
    if (arr.length <= 1) {
      return true;
    }

    for (let i = 1; i < arr.length; i++) {
      const currentDate: any = new Date(arr[i]);
      const previousDate: any = new Date(arr[i - 1]);

      // Check if the current date is not one day after the previous date
      if (currentDate - previousDate !== 24 * 60 * 60 * 1000) {
        return false; // Dates are not continuous.
      }

      // Check if the current date is greater than or equal to the previous date
      if (currentDate <= previousDate) {
        return false; // Dates are not sorted in ascending order.
      }
    }
    return true; // Dates are sorted in ascending order and continuous.
  };

  function formatDatesWithSpaces(dateArray: any) {
    const formattedDates = dateArray.map((dateStr: any) => {
      const [year, month, day] = dateStr.split("-");
      return `${day}/${month}/${year}`;
    });

    return formattedDates.join(", ");
  }

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  const checked: any = useSelector((state: any) => {
    return state.TermsandConditionsReducer.TermsandConditions;
  });

  /*Below class name based on English Arabic*/
  const marginRight_space = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ms-3" : "me-3";
  // onchange for Set address
  const handleAddressChnage = (event: any) => {
    setSingleAddress(event.target.value)
  }

  const [allAddress, setAllAddress] = useState<any>([])
  const [singleAddress, setSingleAddress] = useState<any>()
  return (
    <>
      <div className="tutorcheckout-container">
        <div className="tutoImg_wapper">
          <img src={tutor_icon} alt="" className="img-size1" />
          <label className="checkout-txt mb-2">{t("Checkout")}</label>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="billing-container">
              <label className="billingtext">{t("Billing Contact")}</label>
              <div className="inputfieldss">
                <div className="billng_contact_fields">
                  <label className="emailtext">{t("Email ID")}</label>
                  <input
                    className="inputdesign1"
                    value={userEmail}
                    readOnly
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                    }}
                    placeholder="Enter Your mail address"
                  />
                </div>
                <div className="phone-num">
                  <label className="emailtext">{t("Phone Number")}</label>
                  <PhoneInput
                    inputProps={{
                      className: "mobile__input",
                      name: `${AUTHENTICATION.LOGIN_SIGNUP_FIELDS.MOBILE}`,
                    }}
                    disabled
                    value={userMobile}
                    country={"ae"}
                    countryCodeEditable={GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE}
                    enableSearch={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
                  />
                </div>
              </div>
              <div className="OrderDetails__contants mt-5">
                <div className="Order__Details">{t("Order Details")}</div>
                {route?.state?.free_trial ? (
                  <div className="Order__amount">{t("AED")} 0.00</div>
                ) : (
                  <div className="Order__amount">{t("AED")} {calculateTotal()}</div>
                )}
              </div>

              <div className="OrderDetails__contants mt-2">
                <div className="Order__Details">{t("Total session")}</div>
                <div className="Order__amount">{route?.state?.tutor_checkOut?.length}</div>
              </div>

              {route?.state?.free_trial ? (
                <>
                  <div className="OrderDetails__contants">
                    <div className="left__contant">
                      <div className="date">
                        {route?.state?.free_trial_data?.session_slot_date} to{" "}
                        {route?.state?.free_trial_data?.session_slot_enddate}
                      </div>
                      <div className="course">
                        {route?.state?.free_trial_data.title}
                      </div>
                      <div className="session__count">{t("No. of sessions")} : 1</div>
                    </div>
                    <div className="right__contant">
                      {moment(
                        route?.state?.free_trial_data?.start_time,
                        "HH:mm:ss"
                      ).format("h:mm a")}{" "}
                      to{" "}
                      {moment(
                        route?.state?.free_trial_data?.end_time,
                        "HH:mm:ss"
                      ).format("h:mm a")}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {orderDetailes.slice(0, 2)?.map((data: any) => {
                    return (
                      <>
                        <div className="OrderDetails__contants">
                          <div className="left__contant">
                            {isDateArray(data?.dates) ? (
                              <div className="date">
                                {moment(data?.dates[0]).format("DD/MM/YYYY")} -{" "}
                                {moment(
                                  data?.dates[data?.dates?.length - 1]
                                ).format("DD/MM/YYYY")}
                              </div>
                            ) : (
                              <div className="date">
                                {formatDatesWithSpaces(data?.dates)}
                              </div>
                            )}
                            <div className="course">{data.title}</div>
                            <div className="session__count">
                              {t("No. of sessions")} : {data?.dates?.length}
                            </div>
                          </div>
                          <div className="right__contant">
                            {moment(data?.start_time, "HH:mm:ss").format(
                              "h:mm a"
                            )}{" "}
                            to{" "}
                            {moment(data?.end_time, "HH:mm:ss").format(
                              "h:mm a"
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {orderDetailes.length > 2 && (
                    <div
                      className="OrderDetails__view__more"
                      onClick={() => handleClickOpen(true)}
                    >
                      {" "}
                      {t("View More")}
                    </div>
                  )}
                </>
              )}

              <div className="Course__Details__bottom__contant">
                <div className="title__wapper">
                  <div className="title">{t("Course Details")}</div>
                  {/* <KeyboardArrowDownIcon
                    className={CourseOpen ? "Icon" : "IconInActive"}
                    onClick={() => setCourseOpen(!CourseOpen)}
                  /> */}
                </div>
                {CourseOpen && (
                  <div className="bottom__contant__wapper">
                    {route?.state?.free_trial ? (
                      <div className="bottom__contant">
                        <img
                          src={
                            nullUndefinedEmptyCheck(
                              route?.state?.free_trial_data?.profile_image
                            )
                              ? route?.state?.free_trial_data?.profile_image
                              : tutor_img
                          }
                          alt="" onError={(e: any) => { e.target.src = tutor_img; }}
                        />
                        <div className="name">
                          {route?.state?.free_trial_data?.name}
                        </div>
                      </div>
                    ) : (
                      <div className="bottom__contant">
                        <img
                          src={
                            nullUndefinedEmptyCheck(ProfileData?.profile_image)
                              ? ProfileData?.profile_image
                              : tutor_img
                          }
                          alt="" onError={(e: any) => { e.target.src = tutor_img; }}
                        />
                        <div className="name">
                          {ProfileData?.profile_details?.firstname}{" "}
                          {ProfileData?.profile_details?.lastname}
                        </div>
                      </div>
                    )}
                    <div className="bottom__contant">
                      <div>{t("Course to")}</div>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl>
                          <Select
                            labelId="custom-select-label"
                            id="custom-select"
                            className="child_select__dropdown"
                            value={selectedOption}
                            onChange={handleChange}
                          >
                            {parentProfileData.map(
                              (parentProfileElements: any, i: any) => {
                                return (
                                  <MenuItem
                                    key={i}
                                    value={parentProfileElements}
                                  >
                                    <div className="avatar_name_wrapper">
                                      <div className="avatar_name_active_container">
                                        <img
                                          src={
                                            nullUndefinedEmptyCheck(
                                              parentProfileElements.profile_image
                                            )
                                              ? parentProfileElements.profile_image
                                              : defaultProfile
                                          }
                                          onError={(e: any) => { e.target.src = defaultProfile; }}
                                          alt="Learn and Play"
                                          className="avatar_pic"
                                        />
                                        <div className="profile_name_role">
                                          <div className="profile_name">
                                            {parentProfileElements?.name}
                                          </div>
                                          <div className="role">{t("Parent")}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </MenuItem>
                                );
                              }
                            )}
                            {!route?.state?.free_trial &&
                              childProfileData?.map(
                                (childProfileElements: any, i: any) => {
                                  return (
                                    <MenuItem
                                      key={i}
                                      value={childProfileElements}
                                    >
                                      <div className="avatar_name_wrapper">
                                        <div className="avatar_name_active_container">
                                          <img
                                            src={
                                              nullUndefinedEmptyCheck(
                                                childProfileElements.profile_image
                                              )
                                                ? childProfileElements.profile_image
                                                : defaultProfile
                                            }
                                            onError={(e: any) => { e.target.src = defaultProfile; }}
                                            alt="Learn and Play"
                                            className="avatar_pic"
                                          />
                                          <div className="profile_name_role">
                                            <div className="profile_name">
                                              {childProfileElements?.name}
                                            </div>
                                            <div className="role">{t("Kid")}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>

                  </div>
                )}
                {
                  <div className="bottom__contant__wapper">

                    <div className="d-flex flex-column ">

                      <p className="name mb-0 " style={{ fontWeight: "500" }}>{t("SP Teaching Location")}</p>
                      <div className="d-flex flex-row gap-2 mb-0 text-font"> <p className=" mb-0">{t("State :")}</p>
                        {courseIndex?.state?.map((res: any, index: number) => (
                          <span key={index} className="mb-0" style={{ fontSize: "14px" }}>
                            {res?.state}
                            {index < courseIndex.state.length - 1 && ","}
                          </span>
                        ))}</div>
                      <div className="d-flex flex-row flex-start gap-2  mb-0 "><p className=" mb-0 text-font">{t("Radius :")}</p><span className="text-font">{courseIndex?.state?.length > 0 ? courseIndex?.state[0]?.radius : ""}</span> </div>

                    </div>
                    <div className="bottom__contant">
                      {courseIndex?.course_mode == "Offline" && (
                        <div className="bottom__contant">
                          <div className="text-font">{t("Address")}</div>
                          <Box >
                            <FormControl>
                              <Select
                                key={singleAddress}
                                value={singleAddress}
                                onChange={handleAddressChnage}
                                className="Address_select__dropdown"
                                sx={{ width: "15" }}
                                labelId="custom-select-label"
                                id="custom-select"
                                placeholder="Address"
                              >
                                {console.log(allAddress)}
                                {allAddress?.map((res: any) => (
                                  <MenuItem key={res?.id} value={res?.id} className="d-flex gap-2">
                                    <img src={locationIcon} alt="LocationIcon" width={15} /><span style={{ fontSize: "14px" }}>{res?.address}</span>
                                  </MenuItem>
                                ))}
                                <MenuItem
                                  value=""
                                  onClick={() => {
                                    navigate("/accounts/add-address");
                                  }}
                                  className="d-flex gap-2"
                                >
                                  <AddCircleOutlineSharpIcon className="plus_icon" sx={{
                                    width: "19px"
                                  }} />
                                  <span style={{ fontSize: "14px" }}>Add Address</span>
                                </MenuItem>
                              </Select>

                            </FormControl>
                          </Box>
                        </div>
                      )}
                    </div>


                  </div>
                }
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="summary">
              <div className="CheckOut__discount__container">
                {nullUndefinedEmptyCheck(route?.state?.discount_id) ? (
                  <>
                    {DiscountArray?.map((data: any, i: any) => {
                      return (
                        <>
                          <div
                            className={
                              DiscountSelect == data?.id
                                ? "discount__card__active"
                                : "discount__card"
                            }
                            onClick={() => handleDiscount(data.id)}
                            key={i}
                          >
                            <img
                              src={
                                DiscountSelect == data?.id
                                  ? Discount_Symbole
                                  : Discount_symble_blue
                              }
                              alt=""
                              className="discount__Img"
                            />
                            <div className="card_flex_column">
                              <div className="Black_text">
                                {t("Buy")} {data?.buy_slots} {t("sessions")},
                              </div>
                              <div className="card_flex_row">
                                <div className="blue__text">
                                  {t("Get")} {data?.free_slots}
                                </div>
                                <div className="badge_blue">{t("free")}</div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {DiscountList?.map((data: any, i: any) => {
                      return (
                        <>
                          <div
                            className={
                              DiscountSelect == data?.id
                                ? "discount__card__active"
                                : "discount__card"
                            }
                            onClick={() => setDiscountSelect(data.id)}
                            key={i}
                          >
                            <img
                              src={
                                DiscountSelect == data?.id
                                  ? Discount_Symbole
                                  : Discount_symble_blue
                              }
                              alt=""
                              className="discount__Img"
                            />
                            <div className="card_flex_column">
                              <div className="Black_text">
                                {t("Buy")} {data?.buy_slots} {t("sessions")},
                              </div>
                              <div className="card_flex_row">
                                <div className="blue__text">
                                  {t("Get")} {data?.free_slots}
                                </div>
                                <div className="badge_blue">{t("free")}</div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
              <label className="billingtext">{t("Summary")}</label>
              <div className="Price__detailes__wapper">
                <div className="original-price">
                  <label className="original__price__text">
                    {t("Original Price")}
                  </label>
                  {route?.state?.free_trial ? (
                    <label className="original__price__text">{t("AED")} 0.00</label>
                  ) : (
                    <label className="original__price__text">
                      {t("AED")} {calculateTotal()}
                    </label>
                  )}
                </div>
                <div className="original-price">
                  <label className="discount-text">{t("Discounts")}</label>
                  <label className="discount-text">
                    -{t("AED")} {DiscountPrice ? DiscountPrice : ""}
                  </label>
                </div>
              </div>
              <hr className="hr-tag" />

              <div className="Price__detailes__wapper">
                <div className="original-price">
                  <label className="original__price__text">
                    {t("Total")}
                  </label>
                  {route?.state?.free_trial ? (
                    <label className="original__price__text">{t("AED")} 0.00 </label>
                  ) : (
                    <label className="original__price__text">
                      {t("AED")} {calculateTotal() - +(DiscountPrice || 0)}
                    </label>
                  )}
                </div>
                <div className="original-price">
                  <label className="discount-text">{t("VAT 5%")}</label>
                  <label className="discount-text">
                    +{t("AED")} {calculate_vat().toFixed(2) || ""}
                  </label>
                </div>
              </div>

              <hr className="hr-tag" />
              <div className="Price__detailes__wapper">
                {/* <div className="original-price">
                  <label className="total__small">{t("Total")}</label>
                  {route?.state?.free_trial ? (
                    <label className="total__small">{t("AED")} 0.00</label>
                  ) : (
                    <label className="total__small">
                      {t("AED")} {TotalPrice ? TotalPrice : calculateTotal()}
                    </label>
                  )}
                </div> */}
                <div className="original-price">
                  <label className="total__large">{t("To Pay")}</label>
                  {route?.state?.free_trial ? (
                    <label className="total__large">{t("AED")} 0.00</label>
                  ) : (
                    <label className="total__large">
                      {t("AED")} {TotalPrice ? TotalPrice : calculateTotal() - +(DiscountPrice || 0) + calculate_vat()}
                    </label>
                  )}
                </div>
              </div>
              <div className="summary-btndiv">
                <div className="summary__contant">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={checked}
                    onChange={handleChecked}
                  />
                  <div className="d-flex flex-column">
                    <label className={`paratext ${marginRight_space}`}>
                      {t("By completing your purchase, you agree to these")}
                    </label>
                    <label onClick={() => { setTaCOpen(true) }} className={`terms__text ${marginRight_space}`}>
                      {t("Terms of Services")}
                    </label>
                  </div>

                  <TaCDialog
                    TaCopen={TaCopen}
                    handleCloseDialog={handleCloseDialog}
                  />

                </div>
                <button
                  className={checked ? "proceedBtn" : "proceedBtn__disable"}
                  disabled={checked ? false : true}
                  onClick={() => handleClickOpen(false)}
                >
                  {t("Proceed Payment")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={aMOpen} onClose={handleAMClose}>
        <DialogTitle>
          {t("Note")}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleAMClose}
            aria-label="close"
            sx={{
              position: "absolute",
              top: 6,
              right: 13,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="Tutor__OrderDetails__Dailoge">
            <div className="contant__wapper">
              {addressMessage}
            </div>
          </div>
          <div className="Tutor__bottom__btn">
            <button className="backBtn" onClick={handleAMClose}>
              {t("Back")}
            </button>
          </div>
        </DialogContent>
      </Dialog>

      {open && (
        <div className="overlay-ModelBackround">
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
              {viewMode ? t("Order summary") : t("Confirm Order Summary")}
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                sx={{
                  position: "absolute",
                  top: 6,
                  right: 13,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <div className="Tutor__OrderDetails__Dailoge">
                {route?.state?.free_trial ? (
                  <>
                    <div className="contant__wapper">
                      <div className="left__contant">
                        <div className="date">
                          {route?.state?.free_trial_data?.session_slot_date} to{" "}
                          {route?.state?.free_trial_data?.session_slot_enddate}
                        </div>
                        <div className="course">
                          {route?.state?.free_trial_data.title}
                        </div>
                        <div className="session__count">
                          {t("No. of sessions")} : 1
                        </div>
                      </div>
                      <div className="right__contant">
                        {moment(
                          route?.state?.free_trial_data?.start,
                          "HH:mm:ss"
                        ).format("h:mm a")}{" "}
                        to{" "}
                        {moment(
                          route?.state?.free_trial_data?.end,
                          "HH:mm:ss"
                        ).format("h:mm a")}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {orderDetailes?.map((data: any, i: any) => {
                      return (
                        <div className="contant__wapper" key={i}>
                          <div className="left__contant">
                            {isDateArray(data?.dates) ? (
                              <div className="date">
                                {moment(data?.dates[0]).format("DD/MM/YYYY")} -{" "}
                                {moment(
                                  data?.dates[data?.dates?.length - 1]
                                ).format("DD/MM/YYYY")}
                              </div>
                            ) : (
                              <div className="date">
                                {formatDatesWithSpaces(data?.dates)}
                              </div>
                            )}
                            <div className="course">{data.title}</div>
                            <div className="session__count">
                              {t("No. of sessions")} : {data?.dates?.length}
                            </div>
                          </div>
                          <div className="right__contant">
                            {moment(data?.start_time, "HH:mm:ss").format(
                              "h:mm a"
                            )}{" "}
                            to{" "}
                            {moment(data?.end_time, "HH:mm:ss").format(
                              "h:mm a"
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
              <div className="Tutor__bottom__btn">
                {viewMode ? (
                  <button className="backBtn" onClick={handleClose}>
                    {t("Back")}
                  </button>
                ) : (
                  <button className="paymentBtn" onClick={slotBookingApi}>
                    {t("Proceed to payment")}
                  </button>
                )}
              </div>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default TutorsCheckOut;
