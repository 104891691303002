import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  MarkerF,
  Marker,
  InfoWindow,
  CircleF,
  useJsApiLoader,
} from "@react-google-maps/api";
import "./SchoolMaps.scss";
import { MapProps } from "../../Models/UniversityModel";
import SchoolMapicon from "../../Assets/School/School map.svg";
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import shareIcon from "../../Assets/School/shareIcon.svg";
import LikeIcon from "../../Assets/School/schoolLikeIcon.svg";
import locationIcon from "../../Assets/Tutor/location.svg";
import grades from "../../Assets/Tutor/grades.svg";
import curriculum from "../../Assets/Tutor/curriculum.svg";
import {
  GetSessionStorageData,
  SetSessionStorageData,
  encryptPassData,
  nullUndefinedEmptyCheck,
} from "../../Utilities/Helpers/Helper";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES,
  TUTORMAPCONSTANT,
} from "../../Constants/Constants";
import {
  setLocation,
  setLocationName,
  setLoginSignUpOpen,
} from "../../Redux/Reducer/Reducer";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import toast from "react-hot-toast";
import SchoolFill from "../../Assets/HeartFill/SchoolFill.svg";
import WebShareComponent from "../WebShare/WebShare";
import { useNavigate } from "react-router-dom";

const SchoolMaps = ({
  zoom,
  radius,
  containerStyle,
  disable,
  zoomControl,
  draggable,
  clickableIcons,
  getOneTutor,
  lat,
  lang,
  searchValue,
  filter,
  initFilter,
  initSort,
  language
}: MapProps) => {
  const [currentLocation, setCurrentLocation] = useState<any>(null);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [SchoolList, setSchoolList] = useState<any>([]);
  const [userProfileImg, setUserProfileImg] = useState<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [libraries] = useState([TUTORMAPCONSTANT.LIBRARIES]);

  useEffect(() => {
    const location = GetSessionStorageData(AUTHENTICATION.SHARED.SCHOOL_LOCATION)
    if (location) {
      const parsedlocation = JSON.parse(location)
      setCurrentLocation(parsedlocation)
      dispatch(setLocation(parsedlocation));
    }
    setCurrentLocation({ lat: parseFloat(lat), lng: parseFloat(lang) });
    setUserProfileImg(
      GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_PICTURE)
    );
    if (lat && lang) {
      SchoolListApi();
    }
  }, [lat, lang, searchValue, radius, filter]);

  /**Header search location*/
  const HeaderLoction: any = useSelector((state: any) => {
    return state.locationReducer.location;
  });

  const payload = () => {
    var payload;
    var priceValue: any = [];
    var gradeValue: any = [];
    if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
      priceValue = []
    } else {
      priceValue = {
        min: filter.price[0],
        max: filter.price[1],
      }
    }
    if (initFilter.grade[0] === filter.grade[0] && initFilter.grade[1] === filter.grade[1]) {
      gradeValue = []
    } else {
      gradeValue = {
        min: filter.grade[0],
        max: filter.grade[1],
      }
    }
    return (payload = {
      latitude: lat?.toString(),
      longitude: lang?.toString(),
      radius: filter.radius,
      search: searchValue ? searchValue : "",
      price: priceValue,
      rating: filter.rating,
      curriculum: filter.curriculum,
      grade: gradeValue,
    });

  };

  /**School get List Api*/
  const SchoolListApi = () => {
    var pay = payload();
    axiosInstance
      .post(`${API_END_POINTS.SCHOOLLIST}`, pay)
      .then((Response: any) => {
        setSchoolList(Response?.data?.result?.data);
      })
      .catch(() => { });
  };

  const navigateDetailedView = (Detailes: any) => {
    navigate(ROUTES.SCHOOL_DETAILE_VIEW + "?id=" + encryptPassData(Detailes?.id), {
      state: { tutor_Detailes: Detailes },
    });
  };

  const mapOptions = {
    disableDefaultUI: disable, // This will remove all default UI controls
    zoomControl: zoomControl, // This will specifically remove the zoom control
    draggable: draggable, // Disable dragging the map
    clickableIcons: clickableIcons, // Disable clicking on map icons
    disableDoubleClickZoom: disable,
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  useEffect(() => {
    if (initSort?.price || initSort?.rating) {
      handleProceedSort(initSort?.price, initSort?.rating)
    }
  }, [initSort])

  const handleProceedSort = (checkedPrice: any, checkedRating: any) => {
    if (checkedPrice == 'high') {
      const sortedData = [...SchoolList].sort((a: any, b: any) => b?.least_fee - a?.least_fee);
      setSchoolList(sortedData);
    } else if (checkedPrice == 'low') {
      const sortedData = [...SchoolList].sort((a: any, b: any) => a?.least_fee - b?.least_fee);
      setSchoolList(sortedData)
    } else if (checkedRating == 'high') {
      const sortedData = [...SchoolList].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
      setSchoolList(sortedData)
    } else if (checkedRating == 'low') {
      const sortedData = [...SchoolList].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
      setSchoolList(sortedData)
    }
  }

  const handleChangeFavourites = (val: any, id: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload = {
        service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.FOUR_INT,
        service_provider: id,
        is_favourite: !val,
      };
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            toast.success(Response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
            SchoolListApi();
          }
        })
        .catch((error) => {
          // toast.error(error?.response?.data?.message, {
          //   position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          // });
        });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: TUTORMAPCONSTANT.MAP__ID || "",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "",
    libraries,
  });

  /**Header search location getFormattedAddress Function */
  const geocoder: any = isLoaded ? new window.google.maps.Geocoder() : "";

  const getFormattedAddress = (lat: any, lng: any) => {
    geocoder.geocode(
      { location: { lat, lng } },
      (results: any, status: any) => {
        if (status) {
          var addressComponents = results[GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT]?.address_components;
          if (results[GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT]?.address_components?.length > 0) {
            const regionComponent = addressComponents?.find((component: any) => component?.types.includes("locality"));
            const countryComponent = addressComponents?.find((component: any) => component?.types.includes("country"));
            const countryName = countryComponent ? countryComponent.long_name : null;
            const regionName = regionComponent ? regionComponent.long_name : null;
            SetSessionStorageData(AUTHENTICATION.SHARED.REGION_NAME, regionName);
            SetSessionStorageData(AUTHENTICATION.SHARED.COUNTRY_NAME, countryName);
            dispatch(setLocationName(countryName));
          }
        }
      }
    );
  };

  return isLoaded ? (
    <>
      {/* <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY || ""}
                libraries={["places"]}
            > */}
      <GoogleMap
        id={TUTORMAPCONSTANT.MAP__ID}
        mapContainerStyle={containerStyle}
        zoom={zoom}
        center={currentLocation}
        options={mapOptions}
        onClick={() => setSelectedLocation(null)}
        onMouseOut={() => setSelectedLocation(null)}
      >
        <CircleF
          center={HeaderLoction}
          radius={radius}
          options={{
            fillColor: "#178FFF", // Fill color with opacity
            strokeColor: "rgba(79, 139, 255, 0.60)", // Border color
            strokeOpacity: 0.8, // Border opacity
            strokeWeight: 2, // Border width
            fillOpacity: 0.1, // Fill opacity
          }}
        />
        {HeaderLoction && (
          <MarkerF
            position={HeaderLoction}
            draggable={true}
            zIndex={999}
            shape={{ coords: [17, 17, 18], type: "circle" }}
            icon={{
              scaledSize: new google.maps.Size(40, 40),
              url: userProfileImg,
            }}
            onDragEnd={(e: any) => {
              const locationData: any = {
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
              };
              setCurrentLocation(locationData);
              SetSessionStorageData(AUTHENTICATION.SHARED.SCHOOL_LOCATION, JSON.stringify(locationData));
              dispatch(setLocation(locationData));
              getFormattedAddress(e?.latLng?.lat(), e?.latLng?.lng());
            }}
          />
        )}
        {SchoolList?.map((data: any) => {
          let object = {
            lat: parseFloat(data.latitude),
            lng: parseFloat(data.longitude),
          };
          return (
            <Marker
              position={object}
              icon={{
                scaledSize:
                  lat == data?.latitude && lang == data?.longitude
                    ? new google.maps.Size(65, 65)
                    : new google.maps.Size(40, 40),
                url: SchoolMapicon,
              }}
              onMouseOver={() => setSelectedLocation(data)}
              onClick={() => setSelectedLocation(data)}
              // onMouseOut={() => setSelectedLocation(null)}
              animation={
                lat == data?.latitude && lang == data?.longitude
                  ? window.google.maps.Animation.DROP
                  : undefined
              }
            >
              {selectedLocation === data && (
                <InfoWindow
                  onCloseClick={() => setSelectedLocation(null)}
                  options={{
                    maxWidth: 450,
                  }}
                >
                  <div
                    className=""
                    onDoubleClick={() => navigateDetailedView(data)}
                    onMouseEnter={() => setSelectedLocation(data)}
                    onMouseLeave={() => setSelectedLocation(null)}
                  >
                    <div
                      className="school_map_Hover__Card"
                      onClick={() => {
                        if (clickableIcons) {
                          getOneTutor(data)
                        } else {
                          navigateDetailedView(data)
                        }
                      }}
                    >
                      {/* <img
                        src={
                          nullUndefinedEmptyCheck(data?.images)
                            ? data?.images
                            : tutor_img
                        }
                        alt="profile_image"
                        onError={(e: any) => {
                          e.target.src = tutor_img;
                        }}
                        className="profile_image__map_university"
                      /> */}
                      <div className="rightside_contant">
                        <div className="w-100">
                          <div className="user__Detailes">
                            <div className="notranslate user__name" translate="no">
                            { language === "English" ? data?.school_name : data?.arabic_school_name || data?.school_name }
                            </div>
                            {/* <div className="user__icon__wrapper">
                              <WebShareComponent
                                params={
                                  ROUTES.SCHOOL_DETAILE_VIEW +
                                  "?id=" +
                                  btoa(data?.id)
                                }
                              >
                                <img src={shareIcon} alt="shareIcon" />
                              </WebShareComponent>
                              <img
                                src={data.is_favourite ? SchoolFill : LikeIcon}
                                alt=""
                                onClick={() =>
                                  handleChangeFavourites(
                                    data.is_favourite,
                                    data?.user
                                  )
                                }
                              />
                            </div> */}
                          </div>
                          <div className="school_map_card_over_width">
                            <div className="school_map_user__Detailes__location">
                              <div className="School_infowindow__text" title={data?.rating}>
                                {data?.rating ? data?.rating : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                              </div>
                              <img
                                src={locationIcon}
                                alt="locationIcon"
                                className="grayIcons ms-1"
                              />
                              <div className="location_text" title={data?.city}>{data?.city}</div>
                            </div>
                            <div className="mt-1 school_map_user__Detailes__location">
                              <img src={curriculum} alt="curriculum" />
                              <div className="lable curriculam_text" title={data?.curriculam}>{data?.curriculam}</div>
                              <img src={grades} alt="shareIcon" className="" />
                              <div className="lable">{data?.grade ? data?.grade : "--"}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })}
      </GoogleMap>
      {/* </LoadScript > */}
    </>
  ) : (
    <></>
  );
};

export default SchoolMaps;
