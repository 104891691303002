import { Dialog, DialogContent } from "@mui/material";
import "./OtpVerificationDialog.scss";
import AuthScreenCarousel from "../AuthScreenCarousel/AuthScreenCarousel";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { useEffect } from "react";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
} from "../../Constants/Constants";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { toast } from "react-hot-toast";
import {
  SetSessionStorageData,
  nullUndefinedEmptyCheck,
} from "../../Utilities/Helpers/Helper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const OtpVerificationDialog = (otpVerificationProps: any) => {
  /**Initial states */
  const { t } = useTranslation();
  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  const [otp, setOtp] = useState("");
  const [showResend, setShowResend] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );
  const [submitted, setSubmitted] = useState(false);
  const [timer, setTimer] = useState(
    AUTHENTICATION.OTP_VERIFICATION_TIMER.TIMER_INITIAL_VALUE
  );

  useEffect(() => {
    setOtp("");
    setSubmitted(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
  }, [otpVerificationProps.openOtpVerificationDialog]);

  useEffect(() => {
    setTimer(AUTHENTICATION.OTP_VERIFICATION_TIMER.TIMER_INITIAL_VALUE);
  }, [])

  useEffect(() => {
    if (otpVerificationProps.openOtpVerificationDialog) {
      updateTimer();
    }
  }, [timer, otpVerificationProps.openOtpVerificationDialog]);

  /**Update timer */
  const updateTimer = () => {
    setTimeout(() => {
      if (timer > GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT) {
        setTimer(timer - GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT);
      } else {
        setShowResend(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
      }
    }, AUTHENTICATION.OTP_VERIFICATION_TIMER.OTP_VERIFICATION_TIMEOUT);
  };

  /**Resend Otp */
  const resendOtp = () => {
    setShowResend(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
    setTimer(AUTHENTICATION.OTP_VERIFICATION_TIMER.TIMER_INITIAL_VALUE);
    if (otpVerificationProps.isEmail) {
      emailLoginSignUp();
    } else {
      phoneLoginSignUp();
    }
  };

  /**Email Sign up payload */
  const emailSignUpPayload = () => {
    return {
      email: otpVerificationProps.userName,
      roles: AUTHENTICATION.SIGNUP_SIGNIN_API_PAYLOADS.ROLES,
    };
  };

  /**Email Login Signup */
  const emailLoginSignUp = () => {
    if (otpVerificationProps.isLogin) {
      emailLogin(emailSignUpPayload());
    } else {
      emailSignUp(emailSignUpPayload());
    }
  };

  /**Email sign up */
  const emailSignUp = (payload: any) => {
    axiosInstance
      .post(`${API_END_POINTS.TEMP_OTP}?login_type=1`, payload)
      .then((emailSignUpResponse: any) => {
        if (
          emailSignUpResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          toast.success(emailSignUpResponse.data.message, {
            position: "top-right"
          });
        }
      })
      .catch(() => { });
  };

  /**Email login */
  const emailLogin = (payload: any) => {
    axiosInstance
      .post(`${API_END_POINTS.TEMP_OTP_LOGIN}?login_type=1`, payload)
      .then((emailSignUpResponse: any) => {
        if (
          emailSignUpResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          toast.success(emailSignUpResponse.data.message, {
            position: "top-right"
          });
        }
      })
      .catch(() => { });
  };

  /**Phone signup payload */
  const phoneSignUpPayload = () => {
    return {
      phone_number: otpVerificationProps.userName,
      roles: AUTHENTICATION.SIGNUP_SIGNIN_API_PAYLOADS.ROLES,
    };
  };

  /**Phone login signup */
  const phoneLoginSignUp = () => {
    if (otpVerificationProps.isLogin) {
      phoneLogin(phoneSignUpPayload());
    } else {
      phoneSignUp(phoneSignUpPayload());
    }
  };

  /**Phone sign up */
  const phoneSignUp = (payload: any) => {
    axiosInstance
      .post(`${API_END_POINTS.TEMP_OTP}?login_type=0`, payload)
      .then((phoneSignUpResponse: any) => {
        if (
          phoneSignUpResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          toast.success(phoneSignUpResponse.data.message, {
            position: "top-right"
          });
        }
      })
      .catch(() => { });
  };

  /**Phone login */
  const phoneLogin = (payload: any) => {
    axiosInstance
      .post(`${API_END_POINTS.TEMP_OTP_LOGIN}?login_type=0`, payload)
      .then((phoneSignUpResponse: any) => {
        if (
          phoneSignUpResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          toast.success(phoneSignUpResponse.data.message, {
            position: "top-right"
          });
        }
      })
      .catch(() => { });
  };

  /**submit OTP */
  const verifyOtp = () => {
    setSubmitted(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
    if (otpVerificationProps.isEmail) {
      emailOtpVerification(emailOtpVerificationPayload());
    } else {
      phoneOtpVerification(phoneOtpVerificationPayload());
    }
  };

  /**Email OTP verification payload */
  const emailOtpVerificationPayload = () => {
    return {
      otp: otp,
      email: otpVerificationProps.userName,
    };
  };

  /**Phone OTP verification payload */
  const phoneOtpVerificationPayload = () => {
    return {
      otp: otp,
      phone_number: otpVerificationProps.userName,
    };
  };

  /**Email OTP verification */
  const emailOtpVerification = (payload: any) => {
    axiosInstance
      .post(
        otpVerificationProps.isLogin
          ? `${API_END_POINTS.OTP_VERIFICATION_LOGIN}?login_type=1`
          : `${API_END_POINTS.OTP_VERIFICATION}?login_type=1`,
        payload
      )
      .then((phoneSignUpResponse: any) => {
        if (
          phoneSignUpResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          toast.success(phoneSignUpResponse.data.message, {
            position: "top-right"
          });
          otpVerificationProps.handleCloseOtpVerificationDialog();
          if (!otpVerificationProps.isLogin) {
            otpVerificationProps.setOpenPersonalDetailsDialog(
              GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
            );
          } else {
            SetSessionStorageData(
              AUTHENTICATION.SHARED.ACCESS_TOKEN,
              phoneSignUpResponse.data.result.data.access_token
            );
            SetSessionStorageData(
              AUTHENTICATION.SHARED.REFRESH_TOKEN,
              phoneSignUpResponse.data.result.data.refresh_token
            );
            SetSessionStorageData(
              AUTHENTICATION.SHARED.NAME,
              phoneSignUpResponse.data.result.data.user_info.name
            );
            SetSessionStorageData(
              AUTHENTICATION.SHARED.EMAIL,
              phoneSignUpResponse.data.result.data.user_info.email
            );
            SetSessionStorageData(
              AUTHENTICATION.SHARED.MOBILE,
              phoneSignUpResponse.data.result.data.user_info.phone_number
            );
            SetSessionStorageData(
              AUTHENTICATION.SHARED.PARENT_DOB,
              phoneSignUpResponse.data.result.data.user_info.dob
            );
            SetSessionStorageData(
              AUTHENTICATION.SHARED.USER_ID,
              phoneSignUpResponse.data.result.data.user_info.id
            );
            SetSessionStorageData(
              AUTHENTICATION.SHARED.ACCOUNT_TYPE,
              AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT
            );
            if (
              nullUndefinedEmptyCheck(
                phoneSignUpResponse.data.result.data.user_info.image
              )
            ) {
              SetSessionStorageData(
                AUTHENTICATION.SHARED.PROFILE_PICTURE,
                phoneSignUpResponse.data.result.data.user_info.image
              );
            }
          }
        }
      })
      .catch(() => { });
  };

  /**Phone OTP verification */
  const phoneOtpVerification = (payload: any) => {
    axiosInstance
      .post(
        otpVerificationProps.isLogin
          ? `${API_END_POINTS.OTP_VERIFICATION_LOGIN}?login_type=0`
          : `${API_END_POINTS.OTP_VERIFICATION}?login_type=0`,
        payload
      )
      .then((phoneSignUpResponse: any) => {
        if (
          phoneSignUpResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          toast.success(phoneSignUpResponse.data.message, {
            position: "top-right"
          });
          otpVerificationProps.handleCloseOtpVerificationDialog();
          if (!otpVerificationProps.isLogin) {
            otpVerificationProps.setOpenPersonalDetailsDialog(
              GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
            );
          } else {
            SetSessionStorageData(
              AUTHENTICATION.SHARED.ACCESS_TOKEN,
              phoneSignUpResponse.data.result.data.access_token
            );
            SetSessionStorageData(
              AUTHENTICATION.SHARED.ACCOUNT_TYPE,
              AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT
            );
            SetSessionStorageData(
              AUTHENTICATION.SHARED.REFRESH_TOKEN,
              phoneSignUpResponse.data.result.data.refresh_token
            );
            SetSessionStorageData(
              AUTHENTICATION.SHARED.NAME,
              phoneSignUpResponse.data.result.data.user_info.name
            );
            SetSessionStorageData(
              AUTHENTICATION.SHARED.EMAIL,
              phoneSignUpResponse.data.result.data.user_info.email
            );
            SetSessionStorageData(
              AUTHENTICATION.SHARED.PARENT_DOB,
              phoneSignUpResponse.data.result.data.user_info.dob
            );
            if (
              nullUndefinedEmptyCheck(
                phoneSignUpResponse.data.result.data.user_info.image
              )
            ) {
              SetSessionStorageData(
                AUTHENTICATION.SHARED.PROFILE_PICTURE,
                phoneSignUpResponse.data.result.data.user_info.image
              );
            }
          }
        }
      })
      .catch(() => { });
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      verifyOtp();
      event.preventDefault();
    }
  };
  const skip_button = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "skip_button" : "skip_button_ar";
  return (
    <>
      <Dialog open={otpVerificationProps.openOtpVerificationDialog}
        className="otp-verify-modal-container"
      >
        <DialogContent dir={
          language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
            ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
            : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
        }>
          <div className="carousel_login_navigations_wrapper">
            <div>
              <span className={skip_button} onClick={otpVerificationProps.handleBack}>{t("Back")}</span>
              {/* <span className="skip_button" onClick={otpVerificationProps.handleCloseOtpVerificationDialog}>Skip</span> */}
            </div>
            <div className="carousel_wrapper">
              <AuthScreenCarousel />
            </div>
            <div className="login_signup_navigations_wrapper">
              <div className="otp_verification_wrapper">
                <div className="otp_verificatin_title">{t("OTP Verification")}</div>
                <div className="otp_sent_to_wrapper">
                  {t("Enter the OTP code sent to")}{" "}
                  <span className="email_phone">
                    {otpVerificationProps.userName}
                  </span>
                </div>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  containerStyle="otp_container"
                  inputStyle="input_container"
                  inputType='tel'
                  renderSeparator={<span></span>}
                  shouldAutoFocus={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
                  renderInput={(props, index) => (
                    <input key={index}  {...props} onKeyDown={handleKeyPress} />
                  )}
                />
                {submitted && otp === "" && (
                  <div className="errorMessageOtp">
                    {
                      AUTHENTICATION.LOGIN_SIGNUP_FIELDS_ERROR_MESSAGES
                        .OTP_REQUIRED
                    }
                  </div>
                )}
                {!showResend && (
                  <div className="resend_timer">{t("Resend in")} {timer} {t("secs")}</div>
                )}
                <div className="verfiy_submit_button__wapper">
                  <button onClick={verifyOtp} className="verfiy_submit_button">
                    {t("Verify & Proceed")}
                  </button>
                </div>
                {showResend ? (
                  <div className="resend_otp_button">
                    {t("otpReceive")}{" "}
                    <span className="resend_otp" onClick={resendOtp}>
                      {t("Resend OTP")}
                    </span>
                  </div>
                ) : (
                  <div className="resend_otp_button">
                    {t("otpReceive")}{" "}
                    <span className="resend_otp_button">
                      {t("Resend OTP")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OtpVerificationDialog;
