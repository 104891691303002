import "./ActivitiesDashboardLayout.scss";
import activitiesIcon from "../../Assets/Activities/activitiesIcon.svg";
import search from "../../Assets/Tutor/searchIconGray.svg";
import FilterIcon from "../../Assets/Tutor/FilterIcon.svg";
import cricket from "../../Assets/Activities/cricket.svg";
import basketball from "../../Assets/Activities/basketball-svg.png";
import basketball_player from "../../Assets/Activities/basketball-player-svg.png";
import footballImg from "../../Assets/Activities/footballImg.png";
import { Autocomplete, TextField } from "@mui/material";
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Rating from "@mui/material/Rating";
import ActivitiesMaps from "../../Components/ActivitiesMaps/ActivitiesMaps";
import ActivitiesCardsCarousel from "../../Components/ActivitiesCardsCarousel/ActivitiesCardsCarousel";
import { useLocation, useNavigate } from "react-router-dom";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, FILTER_CONSTANT, ROUTES } from "../../Constants/Constants";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { GetSessionStorageData, SetSessionStorageData, encryptPassData, getCurrentLocation, isObjectEmptyValue, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from "./Dropdown";
import { store } from "../../Redux/Store/Store";
import { setActivitiesFilter, setInitActivitiesFilter, setLoader } from "../../Redux/Reducer/Reducer";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import axios from "axios";
import ExpandMap from "../../Assets/Activities/ExpandMap.svg";
import { useSelector } from "react-redux";
import ActivitesCategoryPopup from "./ActivitesCategoryPopup/ActivitesCategoryPopup";
import AppFilter from "../../Components/AppFilter.tsx/AppFilter";
import { useTranslation } from "react-i18next";


// Render the  Map view Page size
const containerStyle = {
    width: '100%',
    height: '100%'
};

const ActivitiesDashboardLayout = () => {
    const activity_search = GetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.ACTIVITY);
    const route = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState(activity_search ? activity_search : "");
    const [activitiesList, setActivitiesList] = useState<any>([]);
    const [searchTextList, setSearchTextList] = useState([]);
    const [currentLat, setCurrentLat] = useState<any>('');
    const [currentLang, setCurrentLang] = useState<any>('');
    const [FilterData, setFilterData] = useState<any>([]);
    const [IsTrigger, setIsTrigger] = useState<any>(null);
    const [zoomSize, setZoomSize] = useState<any>(12);
    const [Category, setCategory] = useState<any>([]);
    const [ResetBoolean, setResetBoolean] = useState(false);
    const [itemsToShow, setItemsToShow] = useState<any>(7);
    const [CategoryPopOpen, setCategoryPopOpen] = useState(false);
    const [promotionalContent, setPromotionalContent] = useState([]);

    const getActivityFilter: any = useSelector((state: any) => {
        return state.filterReducer?.activityfilter
    });

    const init_ActivityFilter: any = useSelector((state: any) => {
        return state.initFilterReducer?.initActivityfilter
    });

    // Filter
    const init_filter = {
        activities: [],
        gender: [],
        price: [0, 0],
        age: [0, 0],
        experience: [0, 0],
        rating: 0,
        available: [],
        time: [0, 24],
        radius: 10
    }
    const [FilterPopOpen, setFilterPopOpen] = useState(false);
    const [initFilter, setInitFilter] = useState<any>(isObjectEmptyValue(init_ActivityFilter) ? init_ActivityFilter : init_filter);
    const [FilterApplyData, setFilterApplyData] = useState<any>(init_filter);
    const [initSort, setSort] = useState<any>({
        price: '',
        rating: ''
    });


    const onOpenFilterPop = () => {
        setFilterPopOpen(true);
    };

    const onCloseFilterPop = () => {
        setFilterPopOpen(false);
    };


    const handleShowMore = () => {
        // Increase the number of items to show when "Show More" is clicked
        // setItemsToShow(itemsToShow + 2); // You can change the number as needed
        setCategoryPopOpen(true);
    };

    const handleShowLess = () => {
        setItemsToShow(7);
    }

    // Route to the Map view Page
    const handleRouteMap = () => {
        navigate(ROUTES.ACTIVITY_MAPS_VIEW,
            {
                state: {
                    activities_list: activitiesList, search_text: searchValue,
                    redius: FilterApplyData.radius, lat: currentLat, lang: currentLang, filter: FilterApplyData, filterBoolean: IsTrigger,
                }
            });
    }

    /**Header search location*/
    const HeaderLoction: any = useSelector((state: any) => {
        return state.locationReducer.location;
    });

    useEffect(() => {
        if (nullUndefinedEmptyCheck(HeaderLoction?.lat && HeaderLoction?.lng)) {
            setCurrentLat(HeaderLoction?.lat);
            setCurrentLang(HeaderLoction?.lng)
            if (getActivityFilter && isObjectEmptyValue(getActivityFilter)) {
                ActivitiesListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, getActivityFilter);
            } else {
                ActivitiesListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, FilterApplyData);
            }
        }
    }, [HeaderLoction, FilterApplyData, searchValue])

    useEffect(() => {
        // Get current user's location using the browser's Geolocation API
        // getCurrentLocation(successCallback, errorCallback);
        getPromotionalContentData();
        getFilterData();
        get_Categories();
        // setSelectedCheckExperience(route.state?.filter?.selectedCheckExperience ? route.state?.filter?.selectedCheckExperience : "")
        // setSelectedCheckGender(route.state?.filter?.selectedCheckGender ? route.state?.filter?.selectedCheckGender : "")
        // setSelectedCheckActivities(route.state?.filter?.selectedCheckActivities ? route.state?.filter?.selectedCheckActivities : "")
        // setStartRating(route.state?.filter?.startRating ? route.state?.filter?.startRating : "")
        // setPriceRangeValue(route.state?.filter?.PriceRangevalue ? route.state?.filter?.PriceRangevalue : "")
    }, [])

    /**Get Course Categories List*/
    const get_Categories = () => {
        axiosInstance
            .get(`${API_END_POINTS.ACTIVITIES_CATEGORIES}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    setCategory(Response?.data?.result?.data)
                }
            })
            .catch(() => { });
    };

    const timeFormat = (value: number) => {
        if (value === 24) {
            return `23:59`;
        }
        const hours = Math.floor(value);
        const minutes = (value % 1) * 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

    /**Activities List Api Payload*/
    const payload = (latitude: any, longitude: any, value: string, filter: any) => {
        var payload;
        var priceValue: any = [];
        var experienceValue: any = [];
        var ageValue: any = [];
        var timeValue: any = [];
        if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
            priceValue = []
        } else {
            priceValue = {
                min: filter.price[0],
                max: filter.price[1],
            }
        }
        if (initFilter.experience[0] === filter.experience[0] && initFilter.experience[1] === filter.experience[1]) {
            experienceValue = []
        } else {
            experienceValue = {
                min: filter.experience[0],
                max: filter.experience[1],
            }
        }
        if (initFilter.age[0] === filter.age[0] && initFilter.age[1] === filter.age[1]) {
            ageValue = []
        } else {
            ageValue = {
                min: filter.age[0],
                max: filter.age[1],
            }
        }
        if (initFilter.time[0] === filter.time[0] && initFilter.time[1] === filter.time[1]) {
            timeValue = []
        } else {
            timeValue = {
                min: timeFormat(filter.time[0]),
                max: timeFormat(filter.time[1]),
            }
        }
        return payload = {
            latitude: latitude?.toString(),
            longitude: longitude?.toString(),
            radius: filter.radius,
            search: value ? value : "",
            price: priceValue,
            experience: experienceValue,
            age: ageValue,
            time: timeValue,
            activities: filter.activities,
            gender: filter.gender,
            available: filter.available,
            rating: filter.rating,
        }
    }

    /**Activities get List Api*/
    const ActivitiesListApi = (latitude: any, longitude: any, value: string, filter: any) => {
        var pay = payload(latitude, longitude, value, filter);
        axiosInstance
            .post(`${API_END_POINTS.ACTIVITIESLIST}`, pay)
            .then((Response: any) => {
                setActivitiesList(Response?.data?.result?.data);
                setZoomSize(10);
                setTimeout(() => {
                    setZoomSize(12);
                }, 1000)

            })
            .catch(() => { });
    };

    /**Get Filter Categories List*/
    const getFilterData = () => {
        axiosInstance
            .get(`${API_END_POINTS.ACTIVITIES_FILTERLIST}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    let data = Response?.data?.result?.data;
                    setFilterData(data);
                    if (data) {
                        let init = {
                            activities: [],
                            price: [data.price?.min, data.price?.max],
                            experience: [data.experience?.min, data.experience?.max],
                            gender: [],
                            rating: 0,
                            age: [data.age?.min, data.age?.max],
                            available: [],
                            time: [0, 24],
                            radius: 10,
                        }
                        store.dispatch(setInitActivitiesFilter(init));
                        setInitFilter(init);
                        SetSessionStorageData(FILTER_CONSTANT.INIT_FILTER.ACTIVITY, JSON.stringify(init));
                        if (getActivityFilter && isObjectEmptyValue(getActivityFilter)) {
                            setFilterApplyData(getActivityFilter);
                        } else {
                            setFilterApplyData(init);
                        }
                    }

                }
            })
            .catch(() => { });
    };

    /**Activities Search Keyword Api*/
    const searchActivitiesKeyword = (search: any) => {
        const accessToken = GetSessionStorageData(
            AUTHENTICATION.SHARED.ACCESS_TOKEN
        );
        var payload = {
            search_text: search.trim(),
            activity_tag: FilterApplyData?.activities ?FilterApplyData?.activities?.toString():""
        };
        axios
            .post(`${process.env.REACT_APP_BASE_BACKEND_API_URL + API_END_POINTS.ACTIVITIES_KEY_WORD_SEARCH}`, payload, {
                // headers: {
                //     Authorization: "Bearer " + accessToken,
                // },
            })
            .then((Response: any) => {
                setSearchTextList(Response?.data.result?.data);
            })
            .catch(() => { });
    };

    /**Filter Apply api call Function*/
    const FilterApply = (filter: any) => {
        setFilterApplyData(filter);
        setIsTrigger(true);
        SetSessionStorageData(FILTER_CONSTANT.STATE_FILTER.ACTIVITY, JSON.stringify(filter));
        store.dispatch(setActivitiesFilter(filter));
        navigate(ROUTES.ACTIVITY_LIST_VIEW, {
            state: {
                activities_list: "", search_text: searchValue, filterBoolean: true,
                redius: filter.radius, lat: currentLat, lang: currentLang, map_show: false, filter: filter
            }
        });
    }

    const ActivityApiCall = () => {
        ActivitiesListApi(currentLat, currentLang, searchValue, FilterApplyData);
    }

    /**Filter Reset Function*/
    const FilterReset = () => {
        store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));

        setTimeout(() => {
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
        }, 500)
    }

    /**AutoComplete Onchange Function*/
    const onInputChange = (value: any) => {
        if (nullUndefinedEmptyCheck(value)) {
            setSearchValue(value);
            searchActivitiesKeyword(value);
            SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.ACTIVITY, value);
        } else {
            ActivitiesListApi(currentLat, currentLang, searchValue, FilterApplyData);
            SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.ACTIVITY, '');
        }
    }

    /**AutoComplete submit Function*/
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        //  prevent page refresh
        event.preventDefault();
        ActivitiesListApi(currentLat, currentLang, searchValue, FilterApplyData)
    };

    /**Coursecategory Based filter the Data*/
    const handlePassCoursecategory = (value: any) => {
        if (nullUndefinedEmptyCheck(value)) {
            SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.ACTIVITY, value);
            if (value?.includes('near me')) {
                navigate(ROUTES.ACTIVITY_LIST_VIEW, {
                    state: {
                        activities_list: "", search_text: value, filterBoolean: IsTrigger,
                        redius: FilterApplyData.radius, lat: currentLat, lang: currentLang, map_show: true, filter: FilterApplyData,
                    }
                });
            } else {
                navigate(ROUTES.ACTIVITY_LIST_VIEW, {
                    state: {
                        activities_list: "", search_text: value, filterBoolean: IsTrigger,
                        redius: FilterApplyData.radius, lat: currentLat, lang: currentLang, map_show: false, filter: FilterApplyData
                    }
                });
            }
        } else {
            ActivitiesListApi(currentLat, currentLang, value, FilterApplyData)
        }
    }

    const onCloseCategoryPop = () => {
        setCategoryPopOpen(false);
    };

    const getPromotionalContentData = () => {
        axiosInstance
            .get(`${API_END_POINTS.PROMOTIONAL_CONTENT_LIST}?service_provider_type=2`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    let data = Response?.data?.result?.data;
                    data.sort((a: any, b: any) => a.id - b.id);
                    setPromotionalContent(data);
                    // setPromotionalContent(Response?.data?.result?.data);
                }
            })
            .catch(() => { });
    };


    const onNavigate = (content: any) => {
        // navigate(ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + btoa(ActiviesDetailes?.id) + '&type=' + btoa(ActiviesDetailes?.is_service_type), { state: { Activies_Detailes: ActiviesDetailes } });

        let provider_id = content.provider_id
        let type = content?.service_provider_type === 2 ? "personal" : "camp"
        if (nullUndefinedEmptyCheck(provider_id)) {
            navigate(ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(provider_id) + '&type=' + encryptPassData(type));
        } else {
            navigate(ROUTES.ACTIVITY_LIST_VIEW, {
                state: {
                    activities_list: "",
                    search_text: "",
                    redius: FilterApplyData.radius,
                    lat: currentLat,
                    lang: currentLang,
                    map_show: false,
                    filter: FilterApplyData,
                    filterBoolean: IsTrigger,
                },
            });
        }
    };

    const [sortPrice, setSortPrice] = useState("");
    const [sortRating, setSortRating] = useState("")

    useEffect(() => {
        setSortRating(GetSessionStorageData("sort_rating") || "")
        setSortPrice(GetSessionStorageData("sort_price") || "")
        if (sortPrice || sortRating) {
            handleProceedSort(sortPrice, sortRating)
        }
    }, [])

    const handleProceedSort = (checkedPrice: any, checkedRating: any) => {
        if (checkedPrice == 'high') {
            const campData: any = [...activitiesList?.camp].sort((a: any, b: any) => b?.least_price - a?.least_price);
            const personalData: any = [...activitiesList?.personal].sort((a: any, b: any) => b?.least_price - a?.least_price);
            setActivitiesList({
                camp: campData,
                personal: personalData
            });
            setSort({
                price: checkedPrice,
                rating: checkedRating
            })
        } else if (checkedPrice == 'low') {
            const campData: any = [...activitiesList?.camp].sort((a: any, b: any) => a?.least_price - b?.least_price);
            const personalData: any = [...activitiesList?.personal].sort((a: any, b: any) => a?.least_price - b?.least_price);
            setActivitiesList({
                camp: campData,
                personal: personalData
            });
            setSort({
                price: checkedPrice,
                rating: checkedRating
            })
        } else if (checkedRating == 'high') {
            const campData: any = [...activitiesList?.camp].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
            const personalData: any = [...activitiesList?.personal].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
            setActivitiesList({
                camp: campData,
                personal: personalData
            });
            setSort({
                price: checkedPrice,
                rating: checkedRating
            })
        } else if (checkedRating == 'low') {
            const campData: any = [...activitiesList?.camp].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
            const personalData: any = [...activitiesList?.personal].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
            setActivitiesList({
                camp: campData,
                personal: personalData
            });
            setSort({
                price: checkedPrice,
                rating: checkedRating
            })
        }
    }

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });

    /*Below class name based on English Arabic*/
    const Search_icon_top = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "search_button_eng" : "search_button_arb";
    const RowOverideActivityLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "RowOverideActivityLang_Eng" : "RowOverideActivityLang_Arab";

    return (
        <>
            <div className={`${CategoryPopOpen ? 'header_wrapper__blur' : ''}`}>

                {CategoryPopOpen && (
                    <ActivitesCategoryPopup
                        PopOpen={CategoryPopOpen}
                        onClosePop={onCloseCategoryPop}
                        currentLat={currentLat}
                        currentLang={currentLang}
                        CourseData={Category}
                        FilterRadiusvalue={FilterApplyData.radius}
                        FilterApplyData={FilterApplyData}
                    />
                )}
            </div>

            <div className="activities__container">
                {/* <div className="background_circle_1"></div>
                <div className="background_circle_2"></div> */}
                <div className="col-md-12">
                    <div className="activities_Img_wapper mb-2">
                        <img
                            draggable="false"
                            src={activitiesIcon}
                            alt="activitiesImg"
                            className=""
                        />
                        <div className="activities__text">{t("Activities")}</div>
                    </div>

                </div>

                <div className={`${RowOverideActivityLang} row  mb-2 row-overide-activity`}>
                    <div className="col-lg-3 col-md-12 input-style-resp">
                        <div className="search_field_wrapper">
                            <div className="search__autocomplete">
                                <form onSubmit={handleSubmit}>
                                    <Autocomplete
                                        freeSolo
                                        popupIcon
                                        id="free-solo-2-demo"
                                        disableClearable={false}
                                        value={searchValue}
                                        clearIcon={false}
                                        onChange={(e, value) => {
                                            setSearchValue(value);
                                            handlePassCoursecategory(value);
                                            SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.ACTIVITY, value);
                                            // ActivitiesListApi(currentLat, currentLang, value)
                                        }}
                                        options={searchTextList?.map((option: any) => 
                                            language === GLOBAL_CONSTANTS.LANGUAGES.ARABIC ? option?.arabic_tag_name : option?.tag)}
                                        renderOption={(props, option) => (
                                            <Box component='li' sx={[{ px:0.5, py:0.7 }]} {...props} className="notranslate" translate="no">
                                              {option}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={t("Search for Activities")}
                                                onChange={(inputChangeEvent: any) => {
                                                    onInputChange(inputChangeEvent.target.value.trim())
                                                }}
                                                onKeyDown={(e: any) => {
                                                    if (e.key === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                                                        if (searchValue.trim() === '') {
                                                            e.preventDefault();
                                                        } else {
                                                            handlePassCoursecategory(searchValue);
                                                            e.preventDefault();
                                                        }
                                                    }
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'text',
                                                }}
                                            />
                                        )}
                                    />
                                    <img
                                        draggable="false"
                                        src={search}
                                        alt="search"
                                        className={Search_icon_top}
                                        onClick={() => handlePassCoursecategory(searchValue)}
                                    />
                                </form>
                            </div>



                        </div>
                    </div>
                    <div className="col-lg-9 col-md-12 activity-filter-respons">
                        <AppFilter
                            FilterPopOpen={FilterPopOpen}
                            onOpenPop={onOpenFilterPop}
                            onClosePop={onCloseFilterPop}
                            FilterData={FilterData}
                            FilterApply={FilterApply}
                            FilterApplyData={FilterApplyData}
                            initFilter={initFilter}
                            initSort={initSort}
                            handleApplySort={handleProceedSort}
                            type="activity"
                            color="#F0962C" />
                    </div>
                </div>
                <div className="body_scroll_container">
                    {/* <div className="row mt-md-4 mt-5"> */}
                    <div className="row mt-md-4 mt-3">
                        <div className=" col-lg-7 col-md-12  activitiesSports__container">
                            <div className="activitiesSports">
                                {Category?.slice(0, itemsToShow).map((data: any, i: any) => {
                                    return (
                                        <>
                                            <div className="activitiesSports_Img_wapper " key={i} onClick={() => handlePassCoursecategory(data?.category_name)}>
                                                <img
                                                    draggable="false"
                                                    src={nullUndefinedEmptyCheck(data?.display_image) ? data?.display_image : cricket}
                                                    alt="cricket" onError={(e: any) => { e.target.src = cricket; }}
                                                    className=""
                                                />
                                                <span>{data?.category_name}</span>
                                            </div>
                                        </>
                                    )
                                })}
                                {/* {Category.length > 7 ? (
                                <>
                                    {Category?.length > itemsToShow ? (
                                        <div className="show_more_category" onClick={handleShowMore}>Show more</div>
                                    ) : (
                                        <div className="show_more_category" onClick={handleShowLess}>Show less</div>
                                    )}
                                </>
                            ) : ""} */}
                                {Category?.length > itemsToShow ? (
                                    <div className="show_more_category" onClick={handleShowMore}>{t("Show more")}</div>
                                ) : ("")}
                            </div>
                            <div className="row mt-3 activitiesSports__contants">
                                <div className="col-lg-4 col-md-12 col-sm-12 d-flex flex-column flex-column__text">
                                    <div className="text_wapper">
                                        <span className="Sweat">{t("Sweat")}</span><span className="out">{t("it Out")}</span>
                                    </div>
                                    {promotionalContent.length > 0 ? promotionalContent.map((content: any, index: number) => {
                                        if (index === 0) {
                                            return <div className="main_content" onClick={() => onNavigate(content)}>
                                                <img src={content.image} alt="" className="leftImg" onError={(e: any) => { e.target.src = basketball; }} />
                                                <div className="discount__wapper discount_wrap_one">
                                                    <div className="discount__flex__column">
                                                        <div className="discount__text__contant notranslate">
                                                            {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? content?.description : content?.description_ar}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    }) : ""}
                                </div>

                                {promotionalContent.length > 0 ? promotionalContent.map((content: any, index: number) => {
                                    if (index > 0) {
                                        return (
                                            <div className="col-lg-4 col-sm-6 " key={index}>
                                                <div className="main_content" onClick={() => onNavigate(content)}>
                                                    <img src={content.image} alt="" className="rightImg" onError={(e: any) => { e.target.src = footballImg; }} />
                                                    <div className="discount__wapper discount_wrap_two">
                                                        <div className="discount__flex__column">
                                                            <div className="discount__text__contant notranslate">
                                                                {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? content?.description : content?.description_ar}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    }

                                }) : ""}


                                {/* <div className="col-lg-4 col-md-12 col-sm-12 d-flex flex-column flex-column__text">
                                <div className="text_wapper">
                                    <span className="Sweat">Sweat</span><span className="out">it Out</span>
                                </div>
                                <img src={footballImg} alt="" className="leftImg" />
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <img src={basketball} alt="" className="rightImg" />
                            </div>
                            <div className="col-lg-4 col-sm-6 rightImg-two">
                                <img src={basketball_player} alt="" className="rightImg" />
                            </div> */}


                            </div>
                        </div>
                        <div className=" col-lg-5 col-md-12 ">
                            <div className="ActivitiesMaps__container">
                                <div className="ActivitiesMaps__view">
                                    <ActivitiesMaps zoom={zoomSize} radius={FilterApplyData.radius * 1000} lat={currentLat} lang={currentLang} containerStyle={containerStyle} disable={true} zoomControl={false} initSort={initSort}
                                        clickableIcons={false} draggable={false} ActivitiesList={activitiesList} getOneTutor={[]} filter={isObjectEmptyValue(getActivityFilter) ? getActivityFilter : FilterApplyData} searchValue={searchValue} initFilter={initFilter} />
                                    <div className="activity__viewMap" onClick={handleRouteMap}>
                                        <img src={ExpandMap} alt="ExpandMap" />
                                        <span>{t("View on Map")}</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="Activities__Near_sports">
                        <ActivitiesCardsCarousel radius={FilterApplyData.radius * 1000} lat={currentLat} lang={currentLang} ActivityList={activitiesList} ActivityApiCall={ActivityApiCall} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default ActivitiesDashboardLayout;