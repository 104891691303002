import { useEffect, useState } from "react";
import "./SchoolMapView.scss";
import search from "../../../Assets/Tutor/searchIconGray.svg";
import schoolIocn from "../../../Assets/School/schoolIocn.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  API_END_POINTS,
  AUTHENTICATION,
  FILTER_CONSTANT,
  GLOBAL_CONSTANTS,
  ROUTES,
  RadiusMapKm,
} from "../../../Constants/Constants";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Dropdown,
  DropdownMenuItem,
  DropdownNestedMenuItem,
} from "../Dropdown";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { store } from "../../../Redux/Store/Store";
import { setLoader, setSchoolFilter } from "../../../Redux/Reducer/Reducer";
import axios from "axios";
import {
  GetSessionStorageData,
  SetSessionStorageData,
  getCurrentLocation,
  isObjectEmptyValue,
  nullUndefinedEmptyCheck,
} from "../../../Utilities/Helpers/Helper";
import Rating from "@mui/material/Rating";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import FilterIcon from "../../../Assets/Tutor/FilterIcon.svg";
import SchoolMaps from "../../../Components/SchoolMaps/SchoolMaps";
import SchoolModelDilogView from "../SchoolModelDilogView/SchoolModelDilogView";
import { useSelector } from "react-redux";
import TutorMapRadiusIcon from "../../../Assets/School/school__km.svg";
import FilterPopup from "../../../Components/FilterPopup/FilterPopup";
import AppFilter from "../../../Components/AppFilter.tsx/AppFilter";
import { useTranslation } from "react-i18next";

// Render the  Map view Page size
const containerStyle = {
  width: '100vw',
  height: '100vh'
};

const SchoolMapsView = () => {
  const { t } = useTranslation();
  const school_search = GetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.SCHOOL);
  const navigate = useNavigate();
  const route = useLocation();
  const [searchValue, setSearchValue] = useState(school_search ? school_search : '');
  const [triggerVal, setTriggerVal] = useState(false);
  const [dailogSchoolData, setDailogSchoolData] = useState([]);
  const [schoolList, setSchoolList] = useState<any>([]);
  const [searchTextList, setSearchTextList] = useState([]);
  const [currentLat, setCurrentLat] = useState<any>("");
  const [currentLang, setCurrentLang] = useState<any>("");
  const [MapZoom, setMapZoom] = useState(12);
  const getSchoolFilter: any = useSelector((state: any) => {
    return state.filterReducer?.schoolfilter
  })

  const init_Schoolfilter: any = useSelector((state: any) => {
    return state.initFilterReducer.initSchoolfilter
  })


  // Filter
  const init_filter = {
    rating: [],
    price: [0, 0],
    curriculum: [],
    radius: 10,
    grade: [0, 15],
  }
  const [FilterPopOpen, setFilterPopOpen] = useState(false);
  const [FilterData, setFilterData] = useState<any>([]);
  const [FilterApplyData, setFilterApplyData] = useState<any>(init_filter);
  const [radius, setRadius] = useState(init_filter.radius)
  const [initFilter, setInitFilter] = useState<any>(isObjectEmptyValue(init_Schoolfilter) ? init_Schoolfilter : init_filter);
  const [initSort, setSort] = useState<any>({
    price: '',
    rating: ''
  });


  const getOneTutor = (data: any) => {
    setDailogSchoolData(data);
    setTriggerVal(true);
  };

  const onOpenFilterPop = () => {
    setFilterPopOpen(true);
  };

  const onCloseFilterPop = () => {
    setFilterPopOpen(false);
  };



  /**Header search location*/
  const HeaderLoction: any = useSelector((state: any) => {
    return state.locationReducer.location;
  });

  useEffect(() => {
    if (nullUndefinedEmptyCheck(HeaderLoction?.lat && HeaderLoction?.lng)) {
      setCurrentLat(HeaderLoction?.lat);
      setCurrentLang(HeaderLoction?.lng);
      if (getSchoolFilter && isObjectEmptyValue(getSchoolFilter)) {
        SchoolListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, getSchoolFilter);
      } else {
        SchoolListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, FilterApplyData);
      }
    }
  }, [HeaderLoction, route.state?.filter]);

  useEffect(() => {
    getFilterData();

  }, []);

  const payload = (latitude: any, longitude: any, value: string, filter: any) => {
    var payload;
    var priceValue: any = [];
    var gradeValue: any = [];
    if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
      priceValue = []
    } else {
      priceValue = {
        min: filter.price[0],
        max: filter.price[1],
      }
    }
    if (initFilter.grade[0] === filter.grade[0] && initFilter.grade[1] === filter.grade[1]) {
      gradeValue = []
    } else {
      gradeValue = {
        min: filter.grade[0],
        max: filter.grade[1],
      }
    }
    return payload = {
      latitude: latitude?.toString(),
      longitude: longitude?.toString(),
      radius: filter.radius,
      search: value ? value : "",
      price: priceValue,
      rating: filter.rating,
      curriculum: filter.curriculum,
      grade: gradeValue
    };
  };

  /**Tutor get List Api*/
  const SchoolListApi = (latitude: any, longitude: any, value: string, filter: any) => {
    var pay = payload(latitude, longitude, value, filter);
    axiosInstance
      .post(`${API_END_POINTS.SCHOOLLIST}`, pay)
      .then((Response: any) => {
        setSchoolList(Response?.data?.result?.data);
      })
      .catch(() => { });
  };

  /**Tutor Search Keyword Api*/
  const searchSchoolKeyword = (search: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    var payload = {
      search_text: search.trim(),
      latitude: `${currentLat}`,
      longitude: `${currentLang}`,
      radius: radius
    };
    axiosInstance
      .post(`${API_END_POINTS.SCHOOL_KEY_WORD_SEARCH}`, payload)
      .then((Response: any) => {
        setSearchTextList(Response?.data.result?.data);
      })
      .catch(() => { });
  };

  /**Get Filter Categories List*/
  const getFilterData = () => {
    axiosInstance
      .get(`${API_END_POINTS.SCHOOLFILTERLIST}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          let data = Response?.data?.result?.data;
          setFilterData(data);
          if (data) {
            let init = {
              price: [data.price?.min, data.price?.max],
              grade: [data.grade?.min, data.grade?.max],
              rating: [],
              curriculum: [],
              radius: 10,
            }
            setInitFilter(init);
            SetSessionStorageData(FILTER_CONSTANT.INIT_FILTER.SCHOOL, JSON.stringify(init));
            if (getSchoolFilter && isObjectEmptyValue(getSchoolFilter)) {
              setFilterApplyData(getSchoolFilter);
              setRadius(getSchoolFilter.radius);
            } else {
              setFilterApplyData(init);
            }
          }
        }
      })
      .catch(() => { });
  };

  /**Filter Apply api call Function*/
  const FilterApply = (filter_data: any) => {
    setFilterApplyData(filter_data);
    setRadius(filter_data.radius);
    SetSessionStorageData(FILTER_CONSTANT.STATE_FILTER.SCHOOL, JSON.stringify(filter_data));
    store.dispatch(setSchoolFilter(filter_data));
    SchoolListApi(currentLat, currentLang, searchValue, filter_data);
  };

  /**AutoComplete Onchange Function*/
  const onInputChange = (value: any) => {
    store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    if (nullUndefinedEmptyCheck(value)) {
      setSearchValue(value);
      SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.SCHOOL, value);
      if(value.length>2) {
        searchSchoolKeyword(value);
      }
    } 
  };

  /**AutoComplete submit Function*/
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SchoolListApi(currentLat, currentLang, searchValue, FilterApplyData);
  };

  const handleChangeRadiusMapvalue = (value: any) => {

    if (value === 15 || value === 5) {
      setMapZoom(12);
    } else if (value === 50) {
      setMapZoom(10);
    } else if (value === 100) {
      setMapZoom(9);
    }
    setFilterApplyData({ ...FilterApplyData, radius: value })
  };

  // Route to the Dashboard view Page
  const handleRoutedashboard = () => {
    navigate(ROUTES.SCHOOLLAYOUT, {
      state: {
        school_list: schoolList,
        search_text: searchValue,
        redius: FilterApplyData.radius,
        lat: currentLat,
        lang: currentLang,
        filter: FilterApplyData,
      },
    });
  };

  const getLatAndLong = (lat: string, lang: string) => {
    setCurrentLat(lat);
    setCurrentLang(lang);
  };

  const handleProceedSort = (checkedPrice: any, checkedRating: any) => {
    if (checkedPrice == 'high') {
      const sortedData = [...schoolList].sort((a: any, b: any) => b?.least_fee - a?.least_fee);
      setSchoolList(sortedData);
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedPrice == 'low') {
      const sortedData = [...schoolList].sort((a: any, b: any) => a?.least_fee - b?.least_fee);
      setSchoolList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedRating == 'high') {
      const sortedData = [...schoolList].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
      setSchoolList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedRating == 'low') {
      const sortedData = [...schoolList].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
      setSchoolList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    }
  }
  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const Ssearch_Button = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "search_button_eng" : "search_button_arb";

  return (
    <>
      {/* {FilterPopOpen && (
        <FilterPopup
          FilterPopOpen={FilterPopOpen}
          onClosePop={onCloseFilterPop}
          FilterApply={FilterApply}
          FilterApplyData={FilterApplyData}
          initFilter={init_filter}
          type="school"
          color="#FFC812"
        />
      )} */}
      <div className="UniversityMapsView__container">
        <div >
          <SchoolMaps
            zoom={MapZoom}
            radius={FilterApplyData.radius * 1000}
            lat={currentLat}
            lang={currentLang}
            containerStyle={containerStyle}
            disable={false}
            zoomControl={true}
            clickableIcons={true}
            draggable={true}
            getOneTutor={getOneTutor}
            initSort={initSort}
            UniversityList={schoolList}
            filter={isObjectEmptyValue(getSchoolFilter) ? getSchoolFilter : FilterApplyData}
            initFilter={initFilter}
            searchValue={searchValue}
            language = {language}
          />
        </div>

        <div className="UniversityCard_position">
          <div
            className="UniversityCard_Img_wapper"
            onClick={() => handleRoutedashboard()}
          >
            <img draggable="false" src={schoolIocn} />
            <div className="University__text">{t("Schools")}</div>
          </div>
          <div className="search_field_wrapper">
            <div className="search__autocomplete">
              <form onSubmit={handleSubmit}>
                <Autocomplete
                  freeSolo
                  popupIcon
                  id="free-solo-2-demo"
                  disableClearable={false}
                  clearIcon={false}
                  value={searchValue}
                  onChange={(e, value) => {
                    setSearchValue(value);
                    SchoolListApi(currentLat, currentLang, value, FilterApplyData);
                    SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.SCHOOL, value);
                  }}
                  options={searchTextList?.map(
                    (option: any) => language === "English" ? option?.school_name : option?.arabic_school_name || option?.school_name
                  )}
                  renderOption={(props, option) => (
                    <Box component='li' sx={[{ px:0.5, py:0.7}]} {...props} className="notranslate" translate="no">
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // placeholder="Search for School"
                      placeholder={t("Search for School")}
                      onChange={(inputChangeEvent: any) => {
                        onInputChange(inputChangeEvent.target.value.trim());
                      }}
                      onKeyDown={(e: any) => {
                        if (e.key === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                          e.preventDefault();
                          const trimedvalue = searchValue.trim();
                          if (trimedvalue) {
                            SchoolListApi(currentLat, currentLang, searchValue, FilterApplyData);
                          }
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        type: "text",
                      }}
                    />
                  )}
                />
                <img
                  draggable="false"
                  src={search}
                  alt="search"
                  className={Ssearch_Button}
                  onClick={() =>
                    SchoolListApi(currentLat, currentLang, searchValue, FilterApplyData)
                  }
                />
              </form>
            </div>
            <AppFilter
              FilterPopOpen={FilterPopOpen}
              onClosePop={onCloseFilterPop}
              onOpenPop={onOpenFilterPop}
              FilterData={FilterData}
              FilterApply={FilterApply}
              FilterApplyData={FilterApplyData}
              initFilter={initFilter}
              initSort={initSort}
              handleApplySort={handleProceedSort}
              type="school"
              color="#FFC812" />

          </div>

          <div className="schoolCard__RadiusMapKm">
            {RadiusMapKm?.map((item, i) => {
              return (
                <>
                  <div
                    className={
                      FilterApplyData.radius === item.value
                        ? "RadiusMapKm__wapper__active"
                        : "RadiusMapKm__wapper"
                    }
                    key={i}
                    onClick={() => handleChangeRadiusMapvalue(item.value)}
                  >
                    <img src={TutorMapRadiusIcon} alt="" />
                    <span>{item.lable}</span>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <div>
          <SchoolModelDilogView
            triggerModel={triggerVal}
            Detailes={dailogSchoolData}
            schoolList={schoolList}
            close={() => setTriggerVal(false)}
            getLatAndLong={getLatAndLong}
          />
        </div>
      </div>
    </>
  );
};

export default SchoolMapsView;
