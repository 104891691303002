import AliceCarousel from "react-alice-carousel";
import "./TutorCardsCarousel.scss";
import "react-alice-carousel/lib/scss/alice-carousel.scss";

/**Assets */
import card_bg from "../../Assets/Tutor/card_bg.png";
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import share from "../../Assets/Tutor/share.svg";
import favourites from "../../Assets/Tutor/favourites.svg";
import location from "../../Assets/Tutor/location.svg";
import experience from "../../Assets/Tutor/experience.svg";
import teaching_mode from "../../Assets/Tutor/teaching_mode.svg";
import grades from "../../Assets/Tutor/grades.svg";
import curriculum from "../../Assets/Tutor/curriculum.svg";
import rating from "../../Assets/Tutor/rating.svg";
import left from "../../Assets/Tutor/left.svg";
import right from "../../Assets/Tutor/right.svg";
import { useEffect, useRef, useState } from "react";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES } from "../../Constants/Constants";
import { useNavigate } from "react-router-dom";
import Tutorfavourites from "../../Assets/HeartFill/TutorFill.svg";
import toast from 'react-hot-toast';
import { GetSessionStorageData, encryptPassData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import { setLoginSignUpOpen } from "../../Redux/Reducer/Reducer";
import { useDispatch, useSelector } from "react-redux";
import WebShareComponent from "../WebShare/WebShare";
import { useTranslation } from "react-i18next";

const TutorCardsCarousel = ({ radius, lat, lang }: any) => {
  /**Initial states */
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [itemsToSlide, setItemsToSlide] = useState(0);
  const [popularList, setPopularList] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let carousel: any;
  const carouselRef = useRef<any>(null);


  useEffect(() => {

    getPopular_TutorList();
  }, [lat, lang, radius]);

  /**Navigate to tutor deyailed view */
  const navigateTutorDetailedView = (toturDetailes: any) => {
    navigate(ROUTES.TUTORS_DETAILE_VIEW + "?id=" + encryptPassData(toturDetailes?.id), { state: { tutor_Detailes: toturDetailes } });
  }

  /**Navigate to tutor List view */
  const navigateTutorListView = () => {
    navigate(ROUTES.TUTORS_LIST_VIEW, {
      state: {
        tutor_list: popularList, search_text: "",
        redius: radius, lat: lat, lang: lang,
      }
    });
  }


  const slideNext = () => {

    if (activeIndex < totalSlides - (itemsToSlide)) {
      setActiveIndex(activeIndex + itemsToSlide);
      carouselRef.current.slideTo(activeIndex + itemsToSlide);
    }
  };

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  const slidePrev = () => {

    if (activeIndex > 0) {
      setActiveIndex(activeIndex - itemsToSlide);
      carouselRef.current.slideTo(activeIndex - itemsToSlide);

    }
  }

  /**Sync card index on slide */
  const syncActiveIndex = ({ item }: any) => {
    setActiveIndex(item);
  };

  /**Get Popular Tutor List*/
  const getPopular_TutorList = () => {
    axiosInstance
      .get(`${API_END_POINTS.POPULAR_TUTOR_LIST}`, {params:{radius , lat , lang}})
      .then((Response: any) => {
        if (Response?.data?.status) {
          setPopularList(Response?.data?.result?.data);
          setCarouselData(Response?.data?.result?.data.length);
        }
      })
      .catch(() => { });
  };

  const setCarouselData = (count: number) => {
    // const count = carouselRef?.current?.state?.itemsCount; // Change this based on the total number of slides

    const screenWidth = window.innerWidth;
    let itemCount: number;

    if (screenWidth >= 1024) {
      itemCount = 3;
    } else if (screenWidth >= 600) {
      itemCount = 2;
    } else {
      itemCount = 1;
    }

    setItemsToSlide(itemCount);
    setTotalSlides(count);

  }

  const handleChangeFavourites = (val: any, id: any) => {
    const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload = {
        service_provider_type: 1,
        service_provider: id,
        is_favourite: !val
      }
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            toast.success(Response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
            });
            getPopular_TutorList();
          }
        })
        .catch((error) => {
          // toast.error(error?.response?.data?.message, {
          //   position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
          // });
        });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  }

  /**Initial viewList for card Data */
  const viewList = popularList?.map((item: any, i: any) => {
    return (
      <>
        <div className="tutor_card_carousel_wrapper" key={i}>
          <div className="tutor_card_wrapper">
            <div className="cover_image_wrap" onClick={() => navigateTutorDetailedView(item)}>
              <img className="card_bg_img" src={item?.cover_images?.length > 0 ? item?.cover_images[0] : card_bg}
                onError={(e: any) => { e.target.src = card_bg; }} alt="Learn and Play" />
              <img className="tutor_img" src={item?.profile_image ? item?.profile_image : tutor_img}
                onError={(e: any) => { e.target.src = tutor_img; }} alt="Learn and Play" />
            </div>
            <div className="tutor_details_wrapper">
              <div className="name_share_container">
                <div className="name_field notranslate">
                  {
                    language === "English" 
                    ? `${item.profile_details?.firstname || ""} ${item.profile_details?.lastname || ""}`.trim() 
                    : `${item.profile_details?.arabic_firstname || item.profile_details?.firstname} ${item.profile_details?.arabic_lastname || item.profile_details?.lastname}`.trim()
                    
                  }
                  </div>
                <div className="share_fav_button">
                  <WebShareComponent params={ROUTES.TUTORS_DETAILE_VIEW + "?id=" + encryptPassData(item?.id)}>
                    <img src={share} className="mb-1" alt="Learn and Play" />
                  </WebShareComponent>
                  {item.is_favourite ? (<img src={Tutorfavourites} alt="Learn and Play"
                    onClick={() => handleChangeFavourites(item.is_favourite, item?.user)} />) : (<img src={favourites} alt="Learn and Play"
                      onClick={() => handleChangeFavourites(item.is_favourite, item?.user)} />)}
                </div>
              </div>
              <div className="location_exp_mode_container" onClick={() => navigateTutorDetailedView(item)}>
                <div className="tutor_details_logo_container">
                  <img
                    className="tutor_details_logo"
                    src={location}
                    alt="Learn and Play"
                  />
                  <div className="location" title={item.city}>{item.city}</div>
                </div>
                <div className="tutor_details_logo_container">
                  <img
                    className="tutor_details_logo"
                    src={experience}
                    alt="Learn and Play"
                  />
                  <div className="location">{item?.tutor_subjects?.length > 0 ? item?.tutor_subjects[0]?.experience : ""}</div>
                </div>
                <div className="tutor_details_logo_container">
                  <img
                    className="tutor_details_logo"
                    src={teaching_mode}
                    alt="Learn and Play"
                  />
                  <div className="location">{item?.tutor_subjects?.length > 0 ? item?.tutor_subjects[0]?.course_mode : ""}</div>
                </div>
              </div>
              <div className="location_exp_mode_container">
                <div className="tutor_details_logo_container">
                  <img
                    className="tutor_details_logo"
                    src={grades}
                    alt="Learn and Play"
                  />
                  <div className="location" title={item?.tutor_subjects?.length > 0 ? item?.tutor_subjects[0]?.courses
                    : ""}>{item?.tutor_subjects?.length > 0 ? item?.tutor_subjects[0]?.courses : ""}</div>
                </div>
                <div className="tutor_details_logo_container">
                  <img
                    className="tutor_details_logo"
                    src={curriculum}
                    alt="Learn and Play"
                  />
                  <div className="location">{item.curriculum}</div>
                </div>
              </div>
              <div className="name_share_container">
                <div className="charges">{item?.tutor_subjects?.length > 0 ? "AED " + item?.tutor_subjects[0]?.final_fee : ""}</div>
                <div className="share_fav_button">
                  {item.rating ? (<img src={rating} alt="Learn and Play" />) : ('')}
                  <div className="ratings">{item.rating}</div>
                  {/* <img src={rating} alt="Learn and Play" />
                  <div className="ratings">4.2</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  })

  popularList.length > 15 && (
    <>
      {viewList?.push(
        <div className="tutor_card_carousel_exploreAll__wapper"
          onClick={() => navigateTutorListView()}>
          <div className="text">{t("Explore all")}</div>
          <div className="title">{t("Tutor")}</div>
        </div >
      )}
    </>
  );

  
  /*Below class name based on English Arabic*/
  const ArrowNavLanguage = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "arrow_navs_english" : "arrow_navs_arabic";

  return (
    <>
      <div className="tutor_card_carousel_container">
        <div className="arrow_nav_tile_contianer">
          <div className="tutor_carousel_title">
            {t("Find Top Tutors")}
          </div>
          <div className={`arrow_navs me-2 ${ArrowNavLanguage}`}>
            <img
              src={left}
              onClick={slidePrev}
              alt="Learn and Play"
              className={activeIndex > 0 ? "left_arrow " : "disable_arrow"}
            />
            <img
              src={right}
              onClick={slideNext}
              alt="Learn and Play"
              className={activeIndex < totalSlides - (itemsToSlide) ? "right_arrow" : "disable_arrow"}
            />
          </div>
        </div>
        <AliceCarousel
          // infinite
          mouseTracking={false}
          autoWidth
          disableButtonsControls
          items={viewList}
          disableDotsControls
          // activeIndex={activeIndex}
          onSlideChanged={syncActiveIndex}
          // animationDuration={2000} /* some high value here */
          // animationType="fadeout"
          autoPlay={false}
          responsive={{
            0: { items: 1 },
            600: { items: 2 },
            1024: { items: 3 },
          }}
          ref={carouselRef}
        />
      </div>
    </>
  );
};

export default TutorCardsCarousel;
