import "./NotificationsList.scss";
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import React, { useState, useCallback, useEffect } from 'react';
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES, SOCKET } from "../../Constants/Constants";
import { GetSessionStorageData, SetSessionStorageData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { store } from "../../Redux/Store/Store";
import { setErrorMessage, setErrorNotification } from "../../Redux/Reducer/Reducer";

const NotificationsList = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [limit, setlimit] = useState(50);
    const [notificationList, setNotificationList] = useState<any>("");
    const [NewList, setNewList] = useState([]);
    const [SeenList, setSeenList] = useState([]);
    const navigate = useNavigate();
    const [modelOpen, setModelOpen] = useState<boolean>(false)
    const [apiResheduleData, setapiResheduleData] = useState<any>("");

    const notificationUser: any = useSelector((state: any) => {
        return state.notificationRefreshReducer.notificationRefresh;
    });

    const getResheduleList = (data: any) => {

        const payload: any = {
            teach_service_provider_id: data.teach_service_provider_id || "",
            session_id: data.session_id || null,
            start_date: data.date || null,
            start_time: data.start_time || null,
            end_time: data.end_time || null,
            status: "requested",
        }

        axiosInstance.get(API_END_POINTS.MANAGE_RESHEDULE, { params: payload })
            .then((res: any) => {
                if (res?.data?.status) {
                    setapiResheduleData(res?.data?.result.data[0]);
                }
            })
            .catch(() => { })
    }

    const handleReshedule = (data: any, apiResheduleData: any) => {
        console.log("🚀 ~ handleReshedule ~ data:", data)
        let payload = {
            "status": data,
            "booking_id": apiResheduleData?.booking || null,
            "booked_time_slot_id": apiResheduleData?.id || null,
        }

        axiosInstance.put(API_END_POINTS.MANAGE_RESHEDULE, payload)
            .then(() => {
                setModelOpen(false);
                if (data == "accepted") {
                    navigate("/accounts/my-sessions")
                }
                else {
                    navigate("/accounts/my-wallet")
                }
                toast.success("Action Sucess",
                    { position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION })
            })
            .catch(() => { })
    }

    // useEffect(() => {
    //     var user_id = GetSessionStorageData(AUTHENTICATION.SHARED.USER_ID)
    //     const socket = new WebSocket(`${SOCKET.URL_WEB_SOCKET + user_id + "/"}`);

    //     /* Works on socket establishment */
    //     socket.onopen = (event: any) => {
    //         console.log("establishment", event)
    //     };

    //     /* Listen to events for socket server */
    //     socket.onmessage = (event: any) => {
    //         console.log("onmessage", event)
    //     };

    // }, [])

    useEffect(() => {
        getNotification();
        setNotificationList("");
    }, [notificationUser])

    /**Get getNotification  List*/
    const getNotification = () => {
        axiosInstance
            .get(`${API_END_POINTS.NOTIFICATION_LIST + "?page=" + page + "&limit=" + limit}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    setNewList(Response?.data?.result?.data?.new);
                    setSeenList(Response?.data?.result?.data?.seen);
                }
            })
            .catch(() => { });
    };

    /**Get getNotification  Seen*/
    const getNotificationSeen = (id: any) => {
        axiosInstance
            .get(`${API_END_POINTS.NOTIFICATION_SEEN + id}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    setNotificationList(Response?.data?.result?.data);
                    getNotification();
                }
            })
            .catch(() => { });
    };

    /**calculating show time*/
    const calculateTime = (value: any) => {
        const now: any = new Date();
        const time: any = new Date(value);
        const timeDifference = now - time;

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days >= 1) {
            return days === 1 ? '1 day ago' : `${days} days ago`;
        } else if (hours >= 1) {
            return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
        } else if (minutes >= 1) {
            return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
        } else {
            return 'just now';
        }
    }

    const handleOpenNewTab = (url: any, session_slot_id: any, time_slot_id: any) => {
        var payload = {
            session_slot_id: session_slot_id,
            session_time_slot_id: time_slot_id,
            is_attendance: true
        };
        axiosInstance
            .put(`${API_END_POINTS.TUTOR_ATTENDANCE}`, payload)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    // Open a new tab/window
                    window.open(url, "_blank");
                }
            })
            .catch(() => { });
    };
    const blockNavigation = () => {

        axiosInstance.post(`${API_END_POINTS?.BLOCKED_NOTIFICATIO_LIST}`).then((Response: any) => {
            if (Response?.status) {
                if (Response?.data?.result?.data?.is_block) {
                    navigate(ROUTES.HOMEPAGE);
                }

            }
        })

    }
    const handleNavigation = (data: any) => {
        switch (data.key) {
            case "SP session reschedule":
                if (data.redirect_info.user_key) {
                    getResheduleList(notificationList.redirect_info)
                    setModelOpen(true)
                }
                break;
            case 'session_start':
                if (data?.redirect_info?.user_path == "meet_link" && data?.redirect_info?.teach_service_provider_id == GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT) {
                    handleOpenNewTab(data?.redirect_info?.meet_link, data?.redirect_info?.session_slot_id, data?.redirect_info?.time_slot_id)
                } else {
                    // navigate(ROUTES.TUTOR_HUB_DETAILES, {
                    //     state: { Detailes_list: data?.redirect_info, type: data?.redirect_info?.teach_service_provider_id, activeState: GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT },
                    // });
                    // SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, 'My Sessions');
                    // SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, data?.redirect_info?.sender_name);
                    // toast.error('This is on-premises session',
                    //     { position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION }
                    // );
                    store.dispatch(setErrorNotification(true))
                    store.dispatch(setErrorMessage("This is on-premises session"))
                }
                break;
            case 'tutor_join':
                if (data?.redirect_info?.user_path == "session_start") {
                    handleOpenNewTab(data?.redirect_info?.meet_link, data?.redirect_info?.session_slot_id, data?.redirect_info?.time_slot_id)
                }
                break;
            case 'New message':
                if (data?.redirect_info?.user_path == "chat" && data?.redirect_info?.teach_type == "school") {
                    navigate(ROUTES.SCHOOL_CHAT_VIEW, {
                        state: { roomData: data?.redirect_info, is_notification: true, is_data: data },
                    });
                } else if (data?.redirect_info?.user_path == "chat" && data?.redirect_info?.teach_type == "university") {
                    navigate(ROUTES.UNIVERSITY_CHAT_VIEW, {
                        state: { roomData: data?.redirect_info, is_notification: true, is_data: data },
                    });
                } else {
                    navigate(ROUTES.TUTOR_HUB_DETAILES, {
                        state: { Detailes_list: data?.redirect_info, type: data?.redirect_info?.teach_service_provider_id, activeState: GLOBAL_CONSTANTS.INITIAL_VALUES.THREE_INT },
                    });
                    SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, 'My Sessions');
                    SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, data?.redirect_info?.sender_name);
                }
                break;
            case 'document_upload':
                if (data?.redirect_info?.user_path == "document_upload") {
                    navigate(ROUTES.TUTOR_HUB_DETAILES, {
                        state: { Detailes_list: data?.redirect_info, type: data?.redirect_info?.teach_service_provider_id, activeState: GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT },
                    });
                    SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, 'My Sessions');
                    SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, data?.redirect_info?.session_creator_name);
                }
                break;
            case 'session_complete':
                SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, 'My Orders');
                SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
                navigate(ROUTES.MYSESSION, { state: { is_sorted: 'completed', booking_id: data?.redirect_info?.booking_id } });
                break;
            case 'themepark_booking':
                SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, 'My Tickets');
                SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
                navigate(ROUTES.MYTICKETS, { state: { ticket_id: data?.redirect_info?.id } });
                break;
            case 'session_cancellation':
            case 'my_wallet':
            case 'user cancel request accepted':
                SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, 'My Wallet');
                SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
                navigate(ROUTES.MYWALLET);
                break;
            case 'slot_booking':
            case 'Booking confirmation':
            case 'user cancel request rejected':
                if (data?.redirect_info?.user_path == "my_orders") {
                    SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, 'My Orders');
                    navigate(ROUTES.MYSESSION, { state: { is_sorted: 'new', booking_id: data?.redirect_info?.booking_id } });
                } else {
                    SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, "Calendar")
                    SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
                    navigate(ROUTES.CHILDCALENDER, { state: { is_parent: data?.redirect_info?.booked_for_user_auth_id } });
                }
                break;
            case 'favourite':
                SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, 'Favourites');
                SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
                navigate(ROUTES.FAVOURTIES);
                break;
            case 'profile':
            case 'user_register':
            case 'child_switch':
                SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, 'Edit Profile');
                SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
                navigate(ROUTES.EDIT_PROFILE);
                break;
            case 'oneparent_block':
                blockNavigation();
                break;
            case 'all_block':
                blockNavigation();
                break;
            case 'specified_blocked':
                blockNavigation();
                break;
            case 'other_blocked':
                blockNavigation();
                break;
            case 'oneparent_unblock':
                blockNavigation();
                break;
            case 'other_unblocked':
                blockNavigation();
                break;
            case 'child_unblock':
                blockNavigation();
                break;
            case 'child_block':
                blockNavigation();
                break;

            default:
                break;
        }
    }

    return (
        <>
            <div className="Notification__conatiner">
                <div className="row">
                    <div className='col-xl-6 col-lg-6 col-md-6 col-12 left_side_wapper'>
                        {NewList?.length > 0 && (
                            <div className="list__container">
                                <div className="title__wapper">
                                    <span className="text">{t("New")}</span>
                                    <span className="count">{NewList?.length}</span>
                                </div>

                                {NewList?.map((item: any, i) => {
                                    return (
                                        <>
                                            <div className={notificationList?.id === item.id ? "card__wappper__active" : "card__wappper"} key={i} onClick={() => getNotificationSeen(item.id)}>
                                                <div>
                                                    <img src={nullUndefinedEmptyCheck(item.image) ? item.image : tutor_img} alt=""
                                                        onError={(e: any) => { e.target.src = tutor_img; }} className="profile__img" />
                                                </div>

                                                <div className="message__wapper">
                                                    <div className="message">{item?.title}</div>
                                                    <div className="time">{calculateTime(item?.created_on)}</div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}

                            </div>
                        )}
                        {SeenList?.length > 0 && (
                            <div className="list__container">
                                <div className="title__wapper">
                                    <span className="text">{t("Seen")}</span>
                                    <span className="count">{SeenList?.length}</span>
                                </div>

                                {SeenList?.map((item: any, i) => {
                                    return (
                                        <>
                                            <div className={notificationList?.id === item.id ? "card__wappper__active" : "card__wappper"} key={i} onClick={() => setNotificationList(item)}>
                                                <div>
                                                    <img src={nullUndefinedEmptyCheck(item.image) ? item.image : tutor_img} alt=""
                                                        onError={(e: any) => { e.target.src = tutor_img; }} className="profile__img" />
                                                </div>

                                                <div className="message__wapper">
                                                    <div className="message">{item?.title}</div>
                                                    <div className="time">{calculateTime(item?.created_on)}</div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}

                            </div>
                        )}
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-12 right_side_wapper'>
                        {nullUndefinedEmptyCheck(notificationList) ? (
                            <div className="detailed__card" onClick={() => handleNavigation(notificationList)}>
                                <div>
                                    <img src={nullUndefinedEmptyCheck(notificationList?.image) ? notificationList?.image : tutor_img} alt="" className="profile__img" />
                                </div>
                                <span className="message">{notificationList?.title}</span>
                                <p className="message">{notificationList?.description}</p>
                                <div className="bottom_place_wapper">
                                    <div className="time">{calculateTime(notificationList?.created_on)}</div>
                                    <div className="clickBtn">{t("click here")}</div>
                                </div>
                            </div>
                        ) : ""}
                    </div>
                </div>

                <Dialog open={modelOpen} onClose={() => { setModelOpen(false) }}>
                    <DialogTitle>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => { setModelOpen(false) }}
                            aria-label="close"
                            sx={{
                                position: "absolute",
                                top: 6,
                                right: 13,
                            }}
                        >
                            {/* <CloseIcon /> */}
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className="Tutor__Payment__Success__Pop">
                            <div className="Payment__Pop__wapper">
                                {/* <img src={MobileCalenderIcon} alt="" /> */}
                                <div className="text-conflict">{t("This Session is Resheduled by Service Provider , Click Reject or Accept?")}</div>
                            </div>

                            <div className="Payment__bottom__btn">
                                <button className="backBtn" onClick={() => { handleReshedule("rejected", apiResheduleData) }}>
                                    {t("Reject")}
                                </button>
                                <button
                                    className="SuccessBtn"
                                    onClick={() => { handleReshedule("accepted", apiResheduleData) }}
                                >
                                    {t("Accept")}
                                </button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    )
}

export default NotificationsList;