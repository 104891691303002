import { RWebShare } from "react-web-share";
import "./WebShare.scss";
import React from 'react'

const WebShareComponent = ({ children, params }: any) => {
    // Full URL
    const fullURL = window.location.href;

    // Parse the URL
    const urlObject = new URL(fullURL);

    // Extract the base URL
    const baseURL = urlObject.origin;

    return (
        <React.Fragment>
            <RWebShare
                data={{
                    text: "Learn and Play Web Application",
                    url: baseURL + params,
                    title: "Learn and Play",
                }}
                onClick={() => console.log("shared successfully!")}
            >
                {children}
            </RWebShare>
        </React.Fragment>
    );
};

export default WebShareComponent;