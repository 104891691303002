import { useEffect, useState } from "react";
import { API_END_POINTS } from "../../Constants/Constants";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import "./MyTickets.scss";
import { Card } from "@mui/material";
import moment from 'moment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux/es/hooks/useSelector";

const MyTickets = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const route = useLocation();
    const [ticketsList, setTicketsList] = useState([]);
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
      });

    useEffect(() => {
        getTicketsList();
    }, [])

    const getTicketsList = () => {
        axiosInstance.get(API_END_POINTS.MYTICKETS_LIST)
            .then((response: any) => {
                if (response?.data?.status) {
                    if (route?.state?.ticket_id) {
                        var SelectedArr = response?.data?.result?.data?.filter(
                            (item: any) => item?.id == route?.state?.ticket_id
                        );
                        setTicketsList(SelectedArr);
                    } else {
                        setTicketsList(response?.data?.result?.data)
                    }
                    /* setTotalCount(Response?.data?.result?.total_wallet_amount)
                    setPageCount(Response?.data?.result?.total_count) */
                }
            })
            .catch(() => { });
    };

    const onNavigate = (id: any) => {
        navigate(`/accounts/my-tickets/${id}`)
    }

    return (

        <div className="my-tickets-wrapper">
            <div className="card-wrapper">
                {
                    ticketsList?.map((ticket: any, index: number) => {
                        return (
                            <Card className="ticket-card" onClick={() => onNavigate(ticket.id)}>
                                <div className="image-wrapper">
                                    <img className="theme-image" src={ticket.themepark_image[0]} />
                                    <div className="ticket-name-location">
                                        <h5>{
                                            language === "English" ? ticket?.title : ticket?.arabic_name || ticket?.title
                                            }</h5>
                                        <p><LocationOnIcon className="location-icon" />{ticket.city ? ticket.city : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="theme-details mt-2">
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6">
                                            <p className="theme-title">{t("Contact No")}</p>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <p className="theme-value">{ticket.phone_number}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6">
                                            <p className="theme-title">{t("Booked ID")}</p>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <p className="theme-value">{ticket.id}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6">
                                            <p className="theme-title">{t("Booked Date")}</p>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <p className="theme-value">{moment(new Date(ticket.created_on)).format("DD/MM/YYYY")}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6">
                                            <p className="theme-title">{t("Booked By")}</p>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <p className="theme-value">{ticket.parent_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </Card>
                        )
                    })
                }

            </div>

        </div>

    )
}

export default MyTickets;