import React, { useState, useRef, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    CardContent,
    Container,
    Rating,
    styled
} from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import toast from "react-hot-toast";

import {
    API_END_POINTS,
    AUTHENTICATION,
    GLOBAL_CONSTANTS,
    ROUTES,
    getGoogleMapLink
} from "../../../Constants/Constants";
import {
    GetSessionStorageData,
    decrypPassData,
    encryptPassData,
    nullUndefinedEmptyCheck
} from "../../../Utilities/Helpers/Helper";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import {
    setErrorMessage,
    setErrorNotification,
    setGuestSignupOpen,
    setLoginSignUpOpen
} from "../../../Redux/Reducer/Reducer";

import WebShareComponent from "../../../Components/WebShare/WebShare";
import ViewMoreLess from "../../../Components/ViewMoreCommon";

import shareIcon from "../../../Assets/ThemePark/shareIcon.svg";
import themeParkImg from "../../../Assets/ThemePark/themeParkImg-svg.png";
import themeParkIcon from "../../../Assets/SideBar/themeParkActive.svg";
import ThemeMapIcon from "../../../Assets/ThemePark/theme-map-icon.svg";
import PriceRange from "../../../Assets/ThemePark/PriceRange.svg";
import themePark_star from "../../../Assets/ThemePark/themePark_star.svg";
import back_img from "../../../Assets/Tutor/back_img.svg";
import LikeFilled from "../../../Assets/ThemePark/HeartFilled.svg";
import LikeOutline from "../../../Assets/ThemePark/Heartoutline.svg";
import left from "../../../Assets/Tutor/left.svg";
import right from "../../../Assets/Tutor/right.svg";

import "./ThemeParkDetaileView.scss";
import { store } from "../../../Redux/Store/Store";

const ticketDescriptionLength = 120;

const StyledCardContent = styled(CardContent)({
    position: 'absolute',
    bottom: 0,
    width: '100%',
    color: 'white',
    borderRadius: '13px',
    backdropFilter: 'blur(4px)',
    background: 'rgba(0, 0, 0, 0.5)',
});

type Location = { lat: number; lng: number };

const ThemeParkDetaileView = () => {
    const { t } = useTranslation();
    const route = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams(route.search);
    const queryId: any = searchParams.get('id');
    const [activeIndex, setActiveIndex] = useState(0);
    const [ticketActiveIndex, setTicketActiveIndex] = useState(0);
    const [themeParkDetaile, setThemeParkDetaile] = useState<any>(null);
    const [showFullDescription, setShowFullDescription] = useState<boolean[]>([]);
    const carouselRef = useRef<any>(null);
    const TicketcarouselRef = useRef<any>(null);
    //
    const [selectEvent, setSelectEvent] = useState<any>('');
    const [selectTicketEvent, setSelectTicketEvent] = useState<any>('');

    useEffect(() => {
        window.scrollTo(0, 0);
        getOneList();
    }, [queryId]);

    const getOneList = async () => {
        const id = decrypPassData(queryId);
        try {
            const response = await axiosInstance.get(`${API_END_POINTS.THEMEPARK_DETAILED_LIST}${id}`);
            if (response?.data?.status) {
                const data = response?.data?.result?.data;
                setThemeParkDetaile(data);
                setShowFullDescription(Array(data?.ticket_type_list?.length).fill(false));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenMap = () => {
        const themepark = { lat: themeParkDetaile.latitude, lng: themeParkDetaile.longitude };
        const mapsUrl = getGoogleMapLink(themepark);
        if (mapsUrl) window.open(mapsUrl, '_blank');
    };

    const toggleDescription = (index: number) => {
        setShowFullDescription((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = !newStates[index];
            return newStates;
        });
    };

    const handleChangeFavourites = async (val: any, id: any) => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
        if (nullUndefinedEmptyCheck(accessToken)) {
            const payload = {
                service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.SIX_INT,
                service_provider: id,
                is_favourite: !val,
            };
            try {
                const response = await axiosInstance.post(API_END_POINTS.FAVOURITE__ADD, payload);
                if (response?.data?.status) {
                    getOneList();
                    toast.success(response?.data?.message, {
                        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
                    });
                }
            } catch (error: any) {
                // toast.error(error?.response?.data?.message, {
                //     position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
                // });
            }
        } else {
            dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
        }
    };

    const navigateCheckOut = (value: any, ticket: any = null) => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN) || GetSessionStorageData(AUTHENTICATION.SHARED.GUEST_ACCESS_TOKEN);
        if (value.ticket_type_list.length > 0) {
            if (nullUndefinedEmptyCheck(accessToken)) {
                let path = ""
                if (ticket !== null) {
                    path = ROUTES.THEMEPARK_CHECKOUT + "?id=" + themeParkDetaile?.main_events[0]?.id + '&event=' + themeParkDetaile?.id + '&ticket=' + ticket;
                } else {
                    path = ROUTES.THEMEPARK_CHECKOUT + "?id=" + themeParkDetaile?.main_events[0]?.id + '&event=' + themeParkDetaile?.id
                }
                navigate(path, { state: { event: themeParkDetaile?.id, theme_park_id: themeParkDetaile?.main_events[0]?.id, details: themeParkDetaile } });
            } else {
                // dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
                dispatch(setGuestSignupOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
            }
        } else {
            // toast.error("Tickets not Available", {
            //     position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            // });
            store.dispatch(setErrorNotification(true))
            store.dispatch(setErrorMessage("Tickets not Available"))
        }

    }

    const slideNext = (carouselRef: any, index: number, items: any[], setIndex: (index: number) => void) => {
        if (index < items.length - itemsToSlide) {
            setIndex(index + itemsToSlide);
            carouselRef.current.slideTo(index + itemsToSlide);
        }
    };

    const slidePrev = (carouselRef: any, index: number, setIndex: (index: number) => void) => {
        if (index > 0) {
            setIndex(index - itemsToSlide);
            carouselRef.current.slideTo(index - itemsToSlide);
        }
    };

    const handleSlideChange = (setIndex: (index: number) => void) => ({ item }: any) => setIndex(item);

    const handleTicketSlideNext = () => slideNext(TicketcarouselRef, ticketActiveIndex, themeParkDetaile?.ticket_type_list || [], setTicketActiveIndex);
    const handleTicketSlidePrev = () => slidePrev(TicketcarouselRef, ticketActiveIndex, setTicketActiveIndex);
    const handleSlideNext = () => slideNext(carouselRef, activeIndex, themeParkDetaile?.main_events || [], setActiveIndex);
    const handleSlidePrev = () => slidePrev(carouselRef, activeIndex, setActiveIndex);

    const language = useSelector((state: any) => state.languageReducer.language);
    const screenWidth = window.innerWidth;
    const itemsToSlide = screenWidth >= 1024 ? 3 : screenWidth >= 600 ? 2 : 1;

    /*Below class name based on English Arabic*/
    const ArrowAavsLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ArrowAavsLang-Eng" : "ArrowAavsLang-Arab";
    const BackImgLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "BackImgLang-Eng" : "BackImgLang-Arab";

    const TicketTypeContent = themeParkDetaile?.ticket_type_list?.map((ticketitem: any, index: any) => {
        return (
            <>
                <div className={selectTicketEvent === ticketitem?.id ? "Event__curosal__wapper__active_ticket" : "Event__curosal__wapper_ticket"}
                    // onClick={() => setSelectTicketEvent(ticketitem?.id)}
                    onClick={() => navigateCheckOut(themeParkDetaile, ticketitem?.id)}
                >
                    <div className="ticket-card-content">
                        <div>
                            <div className="title">{ticketitem?.ticket_type}</div>
                            <div className="description">
                                {ticketitem?.description.length > ticketDescriptionLength
                                    ? ticketitem?.description.slice(0, ticketDescriptionLength) + (showFullDescription[index] ? ticketitem?.description.slice(70) : "")
                                    : ticketitem?.description
                                }
                                {ticketitem?.description.length > ticketDescriptionLength && (
                                    <span className="more-less-option" onClick={() => toggleDescription(index)}>
                                        {showFullDescription[index] ? "Less" : "..."}
                                    </span>
                                )}
                            </div>
                        </div>
                        {/* { ticketitem?.tick_discount_amount &&  */}
                        <div className="price price-line">{t("AED")} {String(ticketitem?.ticket_price).replace(/(\.00|\.0)$/, '')}</div> 
                        <div className="price">{t("AED")} {String(ticketitem?.tick_discount_amount || ticketitem?.ticket_price ).replace(/(\.00|\.0)$/, '')}</div>
                    </div>

                </div>
            </>
        )
    })

    // Ensure the data is loaded before rendering
    if (!themeParkDetaile) return null;






    return (
        <div>
            <Container component="main" maxWidth="md" sx={{ mb: 4 }} className="Themepark_detail_page">
                <div className="background_circle_1"></div>
                <div className="background_circle_2"></div>
                <div className="ThemePark__Back__card mb-4" onClick={() => navigate(-1)}>
                    <img src={back_img} alt="" className={`${BackImgLang} back-img`} />
                    <img src={themeParkIcon} alt="themeParkIcon" className="themeicon" />
                    <div className="TutorImg__text"><span>{t("Park and Events")}</span></div>
                </div>

                <div className="ThemePark__detaile_view__conatiner mt-2">
                    <div className="Profile__contant__wapper">
                        <img src={themeParkDetaile?.themeparkcover_images[0] || themeParkImg} alt="" className="profile__img" />
                        <div className="Profile__rightside">
                            <div className="Profile_name__wapper">
                                <div className="Profile_name"><span className="notranslate" translate="no">{
                                language === "English" ? themeParkDetaile?.themepark_name : themeParkDetaile?.arabic_themepark_name || themeParkDetaile?.themepark_name
                                // themeParkDetaile?.themepark_name
                                    }</span></div>
                                <div className="imageIcon">
                                    <WebShareComponent params={ROUTES.THEMEPARK_DETAILE_VIEW + "?id=" + encryptPassData(themeParkDetaile?.id)}>
                                        <img src={shareIcon} alt="" className="mb-1" style={{ width: '18px' }} />
                                    </WebShareComponent>
                                    <img src={themeParkDetaile?.is_favourite ? LikeFilled : LikeOutline} alt="" onClick={() =>
                                        handleChangeFavourites(themeParkDetaile?.is_favourite, themeParkDetaile?.user
                                        )} />
                                </div>
                            </div>
                            <div className="Profile__box__wapper">
                                <div className="box__contant">
                                    <img src={themeParkIcon} alt="" />
                                    <span>{t('Theme Park')}</span>
                                </div>
                                <div className="box__contant view-on-map" style={{ cursor: 'pointer' }} onClick={handleOpenMap}>
                                    <img src={ThemeMapIcon} alt="" />
                                    <span>{t('View on Map')}</span>
                                </div>
                            </div>
                            <div className="Themepark_full_location">
                                <LocationOnIcon className="detail-location-icon" />
                                <div title={themeParkDetaile?.address} className="detail-location-text"><span>{themeParkDetaile?.address}</span></div>
                            </div>
                            <div className="Profile__priceRange__wapper">
                                <div className="priceRange__contant">
                                    <img src={PriceRange} alt="" />
                                    <span>{t('Price Range')}</span>
                                </div>
                                <div className="Price_text"><span>{t('AED')} {themeParkDetaile?.min_price} - {t('AED')} {themeParkDetaile?.max_price}</span></div>
                            </div>
                            <div className="Profile__priceRange__wapper">
                                <div className="priceRange__contant">
                                    <img src={themePark_star} alt="" />
                                    <span>{t('Rating')} {themeParkDetaile?.rating} / 5</span>
                                </div>
                                <Rating name="half-rating-read" className="StarIcon detail-star-icon" value={parseInt(themeParkDetaile?.rating)} readOnly />
                            </div>
                        </div>
                    </div>
                    <div className="Description__wapper">
                        <div className="title"><span>{t('Description')}</span></div>
                        <ViewMoreLess text={themeParkDetaile?.description} maxLength={100} />
                    </div>
                    <div className="Description__wapper">
                        <div className="title"><span>{t('Ticket Type')}</span></div>
                        {themeParkDetaile?.ticket_type_list?.length > 0 && (
                            <div className="arrow_nav_tile_contianer">
                                <div className={`${ArrowAavsLang} arrow_navs me-2`}>
                                    <img
                                        src={left}
                                        onClick={handleTicketSlidePrev}
                                        alt="Learn and Play"
                                        // className={ticketActiveIndex === 0 ? "arrow_icon_disable" : "arrow_icon"}
                                        className={ticketActiveIndex > 0 ? "left_arrow " : "disable_arrow"}
                                    />
                                    <img
                                        src={right}
                                        onClick={handleTicketSlideNext}
                                        alt="Learn and Play"
                                        // className={ticketActiveIndex < themeParkDetaile.ticket_type_list.length - itemsToSlide ? "arrow_icon" : "arrow_icon_disable"}
                                        className={ticketActiveIndex < themeParkDetaile?.ticket_type_list?.length - (itemsToSlide) ? "right_arrow" : "disable_arrow"}
                                    />
                                </div>
                            </div>
                        )}
                        <AliceCarousel
                            mouseTracking={false}
                            keyboardNavigation={false}
                            autoWidth
                            disableButtonsControls
                            // items={themeParkDetaile.ticket_type_list.map((ticketitem: any, index: number) => (
                            //     <div className="item" key={index}>
                            //         <div className="card-container">
                            //             <div className="image-container">
                            //                 <img src={ticketitem?.image} alt="ticket" />
                            //             </div>
                            //             <div className="description-container">
                            //                 {showFullDescription[index] ? ticketitem?.description : `${ticketitem?.description.slice(0, ticketDescriptionLength)}${ticketitem?.description.length > ticketDescriptionLength ? '...' : ''}`}
                            //                 {ticketitem?.description.length > ticketDescriptionLength && (
                            //                     <button className="toggle-description-btn" onClick={(e) => { e.stopPropagation(); toggleDescription(index); }}>
                            //                         {showFullDescription[index] ? t('Read Less') : t('Read More')}
                            //                     </button>
                            //                 )}
                            //             </div>
                            //             <StyledCardContent>
                            //                 <div className="price-section">
                            //                     <div className="price">{ticketitem?.currency} {ticketitem?.price}</div>
                            //                     <button className="buy-ticket-btn" onClick={(e) => { e.stopPropagation(); navigateCheckOut(ticketitem?.id); }}>{t('Buy Ticket')}</button>
                            //                 </div>
                            //             </StyledCardContent>
                            //         </div>
                            //     </div>
                            // ))}
                            items={TicketTypeContent}
                            disableDotsControls
                            onSlideChanged={handleSlideChange(setTicketActiveIndex)}
                            autoPlay={false}
                            responsive={{
                                0: { items: 1 },
                                600: { items: 2 },
                                1024: { items: 3 },
                            }}
                            ref={TicketcarouselRef}
                        />
                    </div>
                    <div className="Description__wapper">
                        <div className="title"><span>{t('Highlights')}</span></div>
                        <div className="Highlight__wapper">
                            {themeParkDetaile?.highlights?.map((highlight: string, i: number) => (
                                <div key={i} className="contant">
                                    <span className="text">{i + 1}</span>
                                    <div className="text"><span>{highlight}</span></div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="Description__wapper">
                        <div className="title"><span>{t('Main Events')}</span></div>
                        {themeParkDetaile?.main_events?.length > 0 && (
                            <div className="arrow_nav_tile_contianer">
                                {/* <div className={`arrow_navs-${language} arrow_navs me-2`}> */}
                                <div className={`${ArrowAavsLang} arrow_navs me-2`}>
                                    <img
                                        src={left}
                                        onClick={handleSlidePrev}
                                        alt="Learn and Play"
                                        // className={activeIndex === 0 ? "arrow_icon_disable" : "arrow_icon"}
                                        className={activeIndex > 0 ? "left_arrow " : "disable_arrow"}
                                    />
                                    <img
                                        src={right}
                                        onClick={handleSlideNext}
                                        alt="Learn and Play"
                                        // className={activeIndex < themeParkDetaile.main_events.length - itemsToSlide ? "arrow_icon" : "arrow_icon_disable"}
                                        className={activeIndex < themeParkDetaile?.main_events?.length - (itemsToSlide) ? "right_arrow" : "disable_arrow"}
                                    />
                                </div>
                            </div>
                        )}
                        <AliceCarousel
                            mouseTracking={false}
                            keyboardNavigation={false}
                            autoWidth
                            disableButtonsControls
                            items={themeParkDetaile.main_events.map((event: any, index: number) => (
                                // <div className="item" key={index}>
                                //     <img src={event?.image || themeParkImg} alt="event" />
                                //     <div className="event-details">
                                //         <div className="event-title">{event?.title}</div>
                                //         <div className="event-description">{event?.description}</div>
                                //     </div>
                                // </div>

                                <div key={index} className={selectEvent === event?.id ? "Event__curosal__wapper__active" : "Event__curosal__wapper"}
                                    onClick={() => setSelectEvent(event?.id)}>
                                    <img src={event?.event_image} draggable='false' alt="card_bg" />
                                    <span>{event?.event_name}</span>
                                </div>

                            ))}
                            disableDotsControls
                            onSlideChanged={handleSlideChange(setActiveIndex)}
                            autoPlay={false}
                            responsive={{
                                0: { items: 1 },
                                600: { items: 2 },
                                1024: { items: 3 },
                            }}
                            ref={carouselRef}
                        />
                    </div>
                    <div className="bookTicket__wapper">
                        <button className="bookTicketBtn" onClick={() => navigateCheckOut(themeParkDetaile)}><span>{t('Book Tickets')}</span></button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ThemeParkDetaileView;
