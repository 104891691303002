import React, { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import shareIcon from "../../../Assets/Activities/share_activity.svg";
import LikeIcon from "../../../Assets/Activities/like_activity.svg";
import comments from "../../../Assets/Activities/commend_activity.svg";
import card_bg from "../../../Assets/Tutor/card_bg.png";
import AliceCarousel from "react-alice-carousel";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullSharpIcon from "@mui/icons-material/OpenInFullSharp";
import NavigateNextSharpIcon from "@mui/icons-material/NavigateNextSharp";
import NavigateBeforeSharpIcon from "@mui/icons-material/NavigateBeforeSharp";
import { useLocation, useNavigate } from "react-router-dom";
import "./ActivitesModelDilogView.scss";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES,
  getGoogleMapLink,
} from "../../../Constants/Constants";
import { Rating, Tooltip } from "@mui/material";
import MapIconActivities from "../../../Assets/Activities/MapIconActivities.svg";
import {
  GetSessionStorageData,
  encryptPassData,
  nullUndefinedEmptyCheck,
} from "../../../Utilities/Helpers/Helper";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import toast from "react-hot-toast";
import ActivityFill from "../../../Assets/HeartFill/ActivityFill.svg";
import { useDispatch, useSelector } from "react-redux";
import { setChildLoginRestriction, setLoginSignUpOpen } from "../../../Redux/Reducer/Reducer";
import WebShareComponent from "../../../Components/WebShare/WebShare";
import ViewMoreLess from "../../../Components/ViewMoreCommon";
import ActivityRating from "../../../Assets/Activities/ActivityRating.svg";
import gendar_ac from "../../../Assets/Activities/gendar_ac.svg";
import calendar_ac from "../../../Assets/Activities/calendar_ac.svg";
import total_session from "../../../Assets/Activities/total_session.svg";
import moment from "moment";
import CampBookSession from "../../../Components/CampBookSession/CampBookSession";
import call_icon from "../../../Assets/Activities/call_icon.svg";
import ClockIcon from "../../../Assets/Activities/Clock.svg";
import { useTranslation } from "react-i18next";


const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return (
    <>
      {" "}
      <Slide direction="up" ref={ref} {...props} />
    </>
  );
});

const ActivitesModelDilogView = ({
  triggerModel,
  close,
  Detailes,
  activitiesList,
  getLatAndLong,
}: any) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const carouselRef = useRef<any>(null);
  const [ActiviesDetailes, setActivitiesDetailes] = useState<any>([]);
  const [courseIndex, setCourseIndex] = useState<any>([]);
  const [courseSelectIndex, setCourseSelectIndex] = useState<any>([0]);
  const [AuthenticationOpen, setAuthenticationOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [ActiviesCamps, setActiviesCamps] = useState<any>([]);
  const [currentCampIndex, setCurrentCampIndex] = useState(0);
  const [CampBookOpen, setCampBookOpen] = useState(false);

  useEffect(() => {
    if (nullUndefinedEmptyCheck(Detailes?.id)) {
      setActivitiesDetailes([]);
      getOneList();
    }
  }, [Detailes]);

  /**Get One List*/
  const getOneList = () => {
    var id: any = "";
    if (Detailes?.is_service_type == "camp") {
      id = "?camp_session_id=" + Detailes?.id + '&is_service_type=' + Detailes?.is_service_type;
    } else {
      id = "?activity_id=" + Detailes?.id + '&is_service_type=' + Detailes?.is_service_type;
    }
    axiosInstance
      .get(`${API_END_POINTS.ACTIVITIES_DEATAILES + id}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          if (Detailes?.is_service_type == "camp") {
            setActiviesCamps(Response?.data?.result?.data)
          } else {
            setActivitiesDetailes(Response?.data?.result?.data);
            setCourseIndex(
              Response?.data?.result?.data?.trainer_activity?.length > 0
                ? Response?.data?.result?.data?.trainer_activity[0]
                : []
            );
          }
        }
      })
      .catch(() => { });
  };

  /** Onchange Function to Prev Button*/
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      activitiesList?.personal?.map((value: any, i: any) => {
        if (currentIndex - 1 === i) {
          setActivitiesDetailes(value);
          setCourseIndex(value?.trainer_activity[0]);
          getLatAndLong(value?.latitude, value?.longitude);
        }
      });
    }
  };

  /** Onchange Function to Next Button*/
  const handleNext = () => {
    if (currentIndex < activitiesList?.personal.length - 1) {
      setCurrentIndex(currentIndex + 1);
      activitiesList?.personal?.map((value: any, i: any) => {
        if (currentIndex + 1 === i) {
          setActivitiesDetailes(value);
          setCourseIndex(value?.trainer_activity[0]);
          getLatAndLong(value?.latitude, value?.longitude);
        }
      });
    }
  };

  /** Onchange Function to Prev Button*/
  const handlePrevCamp = () => {
    if (currentCampIndex > 0) {
      setCurrentCampIndex(currentCampIndex - 1);
      activitiesList?.camp?.map((value: any, i: any) => {
        if (currentCampIndex - 1 === i) {
          setActiviesCamps(value);
          getLatAndLong(value?.latitude, value?.longitude);
        }
      });
    }
  };

  /** Onchange Function to Next Button*/
  const handleNextCamp = () => {
    if (currentCampIndex < activitiesList?.camp?.length - 1) {
      setCurrentCampIndex(currentCampIndex + 1);
      activitiesList?.camp?.map((value: any, i: any) => {
        if (currentCampIndex + 1 === i) {
          setActiviesCamps(value);
          getLatAndLong(value?.latitude, value?.longitude);
        }
      });
    }
  };

  /**Slide previous card */
  const slidePrev = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.slidePrev();
    }
  };

  /**Slide next card */
  const slideNext = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.slideNext();
    }
  };

  /**Sync card index on slide */
  const syncActiveIndex = ({ item }: any) => {
    setActiveIndex(item);
  };

  /**Sync card index on slide */
  const navigateTutorDetailedView = () => {
    if (Detailes?.is_service_type == "camp") {
      navigate(ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(ActiviesCamps?.id) + "&type=" +
        encryptPassData(ActiviesCamps?.is_service_type),
        { state: { Activies_Detailes: ActiviesCamps } }
      );
    } else {
      navigate(
        ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(ActiviesDetailes?.id) + "&type=" +
        encryptPassData(ActiviesDetailes?.is_service_type),
        { state: { Activies_Detailes: ActiviesDetailes } }
      );
    }
  };

  /**Navigate BookSlot Page */
  const navigateBookSlot = () => {
    navigate(ROUTES.ACTIVITY_CALENDAR_VIEW, {
      state: {
        activity_sesstion_id: courseIndex?.id,
        camp: ActiviesDetailes.type_camp,
        person: ActiviesDetailes,
        is_reschedule: false
      },
    });
  };

  const CoverImg = ActiviesDetailes?.cover_images?.map((item: any) => {
    return (
      <>
        <img
          src={item ? item : card_bg}
          alt="card_bg" onError={(e: any) => { e.target.src = card_bg; }}
          className="card_bg_img"
        />
      </>
    );
  });

  const CoverImgCamps = ActiviesCamps?.camp_images?.map((item: any) => {

    return (
      <>
        <img src={item ? item : card_bg} alt="card_bg" className='card_bg_img'
          onError={(e: any) => { e.target.src = card_bg; }} />
      </>
    )
  })

  const CoverImgEmpty = [0, 1, 2]?.map((item: any) => {
    return (
      <>
        <img src={card_bg} alt="card_bg" className="card_bg_img" />
      </>
    );
  });

  const handleChangeFavourites = (val: any, id: any, is_service_type: any, campId: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload: any;

      if (is_service_type == "camp") {
        payload = {
          service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.THREE_INT,
          service_provider: id,
          is_favourite: !val,
          camp_session: campId
        };
      } else {
        payload = {
          service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT,
          service_provider: id,
          is_favourite: !val,
        };
      }
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            toast.success(Response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
            getOneList();
          }
        })
        .catch((error) => {
          // toast.error(error?.response?.data?.message, {
          //   position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          // });
        });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const handleAuthentication = () => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.CHILD) {
        dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      } else {
        if (ActiviesCamps?.is_service_type == "camp") {
          onOpenCampBookSession()
        } {
          navigateBookSlot()
        }
      }
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const onOpenCampBookSession = () => {
    setCampBookOpen(true);
  };

  const onCloseCampBookSession = () => {
    setCampBookOpen(false);
  };

  const handleOpenMap = (data: any) => {
    let getDestination = { lat: data.latitude, lng: data.longitude }
    const mapsUrl = getGoogleMapLink(getDestination);
    if (mapsUrl) {
      window.open(mapsUrl, '_blank');
    }
  };

  const getSlotDays = (detail: any) => {
    let slotDescription;
    let weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

    switch (detail?.slot_mode) {
      case 'weekend':
        slotDescription = "Weekends (Saturday & Sunday)";
        break;
      case 'weekdays':
        slotDescription = "Weekdays (Monday to Friday)";
        break;
      case 'daily':
        slotDescription = "Daily (Monday to Sunday)";
        break;
      case 'custom':
        let selectedDays = detail?.days?.sort();
        let slots = selectedDays?.map((day: number) => weekdays[day])
        slotDescription = slots?.join(', ');
        break;
      default:
        slotDescription = "";
        break;
    }
    return slotDescription;
  }

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /*Below class name based on English Arabic*/
  const top_cover_img_wrapper = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "top_cover_img_wrapper" : "top_cover_img_wrapper_arb";
  const Facility_needed_Activities = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "Facility_needed_Activities" : "Facility_needed_Activities_arb";

  return (
    <>
      <div className="Model__Dialog__container">
        <Dialog
          open={triggerModel}
          onClose={close}
          TransitionComponent={Transition}
          keepMounted
          PaperProps={{
            style: {
              position: "absolute",
              right: 0,
              top: 100,
              bottom: -25,
              width: 650,
              backgroundColor: "transparent", // Set the background color to transparent
              boxShadow: "none", // Optional:
            },
          }}
          className="d-none d-md-block"
        >
          {/* <div className="background_circle_left__Activity_model"></div> */}
          <div className="background_circle_right__Activity_model"></div>
          <DialogContent sx={{ zIndex: 1, padding: '20px' }}
            dir={
              language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
                ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
                : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
            } >

            {Detailes?.is_service_type == "camp" ? (
              <>
                <div className="top__contant__wapper_Activities">
                  <div className="top__PageBtn__icons">
                    {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                      <NavigateBeforeSharpIcon className={currentCampIndex > 0 ? "prev__icons" : "icons__disable"} onClick={handlePrevCamp} />
                    ) : (
                      <NavigateNextSharpIcon className={currentCampIndex > 0 ? "prev__icons" : "icons__disable"} onClick={handlePrevCamp} />
                    )}
                    <div
                      className={currentCampIndex > 0 ? "prev__text" : "text__disable"}
                      onClick={handlePrevCamp}
                      style={{ marginRight: '20px' }}

                    >
                      {t("Prev")}
                    </div>
                    <div
                      className={
                        currentCampIndex < activitiesList?.camp?.length - 1
                          ? "prev__text"
                          : "text__disable"
                      }
                      onClick={handleNextCamp}
                    >
                      {t("Next")}
                    </div>
                    {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                      <NavigateNextSharpIcon className={currentCampIndex < activitiesList?.camp?.length - 1 ? "next__icons" : "icons__disable"} onClick={handleNextCamp} />
                    ) : (
                      <NavigateBeforeSharpIcon className={currentCampIndex < activitiesList?.camp?.length - 1 ? "next__icons" : "icons__disable"} onClick={handleNextCamp} />
                    )}
                  </div>
                  <div className="top__close__icons">
                    <Tooltip title="Expanded">
                      <OpenInFullSharpIcon
                        className="expanded__icons"
                        onClick={navigateTutorDetailedView}
                      />
                    </Tooltip>
                    <Tooltip title="close">
                      <CloseIcon className="close__icons" onClick={close} />
                    </Tooltip>
                  </div>
                </div>
                <div className={top_cover_img_wrapper}>
                  <div className="arrow_navs">
                    <NavigateBeforeSharpIcon
                      onClick={slidePrev}
                      className="left_arrow"
                    />
                    <NavigateNextSharpIcon
                      onClick={slideNext}
                      className="right_arrow"
                    />
                  </div>
                  <AliceCarousel
                    // infinite
                    mouseTracking
                    autoWidth
                    disableButtonsControls
                    items={ActiviesCamps?.camp_images?.length > 0 ? CoverImgCamps : CoverImgEmpty}
                    disableDotsControls
                    activeIndex={activeIndex}
                    onSlideChanged={syncActiveIndex}
                    ref={carouselRef}
                  />
                  <img
                    src={ActiviesCamps?.camp_images?.length > 0 ? ActiviesCamps?.camp_images[0] : tutor_img}
                    alt="tutor_img" onError={(e: any) => { e.target.src = tutor_img; }}
                    className="profileImg"
                  />
                </div>

                <div className="card__container">
                  <div className="w-100">
                    <div className="user__Detailes">
                      <div className="title_header notranslate">
                        {language === "English" ? ActiviesCamps?.camp_session_title : ActiviesCamps?.arabic_camp_session_title || ActiviesCamps?.camp_session_title }
                      </div>
                      <div className="user__icon__wrapper">
                        {/* <img src={comments} alt="comments" /> */}
                        <WebShareComponent
                          params={
                            ROUTES.ACTIVITY_DETAILE_VIEW +
                            "?id=" +
                            encryptPassData(ActiviesCamps?.id) +
                            "&type=" +
                            encryptPassData(ActiviesCamps?.is_service_type)
                          }
                        >
                          <img src={shareIcon} alt="shareIcon" className="mb-1" />
                        </WebShareComponent>
                        <img
                          src={
                            ActiviesCamps?.is_favourite ? ActivityFill : LikeIcon
                          }
                          alt="LikeIcon"
                          onClick={() =>
                            handleChangeFavourites(
                              ActiviesCamps.is_favourite,
                              ActiviesCamps?.user,
                              ActiviesCamps?.is_service_type,
                              ActiviesCamps?.id,
                            )
                          }
                        />
                      </div>
                    </div>
                    <ViewMoreLess
                      text={ActiviesCamps?.description}
                      maxLength={100}
                    />


                    <div className="user__Detailes__Grade__Activities">
                      <div className="Grade__Activities">
                        <img src={ActiviesCamps?.category_icon} alt="" />
                        <span>{ActiviesCamps?.category_name}</span>
                      </div>
                      <div
                        className="View__on__map"
                        onClick={() => {
                          handleOpenMap(ActiviesCamps)
                        }}
                      >
                        <img src={MapIconActivities} alt="" />
                        <span>{t("View on Map")}</span>
                      </div>
                    </div>

                    <div className="user__Detailes__fess__Activities">
                      <div className="contant_wapper">
                        <img src={calendar_ac} alt="" />
                        <div className="claendar_text">{moment(new Date(ActiviesCamps?.start_date)).format("MMM DD")} - {moment(new Date(ActiviesCamps?.end_date)).format("MMM DD")}</div>
                      </div>
                      <div className="contant_wapper">
                        {/* <div className='claendar_text'>Price</div> */}
                        <div className="title">{t("AED")} {ActiviesCamps?.total_class_fee}</div>
                      </div>
                    </div>

                    <div className="user__Detailes__fess__Activities">
                      <div className="contant_wapper">
                        <img src={ClockIcon} alt="" />
                        <div className="claendar_text">{ActiviesCamps?.start_time} - {ActiviesCamps?.end_time}</div>
                      </div>
                      <div className="claendar_text">{getSlotDays(ActiviesCamps)}</div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 bottom__contant__wapper">
                        <div className="bottom__contant__left">
                          <img src={total_session} alt="" />
                          <div className="text_contant_bold">{t("No. of session")} </div>
                        </div>
                        <div className="text_contant">{ActiviesCamps?.total_class} {t("classes")}</div>
                      </div>
                      <div className="col-md-6 bottom__contant__wapper">
                        <div className="bottom__contant__left">
                          <img src={gendar_ac} alt="" />
                          <div className="text_contant_bold">{t("Gendar")}</div>
                        </div>
                        <div className="text_contant">{ActiviesCamps?.gender}</div>
                      </div>
                      <div className="col-md-6 bottom__contant__wapper">
                        <div className="bottom__contant__left">
                          <img src={calendar_ac} alt="" />
                          <div className="text_contant_bold">{t("Available Batch")}</div>
                        </div>
                        <div className="text_contant">{ActiviesCamps?.batch_size + "/" + ActiviesCamps?.total_batch_size}</div>
                      </div>
                      <div className="col-md-6 bottom__contant__wapper">
                        <div className="bottom__contant__left">
                          <img src={ActivityRating} alt="" />
                          <div className="text_contant_bold">{t("Rating")}</div>
                        </div>
                        <div className="text_contant">{ActiviesCamps?.rating}</div>
                      </div>
                    </div>

                    {/* <div className="Facility_needed_Activities mt-3 Activities__description_with">
                      <label className="title">Description</label>
                      <ViewMoreLess
                        text={ActiviesCamps?.description}
                        maxLength={100}
                      />
                    </div> */}

                    <div className={Facility_needed_Activities}>
                      <div className='title_header'>{t("Amenities")}</div>
                      <div className="Amenities__container">
                        {ActiviesCamps?.amenities?.map((item: any) => {
                          return (
                            <>
                              <div className="Amenities__wapper">
                                <img src={item?.amenity_icon} alt="" />
                                <span className="text_contant">{item?.amenity_name}</span>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </div>

                    <div className='user__Detailes__Activities'>
                      <div className='title'>{t("Contact US")}</div>
                      <div className="contact_us_wapper" onClick={() => {
                        navigator.clipboard.writeText(ActiviesCamps?.phone_number);
                        toast.success("Copied!", {
                          position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                        });
                      }}>
                        <img src={call_icon} alt="" />
                        <div className='text_contant'>{ActiviesCamps?.phone_number}</div>
                        <span className="text_call">{t("Call")}</span>
                      </div>
                    </div>

                    <div className="user__Detailes__Activities__bookBtn__container">
                      {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) ==
                        GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT ? (
                        <button className="bookBtn" onClick={onOpenCampBookSession}>
                          {t("Book Slots")}
                        </button>
                      ) : (
                        <button className="bookBtn" onClick={handleAuthentication}>
                          {t("Book Slots")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="top__contant__wapper_Activities">
                  <div className="top__PageBtn__icons">
                    {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                      <NavigateBeforeSharpIcon className={currentIndex > 0 ? "prev__icons" : "icons__disable"} onClick={handlePrev} />
                    ) : (
                      <NavigateNextSharpIcon className={currentIndex > 0 ? "prev__icons" : "icons__disable"} onClick={handlePrev} />
                    )}
                    <div
                      className={currentIndex > 0 ? "prev__text" : "text__disable"}
                      onClick={handlePrev}
                      style={{ marginRight: '20px' }}
                    >
                      {t("Prev")}
                    </div>
                    <div
                      className={
                        currentIndex < activitiesList?.length - 1
                          ? "prev__text"
                          : "text__disable"
                      }
                      onClick={handleNext}
                    >
                      {t("Next")}
                    </div>
                    {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                      <NavigateNextSharpIcon className={currentIndex < activitiesList?.length - 1 ? "next__icons" : "icons__disable"} onClick={handleNext} />
                    ) : (
                      <NavigateBeforeSharpIcon className={currentIndex < activitiesList?.length - 1 ? "next__icons" : "icons__disable"} onClick={handleNext} />
                    )}
                  </div>
                  <div className="top__close__icons">
                    <Tooltip title="Expanded">
                      <OpenInFullSharpIcon
                        className="expanded__icons"
                        onClick={navigateTutorDetailedView}
                      />
                    </Tooltip>
                    <Tooltip title="close">
                      <CloseIcon className="close__icons" onClick={close} />
                    </Tooltip>
                  </div>
                </div>
                <div className={top_cover_img_wrapper}>
                  <div className="arrow_navs">
                    <NavigateBeforeSharpIcon
                      onClick={slidePrev}
                      className="left_arrow"
                    />
                    <NavigateNextSharpIcon
                      onClick={slideNext}
                      className="right_arrow"
                    />
                  </div>
                  <AliceCarousel
                    // infinite
                    mouseTracking
                    autoWidth
                    disableButtonsControls
                    items={
                      ActiviesDetailes?.cover_images?.length > 0
                        ? CoverImg
                        : CoverImgEmpty
                    }
                    disableDotsControls
                    activeIndex={activeIndex}
                    onSlideChanged={syncActiveIndex}
                    ref={carouselRef}
                  />
                  <img
                    src={
                      ActiviesDetailes?.profile_image
                        ? ActiviesDetailes?.profile_image
                        : tutor_img
                    } onError={(e: any) => { e.target.src = tutor_img; }}
                    alt="tutor_img"
                    className="profileImg"
                  />
                </div>

                <div className="card__container">
                  <div className="w-100">
                    <div className="user__Detailes">
                      <div className="title_header notranslate">
                      {
                        language === "English"
                         ? `${(ActiviesDetailes?.profile_details?.firstname || "") + " " + (ActiviesDetailes?.profile_details?.lastname || "")}`
                         : `${(ActiviesDetailes?.profile_details?.arabic_firstname || ActiviesDetailes?.profile_details?.firstname || "") + " " + (ActiviesDetailes?.profile_details?.arabic_lastname || ActiviesDetailes?.profile_details?.lastname || "")}`
                       }
                        {/* {ActiviesDetailes?.profile_details?.firstname}{" "}{ActiviesDetailes?.profile_details?.lastname} */}
                      </div>
                      <div className="user__icon__wrapper">
                        {/* <img src={comments} alt="comments" /> */}
                        <WebShareComponent
                          params={
                            ROUTES.ACTIVITY_DETAILE_VIEW +
                            "?id=" +
                            encryptPassData(ActiviesDetailes?.id) +
                            "&type=" +
                            encryptPassData(ActiviesDetailes?.is_service_type)
                          }
                        >
                          <img src={shareIcon} alt="shareIcon" />
                        </WebShareComponent>
                        <img
                          src={
                            ActiviesDetailes?.is_favourite ? ActivityFill : LikeIcon
                          }
                          alt="LikeIcon"
                          onClick={() =>
                            handleChangeFavourites(
                              ActiviesDetailes.is_favourite,
                              ActiviesDetailes?.user,
                              ActiviesDetailes?.is_service_type,
                              ActiviesDetailes?.id,
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="user__Detailes__Grade__Activities">
                      <div className="Grade__Activities">
                        {/* <img src={MapIconActivities} alt="" /> */}
                        {courseIndex.length > 0 ? (
                          <span>{courseIndex.activites}</span>
                        ) : (
                          <span>
                            {ActiviesDetailes?.trainer_activity?.length > 0
                              ? ActiviesDetailes?.trainer_activity[0]?.activites
                              : courseIndex.activites}
                          </span>
                        )}
                      </div>

                      <div
                        className="View__on__map"
                        onClick={() => {
                          handleOpenMap(ActiviesDetailes)
                        }}
                      >
                        <img src={MapIconActivities} alt="" />
                        <span>{t("View on Map")}</span>
                      </div>
                    </div>

                    <div className="user__Detailes__fess__Activities">
                      <div className="text_contant">{t("Avg Fees Per Session")}</div>
                      <h1 className="title">{t("AED")} {courseIndex?.expected_fee}</h1>
                    </div>
                    <div className="user__Detailes__Education_Activities">
                      <div className="text_contant">
                        {t("Rating")} &nbsp;&nbsp;  {ActiviesDetailes?.rating ? ActiviesDetailes?.rating : 0} / 5
                      </div>
                      <Rating
                        name="half-rating-read"
                        defaultValue={ActiviesDetailes?.rating}
                        readOnly
                      />
                    </div>

                    <div className={Facility_needed_Activities}>
                      <div className="title_header">{t("Facility needed")}</div>

                      <ul className="">
                        {courseIndex?.additional_notes?.map((item: any) => {
                          return (
                            <>
                              {nullUndefinedEmptyCheck(item) && <li>{item}</li>}
                            </>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="user__Detailes__Activities">
                      <div className="title_header">{t("Activities")}</div>
                      <div className="badge_wrapeer">
                        {ActiviesDetailes?.trainer_activity?.map(
                          (sub: any, i: any) => {
                            return (
                              <div
                                className={
                                  courseSelectIndex == i
                                    ? "badge__container-Active"
                                    : "badge__container"
                                }
                                onClick={() => {
                                  setCourseSelectIndex(i);
                                  setCourseIndex(sub);
                                }}
                              >
                                <img src={sub.category_icon} alt="" />
                                <span>{sub?.activites}</span>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div className="user__Detailes__Activities">
                      <div className="title_header">{t("About Me")}</div>
                      <div className="description__contant">
                        {ActiviesDetailes?.about_me
                          ? ActiviesDetailes?.about_me
                          : ActiviesDetailes?.about_camp}
                      </div>
                    </div>
                    <div className="user__Detailes__Activities">
                      <div className="title_header">{t("Equipments needed")}</div>
                      <div className="description__contant">
                        {courseIndex?.equipments}
                      </div>
                    </div>

                    <div className="user__Detailes__Activities__bookBtn__container">
                      {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) ==
                        GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT ? (
                        <button className="bookBtn" onClick={navigateBookSlot}>
                          {t("Book Slots")}
                        </button>
                      ) : (
                        <button className="bookBtn" onClick={handleAuthentication}>
                          {t("Book Slots")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </DialogContent>
        </Dialog>
      </div>
      {CampBookOpen && (
        <CampBookSession
          TrialOpen={CampBookOpen}
          onClose={onCloseCampBookSession}
          campsData={ActiviesCamps}
        />
      )}
      {AuthenticationOpen && (
        <div className="overlay-ModelBackround">
          <Dialog open={AuthenticationOpen}>
            <DialogContent>
              <div className="ChildSelect__container">
                <div className="text__contant">
                  {t("Parent only allowed to Book slot")}
                </div>
                <div className="ChildSelectBtn__wapper__Activities">
                  <button
                    className="BackBtn"
                    onClick={() => setAuthenticationOpen(false)}
                  >
                    {t("Back")}
                  </button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default ActivitesModelDilogView;
