import React, { useEffect } from 'react';
import { setLocation, setLocationName } from '../../Redux/Reducer/Reducer';
import { useDispatch } from 'react-redux';
import { SetSessionStorageData, GetSessionStorageData } from '../../Utilities/Helpers/Helper';
import { AUTHENTICATION } from '../../Constants/Constants';

const LatLanComponent = ({ region, country }: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (country) {
      // Construct the URL with the API key as a query parameter
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(country === AUTHENTICATION.SHARED.UNITED_ARAB ? region : country)}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`;
      
      const session_region = GetSessionStorageData(AUTHENTICATION.SHARED.REGION_NAME)
      const session_country = GetSessionStorageData(AUTHENTICATION.SHARED.COUNTRY_NAME)

      // Make the API call
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          const { lat, lng } = data?.results[0]?.geometry?.location;
          var location: any = { lat, lng }
          dispatch(setLocation(location));
          dispatch(setLocationName(country));
          SetSessionStorageData(AUTHENTICATION.SHARED.CURRENT_LOCATION, JSON.stringify(location))
          SetSessionStorageData(AUTHENTICATION.SHARED.COUNTRY_NAME, country)
          SetSessionStorageData(AUTHENTICATION.SHARED.REGION_NAME, region)
          if (session_region !== region || session_country !== AUTHENTICATION.SHARED.UNITED_ARAB) {
            SetSessionStorageData(AUTHENTICATION.SHARED.SCHOOL_LOCATION, JSON.stringify(location))
            SetSessionStorageData(AUTHENTICATION.SHARED.UNIVERSITY_LOCATION, JSON.stringify(location))
            SetSessionStorageData(AUTHENTICATION.SHARED.TUTOR_LOCATION, JSON.stringify(location))
            SetSessionStorageData(AUTHENTICATION.SHARED.ACTIVITIES_LOCATION, JSON.stringify(location))
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [country, region]);

  return null;
};

export default LatLanComponent;


