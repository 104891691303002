import React, { useEffect, useState } from 'react'
import {
    GoogleMap, LoadScript, MarkerF, Circle, Marker, InfoWindow, InfoBox, MarkerClusterer,
    CircleF, Autocomplete, DirectionsService, DirectionsRenderer, useJsApiLoader
} from "@react-google-maps/api";
import "./TutorMaps.scss";
import { MapProps } from '../../Models/TutorModel';
import TutorImg from "../../Assets/Tutor/mapTutorIcon.svg";
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import shareIcon from "../../Assets/Tutor/share.svg";
import LikeIcon from "../../Assets/Tutor/LikeIcon.svg";
import locationIcon from "../../Assets/Tutor/location.svg";
import experienceIcon from "../../Assets/Tutor/experience.svg";
import BadgeIcon from "../../Assets/Tutor/BadgeIcon.svg";
import StarIcon from '@mui/icons-material/Star';
import teaching_mode from "../../Assets/Tutor/teaching_mode.svg";
import grades from "../../Assets/Tutor/grades.svg";
import curriculum from "../../Assets/Tutor/curriculum.svg";
import { GetSessionStorageData, SetSessionStorageData, encryptPassData, nullUndefinedEmptyCheck } from '../../Utilities/Helpers/Helper';
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES, TUTORMAPCONSTANT } from '../../Constants/Constants';
import { setLocation, setLocationName, setLoginSignUpOpen } from '../../Redux/Reducer/Reducer';
import { useDispatch, useSelector } from 'react-redux';
import { axiosInstance } from '../../Utilities/Interceptor/Interceptor';
import toast from 'react-hot-toast';
import Tutorfavourites from "../../Assets/HeartFill/TutorFill.svg";
import { useNavigate } from 'react-router-dom';
import WebShareComponent from '../WebShare/WebShare';

const TutorMaps = ({ zoom, radius, containerStyle, disable, zoomControl, draggable, clickableIcons, tutorList, getOneTutor, lat, lang, filter, searchValue, initFilter, initSort }: MapProps) => {
    const [currentLocation, setCurrentLocation] = useState<any>(null);
    const [selectedLocation, setSelectedLocation] = useState<any>(null);
    const [TutorList, setTutorList] = useState<any>([]);
    const [userProfileImg, setUserProfileImg] = useState<any>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [libraries] = useState([TUTORMAPCONSTANT.LIBRARIES]);

    useEffect(() => {
        const location = GetSessionStorageData(AUTHENTICATION.SHARED.TUTOR_LOCATION)
        if (location) {
        const parsedlocation = JSON.parse(location)
        setCurrentLocation(parsedlocation)
        dispatch(setLocation(parsedlocation));
        }
        setCurrentLocation({ lat: parseFloat(lat), lng: parseFloat(lang) });
        setUserProfileImg(GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_PICTURE));
        if (lat && lang) {
            TutorListApi();
        }
    }, [lat, lang, searchValue, radius, filter])

    /**Header search location*/
    const HeaderLoction: any = useSelector((state: any) => {
        return state.locationReducer.location;
    });


    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });

    const payload = () => {
        var payload;
        var priceValue: any = [];
        var experienceValue: any = [];
        var gradeValue: any = [];

        if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
            priceValue = []
        } else {
            priceValue = {
                min: filter.price[0],
                max: filter.price[1],
            }
        }
        if (initFilter.experience[0] === filter.experience[0] && initFilter.experience[1] === filter.experience[1]) {
            experienceValue = []
        } else {
            experienceValue = {
                min: filter.experience[0],
                max: filter.experience[1],
            }
        }
        if (initFilter.grade[0] === filter.grade[0] && initFilter.grade[1] === filter.grade[1]) {
            gradeValue = []
        } else {
            gradeValue = {
                min: filter.grade[0],
                max: filter.grade[1],
            }
        }
        return payload = {
            latitude: lat?.toString(),
            longitude: lang?.toString(),
            radius: radius / 1000,
            search: searchValue ? searchValue : "",
            price: priceValue,
            experience: experienceValue,
            grade: gradeValue,
            courses: filter.courses,
            mode_of_teaching: filter.mode_of_teaching,
            gender: filter.gender,
            curriculum: filter.curriculum,
            rating: filter.rating,
        }
    }



    /**Tutor get List Api*/
    const TutorListApi = () => {
        var pay = payload();
        axiosInstance
            .post(`${API_END_POINTS.TUTORLIST}`, pay)
            .then((Response: any) => {
                setTutorList(Response?.data?.result?.data)
                tutorList(Response?.data?.result?.data)
            })
            .catch(() => { });
    };

    const mapOptions = {
        disableDefaultUI: disable, // This will remove all default UI controls
        zoomControl: zoomControl, // This will specifically remove the zoom control
        draggable: draggable, // Disable dragging the map
        clickableIcons: clickableIcons, // Disable clicking on map icons
        disableDoubleClickZoom: disable,
        styles: [
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [
                    { visibility: 'off' },
                ],
            },
        ],
    };

    useEffect(() => {
        if (initSort?.price || initSort?.rating) {
            handleProceedSort(initSort?.price, initSort?.rating)
        }
    }, [initSort])

    const handleProceedSort = (checkedPrice: any, checkedRating: any) => {
        if (checkedPrice == 'high') {
            const sortedData = [...TutorList].sort((a: any, b: any) => b?.least_price - a?.least_price);
            setTutorList(sortedData);
        } else if (checkedPrice == 'low') {
            const sortedData = [...TutorList].sort((a: any, b: any) => a?.least_price - b?.least_price);
            setTutorList(sortedData)
        } else if (checkedRating == 'high') {
            const sortedData = [...TutorList].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
            setTutorList(sortedData)
        } else if (checkedRating == 'low') {
            const sortedData = [...TutorList].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
            setTutorList(sortedData)
        }
    }

    const handleChangeFavourites = (val: any, id: any) => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
        if (nullUndefinedEmptyCheck(accessToken)) {
            var payload = {
                service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT,
                service_provider: id,
                is_favourite: !val
            }
            axiosInstance
                .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
                .then((Response: any) => {
                    if (Response?.data?.status) {
                        toast.success(Response?.data?.message, {
                            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                        });
                        TutorListApi()
                    }
                })
                .catch((error) => {
                    // toast.error(error?.response?.data?.message, {
                    //     position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                    // });
                });
        } else {
            dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
        }
    }

    const { isLoaded } = useJsApiLoader({
        id: TUTORMAPCONSTANT.MAP__ID || "",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "",
        libraries,
    });

    /**Header search location getFormattedAddress Function */
    const geocoder: any = isLoaded ? new window.google.maps.Geocoder() : '';

    const getFormattedAddress = (lat: any, lng: any) => {
        geocoder.geocode({ location: { lat, lng } }, (results: any, status: any) => {
            if (status) {
                var addressComponents = results[GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT]?.address_components;
                if (results[GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT]?.address_components?.length > 0) {
                    const regionComponent = addressComponents?.find((component: any) => component?.types.includes("locality"));
                    const countryComponent = addressComponents?.find((component: any) => component?.types.includes("country"));
                    const countryName = countryComponent ? countryComponent.long_name : null;
                    const regionName = regionComponent ? regionComponent.long_name : null;
                    SetSessionStorageData(AUTHENTICATION.SHARED.REGION_NAME, regionName);
                    SetSessionStorageData(AUTHENTICATION.SHARED.COUNTRY_NAME, countryName);
                    dispatch(setLocationName(countryName));
                }
            }
        });
    }

    /**navigate Tutor DetailedView page */
    const navigateTutorDetailedView = (Detailes: any) => {
        navigate(ROUTES.TUTORS_DETAILE_VIEW + "?id=" + encryptPassData(Detailes?.id), { state: { tutor_Detailes: Detailes } });
    }

    const customMarkerIconStyle = {
        width: "40px", // Adjust the width as needed
        height: "40px", // Adjust the height as needed
        borderRadius: "50%", // Make the icon rounded
    };

    return isLoaded ? (
        <>

            {/* <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY || ""}
                libraries={["places"]}
            > */}
            <GoogleMap
                id={TUTORMAPCONSTANT.MAP__ID}
                mapContainerStyle={containerStyle}
                zoom={zoom}
                center={currentLocation}
                options={mapOptions}
                onClick={() => setSelectedLocation(null)}
                onMouseOut={() => setSelectedLocation(null)}
            >
                <CircleF
                    center={{ lat: parseFloat(HeaderLoction?.lat), lng: parseFloat(HeaderLoction?.lng) }}
                    radius={radius}
                    options={{
                        fillColor: '#178FFF', // Fill color with opacity
                        strokeColor: 'rgba(79, 139, 255, 0.60)', // Border color
                        strokeOpacity: 0.8, // Border opacity
                        strokeWeight: 2, // Border width
                        fillOpacity: 0.1, // Fill opacity
                    }}
                />
                {/* {currentLocation && (
                    <Marker position={currentLocation}
                        shape={{ coords: [10, 14, 19,], type: "circle" }}
                        icon={{
                            scaledSize: new google.maps.Size(40, 40),
                            url: userProfileImg,
                        }}
                    />
                )} */}
                {HeaderLoction && (
                    <MarkerF
                        position={HeaderLoction}
                        draggable={true}
                        zIndex={999}
                        shape={{ coords: [17, 17, 18], type: "circle" }}
                        icon={{
                            scaledSize: new google.maps.Size(40, 40),
                            url: userProfileImg,
                        }}
                        onDragEnd={
                            (e: any) => {
                                const locationData: any = {
                                    lat: e.latLng.lat(),
                                    lng: e.latLng.lng()
                                };
                                setCurrentLocation(locationData);
                                dispatch(setLocation(locationData));
                                SetSessionStorageData(AUTHENTICATION.SHARED.TUTOR_LOCATION, JSON.stringify(locationData));
                                getFormattedAddress(e?.latLng?.lat(), e?.latLng?.lng());
                            }}
                    />
                )}
                {TutorList?.map((data: any) => {
                    let object = {
                        lat: parseFloat(data.latitude),
                        lng: parseFloat(data.longitude)
                    }
                    return (
                        <Marker position={object}
                            icon={{
                                scaledSize: lat == data?.latitude && lang == data?.longitude ? new google.maps.Size(65, 65) : new google.maps.Size(40, 40),
                                url: TutorImg,
                            }}
                            onMouseOver={() => setSelectedLocation(data)}
                            // onMouseOut={() => setSelectedLocation(null)}
                            animation={lat == data?.latitude && lang == data?.longitude ? window.google.maps.Animation.DROP : undefined}
                        >
                            {selectedLocation === data && (
                                <InfoWindow onCloseClick={() => setSelectedLocation(null)} onZindexChanged={() => setSelectedLocation(null)}
                                    options={{
                                        maxWidth: 372,
                                    }} >
                                    <div className="" onDoubleClick={() => navigateTutorDetailedView(data)} onMouseEnter={() => setSelectedLocation(data)} onMouseLeave={() => setSelectedLocation(null)}>
                                        <div className='Hover__Card' onClick={() => {
                                            if (clickableIcons) {
                                                getOneTutor(data)
                                            }
                                        }}>
                                            {/* <img src={data?.profile_image ? data?.profile_image : tutor_img} alt="profile_image"
                                                onError={(e: any) => { e.target.src = tutor_img; }} className='profile_image' /> */}
                                            <div className='rightside_contant'>
                                                <div className="w-100">
                                                    <div className="user__Detailes">
                                                        <div className="user__name notranslate">
                                                        {   
                                                            language === "English" 
                                                            ? `${data.profile_details?.firstname || ""} ${data.profile_details?.lastname || ""}`.trim() 
                                                            : `${data.profile_details?.arabic_firstname || data.profile_details?.firstname} ${data.profile_details?.arabic_lastname || data.profile_details?.lastname}`.trim()
                                                            }
                                                            {/* {data?.profile_details?.firstname} */}
                                                            </div>
                                                        <div className="user__icon__wrapper" onClick={(e) => e.stopPropagation()}>
                                                            <WebShareComponent params={ROUTES.TUTORS_DETAILE_VIEW + "?id=" + encryptPassData(data?.id)}>
                                                                <img src={shareIcon} alt="shareIcon" className='mb-1' />
                                                            </WebShareComponent>
                                                            <img src={data.is_favourite ? Tutorfavourites : LikeIcon} alt="Learn and Play"
                                                                onClick={() => handleChangeFavourites(data.is_favourite, data?.user)} />
                                                        </div>
                                                    </div>
                                                    <div className="user__Detailes__location">
                                                        <img src={locationIcon} alt="locationIcon" className="grayIcons" />
                                                        <div className="graytext">{data.city}</div>
                                                        <img src={experienceIcon} alt="experienceIcon" className="grayIcons" />
                                                        <div className="graytext">{data?.tutor_subjects?.length > 0 ? data?.tutor_subjects[0]?.experience : ""}</div>
                                                        <img src={teaching_mode} alt="teaching_mode" className="grayIcons" />
                                                        <div className="graytext">{data?.tutor_subjects?.length > 0 ? data?.tutor_subjects[0]?.course_mode : ""}</div>
                                                    </div>

                                                    <div className="user__Detailes__Course">
                                                        <img src={grades} alt="shareIcon" />
                                                        <div className="lable" title={data?.tutor_subjects?.length > 0 ? data?.tutor_subjects[0]?.courses : ""}
                                                        >{data?.tutor_subjects?.length > 0 ? data?.tutor_subjects[0]?.courses : ""}</div>
                                                        <img src={curriculum} alt="curriculum" />
                                                        <div className="lable">{data.curriculum}</div>
                                                    </div>

                                                    <div className="user__Detailes__rating">
                                                        <span className="title">{data?.tutor_subjects?.length > 0 ? "AED " + data?.tutor_subjects[0]?.final_fee : ""}</span>
                                                        <div className="d-flex gap-2 align-items-center">
                                                            {data.rating ? (<StarIcon className="StarIcon" />) : ('')}
                                                            <div className="card__title">{data.rating}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </InfoWindow>
                            )}
                        </Marker>
                    )
                })}
            </GoogleMap >
            {/* </LoadScript > */}

        </>
    ) : (<></>)
};

export default TutorMaps;