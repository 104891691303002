import React, { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import shareIcon from "../../../Assets/School/shareIcon.svg";
import LikeIcon from "../../../Assets/School/schoolLikeIcon.svg";
import comments from "../../../Assets/School/commentIcon.svg";
import CalenderIcon from "../../../Assets/School/CalendarIcon.svg";
import viewOnMap from "../../../Assets/School/ViewOnMap.svg";
import card_bg from "../../../Assets/Tutor/card_bg.png";
import curriculum from "../../../Assets/School/Curiculam.svg";
import DSIBrating from "../../../Assets/School/DSIBrating.svg";
import FoundYear from "../../../Assets/School/FoundYear.svg";
import Ranking from "../../../Assets/School/Ranking.svg";
import AliceCarousel from "react-alice-carousel";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullSharpIcon from "@mui/icons-material/OpenInFullSharp";
import NavigateNextSharpIcon from "@mui/icons-material/NavigateNextSharp";
import NavigateBeforeSharpIcon from "@mui/icons-material/NavigateBeforeSharp";
import { useLocation, useNavigate } from "react-router-dom";
import "./SchoolModelDilogView.scss";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES,
  getGoogleMapLink,
} from "../../../Constants/Constants";
import { Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import SchoolFill from "../../../Assets/HeartFill/SchoolFill.svg";
import {
  GetSessionStorageData,
  encryptPassData,
  nullUndefinedEmptyCheck,
} from "../../../Utilities/Helpers/Helper";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import { setChildLoginRestriction, setErrorMessage, setErrorNotification, setLoader, setLoginSignUpOpen } from "../../../Redux/Reducer/Reducer";
import emailIcon from "../../../Assets/School/emailIcon.svg";
import websiteIcon from "../../../Assets/School/websiteIcon.svg";
import callIcon from "../../../Assets/School/callIcon.svg";
import location_icon from "../../../Assets/Header/header_location_icon.svg";
import pdf from "../../../Assets/School/school__pdf.svg";
import WebShareComponent from "../../../Components/WebShare/WebShare";
import { store } from "../../../Redux/Store/Store";
import ViewMoreLess from "../../../Components/ViewMoreCommon";
import { useTranslation } from "react-i18next";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return (
    <>
      {" "}
      <Slide direction="up" ref={ref} {...props} />
    </>
  );
});

const SchoolModelDilogView = ({
  triggerModel,
  close,
  Detailes,
  schoolList,
  getLatAndLong,
}: any) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const carouselRef = useRef<any>(null);
  const [toturDetailes, setToturDetailes] = useState<any>([]);
  const [value, setValue] = useState(0);
  const [Detailesvalue, setDetailesValue] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  /** Onchange Function to Prev Button*/
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      schoolList?.map((value: any, i: any) => {
        if (currentIndex - 1 === i) {
          setToturDetailes(value);
          getLatAndLong(value?.latitude, value?.longitude);
        }
      });
    }
  };

  /** Onchange Function to Next Button*/
  const handleNext = () => {
    if (currentIndex < schoolList.length - 1) {
      setCurrentIndex(currentIndex + 1);
      schoolList?.map((value: any, i: any) => {
        if (currentIndex + 1 === i) {
          setToturDetailes(value);
          getLatAndLong(value?.latitude, value?.longitude);
        }
      });
    }
  };

  const handleChangeDetailes = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setDetailesValue(newValue);
  };

  /**Slide previous card */
  const slidePrev = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.slidePrev();
    }
  };

  /**Slide next card */
  const slideNext = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.slideNext();
    }
  };

  /**Sync card index on slide */
  const syncActiveIndex = ({ item }: any) => {
    setActiveIndex(item);
  };

  /**Sync card index on slide */
  const navigateTutorDetailedView = () => {
    navigate(ROUTES.SCHOOL_DETAILE_VIEW + "?id=" + encryptPassData(toturDetailes?.id), {
      state: { tutor_Detailes: toturDetailes },
    });
  };

  const CoverImg = toturDetailes?.images?.map((item: any) => {
    return (
      <>
        <img
          src={item ? item : card_bg}
          alt="card_bg" onError={(e: any) => { e.target.src = card_bg; }}
          className="card_bg_img"
        />
      </>
    );
  });

  const CoverImgEmpty = [0, 1, 2]?.map((item: any) => {
    return (
      <>
        <img src={card_bg} alt="card_bg" className="card_bg_img" />
      </>
    );
  });

  useEffect(() => {
    if (nullUndefinedEmptyCheck(Detailes?.id)) {
      setToturDetailes([]);
      getOneList();
    }
  }, [Detailes]);

  /**Get One List*/
  const getOneList = () => {
    var id = "?id=" + Detailes?.id;
    axiosInstance
      .get(`${API_END_POINTS.SCHOOLLIST + id}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          setToturDetailes(Response?.data?.result?.data);
        }
      })
      .catch(() => { });
  };

  const handleChangeFavourites = (val: any, id: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload = {
        service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.FOUR_INT,
        service_provider: id,
        is_favourite: !val,
      };
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            toast.success(Response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
            getOneList();
          }
        })
        .catch((error) => {
          // toast.error(error?.response?.data?.message, {
          //   position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          // });
        });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const DownloadPdf = (data: any) => {
    if (nullUndefinedEmptyCheck(data)) {
      store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      fetch(data, {
        method: "GET",
      })
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          const urlParts = data?.split('/');
          const fileName = urlParts[urlParts?.length - 1];
          a.style.display = "none";
          a.href = url;
          a.download = fileName; // the filename you want
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          setTimeout(() => {
            toast.success("Download Pdf Successfully", {
              position: "top-right",
            });
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
          }, 500);
        })
        .catch(() => {
          setTimeout(() => {
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
          }, 500);
        });
    } else {
      // toast.error("No data Found", {
      //   position: "top-right",
      // });
      store.dispatch(setErrorNotification(true))
      store.dispatch(setErrorMessage("No data Found"))
    }
  };

  const NavigateCalendar = (url: any, id: any) => {
    if (nullUndefinedEmptyCheck(id)) {
      if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
        var payload = {
          school_id: id,
        };
        axiosInstance
          .post(`${API_END_POINTS.SCHOOL_CONTACT_NOTIFICATION}`, payload)
          .then((Response: any) => {
            if (Response?.data?.status) {
              // Open a new tab/window
              // window.open(url, "_blank");
              toast.success(Response?.data?.message, {
                position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
              });
            }
          })
          .catch((error) => {
            // toast.error(error?.response?.data?.message, {
            //   position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            // });
          });
      } else {
        dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      }
    } else {
      // Open a new tab/window
      window.open(url, "_blank");
    }
  };

  const handleOpenMap = (data: any) => {
    let getDestination = { lat: data.latitude, lng: data.longitude }
    const mapsUrl = getGoogleMapLink(getDestination);
    if (mapsUrl) {
      window.open(mapsUrl, '_blank');
    }
  };

  const handleChatRequest = () => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
        axiosInstance
          .get(`${API_END_POINTS.SCHOOL_EXPERT + '?school_id=' + toturDetailes?.id}`)
          .then((Response: any) => {
            if (Response?.data?.status) {
              var status = Response?.data?.result?.data[0];
              if (status?.room_id === 0) {
                let payload = {
                  school_id: toturDetailes?.id,
                }
                axiosInstance
                  .post(`${API_END_POINTS.SCHOOL_EXPERT}`, payload)
                  .then((Response: any) => {
                    if (Response?.data?.status) {
                      var status_new = Response?.data?.result?.data[0];
                      navigate(ROUTES.SCHOOL_CHAT_VIEW, {
                        state: { roomData: status_new, is_notification: false, },
                      });
                    }
                  })
                  .catch(() => { });
              } else {
                navigate(ROUTES.SCHOOL_CHAT_VIEW, {
                  state: { roomData: status, is_notification: false, },
                });
              }
            }
          })
          .catch(() => { });
      } else {
        dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      }
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const school__tap__contant = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "school__tap__contant" : "school__tap__contant_arb";
  const top_cover_img_wrapper = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "top_cover_img_wrapper" : "top_cover_img_wrapper_arb";

  return (
    <>
      <div className="Model__Dialog__container">
        <Dialog
          open={triggerModel}
          onClose={close}
          TransitionComponent={Transition}
          keepMounted
          PaperProps={{
            style: {
              position: "absolute",
              right: 0,
              top: 100,
              bottom: -25,
              minWidth: 200,
              backgroundColor: "transparent", // Set the background color to transparent
              boxShadow: "none", // Optional:
            },
          }}
        >
          {/* <div className="background_circle_left__School_model"></div> */}
          <div className="background_circle_right__School_model"></div>
          <DialogContent sx={{ zIndex: 1 }} dir={
            language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
              ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
              : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
          } >
            <div className="top__contant__wapper__school">
              <div className="top__PageBtn__icons">
                {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                  <NavigateBeforeSharpIcon className={currentIndex > 0 ? "prev__icons" : "icons__disable"} onClick={handlePrev} />
                ) : (
                  <NavigateNextSharpIcon className={currentIndex > 0 ? "prev__icons" : "icons__disable"} onClick={handlePrev} />
                )}
                <div
                  className={currentIndex > 0 ? "prev__text" : "text__disable"}
                  onClick={handlePrev}
                >
                  {t("Prev")}
                </div>
                <div
                  className={
                    currentIndex < schoolList?.length - 1
                      ? "prev__text"
                      : "text__disable"
                  }
                  onClick={handleNext}
                >
                  {t("Next")}
                </div>
                {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                  <NavigateNextSharpIcon className={currentIndex < schoolList?.length - 1 ? "next__icons" : "icons__disable"} onClick={handleNext} />
                ) : (
                  <NavigateBeforeSharpIcon className={currentIndex < schoolList?.length - 1 ? "next__icons" : "icons__disable"} onClick={handleNext} />
                )}
              </div>
              <div className="top__close__icons">
                <Tooltip title="Expanded">
                  <OpenInFullSharpIcon
                    className="expanded__icons"
                    onClick={navigateTutorDetailedView}
                  />
                </Tooltip>
                <Tooltip title="close">
                  <CloseIcon className="close__icons" onClick={close} />
                </Tooltip>
              </div>
            </div>
            <div className={top_cover_img_wrapper}>
              <div className="arrow_navs">
                <NavigateBeforeSharpIcon
                  onClick={slidePrev}
                  className="left_arrow"
                />
                <NavigateNextSharpIcon
                  onClick={slideNext}
                  className="right_arrow"
                />
              </div>
              <AliceCarousel
                // infinite
                mouseTracking
                autoWidth
                disableButtonsControls
                items={
                  toturDetailes?.images?.length > 0
                    ? CoverImg
                    : CoverImgEmpty
                }
                disableDotsControls
                activeIndex={activeIndex}
                onSlideChanged={syncActiveIndex}
                ref={carouselRef}
              />
              <img
                src={toturDetailes?.images ? toturDetailes?.images[0] : tutor_img}
                alt="tutor_img"
                className="profileImg"
                onError={(e: any) => { e.target.src = tutor_img }}
              />
            </div>

            <div className="card__container">
              <div className="w-100">
                <div className="user__Detailes">
                  <div className="notranslate user__name" translate="no">
                  { language === "English" ? toturDetailes?.school_name : toturDetailes?.arabic_school_name || toturDetailes?.school_name }
                    </div>
                  <div className="user__icon__wrapper">
                    {/* <img src={comments} alt="comments" /> */}
                    <WebShareComponent
                      params={
                        ROUTES.SCHOOL_DETAILE_VIEW +
                        "?id=" +
                        encryptPassData(toturDetailes?.id)
                      }
                    >
                      <img src={shareIcon} alt="shareIcon" className="mb-1" />
                    </WebShareComponent>
                    <img
                      src={toturDetailes.is_favourite ? SchoolFill : LikeIcon}
                      alt=""
                      onClick={() =>
                        handleChangeFavourites(
                          toturDetailes.is_favourite,
                          toturDetailes?.user
                        )
                      }
                    />
                  </div>
                </div>

                <div className="View__on__map_School_container">
                  {nullUndefinedEmptyCheck(
                    toturDetailes?.academic_calendar
                  ) && (
                      <div
                        className="wapperDiv"
                        onClick={() =>
                          NavigateCalendar(toturDetailes?.academic_calendar, null)
                        }
                      >
                        <img src={CalenderIcon} alt="" />
                        <span>{t("Academic Calendar")}</span>
                      </div>
                    )}
                  <div
                    className="wapperDiv"
                    onClick={() => {
                      handleOpenMap(toturDetailes)
                    }}
                  >
                    <img src={viewOnMap} alt="" />
                    <span>{t("View on Map")}</span>
                  </div>
                </div>

                <div className="row School__detailed__container">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                    <div className="detailed__container__wapper">
                      <img src={DSIBrating} alt="" />
                      <span>{t("DSIB Rating")}</span>
                    </div>
                    <div className="number">{toturDetailes?.dsip_rating}</div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                    <div className="detailed__container__wapper">
                      <img src={FoundYear} alt="" />
                      <span>{t("Founded")}</span>
                    </div>
                    <div className="number">{toturDetailes?.founded_year ? toturDetailes?.founded_year : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                    <div className="detailed__container__wapper">
                      <img src={curriculum} alt="" />
                      <span>{t("Curriculum")}</span>
                    </div>
                    <div className="number">{toturDetailes?.curriculam}</div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                    <div className="detailed__container__wapper">
                      <img src={Ranking} alt="" />
                      <span>{t("Ranking")}</span>
                    </div>
                    <div className="number">{toturDetailes?.ranking ? toturDetailes?.ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                  </div>
                </div>

                {toturDetailes?.description && (
                  <div className="university__description__container">
                    <div className="title">{t("Description")}</div>
                    <ViewMoreLess
                      text={toturDetailes?.description}
                      maxLength={100}
                    />
                  </div>
                )}

                {(toturDetailes?.application_process?.length > 0 || toturDetailes?.eligibility_criteria?.length > 0) && (
                  <div className="school__Taps__container school__Taps__container__width">
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Tabs
                        className="School__Taps"
                        value={value}
                        centered
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        textColor="secondary"
                        indicatorColor="secondary"
                        variant="scrollable"
                      // scrollButtons
                      >
                        <Tab label={t("Application Process")} {...a11yProps(0)} />
                        <Tab label={t("Eligibility Criteria")} {...a11yProps(1)} />
                      </Tabs>
                    </Box>

                    <TabPanel value={value} index={0}>
                      <div className={school__tap__contant}>
                        <ul>
                          {toturDetailes?.application_process?.map(
                            (data: any) => {
                              return <li>{data}</li>;
                            }
                          )}
                        </ul>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div className={school__tap__contant}>
                        <ul>
                          {toturDetailes?.eligibility_criteria?.map(
                            (data: any) => {
                              return <li>{data}</li>;
                            }
                          )}
                        </ul>
                      </div>
                    </TabPanel>
                  </div>
                )}
                <div className="school__Taps__container school__Taps__container__width">
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      className="School__Taps"
                      value={Detailesvalue}
                      centered
                      onChange={handleChangeDetailes}
                      aria-label="basic tabs example"
                      textColor="secondary"
                      indicatorColor="secondary"
                      variant="scrollable"
                    // scrollButtons
                    >
                      <Tab label={t("School Details")} {...a11yProps(0)} />
                      <Tab label={t("Fees Structure")} {...a11yProps(1)} />
                      <Tab label={t("Fees Per Year")} {...a11yProps(2)} />
                    </Tabs>
                  </Box>

                  <TabPanel value={Detailesvalue} index={0}>
                    <div className="School__personal__detailes__wapper">
                      <div className="detailes__wapper">
                        <div className="left__side">
                          <img src={websiteIcon} alt="" />
                          <span>{t("Website")}</span>
                        </div>
                        <a
                          className="link"
                          href={toturDetailes?.web_link}
                          target="_blank"
                        >
                          {toturDetailes?.web_link}
                        </a>
                      </div>
                      <div className="detailes__wapper">
                        <div className="left__side">
                          <img src={emailIcon} alt="" />
                          <span>{t("Email")}</span>
                        </div>
                        <div className="number">{toturDetailes?.email}</div>
                      </div>
                      <div className="detailes__wapper">
                        <div className="left__side">
                          <img src={callIcon} alt="" />
                          <span>{t("Call")}</span>
                        </div>
                        <div className="number">
                          {toturDetailes?.phone_number}
                        </div>
                      </div>
                      {/* <div className="detailes__wapper">
                        <div className="left__side">
                          <img src={PersonIcon} alt="" />
                          <span>{t("Principal")}</span>
                        </div>
                        <div className="text">{toturDetailes?.principal}</div>
                      </div> */}
                      <div className="detailes__wapper">
                        <div className="left__side">
                          <img src={location_icon} alt="" />
                          <span>{t("Location")}</span>
                        </div>
                        <div className="text" title={toturDetailes?.address}>
                          {toturDetailes?.address}
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={Detailesvalue} index={1}>
                    <div className="School__fess__structure__wapper">
                      {toturDetailes?.fees_structure?.map((data: any) => {
                        return (
                          <div className="detailes__wapper">
                            <div className="left__side">
                              {/* <img src={down_arrow} alt="" /> */}
                              <span>{Object?.keys(data)}</span>
                            </div>
                            <div className="fees__wapper">
                              <div className="text">{t("AED")}</div>
                              <div className="number">
                                {Object?.values(data)}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </TabPanel>
                  <TabPanel value={Detailesvalue} index={2}>
                    <div className="School__fess__per_Year__wapper">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col" className="light_text">{t("Grade\Year")}</th>
                            <th scope="col" className="light_text">{t("Annual Fee")}</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {toturDetailes?.fees_per_year?.map((data: any) => {
                            return (
                              <tr>
                                <td className="text">{data?.grade}</td>
                                <td><div className="fees__wapper">
                                  <div className="text">{t("AED")}</div>
                                  <div className="number">{data?.fee}</div>
                                </div></td>
                                {data?.detail && (
                                  <td><img
                                    src={pdf}
                                    alt="pdf"
                                    onClick={() => DownloadPdf(data?.detail)}
                                  /></td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </TabPanel>
                </div>

                <div className="school_join__now_wapper">
                  <button
                    className="join__now_Btn"
                    onClick={() => {
                      const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
                      if (nullUndefinedEmptyCheck(accessToken)) {
                        // NavigateCalendar(toturDetailes?.web_link, toturDetailes?.id);
                        handleChatRequest();
                      } else {
                        dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
                      }
                    }}>
                    {t("Chat with our consultant")}
                  </button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default SchoolModelDilogView;
