import {
  GetSessionStorageData,
  SetSessionStorageData,
  nullUndefinedEmptyCheck,
} from "../Helpers/Helper";
import axios from "axios";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
} from "../../Constants/Constants";
import { store } from "../../Redux/Store/Store";
import { setErrorMessage, setErrorNotification, setLoader } from "../../Redux/Reducer/Reducer";
import { toast } from "react-hot-toast";

/**Global axios instance */
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_BACKEND_API_URL,
});

/**API request interceptor */
axiosInstance.interceptors.request.use(
  (config: any): any => {
    store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    const HeaderLoctionName: any = GetSessionStorageData(AUTHENTICATION.SHARED.COUNTRY_NAME)
    const RegionName: any = GetSessionStorageData(AUTHENTICATION.SHARED.REGION_NAME)
    const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN) || GetSessionStorageData(AUTHENTICATION.SHARED.GUEST_ACCESS_TOKEN);
    if (nullUndefinedEmptyCheck(accessToken)) {
      config.headers["Authorization"] = "Bearer " + `${accessToken}`;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["country"] = HeaderLoctionName ?? "";
    config.headers["city"] = RegionName ?? "";
    return config;
  },
  (error: any): any => {
    return Promise.reject(error);
  }
);

/**API response interceptor */
axiosInstance.interceptors.response.use(
  (response: any): any => {
    store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    return response;
  },
  (error: any): any => {
    // Check for the `skipToaster` flag
    if (error.config && error.config.skipToaster) {
      store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
      return Promise.reject(error); // Skip the toaster and return the error
    } else if (error?.response?.status === 401) {
      const refreshToken = GetSessionStorageData(
        AUTHENTICATION.SHARED.REFRESH_TOKEN
      );
      if (nullUndefinedEmptyCheck(refreshToken)) {
        let payload: any = {
          refresh: refreshToken,
        };
        axiosInstance
          .post(API_END_POINTS.REFRESH_TOKEN, payload)
          .then((response: any) => {
            if (
              response.data[AUTHENTICATION.SHARED.STATUS] ===
              AUTHENTICATION.SHARED.TRUE
            ) {
              SetSessionStorageData(
                AUTHENTICATION.SHARED.REFRESH_TOKEN,
                response.data.access
              );
            }
          });
      } else {
        store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
      }
    } else {
      store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
      // toast.error(error?.response?.data?.message, {
      //   position: "top-right"
      // });
      store.dispatch(setErrorNotification(true))
      store.dispatch(setErrorMessage(error?.response?.data?.message))


    }
    return Promise.reject(error);
  }
);

export { axiosInstance };
