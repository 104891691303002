import React from "react";
import LegalAndSecurity from "../LegalAndSecurity/LegalAndSecurity";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const TaCDialog = (props:any) => {

    return(
        <>
        <Dialog
        open={props.TaCopen}
        onClose={()=>{
            props.handleCloseDialog()
        }}
        >
            <DialogContent>
                <LegalAndSecurity/>
            </DialogContent>
        </Dialog>
        </>
    )
};

export default TaCDialog;