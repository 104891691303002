import activitiesIcon from "../../../Assets/Activities/activitiesIcon.svg";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import PhoneInput from "react-phone-input-2";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES,
} from "../../../Constants/Constants";
import { isValidPhoneNumber } from "libphonenumber-js";
import "./ActivitiesCheckOut.scss";
import { useState, useEffect } from "react";
import { GetSessionStorageData } from "../../../Utilities/Helpers/Helper";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { nullUndefinedEmptyCheck } from "../../../Utilities/Helpers/Helper";
import defaultProfile from "../../../Assets/Authentication/defaultProfile.svg";
import toast from "react-hot-toast";
import calendar_ac from "../../../Assets/Activities/calendar_ac.svg";
import total_session from "../../../Assets/Activities/total_session.svg";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import ClockIcon from "../../../Assets/Activities/Clock.svg";
import Discount_symble_blue from "../../../Assets/Global/Discount_activity.svg";
import Discount_Symbole from "../../../Assets/Global/Discount_Symbole.svg";
import back_img from "../../../Assets/Activities/back_img.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TaCDialog from "../../../Components/TaCDialog/TaCDialog";
import { setTermsandConditions } from "../../../Redux/Reducer/Reducer";
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import locationIcon from "../../../Assets/Tutor/location.svg";
const ActivitiesCheckOut = () => {
  const route = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [userProfileImg, setUserProfileImg] = useState<any>();
  const [userName, setUserName] = useState<any>("");
  const [userEmail, setUserEmail] = useState<any>("");
  const [userMobile, setUserMobile] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [orderDetailes, setOrderDetailes] = useState([]);
  const [childProfileData, setChildProfileData] = useState<any>([]);
  const [parentProfileData, setParentProfileData] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>([]);
  const [selectedCampChild, setSelectedCampChild] = useState<any>([]);
  const [ProfileData, setProfileData] = useState<any>("");
  const [PaymentTotal, setPaymentTotal] = useState<any>("");
  const [DiscountList, setDiscountList] = useState([]);
  const [DiscountArray, setDiscountArray] = useState<any>([]);
  const [DiscountSelect, setDiscountSelect] = useState<any>(route?.state?.discount_id);
  const [DiscountPrice, setDiscountPrice] = useState<any>(0);
  const [FreeSlotList, setFreeSlotList] = useState([]);
  const [FreeSlotCount, setFreeSlotCount] = useState<any>("");
  const [TaCopen, setTaCOpen] = useState<boolean>(false);
  const [addressMessage, setAddressMessage] = useState<string>("")
  const [aMOpen, setAMOpen] = useState<boolean>(false)
  const [courseIndex, setCourseIndex] = useState<any>(route?.state?.courseIndex)
  
  const dispatch = useDispatch();

  const handleClickOpen = (value: any) => {
    if (!route?.state?.camp_book_session) {
      addressValidation(selectedAddress, courseIndex)
    } else {
      setOpen(true)
    }
    setViewMode(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAMClose = () => {
    setAMOpen(false)
  }

  const handleCloseDialog = () => {
    setTaCOpen(false);
  };

  /** handle Discount*/
  const handleDiscount = (option: any) => {
    axiosInstance
      .get(`${API_END_POINTS.VALIDATE_DISCOUNT + option}`)
      .then((Response: any) => {
        if (Response?.data?.result?.is_discount) {
          DiscountSelect == option
            ? setDiscountSelect("")
            : setDiscountSelect(option);
        }
      })
      .catch((error) => { });
  };

  const addressValidation = (id: number, state: any) => {
    let payload = {
      address_id: id,
      location: state?.state
    }
    axiosInstance.post(API_END_POINTS.ADDRESS_VALIDATION, payload)
      .then((res: any) => {
        if (res?.data?.status) {
          if (res?.data?.result?.data?.is_book) {
            setOpen(true)
          } else {
            setAddressMessage(res?.data?.message)
            setAMOpen(true)
          }
        }
      })
      .catch(() => { })
  }

  /**Get Discount List*/
  const get_Discount_List = () => {
    axiosInstance
      .get(
        `${API_END_POINTS.DISCOUNT_LIST +
        "?trainer_activity_id=" +
        route?.state?.activity_sesstion_id
        }`
      )
      .then((Response: any) => {
        if (Response?.data?.status) {
          setFreeSlotList(Response?.data?.result?.data);
          if (nullUndefinedEmptyCheck(route?.state?.discount_id)) {
            var Array = Response?.data?.result?.data?.filter(
              (item: any) => item?.id === route?.state?.discount_id
            );
            setDiscountArray(Array);
          } else {
            var Array = Response?.data?.result?.data?.filter(
              (item: any) =>
                item?.total_slots <= route?.state?.activities_checkOut?.length
            );
            setDiscountList(Array);
          }
        }
      })
      .catch(() => { });
  };

  /*calculating the total amount without discount (Original Price) */
  const calculateTotal = () => {
    // Use the reduce method to sum the array elements
    const total = route?.state?.activities_checkOut.reduce(
      (accumulator: any, slot_price: any) => {
        return accumulator + +slot_price.slot_price || 0;
      },
      0
    ); // 0 is the initial value of the accumulator
    return total;
  };

  /* calculating overall price without discount (Original Price) */
  const calculateOriginalCamp = () => {
    return (
      route?.state?.campsData?.total_class_fee * selectedCampChild.length || 0
    );
  };

  /*calculating overall camp discount amount only (Discounts)*/
  const calculateCampDiscount = () => {
    return (
      (route?.state?.campsData?.total_class_fee *
        route?.state?.campsDiscount?.buy_sessions *
        parseInt(route?.state?.campsDiscount?.discount)) /
      100 || 0
    );
  };

  /*calculating price with dicount for camp & PT (Total) */
  const calculateTotalAct = () => {
    if (route?.state?.camp_book_session) {
      return Math.abs(
        +calculateOriginalCamp().toFixed(0) -
        +calculateCampDiscount().toFixed(0) || 0
      );
    } else {
      return calculateTotal() - +DiscountPrice || 0;
    }
  };

  /* calculating VAT for camp & PT 5% */
  const calculateVat = () => {
    return +(calculateTotalAct() * 0.05 || 0).toFixed(0);
  };

  /*calculating final amount with VAT for camp % PT (To Pay) */
  const calculateToPay = () => {
    return calculateTotalAct() + calculateVat();
  };

  // Create a map to store groups
  const groupData = () => {
    const groupedData = new Map();

    route?.state?.activities_checkOut.forEach((item: any) => {
      const { title, start_time, end_time } = item;
      const groupKey = `${title}-${start_time}-${end_time}`;

      if (!groupedData.has(groupKey)) {
        groupedData.set(groupKey, { title, start_time, end_time, dates: [] });
      }

      const group = groupedData.get(groupKey);
      group.dates.push(item?.session_slot_date);
    });

    // Convert the map to an array of objects
    const result: any = Array.from(groupedData.values());
    setOrderDetailes(result);
  };

  useEffect(() => {

    if (!route?.state?.camp_book_session) {
      groupData();
      setProfileData(route?.state?.person);
      setPaymentTotal(calculateTotal());
      get_Discount_List();
    }
    getAddress()
    getProfileList();
    setUserProfileImg(route?.state?.childProfileData?.profile_image);
    setUserName(route?.state?.childProfileData?.name);
    setUserEmail(GetSessionStorageData(AUTHENTICATION.SHARED.EMAIL));
  }, []);

  useEffect(() => {
    if (!route?.state?.camp_book_session) {
      var oneSlot_price = route?.state?.activities_checkOut[0]?.slot_price;
      var Original_price = calculateTotal();
      var Vat_price = calculateVat();
      var Free_slot: any = FreeSlotList?.filter(
        (item: any) => item?.id === DiscountSelect
      );
      var discount_amount = Free_slot[0]?.free_slots * oneSlot_price || 0;
      setDiscountPrice(discount_amount);
      setFreeSlotCount(Free_slot[0]?.free_slots);
    }
  }, [DiscountSelect, FreeSlotList]);

  /**Get profile data */
  const getProfileList = () => {
    var selectArray: any = [];
    axiosInstance
      .get(`${API_END_POINTS.PROFILE_LIST}`)
      .then((profileListResponse: any) => {
        if (
          profileListResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          setParentProfileData(profileListResponse.data.result.data.parent);
          setChildProfileData(profileListResponse?.data?.result?.data?.child);
          profileListResponse.data.result.data.parent?.map((item: any) =>
            selectArray.push(item)
          );
          profileListResponse?.data?.result?.data?.child?.map((item: any) =>
            selectArray.push(item)
          );
          if (route?.state?.camp_book_session) {
            const selectedCamp = route?.state?.selected_child_data
              ?.map((rou: any) => {
                return selectArray?.filter((res: any) => {
                  if (rou === res?.id) {
                    return res;
                  }
                });
              })
              ?.flat();
            setSelectedCampChild(selectedCamp);
          } else {
            var SelectedArr = selectArray?.filter(
              (item: any) => item.id == route?.state?.childProfileData?.id
            );
            setSelectedOption(SelectedArr[0]);
          }
          setUserMobile(
            profileListResponse?.data?.result?.data?.parent[0]?.phone_number
          );
        }
      })
      .catch(() => { });
  };

  const payload = () => {
    var transformedData: any = [];
    // Iterate through the input response and transform each item
    route?.state?.activities_checkOut?.forEach((item: any) => {
      transformedData?.push({
        time_slot_id: item.id,
        start_time: item.start_time,
        end_time: item.end_time,
        slot_price: item.slot_price,
        start_date: moment(item.start).format(
          GLOBAL_CONSTANTS.INITIAL_VALUES.DATE_FORMAT
        ),
        session_slot_id: item.session_slot,
        session_id: item?.session_id,
      });
    });
    const transformedSlotIds = route?.state?.activities_checkOut?.map(
      (item: any) => item.id.toString()
    );

    var Response = {
      child_id: selectedOption?.is_primary
        ? null
        : selectedOption?.id.toString(),
      // service_provider_id: route?.state?.activities_checkOut[0]?.service_provider_id.toString(),
      teach_service_provider_id:
        route?.state?.activities_checkOut[0]?.teach_service_provider_id.toString(),
      // session_title: route?.state?.activities_checkOut[0]?.title,
      sp_user_id: route?.state?.activities_checkOut[0]?.user_id,
      booked_email: userEmail,
      booked_phone_number: userMobile,
      time_slot_ids: transformedSlotIds,
      slot_count: route?.state?.activities_checkOut?.length.toString(),
      payment_mode: GLOBAL_CONSTANTS.INITIAL_VALUES.CARD,
      payment_type: GLOBAL_CONSTANTS.INITIAL_VALUES.ONLINE,
      booked_slot_detail: transformedData,
      is_discount: nullUndefinedEmptyCheck(DiscountSelect) ? true : false,
      discount_id: nullUndefinedEmptyCheck(DiscountSelect)
        ? DiscountSelect
        : null,
      free_slots_count: nullUndefinedEmptyCheck(DiscountSelect)
        ? FreeSlotCount
        : "",
      total_amount: calculateTotal(),
      discount_amount: nullUndefinedEmptyCheck(DiscountSelect)
        ? DiscountPrice
        : 0.0,
      final_amount: calculateToPay(),
      free_session: GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE,
      is_primary: selectedOption?.is_primary ? true : false,
      parent_id: selectedOption?.is_primary ? selectedOption?.id : null,
      vat_percent: 5,
      vat_amount: calculateVat() || 0,
      vat_total_amount: calculateToPay(),
      user_address: selectedAddress || null,
      commission: courseIndex?.commission || null,
    };

    return Response;
  };

  /**Post slot booking Api */
  const slotBookingApi = () => {
    var pay = payload();
    axiosInstance
      .post(`${API_END_POINTS.SLOT_BOOKING}`, pay)
      .then((Response: any) => {
        if (Response?.data?.status) {
          toast.success(Response?.data?.message, {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          });
          handleClose();
          navigate(ROUTES.ACTIVITY_PAYMENT_SUCCESS, {
            state: {
              calender_link: Response?.data?.result?.data,
              ProfileData: ProfileData,
              PaymentTotal: calculateToPay(),
              orderDetailes: orderDetailes,
              camp_book_session: false,
            },
          });
        }
      })
      .catch((error) => {
        handleClose();
      });
  };

  const handleTermsandConditions = () => {
    axiosInstance
      .post(API_END_POINTS.TERMS_AND_CONDITIONS)
      .then((response) => {
        if (response.data.status) {
        }
      })
      .catch(() => { });
  };

  /**Post Camps booking Api */
  const CampsBookingApi = () => {
    var pay = {
      teach_service_provider_id: 3,
      is_primary: false,
      booked_email: userEmail,
      booked_phone_number: userMobile,
      payment_mode: GLOBAL_CONSTANTS.INITIAL_VALUES.CARD,
      payment_type: GLOBAL_CONSTANTS.INITIAL_VALUES.ONLINE,
      sp_user_id: route?.state?.campsData?.user,
      session_id: route?.state?.campsData?.id,
      child_ids: route?.state?.selected_child_data,
      total_amount: calculateOriginalCamp(),
      discount_amount: route?.state?.campsDiscount || calculateCampDiscount(),
      final_amount: route?.state?.campsDiscount
        ? calculateTotalAct()
        : calculateOriginalCamp(),
      discount_id: route?.state?.campsDiscount.id,
      is_discount: route?.state?.campsDiscount ? true : false,
      vat_percent: 5,
      vat_amount: calculateVat(),
      vat_total_amount: calculateToPay(),
      commission: route?.state?.campsData?.commission || null,
    };

    axiosInstance
      .post(`${API_END_POINTS.CAMPS_BOOKING}`, pay)
      .then((Response: any) => {
        if (Response?.data?.status) {
          toast.success(Response?.data?.message, {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          });
          handleClose();
          navigate(ROUTES.ACTIVITY_PAYMENT_SUCCESS, {
            state: {
              calender_link: Response?.data?.result?.data,
              camp_book_session: true,
              campsData: route?.state?.campsData,
              camp_booking_amount: calculateToPay(),
            },
          });
        }
      })
      .catch((error) => {
        handleClose();
      });
  };

  /**onclick Function to get particular child data*/
  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const handleChecked = () => {
    dispatch(setTermsandConditions(true));
    handleTermsandConditions();
  };

  // An array with one date is considered sorted and continuous or not.
  const isDateArray = (arr: any) => {
    if (arr.length <= 1) {
      return true;
    }

    for (let i = 1; i < arr.length; i++) {
      const currentDate: any = new Date(arr[i]);
      const previousDate: any = new Date(arr[i - 1]);

      // Check if the current date is not one day after the previous date
      if (currentDate - previousDate !== 24 * 60 * 60 * 1000) {
        return false; // Dates are not continuous.
      }

      // Check if the current date is greater than or equal to the previous date
      if (currentDate <= previousDate) {
        return false; // Dates are not sorted in ascending order.
      }
    }
    return true; // Dates are sorted in ascending order and continuous.
  };

  // show the un order dates
  function formatDatesWithSpaces(dateArray: any) {
    const formattedDates = dateArray.map((dateStr: any) => {
      const [year, month, day] = dateStr.split("-");
      return `${day}/${month}/${year}`;
    });

    return formattedDates.join(", ");
  }

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  const checked: any = useSelector((state: any) => {
    return state.TermsandConditionsReducer.TermsandConditions;
  });

  /*Below class name based on English Arabic*/
  const Activities_back_img =
    language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
      ? "back-img"
      : "back-img_arb";
  const marginRight_space =
    language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ms-3" : "me-3";

  const handleAddressChnage = (event: any) => {
    setSelectedAddress(event.target.value)
  }


  const getAddress = () => {
    axiosInstance.get(API_END_POINTS.MANAGE_ADDRESS)
      .then((response) => {
        setallAddress(response?.data?.result.data)
        setSelectedAddress(response?.data?.result?.data[0].id)
      }).catch(() => { })
  }

  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [allAddress, setallAddress] = useState<any>([]);

  return (
    <>
      <div className="Activitiescheckout-container">
        {/* <div className="ActivitiesImg_wapper">
                    <img src={activitiesIcon} alt="" className="img-size1" />
                    <label className="checkout-txt">Checkout</label>
                </div> */}
        <div className="activities_Img_wapper" onClick={() => navigate(-1)}>
          <img src={back_img} alt="" className={Activities_back_img} />
          <img
            draggable="false"
            src={activitiesIcon}
            alt="activitiesImg"
            className=""
          />
          <div className="activities__text">{t("Checkout")}</div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-7">
            <div className="billing-container">
              <label className="billingtext">{t("Billing Contact")}</label>
              <div className="inputfieldss">
                <div className="billng_contact_fields">
                  <label className="emailtext">{t("Email ID")}</label>
                  <input
                    className="inputdesign1"
                    value={userEmail}
                    readOnly
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                    }}
                    placeholder="Enter Your mail address"
                  />
                </div>
                <div className="phone-num">
                  <label className="emailtext">{t("Phone Number")}</label>
                  <PhoneInput
                    inputProps={{
                      className: "mobile__input",
                      name: `${AUTHENTICATION.LOGIN_SIGNUP_FIELDS.MOBILE}`,
                    }}
                    disabled
                    value={userMobile}
                    country={"ae"}
                    countryCodeEditable={GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE}
                    enableSearch={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
                  />
                </div>
              </div>
              <div className="OrderDetails__contants mt-3">
                <div className="billingtext">{t("Order Details")}</div>
              </div>

              {route?.state?.camp_book_session ? (
                <>
                  <div className=" leftside__contant">
                    <div className="OrderDetails__contants mb-3">
                      <div className="coursetext">{t("Course Details")}</div>
                      <div className="Order__amount">
                        AED{" "}
                        {route?.state?.camp_book_session
                          ? route?.state?.campsData?.total_class_fee
                          : calculateTotal()}
                      </div>
                    </div>
                    <div className="age_text">
                      {t("Age")} {route?.state?.campsData?.max_age}{" "}
                      {t("And Below")}
                    </div>
                    <div className="contant_wapper">
                      <div className="title">
                        {route?.state?.campsData?.camp_session_title}
                      </div>
                      {/* <div className="price_text">{route?.state?.campsData?.total_class_fee} AED</div> */}
                    </div>
                    <div className="descrption">
                      {route?.state?.campsData?.description}
                    </div>
                    <div className="date__filed_wapper">
                      <div className="date__filed_wapper mb-3">
                        <img src={calendar_ac} alt="" />
                        <span>
                          {moment(
                            new Date(route?.state?.campsData?.start_date)
                          ).format("MMM DD")}{" "}
                          -{" "}
                          {moment(
                            new Date(route?.state?.campsData?.end_date)
                          ).format("MMM DD")}
                        </span>
                      </div>
                      <div className="date__filed_wapper mb-3">
                        <img src={total_session} alt="" />
                        <span>
                          {route?.state?.campsData.total_class} Classes
                        </span>
                      </div>
                    </div>
                    <div className="date__filed_wapper">
                      <div className="date__filed_wapper mb-3">
                        <img src={ClockIcon} alt="" />
                        <span>
                          {route?.state?.campsData?.start_time} -{" "}
                          {route?.state?.campsData?.end_time}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {orderDetailes.slice(0, 2)?.map((data: any) => {
                    return (
                      <>
                        <div className="leftside__contant">
                          <div className="OrderDetails__contants">
                            <div className="left__contant">
                              {isDateArray(data?.dates) ? (
                                <div className="date">
                                  {moment(data?.dates[0]).format("DD/MM/YYYY")}{" "}
                                  -{" "}
                                  {moment(
                                    data?.dates[data?.dates?.length - 1]
                                  ).format("DD/MM/YYYY")}
                                </div>
                              ) : (
                                <div className="date">
                                  {formatDatesWithSpaces(data?.dates)}
                                </div>
                              )}
                              <div className="course">{data.title}</div>
                              {/* <div className="session__count">No. of sessions : {data?.dates?.length}</div> */}
                            </div>

                            <div className="right__contant">
                              {t("Sessions")}: {data?.dates?.length} (From:{" "}
                              {moment(data?.start_time, "HH:mm:ss").format(
                                "h:mm a"
                              )}{" "}
                              - To:{" "}
                              {moment(data?.end_time, "HH:mm:ss").format(
                                "h:mm a"
                              )}
                              )
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
              {orderDetailes.length > 2 && (
                <div
                  className="OrderDetails__view__more"
                  onClick={() => handleClickOpen(true)}
                >
                  {" "}
                  {t("View More")}
                </div>
              )}

              <div className="Course__Details__bottom__contant">
                <div className="OrderDetails__contants mb-3">
                  <div className="coursetext">{t("Course to")}</div>
                  <div className="coursetext mr-4" >{t("Address")}</div>
                </div>
                <div className="bottom__contant__wapper">
                  {route?.state?.camp_book_session ? (
                    <>
                      <div className="bottom__contant">
                        <AvatarGroup max={2}>
                          {selectedCampChild.map((item: any) => {
                            return (
                              <>
                                <Avatar
                                  alt="Remy Sharp"
                                  src={
                                    nullUndefinedEmptyCheck(item?.profile_image)
                                      ? item?.profile_image
                                      : defaultProfile
                                  }
                                  onError={(e: any) => {
                                    e.target.src = defaultProfile;
                                  }}
                                />
                              </>
                            );
                          })}
                        </AvatarGroup>
                        {selectedCampChild.length > 1 ? (
                          <div className="camp_child_img_text">
                            {t("Kid Account")} {t("and")}{" "}
                            {selectedCampChild.length - 1} {t("More Person")}
                          </div>
                        ) : (
                          <div className="camp_child_img_text">
                            {t("Kid Account")}
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="bottom__contant">
                      {/* <div>Course to</div> */}
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl>
                          <Select
                            labelId="custom-select-label"
                            id="custom-select"
                            className="child_select__dropdown"
                            value={selectedOption}
                            onChange={handleChange}
                          >
                            {parentProfileData?.map(
                              (parentProfileElements: any, i: any) => {
                                return (
                                  <MenuItem
                                    key={i}
                                    value={parentProfileElements}
                                  >
                                    <div className="avatar_name_wrapper">
                                      <div className="avatar_name_active_container">
                                        <img
                                          src={
                                            nullUndefinedEmptyCheck(
                                              parentProfileElements.profile_image
                                            )
                                              ? parentProfileElements.profile_image
                                              : defaultProfile
                                          }
                                          onError={(e: any) => {
                                            e.target.src = defaultProfile;
                                          }}
                                          alt="Learn and Play"
                                          className="avatar_pic"
                                        />
                                        <div className="profile_name_role">
                                          <div className="profile_name">
                                            {parentProfileElements?.name}
                                          </div>
                                          <div className="role">
                                            {t("Parent")}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </MenuItem>
                                );
                              }
                            )}
                            {childProfileData?.map(
                              (childProfileElements: any, i: any) => {
                                return (
                                  <MenuItem
                                    key={i}
                                    value={childProfileElements}
                                  >
                                    <div className="avatar_name_wrapper">
                                      <div className="avatar_name_active_container">
                                        <img
                                          src={
                                            nullUndefinedEmptyCheck(
                                              childProfileElements.profile_image
                                            )
                                              ? childProfileElements.profile_image
                                              : defaultProfile
                                          }
                                          onError={(e: any) => {
                                            e.target.src = defaultProfile;
                                          }}
                                          alt="Learn and Play"
                                          className="avatar_pic"
                                        />
                                        <div className="profile_name_role">
                                          <div className="profile_name">
                                            {childProfileElements?.name}
                                          </div>
                                          <div className="role">{t("Kid")}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                  )}

                  <div>
                    {route?.state?.camp_book_session ? (
                      <div className="bottom__contant">
                        <img
                          src={
                            route?.state?.campsData?.camp_images.length > 0
                              ? route?.state?.campsData?.camp_images[0]
                              : tutor_img
                          }
                          alt=""
                        />
                        <div className="name">
                          {route?.state?.campsData?.camp_session_title}
                        </div>
                      </div>
                    ) : (
                      <div className="bottom__contant">
                        <img
                          src={
                            nullUndefinedEmptyCheck(ProfileData?.profile_image)
                              ? ProfileData?.profile_image
                              : tutor_img
                          }
                          alt=""
                        />
                        <div className="name">
                          {ProfileData?.profile_details?.firstname +
                            " " +
                            ProfileData?.profile_details?.lastname}
                        </div>
                      </div>
                    )}
                  </div>


                </div>
                {
                  <div className="bottom__contant__wapper mt-3">

                    <div className="d-flex flex-column ">

                      <p className="name mb-0 " style={{ fontWeight: "500" }}>{t("SP Teaching Location")}</p>
                      <div className="d-flex flex-row gap-2 mb-0 text-font"> <p className=" mb-0">{t("State :")}</p>
                        {courseIndex?.state && courseIndex?.state?.map((res: any, index: number) => (
                          <span key={index} className="mb-0" style={{ fontSize: "14px" }}>
                            {res?.state}
                            {index < courseIndex.state.length - 1 && ","}
                          </span>
                        ))}</div>
                      <div className="d-flex flex-row flex-start gap-2  mb-0 "><p className=" mb-0 text-font">{t("Radius :")}</p>
                        <span className="text-font">{courseIndex?.state?.length > 0 ? courseIndex?.state[0]?.radius : ""}</span>
                      </div>

                    </div>
                    <div className="bottom__contant">


                      <p className="coursetext">{t("Address")}</p>
                      <Box >
                        <FormControl>
                          <Select
                            key={selectedAddress}
                            value={selectedAddress}
                            onChange={handleAddressChnage}
                            className="Address_select__dropdown"
                            // sx={{ minWidth: "100px" }}
                            sx={{ width: "15" }}
                            labelId="custom-select-label"
                            id="custom-select"
                            label="Address"
                            placeholder="Address"
                          >
                            {allAddress?.map((res: any) => (
                              <MenuItem key={res?.id} value={res?.id} className="d-flex gap-2">
                                <img src={locationIcon} alt="LocationIcon" width={15} /><span style={{ fontSize: "14px" }}>{res?.address}</span>
                              </MenuItem>
                            ))}
                            <MenuItem
                              value=""
                              onClick={() => {
                                navigate("/accounts/add-address");
                              }}
                              className="d-flex gap-2"
                            >
                              <AddCircleOutlineSharpIcon className="plus_icon" sx={{
                                width: "19px"
                              }} />
                              <span style={{ fontSize: "14px" }}>Add Address</span>
                            </MenuItem>
                          </Select>

                        </FormControl>
                      </Box>
                    </div>

                  </div>
                }
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="summary">
              <div className="Calendar__discount__container__activity">
                {nullUndefinedEmptyCheck(route?.state?.discount_id) ? (
                  <>
                    {DiscountArray?.map((data: any, i: any) => {
                      return (
                        <>
                          <div
                            className={
                              DiscountSelect == data?.id
                                ? "discount__card__active"
                                : "discount__card"
                            }
                            onClick={() => handleDiscount(data?.id)}
                          >
                            <img
                              src={
                                DiscountSelect == data?.id
                                  ? Discount_Symbole
                                  : Discount_symble_blue
                              }
                              alt=""
                              className="discount__Img"
                            />
                            <div className="Black_text">
                              {t("Buy")} {data?.buy_slots} {t("sessions")},
                            </div>
                            <div className="blue__text">
                              {t("Get")} {data?.free_slots}
                            </div>
                            <div className="badge_blue">{t("free")}</div>
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {DiscountList?.map((data: any, i: any) => {
                      return (
                        <>
                          <div
                            className={
                              DiscountSelect == data?.id
                                ? "discount__card__active"
                                : "discount__card"
                            }
                            onClick={() => handleDiscount(data?.id)}
                          >
                            <img
                              src={
                                DiscountSelect == data?.id
                                  ? Discount_Symbole
                                  : Discount_symble_blue
                              }
                              alt=""
                              className="discount__Img"
                            />
                            <div className="Black_text">
                              {t("Buy")} {data?.buy_slots} {t("sessions")},
                            </div>
                            <div className="blue__text">
                              {t("Get")} {data?.free_slots}
                            </div>
                            <div className="badge_blue">{t("free")}</div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}

                {route?.state?.camp_book_session &&
                  route?.state?.campsDiscount && (
                    <div className="discount__card__active">
                      <img
                        src={Discount_Symbole}
                        alt=""
                        className="discount__Img"
                      />
                      <div className="Black_text">
                        {t("Book")} {t("for")}{" "}
                        {route?.state?.campsDiscount?.buy_sessions}{" "}
                        {route?.state?.campsDiscount?.buy_sessions > 1
                          ? "Children"
                          : "Child"}
                        ,
                      </div>
                      <div className="blue__text">
                        {t("Get")}{" "}
                        {parseInt(route?.state?.campsDiscount?.discount)}%
                      </div>
                      <div className="badge_blue">{t("free")}</div>
                    </div>
                  )}
              </div>
              <label className="billingtext">{t("Summary")}</label>
              <div className="Price__detailes__wapper">
                <div className="original-price">
                  <label className="original__price__text">
                    {t("Original Price")}
                  </label>
                  {/* <label className="original__price__text_amount">AED {route?.state?.camp_book_session ? route?.state?.campsData.total_class_fee  : calculateTotal()}</label> */}
                  <label className="original__price__text_amount">
                    {t("AED")}{" "}
                    {route?.state?.camp_book_session
                      ? calculateOriginalCamp()
                      : calculateTotal()}
                  </label>
                </div>
                <div className="original-price">
                  <label className="discount-text">{t("Discounts")}</label>
                  {route?.state?.camp_book_session ? (
                    <label className="discount-text_amount">
                      -{t("AED")} {calculateCampDiscount()}
                    </label>
                  ) : (
                    <label className="discount-text_amount">
                      -{t("AED")} {DiscountPrice}
                    </label>
                  )}
                </div>
              </div>
              <hr className="hr-tag" />

              <div className="Price__detailes__wapper">
                <div className="original-price">
                  <label className="original__price__text">{t("Total")}</label>
                  <label className="original__price__text_amount">
                    {t("AED")} {calculateTotalAct()}
                  </label>
                </div>
                <div className="original-price">
                  <label className="discount-text">{t("VAT 5%")}</label>
                  <label className="discount-text_amount">
                    +{t("AED")} {calculateVat().toFixed(0)}
                  </label>
                </div>
              </div>
              <hr className="hr-tag" />

              <div className="Price__detailes__wapper">
                <div className="original-price">
                  <label className="total__large">{t("To Pay")}</label>
                  <label className="total__large">
                    {t("AED")} {calculateToPay()}
                  </label>
                </div>
              </div>
              <div className="summary__contant">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={checked}
                  onChange={handleChecked}
                />
                <label className={`paratext ${marginRight_space}`}>
                  {t("By completing your purchase, you agree to these")}{" "}
                  <span
                    onClick={() => {
                      setTaCOpen(true);
                    }}
                    className="terms__text"
                  >
                    {t("Terms of Services")}
                  </span>
                </label>
              </div>

              <TaCDialog
                TaCopen={TaCopen}
                handleCloseDialog={handleCloseDialog}
              />
              <div className="summary-btndiv">
                <button
                  className={checked ? "proceedBtn" : "proceedBtn__disable"}
                  disabled={checked ? false : true}
                  onClick={() => handleClickOpen(false)}
                >
                  {t("Proceed Payment")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={aMOpen} onClose={handleAMClose}>
        <DialogTitle>
          {"Address Mismatch"}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleAMClose}
            aria-label="close"
            sx={{
              position: "absolute",
              top: 6,
              right: 13,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="Tutor__OrderDetails__Dailoge">
            <div className="contant__wapper">
              {addressMessage}
            </div>
          </div>
          <div className="Tutor__bottom__btn">
            <button className="backBtn" onClick={handleAMClose}>
              {t("Back")}
            </button>
          </div>
        </DialogContent>
      </Dialog>

      {open && (
        <div className="overlay-ModelBackround">
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
              {viewMode ? t("Order summary") : t("Confirm Order Summary")}
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                sx={{
                  position: "absolute",
                  top: 6,
                  right: 13,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className="Activities__OrderDetails__Dailoge">
                {route?.state?.camp_book_session ? (
                  <>
                    <div className=" leftside__contant">
                      <div className="age_text">
                        {t("Age")} {route?.state?.campsData?.max_age}{" "}
                        {t("And Below")}
                      </div>
                      <div className="contant_wapper">
                        <div className="title">
                          {route?.state?.campsData?.camp_session_title}
                        </div>
                        <div className="price_text">
                          {calculateToPay()} {t("AED")}
                        </div>
                      </div>
                      <div className="descrption">
                        {route?.state?.campsData?.description}
                      </div>
                      <div className="date__filed_wapper ">
                        <div className="date__filed_wapper mb-3">
                          <img src={calendar_ac} alt="" />
                          <span>
                            {moment(
                              new Date(route?.state?.campsData?.start_date)
                            ).format("MMM DD")}{" "}
                            -{" "}
                            {moment(
                              new Date(route?.state?.campsData?.end_date)
                            ).format("MMM DD")}
                          </span>
                        </div>
                        <div className="date__filed_wapper mb-3">
                          <img src={total_session} alt="" />
                          <span>
                            {route?.state?.campsData.total_class} {t("classes")}
                          </span>
                        </div>
                      </div>
                      <div className="date__filed_wapper ">
                        <div className="date__filed_wapper mb-3">
                          <img src={ClockIcon} alt="" />
                          <span>
                            {route?.state?.campsData?.start_time} -{" "}
                            {route?.state?.campsData?.end_time}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {orderDetailes?.map((data: any, i: any) => {
                      return (
                        <div className="contant__wapper" key={i}>
                          <div className="left__contant">
                            {isDateArray(data?.dates) ? (
                              <div className="date">
                                {moment(data?.dates[0]).format("DD/MM/YYYY")} -{" "}
                                {moment(
                                  data?.dates[data?.dates?.length - 1]
                                ).format("DD/MM/YYYY")}
                              </div>
                            ) : (
                              <div className="date">
                                {formatDatesWithSpaces(data?.dates)}
                              </div>
                            )}
                            <div className="course">{data.title}</div>
                            {/* <div className="session__count">No. of sessions : {data?.dates?.length}</div> */}
                          </div>
                          <div className="right__contant">
                            {t("Sessions")}: {data?.dates?.length} (From:{" "}
                            {moment(data?.start_time, "HH:mm:ss").format(
                              "h:mm a"
                            )}{" "}
                            - To:{" "}
                            {moment(data?.end_time, "HH:mm:ss").format(
                              "h:mm a"
                            )}
                            )
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
              <div className="Activities__bottom__btn">
                {viewMode ? (
                  <button className="backBtn" onClick={handleClose}>
                    {t("Back")}
                  </button>
                ) : (
                  <button
                    className="paymentBtn"
                    onClick={() =>
                      route?.state?.camp_book_session
                        ? CampsBookingApi()
                        : slotBookingApi()
                    }
                  >
                    {t("Proceed to payment")}
                  </button>
                )}
              </div>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default ActivitiesCheckOut;
