import { useEffect, useState } from "react";
import "./UniversityDashboardLayout.scss";
import search from "../../Assets/Tutor/searchIconGray.svg";
import universityIcon from "../../Assets/University/universityIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  API_END_POINTS,
  AUTHENTICATION,
  FILTER_CONSTANT,
  GLOBAL_CONSTANTS,
  ROUTES,
  onInputChangeGoogleApi,
} from "../../Constants/Constants";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { store } from "../../Redux/Store/Store";
import { setLoader, setLoginSignUpOpen, setUniversityFilter } from "../../Redux/Reducer/Reducer";
import {
  GetSessionStorageData,
  encryptPassData,
  SetSessionStorageData,
  getCurrentLocation,
  isObjectEmptyValue,
  nullUndefinedEmptyCheck,
} from "../../Utilities/Helpers/Helper";
import ExpandMap from "../../Assets/Activities/ExpandMap.svg";
import UniversityMaps from "../../Components/UniversityMaps/UniversityMaps";
import universityImg from "../../Assets/University/universityImg.png";
import UniversityLikeIcon from "../../Assets/University/UniversityLikeIcon.svg";
import universityShareIcon from "../../Assets/University/universityShareIcon.svg";
import locationIcon from "../../Assets/Tutor/location.svg";
import toast from "react-hot-toast";
import UniversityFill from "../../Assets/HeartFill/UniversityFill.svg";
import { useDispatch, useSelector } from "react-redux";
import spacemen from "../../Assets/Global/spacemen.svg";
import WebShareComponent from "../../Components/WebShare/WebShare";
import AppFilter from "../../Components/AppFilter.tsx/AppFilter";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

// Render the  Map view Page size
const containerStyle = {
  width: "100%",
  height: "100%",
};

const UniversityDashboardLayout = () => {
  const { t } = useTranslation();
  const University_search = GetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.UNIVERSITY);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState(University_search ? University_search : "");
  const [UniversityList, setUniversityList] = useState<any>([]);
  const [list, setList] = useState<any>([])
  const [searchTextList, setSearchTextList] = useState([]);
  const [currentLat, setCurrentLat] = useState<any>("");
  const [currentLang, setCurrentLang] = useState<any>("");
  const [IsTrigger, setIsTrigger] = useState<any>(null);
  const [nearSearchList, setNearSearchList] = useState<any>([]);

  const getUniversityFilter: any = useSelector((state: any) => {
    return state.filterReducer?.universityfilter
  })

  const init_Universityfilter: any = useSelector((state: any) => {
    return state.initFilterReducer.initUniversityfilter
  })
  // Filter
  const init_filter = {
    programs: [],
    price: [0, 0],
    qs_ranking: [0, 0],
    the_ranking: [0, 0],
    sh_ranking: [0, 0],
    language_instruction: [],
    radius: 10,
  }
  const [FilterPopOpen, setFilterPopOpen] = useState(false);
  const [FilterData, setFilterData] = useState<any>([]);
  const [initFilter, setInitFilter] = useState<any>(isObjectEmptyValue(init_Universityfilter) ? init_Universityfilter : init_filter);
  const [FilterApplyData, setFilterApplyData] = useState<any>(init_filter);
  const [radius, setRadius] = useState(init_filter.radius)
  const [initSort, setSort] = useState<any>({
    price: '',
    rating: '',
    the_rating: '',
    sh_rating: '',
  });

  const onOpenFilterPop = () => {
    setFilterPopOpen(true);
  };

  const onCloseFilterPop = () => {
    setFilterPopOpen(false);
  };


  // Route to the Map view Page
  const handleRouteMap = () => {

    navigate(ROUTES.UNIVERSITY_MAPS_VIEW, {
      state: {
        University_list: UniversityList,
        search_text: searchValue,
        redius: FilterApplyData.radius,
        lat: currentLat,
        lang: currentLang,
        filter: FilterApplyData,
        filterBoolean: IsTrigger,
      },
    });
  };

  /**Sync card index on slide */
  const navigateUniversityDetailedView = (value: any) => {
    navigate(ROUTES.UNIVERSITY_DETAILE_VIEW + "?id=" + encryptPassData(value?.id), {
      state: { tutor_Detailes: value },
    });
  };

  /**Header search location*/
  const HeaderLoction: any = useSelector((state: any) => {
    return state.locationReducer.location;
  });

  useEffect(() => {
    if (nullUndefinedEmptyCheck(HeaderLoction?.lat && HeaderLoction?.lng)) {
      setCurrentLat(HeaderLoction?.lat);
      setCurrentLang(HeaderLoction?.lng);
      if (getUniversityFilter && isObjectEmptyValue(getUniversityFilter)) {
        UniversityListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, getUniversityFilter);
      } else {
        UniversityListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, FilterApplyData);
      }
    }
  }, [HeaderLoction, FilterApplyData]);

  useEffect(() => {
    getFilterData();
  }, []);

  const payload = (latitude: any, longitude: any, value: string, filter: any) => {
    var payload;
    var qsValue: any = [];
    var shValue: any = [];
    var theValue: any = [];
    var priceValue: any = [];

    if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
      priceValue = []
    } else if (initFilter.price[0]==0 && initFilter.price[1]==0) {
      priceValue = []
    }     else {
      priceValue = {
        min: filter.price[0],
        max: filter.price[1],
      }
    }
    if (initFilter.qs_ranking[0] === filter.qs_ranking[0] && initFilter.qs_ranking[1] === filter.qs_ranking[1]) {
      qsValue = []
    }else if (initFilter.qs_ranking[0]==0 && initFilter.qs_ranking[1]==0) {
      priceValue = []
    } else {
      qsValue = {
        min: filter.qs_ranking[0],
        max: filter.qs_ranking[1],
      }
    }

    if (initFilter.sh_ranking[0] === filter.sh_ranking[0] && initFilter.sh_ranking[1] === filter.sh_ranking[1]) {
      shValue = []
    }else if (initFilter.sh_ranking[0]==0 && initFilter.sh_ranking[1]==0) {
      priceValue = []
    } else {
      shValue = {
        min: filter.sh_ranking[0],
        max: filter.sh_ranking[1],
      }
    }

    if (initFilter.the_ranking[0] === filter.the_ranking[0] && initFilter.the_ranking[1] === filter.the_ranking[1]) {
      theValue = []
    }else if (initFilter.the_ranking[0]==0 && initFilter.the_ranking[1]==0) {
      priceValue = []
    } else {
      theValue = {
        min: filter.the_ranking[0],
        max: filter.the_ranking[1],
      }
    }
    return payload = {
      latitude: latitude?.toString(),
      longitude: longitude?.toString(),
      radius: filter.radius,
      search: value ? value : "",
      price: priceValue,
      qs_ranking: qsValue,
      sh_ranking: shValue,
      the_ranking: theValue,
      programs: filter.programs,
      language_instruction: filter.language_instruction
    };

  };

  /**Tutor get List Api*/
  const UniversityListApi = (latitude: any, longitude: any, value: string, filter: any) => {
    var pay = payload(latitude, longitude, value, filter);
    axiosInstance
      .post(`${API_END_POINTS.UNIVERSITYLIST}`, pay)
      .then((Response: any) => {
        setList(Response?.data?.result?.data)
        if (initSort?.price || initSort?.rating || initSort?.the_rating || initSort?.sh_rating) {
          handleProceedSort(initSort?.price, initSort?.rating, initSort?.the_rating, initSort?.sh_rating, Response?.data?.result?.data )
        }else {
          setUniversityList(Response?.data?.result?.data)
        }
      })
      .catch(() => { });
  };

  /**Tutor Search Keyword Api*/
  const searchUniversityKeyword = (search: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    var payload = {
      search_text: search,
      latitude: `${currentLat}`,
      longitude: `${currentLang}`,
      radius: radius
    };
    axiosInstance
      .post(`${API_END_POINTS.UNIVERSITY_KEY_WORD_SEARCH}`, payload)
      .then((Response: any) => {
        setSearchTextList(Response?.data.result?.data);
      })
      .catch(() => { });
  };

  /**Get Filter Categories List*/
  const getFilterData = () => {
    axiosInstance
      .get(`${API_END_POINTS.UNIVERSITYFILTERLIST}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          let data = Response?.data?.result?.data;
          setFilterData(data);
          if (data) {
            let init = {
              programs: [],
              price: [data.price?.min, data.price?.max],
              // qs_ranking: [0, 0],
              // the_ranking: [0, 0],
              // sh_ranking: [0, 0],
              qs_ranking: [data.qs_ranking?.min, data.qs_ranking?.max],
              the_ranking: [data.the_ranking?.min, data.the_ranking?.max],
              sh_ranking: [data.sh_ranking?.min, data.sh_ranking?.max],
              language_instruction: [],
              radius: 10,
            }
            setInitFilter(init);
            SetSessionStorageData(FILTER_CONSTANT.INIT_FILTER.UNIVERSITY, JSON.stringify(init));
            if (getUniversityFilter && isObjectEmptyValue(getUniversityFilter)) {
              setFilterApplyData(getUniversityFilter);
              setRadius(getUniversityFilter.radius);
            } else {
              setFilterApplyData(init);
            }
          }

        }
      })
      .catch(() => { });
  };

  /**Filter Apply api call Function*/
  const FilterApply = (filter: any) => {
    setIsTrigger(true);
    SetSessionStorageData(FILTER_CONSTANT.STATE_FILTER.UNIVERSITY, JSON.stringify(filter));
    store.dispatch(setUniversityFilter(filter));
    setFilterApplyData(filter)
    setRadius(filter.radius);
    UniversityListApi(currentLat, currentLang, searchValue, filter);
  };

  /**AutoComplete Onchange Function*/
  const onInputChange = (value: any) => {
    store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    if (nullUndefinedEmptyCheck(value)) {
      setSearchValue(value);
      SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.UNIVERSITY, value);
      if (value?.length > 2) {
        searchUniversityKeyword(value);
      }
    }
  };

  /**AutoComplete submit Function*/
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //  prevent page refresh
    event.preventDefault();
    UniversityListApi(currentLat, currentLang, searchValue, FilterApplyData);
  };

  /**onInputChangeGoogle api Function*/
  const onInputChangeGoogle = async (value: any) => {
    var response = await onInputChangeGoogleApi(value);
    setNearSearchList(response?.data?.results);
  };

  /**Change the location api Function*/
  const NearPacleApi = (data: any) => {
    setCurrentLat(data?.geometry?.location?.lat);
    setCurrentLang(data?.geometry?.location?.lng);
    UniversityListApi(
      data?.geometry?.location?.lat,
      data?.geometry?.location?.lng,
      searchValue,
      FilterApplyData
    );
  };

  const handleChangeFavourites = (val: any, id: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload = {
        service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT,
        service_provider: id,
        is_favourite: !val,
      };
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            UniversityListApi(currentLat, currentLang, searchValue, FilterApplyData);
            toast.success(Response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
          }
        })
        .catch((error) => {
          // toast.error(error?.response?.data?.message, {
          //   position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          // });
        });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  /**Loader state*/
  const Loader: any = useSelector((state: any) => {
    return state.loaderReducer.loader;
  });

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const Ssearch_Button = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ssearch_button_english" : "ssearch_button_arab";
  const RowOverRideSchool = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "row-override-school" : "row-override-school_arab";



  const handlePassListPage = (value: any) => {
    if (nullUndefinedEmptyCheck(value)) {
      if (value?.includes("near me")) {
        navigate(ROUTES.UNIVERSITY_LIST_VIEW, {
          state: {
            University_list: UniversityList,
            search_text: value,
            redius: FilterApplyData.radius,
            lat: currentLat,
            lang: currentLang,
            map_show: true,
            filter: FilterApplyData,
            filterBoolean: IsTrigger,
          },
        });
      } else {
        navigate(ROUTES.UNIVERSITY_LIST_VIEW, {
          state: {
            University_list: UniversityList,
            search_text: value,
            redius: FilterApplyData.radius,
            lat: currentLat,
            lang: currentLang,
            map_show: false,
            filter: FilterApplyData,
            filterBoolean: IsTrigger,
          },
        });
      }
    } else {
      UniversityListApi(currentLat, currentLang, value, FilterApplyData);
    }
  };

  const sort: any = GetSessionStorageData("sort")
  useEffect(() => {
    let data = JSON.parse(sort)
    if (data?.price || data?.rating || data?.the_rating || data?.sh_rating) {
      handleProceedSort(data?.price, data?.rating, data?.the_rating, data?.sh_rating)
    }
  }, [sort])

  const handleProceedSort = (checkedPrice: any, checkedRating: any, theRating: any = "", shRating: any = "", data: any = null) => {
    const sortedData = data ? [...data] : [...list]

    // sorting based on price
    if (checkedPrice == 'high') {
      sortedData.sort((a: any, b: any) => b?.least_price - a?.least_price);
    } else if (checkedPrice == 'low') {
      sortedData.sort((a: any, b: any) => a?.least_price - b?.least_price);
    }

    // sorting based on rating or qs_ranking
    else if (checkedRating == 'high') {
      sortedData.sort((a: any, b: any) => {
        if (a?.QS_ranking == null) return 1;  
        if (b?.QS_ranking == null) return -1; 
        return a.QS_ranking - b.QS_ranking; 
      });
        
    } else if (checkedRating == 'low') {
      sortedData.sort((a: any, b: any) => {
        if (a?.QS_ranking == null) return 1;  
        if (b?.QS_ranking == null) return -1; 
        return b.QS_ranking - a.QS_ranking; 
      })
    }
    // Sorting based on the_rating
    else if (theRating === "high") {
      sortedData.sort((a: any, b: any) => {
        if (a?.THE_ranking == null) return 1;  
        if (b?.THE_ranking == null) return -1; 
        return a.THE_ranking - b.THE_ranking; 
      });
    } else if (theRating === "low") {
      sortedData.sort((a: any, b: any) => {
        if (a?.THE_ranking == null) return 1;  
        if (b?.THE_ranking == null) return -1; 
        return b.THE_ranking - a.THE_ranking; 
      });
    }
    // Additional sorting for sh_Rating
    else if (shRating === "high") {
      sortedData.sort((a: any, b: any) => {
        if (a?.SH_ranking == null) return 1;  
        if (b?.SH_ranking == null) return -1; 
        return a.SH_ranking - b.SH_ranking; 
      });
    } else if (shRating === "low") {
      sortedData.sort((a: any, b: any) => {
        if (a?.SH_ranking == null) return 1;  
        if (b?.SH_ranking == null) return -1; 
        return b.SH_ranking - a.SH_ranking; 
      });
    }

    setUniversityList(sortedData);
    // setToturDetailes(sortedData[0]);
    setSort({
      price: checkedPrice,
      rating: checkedRating,
      the_rating: theRating,
      sh_rating: shRating,
    })
  }
  /**get selected or current locationName from redux */
  const HeaderLocationName: any = useSelector((state: any) => {
    return state.locationNameReducer.locationName;
  });

  return (
    <>

      <div className="University__dashboard__container">
        <div className="row">
          <div className="col-12 title_container">
            <div className="Dashboard__UniversityCard_Img_wapper">
              <img
                draggable="false"
                src={universityIcon}
                alt="TutorImg"
                className=""
              />
              <div className="text">{t("Universities")}</div>
            </div>
          </div>

          {/* <div className="row  mb-2 row-override-school"> */}
          <div className={`row  mb-2 ${RowOverRideSchool}`}>
            <div className="col-lg-3 col-md-12 input-style-phone">
              <div className="search_field_wrapper">
                <div className="search__autocomplete">
                  <form onSubmit={handleSubmit}>
                    <Autocomplete
                      freeSolo
                      popupIcon
                      id="free-solo-2-demo"
                      disableClearable={false}
                      value={searchValue}
                      clearIcon={false}
                      onChange={(e, value) => {
                        const trimedvalue = value ? value.trim() : "";
                        setSearchValue(trimedvalue);
                        handlePassListPage(trimedvalue);
                        SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.UNIVERSITY, trimedvalue);
                      }}
                      options={searchTextList?.map(
                        (option: any) =>
                          language === "English" ? option?.university_name : option?.arabic_university_name || option?.university_name
                        // option?.university_name
                      )}
                      renderOption={(props, option) => (
                        <Box component='li' sx={[{ px: 0.5, py: 0.7 }]} {...props} className="notranslate" translate="no">
                          {option}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("Search for University")}
                          onChange={(inputChangeEvent: any) => {
                            onInputChange(inputChangeEvent.target.value.trim());
                          }}
                          onKeyDown={(e: any) => {
                            if (e.key === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                              e.preventDefault();
                              const trimedvalue = searchValue.trim();
                              if (trimedvalue) { handlePassListPage(trimedvalue) }
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            type: "text",
                          }}
                        />
                      )}
                    />
                    <img
                      draggable="false"
                      src={search}
                      alt="search"
                      // className="search_button"
                      className={`${Ssearch_Button} search_button`}
                      onClick={() => handlePassListPage(searchValue)}
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-12">
              <AppFilter
                FilterPopOpen={FilterPopOpen}
                onOpenPop={onOpenFilterPop}
                onClosePop={onCloseFilterPop}
                FilterData={FilterData}
                FilterApply={FilterApply}
                FilterApplyData={FilterApplyData}
                initFilter={initFilter}
                initSort={initSort}
                handleApplySort={handleProceedSort}
                type="university"
                color="#12CC92" />
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">

            <div className="University_map_view">
              <div className="University__viewMap" onClick={handleRouteMap}>
                <img src={ExpandMap} alt="ExpandMap" />
                <span>{t("View on Map")}</span>
              </div>
              <UniversityMaps
                zoom={12}
                radius={FilterApplyData.radius * 1000}
                lat={currentLat}
                lang={currentLang}
                containerStyle={containerStyle}
                disable={true}
                zoomControl={false}
                clickableIcons={false}
                draggable={false}
                UniversityList={UniversityList}
                initSort={initSort}
                getOneTutor={[]}
                filter={isObjectEmptyValue(getUniversityFilter) ? getUniversityFilter : FilterApplyData}
                initFilter={initFilter}
                searchValue={searchValue}
                language={language}
              />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 university__list__container">
            {UniversityList?.map((item: any, i: any) => {
              return (
                <>
                  <div className="university__list__wapper" key={i} onClick={() => navigateUniversityDetailedView(item)}>
                    <div className="university__leftside">
                      <img
                        src={
                          nullUndefinedEmptyCheck(item?.images)
                            ? item?.images
                            : universityImg
                        }
                        alt=""
                        onError={(e: any) => {
                          e.target.src = universityImg;
                        }}
                      />
                    </div>
                    <div className="university__rightside">
                      <div className="university__name">
                        <span className="notranslate name" translate="no">
                          {language === "English" ? item?.university_name : item?.arabic_university_name || item?.university_name}
                        </span>
                        <div className="imageIcon" onClick={(e) => e.stopPropagation()}>
                          <WebShareComponent
                            params={
                              ROUTES.UNIVERSITY_DETAILE_VIEW + "?id=" + encryptPassData(item?.id)
                            }
                          >
                            <img
                              src={universityShareIcon}
                              alt=""
                              className="mb-1"
                            />
                          </WebShareComponent>
                          <img
                            src={
                              item?.is_favourite
                                ? UniversityFill
                                : UniversityLikeIcon
                            }
                            alt=""
                            onClick={() =>
                              handleChangeFavourites(
                                item.is_favourite,
                                item?.user
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="university__location">
                        <img src={locationIcon} alt="" />
                        <span className="location" title={nullUndefinedEmptyCheck(item?.city) ? item?.city : item.address}>
                          {nullUndefinedEmptyCheck(item?.city) ? item?.city : item.address}
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-xxl-4 col-lg-4 col-md-4 university__ranking">
                          <div className="text__black">{t("QS")} :</div>
                          <div className="text__green">{item?.QS_ranking ? item?.QS_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                        </div>
                        <div className="col-xxl-4 col-lg-4 col-md-4 university__ranking">
                          <div className="text__black">{t("THE")} : </div>
                          <div className="text__green">{item?.THE_ranking ? item?.THE_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                        </div>
                        <div className="col-xxl-4 col-lg-4 col-md-4 university__ranking">
                          <div className="text__black">{t("SH")} : </div>
                          <div className="text__green">{item?.SH_ranking ? item?.SH_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                        </div>
                        <div className="col-xxl-6 col-lg-6 col-md-6 marginTop university__ranking">
                          <div className="text__black">{t("No. of students")} :</div>
                          <div className="text__green">{item?.students_count ? item?.students_count : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                        </div>
                        <div className="col-xxl-6 col-lg-6 col-md-6 marginTop university__ranking">
                          <div className="text__black">{t("Mediums")} :</div>
                          <div className="text__green">{item?.available_mediums === 'Both' ? t('Online & On-premises') : item?.available_mediums}</div>
                        </div>
                      </div>
                      <div className="university__rating">
                        <div className="year">
                          {t("Founded")} : {item?.founded_year ? item?.founded_year : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                        </div>
                        {/* <div className="d-flex flex-row">
                          <StarIcon className="StarIcon me-2" />
                          <span className="points">{item?.rating}</span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            {UniversityList?.length === 0 && !Loader && (
              <>
                <div className="No__data__contant">
                  <img src={spacemen} alt="" />
                  <div className="text">{t("No Data Found")}</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default UniversityDashboardLayout;
