import { useEffect, useState, useRef } from "react";
import TutorImg from "../../../Assets/Tutor/TutorImg.svg";
import FilterIcon from "../../../Assets/Tutor/FilterIcon.svg";
import search from "../../../Assets/Tutor/searchIconGray.svg";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import shareIcon from "../../../Assets/Tutor/share.svg";
import LikeIcon from "../../../Assets/Tutor/LikeIcon.svg";
import locationIcon from "../../../Assets/Tutor/location.svg";
import experienceIcon from "../../../Assets/Tutor/experience.svg";
import BadgeIcon from "../../../Assets/Tutor/BadgeIcon.svg";
import right from "../../../Assets/Tutor/right.svg";
import StarIcon from "@mui/icons-material/Star";
import "./TutorsListView.scss";
import TutorMaps from "../../../Components/TutorMaps/TutorMaps";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownMenuItem,
  DropdownNestedMenuItem,
} from "../Dropdown";
import { ArrowRight } from "@mui/icons-material";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import {
  API_END_POINTS,
  AUTHENTICATION,
  FILTER_CONSTANT,
  GLOBAL_CONSTANTS,
  ROUTES,
  RadiusMapKm,
} from "../../../Constants/Constants";
import {
  GetSessionStorageData,
  SetSessionStorageData,
  encryptPassData,
  getCurrentLocation,
  isObjectEmptyValue,
  nullUndefinedEmptyCheck,
} from "../../../Utilities/Helpers/Helper";
import { store } from "../../../Redux/Store/Store";
import { setChildLoginRestriction, setErrorMessage, setErrorNotification, setInitTutorFilter, setLoader, setLoginSignUpOpen, setTutorFilter } from "../../../Redux/Reducer/Reducer";
import { Autocomplete, Box, TextField } from "@mui/material";
import axios from "axios";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import TutorMapRadiusIcon from "../../../Assets/Tutor/tutor_km.svg";
import card_bg from "../../../Assets/Tutor/card_bg.png";
import curriculum from "../../../Assets/Tutor/curriculumBlue.svg";
import teaching_mode from "../../../Assets/Tutor/bookBlue.svg";
import AliceCarousel from "react-alice-carousel";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullSharpIcon from "@mui/icons-material/OpenInFullSharp";
import NavigateNextSharpIcon from "@mui/icons-material/NavigateNextSharp";
import NavigateBeforeSharpIcon from "@mui/icons-material/NavigateBeforeSharp";
import { Tooltip } from "@mui/material";
import comments from "../../../Assets/Tutor/commentIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Tutorfavourites from "../../../Assets/HeartFill/TutorFill.svg";
import Private_session from "../../../Assets/Tutor/twomemberBlue.svg";
import mode_of_teaching from "../../../Assets/Tutor/oneMemberBlue.svg";
import syllabus from "../../../Assets/Tutor/syllabusBlue.svg";
import pdfIcon from "../../../Assets/Tutor/pdfIcon.svg";
import experienceBlue from "../../../Assets/Tutor/experienceBlue.svg";
import spacemen from "../../../Assets/Global/spacemen.svg";
import WebShareComponent from "../../../Components/WebShare/WebShare";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Dialog, DialogContent } from "@mui/material";
import ExpandMap from "../../../Assets/Activities/ExpandMap.svg";
import TutorTrialSession from "../../../Components/TutorTrialSession/TutorTrialSession";
import TutorFilterPopup from "../TutorFilterPopup/TutorFilterPop";
import CalendarIcon from "../../../Assets/Tutor/CALENDAR.svg";
import PdfDownloadFile from "../../../Components/PdfDownload";
import tutor_icon from "../../../Assets/Tutor/TutorImg.svg";
import AppFilter from "../../../Components/AppFilter.tsx/AppFilter";
import { useTranslation } from "react-i18next";

const TutorsListView = () => {
  const { t } = useTranslation();
  const tutor_search = GetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.TUTOR);
  const route = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const carouselRef = useRef<any>(null);
  const [searchValue, setSearchValue] = useState(tutor_search ? tutor_search : '');
  const [triggerVal, setTriggerVal] = useState(true);
  const [tutorList, setTutorList] = useState<any>([]);
  const [list, setList] = useState<any>([])
  const [toturDetailes, setToturDetailes] = useState<any>([]);
  const [searchTextList, setSearchTextList] = useState([]);
  const [currentLat, setCurrentLat] = useState<any>(route?.state?.lat);
  const [currentLang, setCurrentLang] = useState<any>(route?.state?.lang);
  const [selectedCheckExperience, setSelectedCheckExperience] = useState<any>(
    []
  );
  const [FilterRadiusvalue, setFilterRadiusvalue] = useState<number[]>([
    route?.state?.redius ? route?.state?.redius : 10,
  ]);
  const [IsTrigger, setIsTrigger] = useState<any>(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [courseIndex, setCourseIndex] = useState<any>([]);
  const [courseSelectIndex, setCourseSelectIndex] = useState<any>([0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [AuthenticationOpen, setAuthenticationOpen] = useState(false);
  const [CourseOpen, setCourseOpen] = useState(true);
  const [ViewOnMapActive, setViewOnMapActive] = useState(
    route?.state?.map_show
  );
  const [FreeTrialOpen, setFreeTrialOpen] = useState(false);
  const [FilterPopOpen, setFilterPopOpen] = useState(false);
  const [PdfOpen, setPdfOpen] = useState(false);
  const [PdfData, setPdfData] = useState([]);

  const tutorfilter: any = useSelector((state: any) => {
    return state.filterReducer.tutorfilter
  })

  const init_tutorfilter: any = useSelector((state: any) => {
    return state.initFilterReducer.initTutorfilter
  })

  // Filter
  const init_filter = {
    courses: [],
    curriculum: [],
    price: [0, 0],
    mode_of_teaching: [],
    experience: [0, 0],
    rating: 0,
    gender: [],
    grade: [0, 0],
    radius: 10,

  }
  const [FilterData, setFilterData] = useState<any>([]);
  const [initFilter, setInitFilter] = useState<any>(isObjectEmptyValue(init_tutorfilter) ? init_tutorfilter : init_filter);
  const [FilterApplyData, setFilterApplyData] = useState<any>(init_filter);
  const [initSort, setSort] = useState<any>({
    price: '',
    rating: ''
  });

  const onOpenFilterPop = () => {
    setFilterPopOpen(true);
  };

  const onCloseFilterPop = () => {
    setFilterPopOpen(false);
  };

  /** Onchange Function to Prev Button*/
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      tutorList?.map((value: any, i: any) => {
        if (currentIndex - 1 === i) {
          setToturDetailes(value);
          setCourseIndex(value?.tutor_subjects[0]);
          setCurrentLat(value?.latitude);
          setCurrentLang(value?.longitude);
        }
      });
    }
  };

  /** Onchange Function to Next Button*/
  const handleNext = () => {
    if (currentIndex < tutorList?.length - 1) {
      setCurrentIndex(currentIndex + 1);
      tutorList?.map((value: any, i: any) => {
        if (currentIndex + 1 === i) {
          setToturDetailes(value);
          setCourseIndex(value?.tutor_subjects[0]);
          setCurrentLat(value?.latitude);
          setCurrentLang(value?.longitude);
        }
      });
    }
  };

  /**Slide previous card */
  const slidePrev = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.slidePrev();
    }
  };

  /**Slide next card */
  const slideNext = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.slideNext();
    }
  };

  /**Sync card index on slide */
  const syncActiveIndex = ({ item }: any) => {
    setActiveIndex(item);
  };

  /**navigate Tutor DetailedView page */
  const navigateTutorDetailedView = (Detailes: any) => {
    navigate(ROUTES.TUTORS_DETAILE_VIEW + "?id=" + encryptPassData(Detailes?.id), {
      state: { tutor_Detailes: Detailes },
    });
  };

  /**navigate to  BookSlot page*/
  const navigateBookSlot = () => {
    if (nullUndefinedEmptyCheck(courseIndex?.id)) {
      navigate(ROUTES.TUTORS_CALENDAR_VIEW, {
        state: {
          tutor_sesstion_id: courseIndex?.id,
          tutor_del: toturDetailes,
          courseIndex: courseIndex,
          is_reschedule: false,
          tutor_name:
            toturDetailes?.profile_details?.firstname +
            " " +
            toturDetailes?.profile_details?.lastname,
        },
      });
    }
  };

  const CoverImg = toturDetailes?.cover_images?.map((item: any) => {
    return (
      <>
        <img
          src={item ? item : card_bg}
          alt="card_bg"
          className="card_bg_img"
          onError={(e: any) => { e.target.src = tutor_img; }}
        />
      </>
    );
  });

  useEffect(() => {
    Introvideo();
  }, [toturDetailes?.intro_video, courseIndex, carouselRef, CourseOpen])

  const Introvideo = () => {
    if (nullUndefinedEmptyCheck(toturDetailes?.intro_video)) {
      CoverImg?.push(
        <video controls width="270" height="150">
          <source src={toturDetailes?.intro_video} type="video/mp4" />
        </video>
      )
    }
  }

  const CoverImgEmpty = [0, 1, 2]?.map((item: any) => {
    return (
      <>
        <img src={card_bg} alt="card_bg" className="card_bg_img" />
      </>
    );
  });

  /**Header search location*/
  const HeaderLoction: any = useSelector((state: any) => {
    return state.locationReducer.location;
  });

  useEffect(() => {
    if (nullUndefinedEmptyCheck(HeaderLoction?.lat && HeaderLoction?.lng) && !ViewOnMapActive) {
      setCurrentLat(HeaderLoction?.lat);
      setCurrentLang(HeaderLoction?.lng);
      if (tutorfilter && isObjectEmptyValue(tutorfilter)) {
        TutorListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, tutorfilter);
      } else {
        TutorListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, FilterApplyData);
      }
    }
  }, [HeaderLoction, ViewOnMapActive, searchValue]);

  useEffect(() => {
    if (ViewOnMapActive && tutorList?.length > 0) {
      setCurrentLat(tutorList[currentIndex].latitude);
      setCurrentLang(tutorList[currentIndex].longitude);
    }
  }, [ViewOnMapActive])

  /**Get Filter Categories List*/
  useEffect(() => {
    axiosInstance
      .get(`${API_END_POINTS.TUTORFILTERLIST}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          let data = Response?.data?.result?.data;
          setFilterData(data);
          if (data) {
            let init = {
              courses: [],
              curriculum: [],
              price: [data.price?.min, data.price?.max],
              mode_of_teaching: [],
              experience: [data.experience?.min, data.experience?.max],
              rating: 0,
              gender: [],
              grade: [data.grade?.min, data.grade?.max],
              radius: 10,
            }
            store.dispatch(setInitTutorFilter(init));
            SetSessionStorageData(FILTER_CONSTANT.INIT_FILTER.TUTOR, JSON.stringify(init));
            setInitFilter(init);
            setFilterApplyData(isObjectEmptyValue(tutorfilter) ? tutorfilter : init);
          }
        }
      })
      .catch(() => { });
  }, []);

  /**Tutor List  Post Api Payload*/
  const payload = (latitude: any, longitude: any, value: string, filter: any) => {
    var payload;
    var priceValue: any = [];
    var experienceValue: any = [];
    var gradeValue: any = [];

    if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
      priceValue = []
    } else if (filter.price[0]==0 && filter.price[1]==0) {
      priceValue = []
    } else {
      priceValue = {
        min: filter.price[0],
        max: filter.price[1],
      }
    }

    if (initFilter.experience[0] === filter.experience[0] && initFilter.experience[1] === filter.experience[1]) {
      experienceValue = []
    }else if (filter.experience[0]==0 && filter.experience[1]==0) {
      priceValue = []
    } else {
      experienceValue = {
        min: filter.experience[0],
        max: filter.experience[1],
      }
    }
    if (initFilter.grade[0] === filter.grade[0] && initFilter.grade[1] === filter.grade[1]) {
      gradeValue = []
    } else if (filter.grade[0]==0 && filter.grade[1]==0) {
      priceValue = []
    }  else {
      gradeValue = {
        min: filter.grade[0],
        max: filter.grade[1],
      }
    }
    return payload = {
      latitude: latitude?.toString(),
      longitude: longitude?.toString(),
      radius: filter.radius,
      search: value ? value : "",
      price: priceValue,
      experience: experienceValue,
      grade: gradeValue,
      courses: filter.courses,
      mode_of_teaching: filter.mode_of_teaching,
      gender: filter.gender,
      curriculum: filter.curriculum,
      rating: filter.rating,
    };
  };

  /**Tutor get List Api*/
  const TutorListApi = (latitude: any, longitude: any, value: string, filter: any) => {
    var pay = payload(latitude, longitude, value, filter);

    axiosInstance
      .post(`${API_END_POINTS.TUTORLIST}`, pay)
      .then((Response: any) => {
        if (nullUndefinedEmptyCheck(route?.state?.tutor_list)) {
          if (initSort?.price || initSort?.rating) {
            handleProceedSort(initSort?.price, initSort?.rating, route?.state?.tutor_list)
          } else {
            setTutorList(route?.state?.tutor_list)
            setToturDetailes(route?.state?.tutor_list[0]);
            setCourseIndex(route?.state?.tutor_list[0]?.tutor_subjects[0]);
            setCurrentIndex(0);
          }
        } else {
          setList(Response?.data?.result?.data)
          // setTutorList(Response?.data?.result?.data);
          // setToturDetailes(Response?.data?.result?.data[0]);
          // setCourseIndex(Response?.data?.result?.data[0]?.tutor_subjects[0]);
          if (initSort?.price || initSort?.rating) {
            handleProceedSort(initSort?.price, initSort?.rating, Response?.data?.result?.data )
          }else {
            setTutorList(Response?.data?.result?.data);
            setToturDetailes(Response?.data?.result?.data[0]);
            setCourseIndex(Response?.data?.result?.data[0]?.tutor_subjects[0]);
            setCurrentIndex(0)
          }
          // setTriggerVal(Response?.data?.result?.data?.length > 0 ? true : false)
        }
      })
      .catch(() => { });
  };

  /**Get One List*/
  const getOneList = () => {
    var id: any = "?tutor_id=" + route?.state?.tutor_list[0]?.id;
    axiosInstance
      .get(`${API_END_POINTS.TUTOR_DETAIL + id}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          setTutorList([Response?.data?.result?.data]);
          setToturDetailes(Response?.data?.result?.data);
          setCourseIndex(
            Response?.data?.result?.data?.tutor_subjects?.length > 0
              ? Response?.data?.result?.data?.tutor_subjects[0]
              : []
          );
        }
      })
      .catch(() => { });
  };

  /**Tutor Search Keyword Api*/
  const searchTutorKeyword = (search: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    var payload = {
      search_text: search,
      course_tag: FilterApplyData?.courses ? FilterApplyData?.courses.toString() : ""
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_BACKEND_API_URL +
        API_END_POINTS.TUTOR_KEY_WORD_SEARCH
        }`,
        payload
        // {
        //     headers: {
        //         Authorization: "Bearer " + accessToken,
        //     },
        // }
      )
      .then((Response: any) => {
        setSearchTextList(Response?.data.result?.data);
      })
      .catch(() => { });
  };


  /**Filter Apply api call Function*/
  const FilterApply = (filter: any) => {
    setIsTrigger(true)
    setFilterApplyData(filter);
    SetSessionStorageData(FILTER_CONSTANT.STATE_FILTER.TUTOR, JSON.stringify(filter));
    store.dispatch(setTutorFilter(filter))
    TutorListApi(currentLat, currentLang, searchValue, filter);
  };

  /**Autocomplete Onchange Function*/
  const onInputChange = (value: any) => {
    store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    if (nullUndefinedEmptyCheck(value)) {
      setSearchValue(value);
      SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.TUTOR, value);
      if (value.length > 2) {
        searchTutorKeyword(value);
      }
    };
  };

  /**Autocomplete Submit Function*/
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //  prevent page refresh
    event.preventDefault();
    TutorListApi(currentLat, currentLang, searchValue, FilterApplyData);
  };

  const handleChangeRadiusMapvalue = (value: any) => {
    if (FilterRadiusvalue[0] === value) {
      setFilterRadiusvalue([0.5]);
    } else {
      setFilterRadiusvalue([value]);
    }
  };

  /**Open the Model Diloge*/
  const handlePassSchoolData = (value: any) => {
    tutorList?.map((item: any, i: any) => {
      if (value === i) {
        setToturDetailes(item);
        setCurrentIndex(value);
        setCourseIndex(item?.tutor_subjects[0]);
      }
    });
    if (nullUndefinedEmptyCheck(value)) {
      setTriggerVal(true);
    } else {
      setTriggerVal(false);
    }
  };

  const handleChangeFavourites = (val: any, id: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload = {
        service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT,
        service_provider: id,
        is_favourite: !val,
      };
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            if (nullUndefinedEmptyCheck(route?.state?.tutor_list)) {
              getOneList();
            } else {
              TutorListApi(currentLat, currentLang, searchValue, FilterApplyData);
            }
            toast.success(Response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
          }
        })
        .catch((error) => { });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  /**Loader state*/
  const Loader: any = useSelector((state: any) => {
    return state.loaderReducer.loader;
  });

  const handleAuthentication = () => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.CHILD) {
        dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      } else {
        navigateBookSlot()
      }
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const handleAuthenticationFreeTrial = () => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.CHILD) {
        dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      } else {
        onOpenFreeTrial();
      }
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const DownloadPdf = (data: any) => {
    if (nullUndefinedEmptyCheck(data)) {
      store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      fetch(data, {
        method: "GET",
      })
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          const urlParts = data?.split('/');
          const fileName = urlParts[urlParts?.length - 1];
          a.style.display = "none";
          a.href = url;
          a.download = fileName; // the filename you want
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          setTimeout(() => {
            toast.success("Download Pdf Successfully", {
              position: "top-right",
            });
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
          }, 500);
        })
        .catch(() => {
          setTimeout(() => {
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
          }, 500);
        });
    } else {
      // toast.error("No data Found", {
      //   position: "top-right",
      // });
      store.dispatch(setErrorNotification(true))
      store.dispatch(setErrorMessage("No data Found"))
    }
  };

  /**Coursecategory Based filter the Data*/
  const handlePassCoursecategory = (value: any) => {
    if (nullUndefinedEmptyCheck(value)) {
      if (value?.includes("near me")) {
        handleRouteMap(value);
      } else {
        TutorListApi(currentLat, currentLang, value, FilterApplyData);
      }
    } else {
      TutorListApi(currentLat, currentLang, value, FilterApplyData);
    }
  };

  // Render the  Map view Page size
  const containerStyle = {
    width: "100%",
    height: "100%",
  };



  // Route to the Map view Page
  const handleRouteMap = (value: any) => {
    navigate(ROUTES.TUTORS_MAPS_VIEW, {
      state: {
        tutor_list: tutorList,
        search_text: value,
        redius: FilterRadiusvalue[0],
        lat: currentLat,
        lang: currentLang,
        filter: FilterApplyData,
        filterBoolean: IsTrigger,
      },
    });
  };

  const onOpenFreeTrial = () => {
    setFreeTrialOpen(true);
  };

  const onCloseFreeTrial = () => {
    setFreeTrialOpen(false);
  };


  const onOpenPdfDownload = (data: any) => {
    setPdfData(data)
    setPdfOpen(true);
  };

  const onClosePdfDownload = () => {
    setPdfOpen(false);
    setPdfData([])
  };

  const checkString = (data: any) => {

    // Convert array to a string
    var dataAsString = JSON.stringify(data);

    // Check if the string contains square brackets
    if (dataAsString?.includes('[') && dataAsString?.includes(']')) {
      const match = data?.match(/\['([^']+)'\]/);
      return match ? match[1] : null;
    } else {
      return data;
    }
  }
  
  const sort: any = GetSessionStorageData("sort")
    useEffect(() => {
      let data = JSON.parse(sort)
      if (data?.price || data?.rating) {
        handleProceedSort(data?.price, data?.rating)
      }
    }, [sort])

  const handleProceedSort = (checkedPrice: any, checkedRating: any, data: any = null) => {
    const sortedData = data ? [...data] : [...list]
    if (checkedPrice == 'high') {
      sortedData.sort((a: any, b: any) => b?.least_price - a?.least_price);
    } else if (checkedPrice == 'low') {
      sortedData.sort((a: any, b: any) => a?.least_price - b?.least_price);
    } else if (checkedRating == 'high') {
      sortedData.sort((a: any, b: any) => b?.least_rating - a?.least_rating);
    } else if (checkedRating == 'low') {
      sortedData.sort((a: any, b: any) => a?.least_rating - b?.least_rating);
    }
    setTutorList(sortedData);
    setToturDetailes(sortedData[0]);
    setSort({
      price: checkedPrice,
      rating: checkedRating
    })
    setCurrentIndex(0)
  }

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const tutorViewOnMap = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "tutor-view-map-english" : "tutor-view-map-arab";

  const Top_PageBtn_Icons_Lang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "top__PageBtn__icons-english" : "top__PageBtn__icons-arab";

  const Tsearch_Button_Lang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "tsearch_button_english" : "tsearch_button_arab";
  const top_cover_img_wrapper = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "top_cover_img_wrapper" : "top_cover_img_wrapper_arb";
  const tutorsListViewBodyOneLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "tutorsListViewBodyOneEng" : "tutorsListViewBodyOneArab";
  return (
    <>
      <div className="TutorsMapsView__container">
        <div className="row mt-3 tutor-row-paddins-head">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 tutor-row-paddins">
            <div className="List__topView__wapper">
              <div
                className="TutorCard_Img_wapper"
                onClick={() => navigate(ROUTES.TUTORS)}
              >
                <img draggable="false" src={TutorImg} alt="TutorImg" />
                <h5>{t("Tutors")}</h5>
              </div>
              <div
                className={`
                  ${ViewOnMapActive
                    ? "Tutor__listView__Active List__topView__onMap"
                    : `List__topView__onMap`}
                `}
                onClick={() => setViewOnMapActive(!ViewOnMapActive)}
              >
                <img src={ExpandMap} alt="ExpandMap" />
                <span>
                  {ViewOnMapActive ? t("View on List") : t("View on Map")}
                </span>
              </div>
            </div>
          </div>

          <div className="row tutor-row-paddins">
            <div className="col-lg-3 col-md-12 ">
              <div className="search_field_wrapper">
                <div className="search__autocomplete" style={{ width: '100%' }}>
                  <form onSubmit={handleSubmit}>
                    <Autocomplete
                      freeSolo
                      popupIcon
                      id="free-solo-2-demo"
                      disableClearable={false}
                      clearIcon={false}
                      value={searchValue}
                      onChange={(e, value) => {
                        const trimedvalue = value ? value.trim() : '';
                        setSearchValue(trimedvalue);
                        handlePassCoursecategory(trimedvalue);
                        SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.TUTOR, trimedvalue);
                      }}

                      options={searchTextList?.map((option: any) =>
                        language === GLOBAL_CONSTANTS.LANGUAGES.ARABIC ? option?.arabic_tag_name : option?.tag)}
                      renderOption={(props, option) => (
                        <Box component='li' sx={[{ px: 0.5, py: 0.7 }]} {...props} className="notranslate" translate="no">
                          {option}
                        </Box>
                      )}
                      // options={searchTextList?.map(
                      //   (option: any) => option?.tag
                      // )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={searchValue}
                          placeholder="Search for tutor"
                          onChange={(inputChangeEvent: any) => {
                            onInputChange(inputChangeEvent.target.value.trim());
                          }}
                          onKeyDown={(e: any) => {
                            if (e.key === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                              e.preventDefault();
                              const trimedvalue = searchValue.trim()
                              if (trimedvalue) {
                                handlePassCoursecategory(trimedvalue)
                              }
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            type: "text",
                          }}
                        />
                      )}
                    />
                    <img
                      draggable="false"
                      src={search}
                      alt="search"
                      className={`${Tsearch_Button_Lang} search_button`}
                      onClick={() => handlePassCoursecategory(searchValue)}
                    />
                  </form>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-12">
              <AppFilter
                FilterPopOpen={FilterPopOpen}
                onOpenPop={onOpenFilterPop}
                onClosePop={onCloseFilterPop}
                FilterData={FilterData}
                FilterApply={FilterApply}
                FilterApplyData={FilterApplyData}
                initFilter={initFilter}
                initSort={initSort}
                handleApplySort={handleProceedSort}
                type="tutor"
                color="#178fff"
              />
            </div>
          </div>

          <div className="row mt-3 tutors-list-view-body tutor-row-paddins">
            <div className={`col-xl-6 col-lg-6 col-md-12 col-sm-12 ${tutorsListViewBodyOneLang} tutors-list-view-body-one`}>


              {ViewOnMapActive ? (
                <>
                  <div className="Maps__view__listPage">
                    <TutorMaps
                      zoom={12}
                      radius={FilterRadiusvalue[0] * 1000}
                      lat={currentLat}
                      lang={currentLang}
                      containerStyle={containerStyle}
                      disable={true}
                      zoomControl={false}
                      clickableIcons={false}
                      draggable={false}
                      tutorList={tutorList}
                      getOneTutor={[]}
                      filter={isObjectEmptyValue(tutorfilter) ? tutorfilter : FilterApplyData}
                      initFilter={initFilter}
                      searchValue={searchValue}
                      initSort={initSort}
                    />
                    <div
                      className="viewMap"
                      onClick={() => handleRouteMap(searchValue)}
                    >
                      <img src={ExpandMap} alt="ExpandMap" />
                      <span>{t("View on Map")}</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className=" Tutor__listView__container">
                    {tutorList?.map((item: any, i: any) => {
                      return (
                        <>
                          <div
                            className={
                              currentIndex === i
                                ? "Tutor__list__wapper Tutor__listView__Active"
                                : "Tutor__list__wapper"
                            }
                            key={i}
                            onClick={() => handlePassSchoolData(i)}
                            onDoubleClick={() =>
                              navigateTutorDetailedView(item)
                            }
                          >
                            <div className="Tutor__leftside">
                              <img
                                src={
                                  nullUndefinedEmptyCheck(item?.profile_image)
                                    ? item?.profile_image
                                    : tutor_img
                                }
                                alt="TutorImg" onError={(e: any) => { e.target.src = tutor_img; }}
                              />
                            </div>
                            <div className="Tutor__rightside">
                              <div className="Tutor__name">
                                <span className="name notranslate">
                                  {
                                    language === "English"
                                      ? `${item.profile_details?.firstname || ""} ${item.profile_details?.lastname || ""}`.trim()
                                      : `${item.profile_details?.arabic_firstname || item.profile_details?.firstname} ${item.profile_details?.arabic_lastname || item.profile_details?.lastname}`.trim()
                                  }
                                  {/* {item?.profile_details?.firstname}{" "}
                                  {item?.profile_details?.lastname} */}
                                </span>
                                <div className="imageIcon" onClick={(e) => e.stopPropagation()}>
                                  <WebShareComponent
                                    params={
                                      ROUTES.TUTORS_DETAILE_VIEW +
                                      "?id=" +
                                      encryptPassData(item?.id)
                                    }
                                  >
                                    <img
                                      src={shareIcon}
                                      alt=""
                                      className="mb-1"
                                    />
                                  </WebShareComponent>
                                  <img
                                    src={
                                      item.is_favourite
                                        ? Tutorfavourites
                                        : LikeIcon
                                    }
                                    alt="Learn and Play"
                                    onClick={() =>
                                      handleChangeFavourites(
                                        item.is_favourite,
                                        item?.user
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="user__Detailes__location">
                                <img
                                  src={locationIcon}
                                  alt="locationIcon"
                                  className="grayIcons"
                                />
                                <div className="graytext">{item?.city}</div>
                                <img
                                  src={experienceIcon}
                                  alt="experienceIcon"
                                  className="grayIcons"
                                />
                                <div className="graytext">
                                  {item?.tutor_subjects?.length > 0
                                    ? item?.tutor_subjects[0]?.experience
                                    : ""}
                                </div>
                              </div>

                              <div className="user__Detailes__Curriculam">
                                <img src={BadgeIcon} alt="shareIcon" />
                                <div className="black__text">{t("Course")}:</div>
                                <div className="black__text">
                                  {item?.tutor_subjects?.length > 0
                                    ? item?.tutor_subjects[0]?.courses +
                                    " - " +
                                    item?.tutor_subjects[0]?.grade_name
                                    : ""}
                                </div>
                              </div>

                              {/* <div className="user__Detailes__Curriculam">
                                <img src={mode_of_teaching} alt="" width={"10px"} />
                                <div className="black__text">{t("Mode Of Teaching")}:</div>
                                <div className="black__text">
                                  {item?.tutor_subjects?.length > 0
                                    ? item?.tutor_subjects[0]?.course_mode : "N/A"}
                                </div>
                              </div> */}

                              <div className="user__Detailes__Curriculam">
                                <img src={mode_of_teaching} alt="" width={"10px"} />
                                <div className="black__text">{t("Overall Teaching Mode")}:</div>
                                <div className="black__text">
                                  {item?.overall_mode_of_teaching || "N/A"}
                                </div>
                              </div>

                              <div className="Tutor__rating">
                                <div className="text__contant">
                                  {t("AED")}: {item?.tutor_subjects[0]?.final_fee}
                                </div>
                                <div className="d-flex flex-row">
                                  {nullUndefinedEmptyCheck(item?.rating) && (
                                    <StarIcon className="StarIcon" />
                                  )}
                                  <span className="points ms-2">
                                    {item?.rating}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    {tutorList?.length === 0 && !Loader && (
                      <>
                        <div className="No__data__contant">
                          <img src={spacemen} alt="" />
                          <div className="text">{t("No Data Found")}</div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 tutors-list-view-body-two">
              {tutorList?.length > 0 && (
                <div
                  className={
                    triggerVal
                      ? "TutorListView__rightSide__Pop"
                      : "animate-slideInRight Model__display__none"
                  }
                >
                  <div className="top__contant__wapper">
                    <div className={`${Top_PageBtn_Icons_Lang} top__PageBtn__icons`}>
                      <NavigateBeforeSharpIcon
                        className={
                          currentIndex > 0 ? "prev__icons" : "icons__disable"
                        }
                        onClick={handlePrev}
                      />
                      <div
                        className={
                          currentIndex > 0 ? "prev__text" : "text__disable"
                        }
                        onClick={handlePrev}
                      >
                        {t("Prev")}
                      </div>
                      <div
                        className={
                          currentIndex < tutorList?.length - 1
                            ? "prev__text"
                            : "text__disable"
                        }
                        onClick={handleNext}
                      >
                        {t("Next")}
                      </div>
                      <NavigateNextSharpIcon
                        className={
                          currentIndex < tutorList?.length - 1
                            ? "next__icons"
                            : "icons__disable"
                        }
                        onClick={handleNext}
                      />
                    </div>
                    <div className="top__close__icons">
                      <Tooltip title="Expanded">
                        <OpenInFullSharpIcon
                          className="expanded__icons"
                          onClick={() => navigateTutorDetailedView(toturDetailes)}
                        />
                      </Tooltip>
                      <Tooltip title="close">
                        <CloseIcon
                          className="close__icons"
                          onClick={() => setTriggerVal(!triggerVal)}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className={top_cover_img_wrapper}>
                    <div className="arrow_navs">
                      <NavigateBeforeSharpIcon
                        onClick={slidePrev}
                        className="left_arrow"
                      />
                      <NavigateNextSharpIcon
                        onClick={slideNext}
                        className="right_arrow"
                      />
                    </div>
                    {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? (
                      <div
                        className="viewCalendar"
                        onClick={() => navigateBookSlot()}
                      >
                        <img src={CalendarIcon} alt="ExpandMap" />
                        <span>{t("View on calendar")}</span>
                      </div>
                    ) : (
                      <div
                        className="viewCalendar"
                        onClick={handleAuthentication}
                      >
                        <img src={CalendarIcon} alt="ExpandMap" />
                        <span>{t("View on calendar")}</span>
                      </div>
                    )}

                    <AliceCarousel
                      // infinite
                      mouseTracking
                      autoWidth
                      disableButtonsControls
                      items={
                        toturDetailes?.cover_images?.length > 0 || toturDetailes?.intro_video
                          ? CoverImg
                          : CoverImgEmpty
                      }
                      disableDotsControls
                      activeIndex={activeIndex}
                      onSlideChanged={syncActiveIndex}
                      ref={carouselRef}
                    />
                    <img
                      src={
                        toturDetailes?.profile_image
                          ? toturDetailes?.profile_image
                          : tutor_img
                      }
                      alt="tutor_img" onError={(e: any) => { e.target.src = tutor_img; }}
                      className="profileImg"
                    />
                  </div>

                  <div className="card__container">
                    <div className="w-100">
                      <div className="user__Detailes">
                        <div className="user__name notranslate">
                          {
                            language === "English"
                              ? `${toturDetailes.profile_details?.firstname || ""} ${toturDetailes.profile_details?.lastname || ""}`.trim()
                              : `${toturDetailes.profile_details?.arabic_firstname || toturDetailes.profile_details?.firstname} ${toturDetailes.profile_details?.arabic_lastname || toturDetailes.profile_details?.lastname}`.trim()
                          }
                        </div>
                        <div className="user__icon__wrapper">
                          <WebShareComponent
                            params={
                              ROUTES.TUTORS_DETAILE_VIEW +
                              "?id=" +
                              encryptPassData(toturDetailes?.id)
                            }
                          >
                            <img
                              src={shareIcon}
                              alt="shareIcon"
                              className="mb-1"
                            />
                          </WebShareComponent>
                          {toturDetailes?.is_favourite ? (
                            <img
                              src={Tutorfavourites}
                              alt="LikeIcon"
                              onClick={() =>
                                handleChangeFavourites(
                                  toturDetailes.is_favourite,
                                  toturDetailes?.user
                                )
                              }
                            />
                          ) : (
                            <img
                              src={LikeIcon}
                              alt="LikeIcon"
                              onClick={() =>
                                handleChangeFavourites(
                                  toturDetailes.is_favourite,
                                  toturDetailes?.user
                                )
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="description__contant">
                        {toturDetailes?.description}
                      </div>

                      <div className="row tutor__user__Detailes__Education">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                          <div className="detailed__container__wapper">
                            <img
                              src={teaching_mode}
                              alt="teaching_mode"
                              className="grayIcons"
                            />
                            <div className="graytext">{t("Education")}:</div>
                          </div>
                          <div className="Bluetext" title={toturDetailes?.degree ? toturDetailes?.degree : toturDetailes?.high_education}>
                            {toturDetailes?.degree ? toturDetailes?.degree : toturDetailes?.high_education}
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                          <div className="detailed__container__wapper">
                            <StarIcon className="StarIcon" />
                            <div className="graytext">{t("Rating")}:</div>
                          </div>
                          <div className="Bluetext">{toturDetailes?.rating}</div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv mt-2">
                          <div className="detailed__container__wapper">
                            <img
                              src={BadgeIcon}
                              alt="Grade"
                              className="grayIcons"
                            />
                            <div className="lable">{t("Grade")}:</div>
                          </div>
                          {courseIndex?.courses ? (
                            <div
                              className="Bluetext"
                              title={courseIndex?.grade_name}
                            >
                              {courseIndex?.grade_name}
                            </div>
                          ) : (
                            <div
                              className="Bluetext"
                              title={
                                toturDetailes?.tutor_subjects?.length > 0
                                  ? toturDetailes?.tutor_subjects[0]?.grade_name
                                  : ""
                              }
                            >
                              {toturDetailes?.tutor_subjects?.length > 0
                                ? toturDetailes?.tutor_subjects[0]?.grade_name
                                : ""}
                            </div>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv mt-2">
                          <div className="detailed__container__wapper">
                            <img
                              src={curriculum}
                              alt="curriculum"
                              className="grayIcons"
                            />
                            <div className="lable">{t("Curriculum")}:</div>
                          </div>
                          <div className="Bluetext" title={toturDetailes?.curriculum?.length > 0 ? toturDetailes?.curriculum[0] : toturDetailes?.curriculum}>
                            {toturDetailes?.curriculum?.length > 0 ? toturDetailes?.curriculum[0] : toturDetailes?.curriculum}
                          </div>
                        </div>
                      </div>

                      <div className="user__Detailes__fess">
                        <div className="Avg__Fees">{t("Fees Per Session (1hr)")}:</div>
                        <h1 className="title">{t("AED")}: {courseIndex?.final_fee}</h1>
                      </div>
                      <div className="user__Detailes__Courses">
                        <div className="tutorList_selectcourse-div mb-1">
                          <div className="title">{t("Courses")}:</div>
                          <KeyboardArrowDownIcon
                            className={CourseOpen ? "Icon" : "IconInActive"}
                            onClick={() => setCourseOpen(!CourseOpen)}
                          />
                        </div>
                        {CourseOpen && (
                          <div className="display-container2">
                            {toturDetailes?.tutor_subjects?.map((sub: any, i: any) => {
                              return (
                                <>
                                  <div
                                    className={
                                      courseSelectIndex == i
                                        ? "courses-Active"
                                        : "courses-div"
                                    }
                                    onClick={() => {
                                      setCourseSelectIndex(i);
                                      setCourseIndex(sub);
                                    }}
                                  >
                                    <img src={sub?.coursecategory_icon ? sub?.coursecategory_icon : tutor_icon} onError={(e: any) => { e.target.src = tutor_icon; }} alt="" />
                                    <div className="coursetxt">
                                      {sub.courses} - {sub.grade_name}
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        )}
                      </div>
                      <div className="Description_contant_tutor">
                        <label className="title">{t("About Me")}</label>
                        <div className="para-content">{toturDetailes?.about_me}</div>
                      </div>
                      <div className="Description_contant_tutor mt-3">
                        <label className="title">{t("Additional notes")}</label>
                        <div className="para-content">
                          {checkString(courseIndex?.additional_notes)}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6 bottom__contant__wapper">
                          <div className="bottom__contant__left">
                            <img src={experienceBlue} alt="" />
                            <div>{t("Experience")}:</div>
                          </div>
                          <div>{courseIndex?.experience}{t("Years")}</div>
                        </div>
                        <div className="col-md-6 bottom__contant__wapper">
                          <div className="bottom__contant__left">
                            <img src={Private_session} alt="" />
                            <div>{t("Private 1-1 sessions")}:</div>
                          </div>
                          <div>{courseIndex?.course_mode == 'Offline' ? t("On-premises") : courseIndex?.course_mode}</div>
                        </div>
                        <div className="col-md-6 bottom__contant__wapper">
                          <div className="bottom__contant__left">
                            <img src={mode_of_teaching} alt="" />
                            <div>{t("Mode Of Teaching")}:</div>
                          </div>
                          <div>{courseIndex?.course_mode == 'Offline' ? t("On-premises") : courseIndex?.course_mode}</div>
                        </div>
                        <div className="col-md-6 bottom__contant__wapper">
                          <div className="bottom__contant__left">
                            <img src={syllabus} alt="" />
                            <div>{t("Syllabus Covers")}:</div>
                          </div>
                          {courseIndex?.syllabus_covers?.length > 0 && (
                            <img
                              src={pdfIcon}
                              alt=""
                              className="pdfIcon"
                              onClick={() =>
                                onOpenPdfDownload(courseIndex?.syllabus_covers)
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="model__diloge__bookSlot_container mt-4">
                        {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? (
                          <button
                            className="free_session_Btn"
                            onClick={() => onOpenFreeTrial()}
                          >
                            {t("Book an introduction session (Free)")}
                          </button>
                        ) : (
                          <button
                            className="free_session_Btn"
                            onClick={handleAuthenticationFreeTrial}
                          >
                            {t("Book an introduction session (Free)")}

                          </button>
                        )}
                      </div>
                      <div className="model__diloge__bookSlot_container mt-4">
                        {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? (
                          <button
                            className="Book-Slots-btn"
                            onClick={() => navigateBookSlot()}
                          >
                            {t("Book Slots")}
                          </button>
                        ) : (
                          <button
                            className="Book-Slots-btn"
                            onClick={handleAuthentication}
                          >
                            {t("Book Slots")}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <TutorTrialSession
            TrialOpen={FreeTrialOpen}
            onClose={onCloseFreeTrial}
            tutor_id={toturDetailes?.id}
          />
          <PdfDownloadFile open={PdfOpen} handleClose={onClosePdfDownload} data={PdfData} />
        </div >
      </div>
      {AuthenticationOpen && (
        <div className="overlay-ModelBackround">
          <Dialog open={AuthenticationOpen}>
            <DialogContent>
              <div className="ChildSelect__container">
                <div className="text__contant">
                  {t("Parent Required For Further Action!")}
                </div>
                <div className="ChildSelectBtn__wapper__Tutor">
                  <button
                    className="BackBtn"
                    onClick={() => setAuthenticationOpen(false)}
                  >
                    {t("Back")}
                  </button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      )
      }
    </>
  );
};

export default TutorsListView;
