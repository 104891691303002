import "./App.scss";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import React, { lazy, Suspense, useCallback, useRef } from 'react';
import { useEffect, useState } from 'react';
import PrivateRoutesCheck from "./Utilities/Routes/PrivateRoutes";
import PublicRoutesCheck from "./Utilities/Routes/PublicRoutes";
import { AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES } from "./Constants/Constants";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "./Pages/DashBoardLayout/DashboardLayout";
import { ToastContainer } from "react-toast";
import { Toaster } from 'react-hot-toast';
import { ProSidebarProvider } from "react-pro-sidebar";
import ProfileLayout from "./Pages/ProfileLayout/ProfileLayout";
import Loader from "./Components/Loader/Loader";

// routing page imports
import HomePage from "./Pages/HomePage/HomePage";
import ThemeParkHomePage from "./Pages/ThemeParkHomePage/ThemeParkHomePage";
import TutorsDashboardLayout from "./Pages/TutorsDashboardLayout/TutorsDashboardLayout";
import TutorsMapsView from "./Pages/TutorsDashboardLayout/TutorsMapsView/TutorsMapsView";
import TutorsDetaileView from "./Pages/TutorsDashboardLayout/TutorsDetaileView/TutorsDetaileView";
import TutorsCalendarView from "./Pages/TutorsDashboardLayout/TutorsCalendarView/TutorsCalendarView";
import TutorsListView from "./Pages/TutorsDashboardLayout/TutorsListView/TutorsListView";
import TutorsCheckOut from "./Pages/TutorsDashboardLayout/TutorsCheckOut/TutorsCheckOut";
import TutorsPaymentSuccess from "./Pages/TutorsDashboardLayout/TutorsPaymentSuccess/TutorsPaymentSuccess";
import ActivitiesDashboardLayout from "./Pages/ActivitiesDashboardLayout/ActivitiesDashboardLayout";
import ActivitiesMapsView from "./Pages/ActivitiesDashboardLayout/ActivitiesMapsView/ActivitiesMapsView";
import ActivitiesListView from "./Pages/ActivitiesDashboardLayout/ActivitiesListView/ActivitiesListView";
import ActivitiesDetaileView from "./Pages/ActivitiesDashboardLayout/ActivitiesDetaileView/ActivitiesDetaileView";
import ActivitiesCheckOut from "./Pages/ActivitiesDashboardLayout/ActivitiesCheckOut/ActivitiesCheckOut";
import ActivitiesCalendarView from "./Pages/ActivitiesDashboardLayout/ActivitiesCalendarView/ActivitiesCalendarView";
import ActivitiesPaymentSuccess from "./Pages/ActivitiesDashboardLayout/ActivitiesPaymentSuccess/ActivitiesPaymentSuccess";
import UniversityDashboardLayout from "./Pages/UniversityDashboard/UniversityDashboardLayout";
import UniversityMapsView from "./Pages/UniversityDashboard/UniversityMapsView/UniversityMapsView";
import UniversityDetaileView from "./Pages/UniversityDashboard/UniversityDetaileView/UniversityDetaileView";
import UniversityListView from "./Pages/UniversityDashboard/UniversityListView/UniversityListView";
import UniversityChatExpert from "./Pages/UniversityDashboard/UniversityChatExpert/UniversityChatExpert";
import UniversityCheckOut from "./Pages/UniversityDashboard/UniversityCheckOut/UniversityCheckOut";
import UniversityPaymentSuccess from "./Pages/UniversityDashboard/UniversityPaymentSuccess/UniversityPaymentSuccess";
import SchoolDashboardLayout from "./Pages/SchoolDashboardLayout/SchoolDashboardLayout";
import SchoolMapsView from "./Pages/SchoolDashboardLayout/SchoolMapView/SchoolMapView";
import SchoolDetaileView from "./Pages/SchoolDashboardLayout/SchoolDetaileView/SchoolDetaileView";
import SchoolListView from "./Pages/SchoolDashboardLayout/SchoolListView/SchoolListView";
import ThemeParkDasboard from "./Pages/ThemeParkDasboard/ThemeParkDasboard";
import ThemeParkListView from "./Pages/ThemeParkDasboard/ThemeParkListView/ThemeParkListView";
import ThemeParkDetaileView from "./Pages/ThemeParkDasboard/ThemeParkDetaileView/ThemeParkDetaileView";
import ThemeParkCheckOut from "./Pages/ThemeParkDasboard/ThemeParkCheckOut/ThemeParkCheckOut";
import ThemeParkPaymentSucces from "./Pages/ThemeParkDasboard/ThemeParkPaymentSucces/ThemeParkPaymentSucces";
import AddProfile from "./Components/AddProfile/AddProfile";
import EditProfile from "./Components/EditProfile/EditProfile";
import ChildCalender from "./Components/ChildCalender/ChildCalender";
import Favourites from "./Components/Favourites/Favourites";
import NotificationsList from "./Components/NotificationsList/NotificationsList";
import LegalAndSecurity from "./Components/LegalAndSecurity/LegalAndSecurity";
import AboutUS from "./Components/AboutUS/AboutUS";
import PaymentHistory from "./Components/PaymentHistory/PaymentHistory";
import MyWallet from "./Components/MyWallet/MyWallet";
import TutorHubDetailes from "./Components/TutorHubDetailes/TutorHubDetailes";
import MyTickets from "./Components/MyTickets/MyTickets";
import AdminChat from "./Components/AdminChat/AdminChat";
import TutorHub from "./Components/TutorHub/TutorHub";
import MySessions from "./Components/MySessions/MySessions";
import Support from "./Components/Support/Support";
import MyTicketsDetail from "./Components/MyTicketsDetail/MyTicketsDetail";
import SchoolChatExpert from "./Pages/SchoolDashboardLayout/SchoolChatExpert/SchoolChatExpert";
import { store } from "./Redux/Store/Store";
import { getLanguageJsonArabic, getLanguageJsonEnglish, setErrorNotification, setLanguage } from "./Redux/Reducer/Reducer";
import i18n, { t } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { GetSessionStorageData, SetSessionStorageData } from "./Utilities/Helpers/Helper";
import PreferenceScreen from "./Components/PreferenceScreen/PreferenceScreen";
import AddAddress from "./Components/AddAddress/AddAddress";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Tooltip } from "@mui/material";
import ErrorImage from "./Assets/Global/Errorimage.svg"
// import ThemeParkListPage from "./Pages/ThemeParkHomePage/themepark-list-page/ThemeParkListPage";

//Lazy loading routing imports

// const HomePage = lazy(() => import('./Pages/HomePage/HomePage'));
// const ThemeParkHomePage = lazy(() => import('./Pages/ThemeParkHomePage/ThemeParkHomePage'));
// const TutorsDashboardLayout = lazy(() => import('./Pages/TutorsDashboardLayout/TutorsDashboardLayout'));
// const TutorsDetaileView = lazy(() => import('./Pages/TutorsDashboardLayout/TutorsDetaileView/TutorsDetaileView'));
// const TutorsMapsView = lazy(() => import('./Pages/TutorsDashboardLayout/TutorsMapsView/TutorsMapsView'));
// const TutorsListView = lazy(() => import('./Pages/TutorsDashboardLayout/TutorsListView/TutorsListView'));
// const TutorsCalendarView = lazy(() => import('./Pages/TutorsDashboardLayout/TutorsCalendarView/TutorsCalendarView'));
// const TutorsCheckOut = lazy(() => import('./Pages/TutorsDashboardLayout/TutorsCheckOut/TutorsCheckOut'));
// const TutorsPaymentSuccess = lazy(() => import('./Pages/TutorsDashboardLayout/TutorsPaymentSuccess/TutorsPaymentSuccess'));
// //ActivitiesDashboardLayout routing component
// const ActivitiesDashboardLayout = lazy(() => import('./Pages/ActivitiesDashboardLayout/ActivitiesDashboardLayout'));
// const ActivitiesMapsView = lazy(() => import('./Pages/ActivitiesDashboardLayout/ActivitiesMapsView/ActivitiesMapsView'));
// const ActivitiesListView = lazy(() => import('./Pages/ActivitiesDashboardLayout/ActivitiesListView/ActivitiesListView'));
// const ActivitiesCalendarView = lazy(() => import('./Pages/ActivitiesDashboardLayout/ActivitiesCalendarView/ActivitiesCalendarView'));
// const ActivitiesDetaileView = lazy(() => import('./Pages/ActivitiesDashboardLayout/ActivitiesDetaileView/ActivitiesDetaileView'));
// const ActivitiesCheckOut = lazy(() => import('./Pages/ActivitiesDashboardLayout/ActivitiesCheckOut/ActivitiesCheckOut'));
// const ActivitiesPaymentSuccess = lazy(() => import('./Pages/ActivitiesDashboardLayout/ActivitiesPaymentSuccess/ActivitiesPaymentSuccess'));
// //UniversityDashboardLayout routing component
// const UniversityDashboardLayout = lazy(() => import('./Pages/UniversityDashboard/UniversityDashboardLayout'));
// const UniversityMapsView = lazy(() => import('./Pages/UniversityDashboard/UniversityMapsView/UniversityMapsView'));
// const UniversityDetaileView = lazy(() => import('./Pages/UniversityDashboard/UniversityDetaileView/UniversityDetaileView'));
// const UniversityListView = lazy(() => import('./Pages/UniversityDashboard/UniversityListView/UniversityListView'));
// const UniversityChatExpert = lazy(() => import('./Pages/UniversityDashboard/UniversityChatExpert/UniversityChatExpert'));
// const UniversityCheckOut = lazy(() => import('./Pages/UniversityDashboard/UniversityCheckOut/UniversityCheckOut'));
// const UniversityPaymentSuccess = lazy(() => import('./Pages/UniversityDashboard/UniversityPaymentSuccess/UniversityPaymentSuccess'));
// //SchoolDashboardLayout routing component
// const SchoolDashboardLayout = lazy(() => import('./Pages/SchoolDashboardLayout/SchoolDashboardLayout'));
// const SchoolMapsView = lazy(() => import('./Pages/SchoolDashboardLayout/SchoolMapView/SchoolMapView'));
// const SchoolDetaileView = lazy(() => import('./Pages/SchoolDashboardLayout/SchoolDetaileView/SchoolDetaileView'));
// const SchoolListView = lazy(() => import('./Pages/SchoolDashboardLayout/SchoolListView/SchoolListView'));
// //ThemeParkDashboardLayout routing component
// const ThemeParkDasboard = lazy(() => import('./Pages/ThemeParkDasboard/ThemeParkDasboard'));
// const ThemeParkListView = lazy(() => import('./Pages/ThemeParkDasboard/ThemeParkListView/ThemeParkListView'));
// const ThemeParkDetaileView = lazy(() => import('./Pages/ThemeParkDasboard/ThemeParkDetaileView/ThemeParkDetaileView'));
// const ThemeParkCheckOut = lazy(() => import('./Pages/ThemeParkDasboard/ThemeParkCheckOut/ThemeParkCheckOut'));
// const ThemeParkPaymentSucces = lazy(() => import('./Pages/ThemeParkDasboard/ThemeParkPaymentSucces/ThemeParkPaymentSucces'));
// //Profile Layout routing component
// const AddProfile = lazy(() => import('./Components/AddProfile/AddProfile'));
// const EditProfile = lazy(() => import('./Components/EditProfile/EditProfile'));
// const ChildCalender = lazy(() => import('./Components/ChildCalender/ChildCalender'));
// const Favourites = lazy(() => import('./Components/Favourites/Favourites'));
// const MySessions = lazy(() => import('./Components/MySessions/MySessions'));
// const NotificationsList = lazy(() => import('./Components/NotificationsList/NotificationsList'));
// const LegalAndSecurity = lazy(() => import('./Components/LegalAndSecurity/LegalAndSecurity'));
// const AboutUS = lazy(() => import('./Components/AboutUS/AboutUS'));
// const Support = lazy(() => import('./Components/Support/Support'));
// const PaymentHistory = lazy(() => import('./Components/PaymentHistory/PaymentHistory'));
// const MyWallet = lazy(() => import('./Components/MyWallet/MyWallet'));
// const TutorHub = lazy(() => import('./Components/TutorHub/TutorHub'));
// const TutorHubDetailes = lazy(() => import('./Components/TutorHubDetailes/TutorHubDetailes'));
// const MyTickets = lazy(() => import('./Components/MyTickets/MyTickets'));
// const AdminChat = lazy(() => import('./Components/AdminChat/AdminChat'));

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'English',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      English: {
        translation: {}, // Initialize with an empty object
      },
      Arabic: {
        translation: {}, // Initialize with an empty object
      },
    },
  });


function App() {
  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const language1 = useSelector((state: any) => state.languageReducer.jsonDataEnglish);
  const language2 = useSelector((state: any) => state.languageReducer.jsonDataArabic);

  useEffect(() => {
    fetchDataEnglish();
    fetchDataArabic();
  }, []);

  let englistUrl = 'https://phygital-landp.s3.ap-south-1.amazonaws.com/label_en.json';
  let arabicUrl = 'https://phygitalweb.s3.ap-south-1.amazonaws.com/label_ar.json';

  /**Get English Language Json file */
  const fetchDataEnglish = async () => {
    try {
      const response = await fetch(englistUrl);
      const data = await response.json();
      store.dispatch(getLanguageJsonEnglish(data));
    } catch (error) { }
  };

  /**Get Arabic Language Json file */
  const fetchDataArabic = async () => {
    try {
      const response = await fetch(arabicUrl);
      const data = await response.json();
      store.dispatch(getLanguageJsonArabic(data));
    } catch (error) { }
  };

  /**Routes and related components */
  const routes: any = [
    {
      path: "",
      element: (
        <>
          <Navigate to={ROUTES.HOMEPAGE} />
        </>
      ),
    },
    {
      path: "",
      // element: <Suspense fallback={<Loader />}><PublicRoutesCheck /></Suspense>,
      element: <PublicRoutesCheck />,
      children: [
        {
          path: "",
          element: <DashboardLayout />,
          children: [
            { path: ROUTES.HOMEPAGE, element: <HomePage /> },
            { path: ROUTES.THEMEPARK__HOMEPAGE, element: <ThemeParkHomePage /> },
            // { path: ROUTES.THEMEPARK_LIST_VIEW, element: <ThemeParkListPage /> },
            { path: ROUTES.TUTORS, element: <TutorsDashboardLayout /> },
            { path: ROUTES.TUTORS_MAPS_VIEW, element: <TutorsMapsView />, },
            { path: ROUTES.TUTORS_DETAILE_VIEW, element: <TutorsDetaileView />, },
            { path: ROUTES.TUTORS_LIST_VIEW, element: <TutorsListView />, },
            { path: ROUTES.TUTORS_CALENDAR_VIEW, element: <TutorsCalendarView />, },
            { path: ROUTES.TUTORS_CHECKOUT, element: <TutorsCheckOut />, },
            { path: ROUTES.TUTORS_PAYMENT_SUCCESS, element: <TutorsPaymentSuccess />, },
            //ActivitiesDashboardLayout routing component
            { path: ROUTES.ACTIVITYLAYOUT, element: <ActivitiesDashboardLayout /> },
            { path: ROUTES.ACTIVITY_MAPS_VIEW, element: <ActivitiesMapsView /> },
            { path: ROUTES.ACTIVITY_LIST_VIEW, element: <ActivitiesListView /> },
            { path: ROUTES.ACTIVITY_DETAILE_VIEW, element: <ActivitiesDetaileView />, },
            { path: ROUTES.ACTIVITY_CALENDAR_VIEW, element: <ActivitiesCalendarView />, },
            { path: ROUTES.ACTIVITY_CHECKOUT, element: <ActivitiesCheckOut />, },
            { path: ROUTES.ACTIVITY_PAYMENT_SUCCESS, element: <ActivitiesPaymentSuccess />, },
            //UniversityDashboardLayout routing component
            { path: ROUTES.UNIVERSITYLAYOUT, element: <UniversityDashboardLayout /> },
            { path: ROUTES.UNIVERSITY_MAPS_VIEW, element: <UniversityMapsView /> },
            { path: ROUTES.UNIVERSITY_DETAILE_VIEW, element: <UniversityDetaileView /> },
            { path: ROUTES.UNIVERSITY_LIST_VIEW, element: <UniversityListView /> },
            { path: ROUTES.UNIVERSITY_CHAT_VIEW, element: <UniversityChatExpert /> },
            { path: ROUTES.UNIVERSITY_CHECKOUT, element: <UniversityCheckOut /> },
            { path: ROUTES.UNIVERSITY_PAYMENT_SUCCESS, element: <UniversityPaymentSuccess /> },
            //SchoolDashboardLayout routing component
            { path: ROUTES.SCHOOLLAYOUT, element: <SchoolDashboardLayout /> },
            { path: ROUTES.SCHOOL_MAPS_VIEW, element: <SchoolMapsView /> },
            { path: ROUTES.SCHOOL_DETAILE_VIEW, element: <SchoolDetaileView /> },
            { path: ROUTES.SCHOOL_LIST_VIEW, element: <SchoolListView /> },
            { path: ROUTES.SCHOOL_CHAT_VIEW, element: <SchoolChatExpert /> },
            //ThemeParkDashboardLayout routing component
            { path: ROUTES.THEMEPARK_LAYOUT, element: <ThemeParkDasboard /> },
            { path: ROUTES.THEMEPARK_LIST_VIEW, element: <ThemeParkListView /> },
            { path: ROUTES.THEMEPARK_DETAILE_VIEW, element: <ThemeParkDetaileView /> },
            { path: ROUTES.THEMEPARK_CHECKOUT, element: <ThemeParkCheckOut /> },
            { path: ROUTES.THEMEPARK_PAYMENT_SUCCESS, element: <ThemeParkPaymentSucces /> },
            { path: ROUTES.ABOUTUS_WITHOUT_LOGIN, element: <AboutUS /> },
          ],
        },
      ],
    },
    {
      path: "",
      element: <PrivateRoutesCheck />,
      children: [
        {
          path: "",
          // element: <Suspense fallback={<Loader />}><DashboardLayout /></Suspense>,
          element: <DashboardLayout />,
          children: [
            {
              path: ROUTES.ACCOUNTS,
              element: <ProSidebarProvider><ProfileLayout /></ProSidebarProvider>,
              children: [
                { path: ROUTES.ADD_PROFILE, element: <AddProfile /> },
                { path: ROUTES.EDIT_PROFILE, element: <EditProfile /> },
                { path: ROUTES.CHILDCALENDER, element: <ChildCalender /> },
                { path: ROUTES.FAVOURTIES, element: <Favourites /> },
                { path: ROUTES.ADD_ADDRESS, element: <AddAddress /> },
                { path: ROUTES.MYSESSION, element: <MySessions /> },
                { path: ROUTES.NOTIFICATION, element: <NotificationsList /> },
                { path: ROUTES.LEGALANDSECURITY, element: <LegalAndSecurity /> },
                { path: ROUTES.ABOUTUS, element: <AboutUS /> },
                { path: ROUTES.PREFERENCESCREEN, element: <PreferenceScreen /> },
                { path: ROUTES.SUPPORT, element: <Support /> },
                { path: ROUTES.PAYMENTHISTORY, element: <PaymentHistory /> },
                { path: ROUTES.MYWALLET, element: <MyWallet /> },
                { path: ROUTES.TUTOR_HUB, element: <TutorHub /> },
                { path: ROUTES.TUTOR_HUB_DETAILES, element: <TutorHubDetailes /> },
                { path: ROUTES.MYTICKETS, element: <MyTickets /> },
                { path: ROUTES.MYTICKETS_DETAIL, element: <MyTicketsDetail /> },
                { path: ROUTES.ADMIN_CHAT, element: <AdminChat /> },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: (
        <>
          <Navigate to={ROUTES.HOMEPAGE} />
        </>
      ),
    },
  ];

  /**Router instance */
  const routers = createBrowserRouter(routes);
  const sessionLanguage = GetSessionStorageData(AUTHENTICATION.SHARED.LANGUAGE_SWICTH);
  // Update resources when language data changes
  useEffect(() => {
    if (sessionLanguage === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH) {
      i18n.addResourceBundle(GLOBAL_CONSTANTS.LANGUAGES.ENGLISH, 'translation', language1, true, true);
    } else {
      i18n.addResourceBundle('Arabic', 'translation', language2, true, true);

    }
  }, [language1, language, language2]); // Only re-run the effect if language changes

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language])

  //* For No Transalation Delay *//
  const [translateAttribute, setTranslateAttribute] = useState<any>("yes");
  const dispatch = useDispatch();

  //*This Below Line For Login Carrosol Page Fetch English Spash Content*//
  useEffect(() => {
    // SetSessionStorageData(AUTHENTICATION.SHARED.LANGUAGE_SWICTH, 'English');
    // dispatch(setLanguage('English'));
    const currentLanguage = GetSessionStorageData(AUTHENTICATION.SHARED.LANGUAGE_SWICTH);
    if (!currentLanguage || currentLanguage === "العربية") {
      SetSessionStorageData(AUTHENTICATION.SHARED.LANGUAGE_SWICTH, 'English');
      dispatch(setLanguage('English'));
      i18n.changeLanguage('English');
    }
  }, [])

  //*Bleow Lines For Prevent the Transalation for English*//
  useEffect(() => {
    if (language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH) {
      const timer = setTimeout(() => {
        setTranslateAttribute("no");
      }, 1200); // 1.2 seconds delay

      return () => clearTimeout(timer); // Cleanup timer on unmount or when language changes
    } else {
      setTranslateAttribute("yes");
    }
  }, [language]);

  // Below line for redirection for Android ,ios
  const redirectToApp = useCallback(() => {
    const getMobileOperatingSystem = () => {
      const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

      if (/android/i.test(userAgent)) {
        return 'Android';
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
        return 'iOS';
      }

      return 'unknown';
    };

    const os = getMobileOperatingSystem();

    if (os === 'Android') {
      window.location.href = 'learnandplay://';
    } else if (os === 'iOS') {
      window.location.href = 'learnandplay://';

    } else {
      // window.location.href = 'http://192.168.1.45:3002/home';

    }
  }, []);

  useEffect(() => {
    redirectToApp();
  }, [])
  const state = useSelector((state: any) => {
    return state?.ErrorNotificationReducer?.check

  })
  console.log("🚀 ~ state ~ state:", state)
  let errorMessage = useSelector((state: any) => {
    return state?.ErrorNotificationReducer?.errormessage

  })
  const ErrorNotification = () => {

    const handleClose = () => {
      dispatch(setErrorNotification(false));
    };
    return (
      <React.Fragment>
        <Dialog
          open={state}
          onClose={(e, reason) => {
            if (reason === "backdropClick") return;
            handleClose();
          }}
          className="error-popup"
        >
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 col-lg-12">
                <div className="d-flex flex-column align-items-center text-center">
                  <div className="dialog-text">
                    <img src={ErrorImage} alt="errorIcon" className="img-fluid" />
                  </div>
                  <div className="dialog-text">
                    {t(errorMessage)}
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <Button onClick={handleClose} className="close-btn">
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>

      </React.Fragment>
    )
  }

  return (
    <>
      <div translate={translateAttribute}
        className={
          language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
            ? "english_container"
            : "arabic_container"
        }
      >

        <Loader></Loader>
        <RouterProvider router={routers} />
        <ToastContainer position="bottom-right" delay={4000} />
        <Toaster />
        <ErrorNotification />
      </div>
    </>
  );
}

export default App;
