import "./ThemeParkCheckOut.scss";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES } from "../../../Constants/Constants";
import { GetSessionStorageData } from "../../../Utilities/Helpers/Helper";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import themeParkIcon from "../../../Assets/SideBar/themeParkActive.svg";
import { Container } from '@mui/material';
import back_img from "../../../Assets/Tutor/back_img.svg";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import OneDayPassIcon from '../../../Assets/ThemePark/OneDayPassIcon.svg';
import OneDayPassIconWhite from '../../../Assets/ThemePark/One-day-pass.svg';
import EditeIcon from '../../../Assets/ThemePark/EditeIcon.svg'
import BlueMapIcon from '../../../Assets/ThemePark/BlueMapIcon.svg'
import TbookHeadIcon from '../../../Assets/ThemePark/TbookHeadIcon.svg'
import DatePicIcon from '../../../Assets/ThemePark/DatePicIcon.svg'
import TicketDecIcon from '../../../Assets/ThemePark/TicketDecIcon.svg'
import TicketIncIcon from '../../../Assets/ThemePark/TicketIncIcon.svg'
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import ThemeParkGuestUserLogin from "../ThemeParkGuestUserLogin/ThemeParkGuestUserLogin";
import moment from "moment";
//
import { useTranslation } from "react-i18next";
import TaCDialog from "../../../Components/TaCDialog/TaCDialog";
import { store } from "../../../Redux/Store/Store";
import { setErrorMessage, setErrorNotification, setTermsandConditions } from "../../../Redux/Reducer/Reducer";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ThemeParkCheckOut = () => {
    const { t } = useTranslation();
    const route = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(route.search);
    const qureyId = searchParams.get('id');
    const eventId = searchParams.get('event');
    const ticketId = searchParams.get('ticket');
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 10);
    const [DynamicDate, setDynamicDate] = useState<any>(nextDate);
    const [TicketList, setTicketList] = useState<any>([]);
    const [userEmail, setUserEmail] = useState<any>('');
    const [userMobile, setUserMobile] = useState<any>('');
    const [orderSummary, setOrderSummary] = useState(false);
    const [open, setOpen] = useState<boolean>(false);
    const [TaCopen, setTaCOpen] = useState<boolean>(false);
    const [TaCclose, setTaCclose] = useState<boolean>(false);
    const [openTicketData, setOpenTicketData] = useState<any>('');
    const [openUserLogin, setOpenUserLogin] = useState<boolean>(false);
    const { state } = route;
    const details = state?.details;
    const tempDetails = details?.ticket_type_list?.map((res: any) => {
        return {
            ...res,
            tickets_qty: 0,
            tickets_select: false,
        }
    })
    const [ticketArr, setTicketArr] = useState(tempDetails)
    const [ticketIndex, setTicketIndex] = useState<any>(null)
    const [booking, setBooking] = useState<boolean>(false);

    const calculateTotal = useCallback(() => {
        // Use the reduce method to sum the array elements
        const total = ticketArr?.reduce((accumulator: any, currentValue: any) => {
            // Check if tickets_qty is greater than 0
            if (currentValue.tickets_qty > 0 && currentValue.tickets_select) {
                // Multiply ticket_price by tickets_qty for each ticket and add to accumulator
                return accumulator + ((currentValue.tick_discount_amount ? currentValue.tick_discount_amount : currentValue.ticket_price) * currentValue.tickets_qty);
            } else {
                // If tickets_qty is 0 or less, return accumulator unchanged
                return accumulator;
            }
        }, 0); // 0 is the initial value of the accumulator

        return total;
    }, [ticketArr]);

    const totalAmount = useMemo(() => calculateTotal(), [ticketArr]);
    const dispatch=useDispatch();

    const calculateVat = () => {
        return calculateTotal()*0.05
    }

    useEffect(() => {
        getTicketList();
        setUserEmail(GetSessionStorageData(AUTHENTICATION.SHARED.EMAIL));
        setUserMobile(GetSessionStorageData(AUTHENTICATION.SHARED.MOBILE));
    }, [])

    /**Get Ticket List*/
    const getTicketList = () => {
        // axiosInstance
        //     .get(`${API_END_POINTS.THEMEPARK_CHECKOUT_LIST + eventId + '&theme_park_id=' + qureyId}`)
        //     .then((Response: any) => {
        //         if (Response?.data?.status) {
        //             setTicketList(Response?.data?.result?.data[0]);
        //         }
        //     })
        //     .catch(() => { });
    };
    /**For Model Open**/
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleOpenDialog = () => {
        setTaCOpen(true)
    }

    const handleCloseDialog = () => {
        setTaCOpen(false)
    }

    const handleChecked = ()=>{
        handleTermsandConditions()
    }

    const handleTermsandConditions = () => {
        axiosInstance
        .post(API_END_POINTS.TERMS_AND_CONDITIONS)
        .then((response)=>{
          if(response.data.status) {
             if(response.data.status) {
                      dispatch(setTermsandConditions(response.data.result.data.terms_condition))
                    }
          }
        })
        .catch(()=>{})
    }

    /**Post Camps booking Api */
    const ThemeParkBookingApi = () => {
        var transformedData: any = [];
        // Iterate through the input response and transform each item
        ticketArr?.forEach((item: any) => {
            if (item?.tickets_qty > 0 && item?.tickets_select) {
                transformedData?.push({
                    ticket_type_id: item.id,
                    no_of_tickets: item.tickets_qty,
                });
            }

        });
        var pay = {
            themepark_id: details.id,
            booked_email: userEmail,
            booked_phone_number: userMobile,
            payment_mode: GLOBAL_CONSTANTS.INITIAL_VALUES.CARD,
            payment_type: GLOBAL_CONSTANTS.INITIAL_VALUES.ONLINE,
            total_amount: calculateTotal(),
            booked_date: moment(new Date()).format("YYYY-MM-DD"),
            ticket: transformedData,
            vat_percent: 5,
            vat_amount: +(+totalAmount*0.05).toFixed(0) || 0,
            vat_total_amount: +(+totalAmount*0.05).toFixed(0) + totalAmount || 0 ,
        }

        axiosInstance
            .post(`${API_END_POINTS.THEMEPARK_BOOKING}`, pay)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    toast.success(Response?.data?.message, {
                        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                    });
                    handleClose();
                    navigate(ROUTES.THEMEPARK_PAYMENT_SUCCESS, {
                        state: {
                            details: details,
                            ticketArr: ticketArr,
                            pdfTicketList: Response?.data?.result?.data,
                            PaymentTotal: (+totalAmount*0.05) + +totalAmount,
                        }
                    })
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.message, {
                //     position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                // });
                handleClose();
            });
    };


    const handleClose = () => {
        setOrderSummary(false);
    };

    /**For User Login Model**/
    const OpenUserLoginModel = () => {
        setOpenUserLogin(true);
    };

    const CloseUserLoginModel = () => {
        setOpenUserLogin(false);
    };

    /**For Ticket choose Model**/

    const OpenTicketChooseModel = (value: any) => {
        setOpen(true);
        setOpenTicketData(value);
    };

    const CloseTicketChooseModel = () => {
        setOpen(false);
        // setOpenTicketData("");
    };

    const handleConfirm = () => {
        const updatedArray = ticketArr?.map((ticket: any) => {
            if (ticket.id === openTicketData.id) {
                return { ...ticket, tickets_qty: openTicketData.tickets_qty, tickets_select: true };
            }
            return ticket;
        });
        setTicketArr(updatedArray);
        CloseTicketChooseModel()
    };

    useEffect(() => {
        const checkTicketCount = ticketArr.filter((item: any) => item.tickets_qty > 0);
        if (checkTicketCount.length > 0) {
            setBooking(true);
        } else {
            setBooking(false);
        }
    }, [ticketArr])

    const confirmBooking = () => {

        const checkTicketCount = ticketArr.filter((item: any) => item.tickets_qty > 0);
        if (checkTicketCount.length > 0) {
            setOrderSummary(true);

        } else {
            // toast.error("Please choose your tickets before proceeding to booking", { position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION });
            store.dispatch(setErrorNotification(true))
            store.dispatch(setErrorMessage("Please choose your tickets before proceeding to booking"))
        }
    }


    const handleDecrement = () => {
        if (openTicketData?.tickets_qty !== 0) {
            setOpenTicketData({ ...openTicketData, tickets_qty: openTicketData?.tickets_qty - 1 })
        } else {
            setOpenTicketData({ ...openTicketData, tickets_select: false })
        }

    }

    const handleIncrement = () => {
        if (openTicketData?.tickets_qty < openTicketData?.remaining_tickets) {
            setOpenTicketData({ ...openTicketData, tickets_qty: openTicketData?.tickets_qty + 1 })
        } else {
            let message = openTicketData?.remaining_tickets === 1 ? `There is only ${openTicketData?.remaining_tickets} ticket available` : `There are only ${openTicketData?.remaining_tickets} tickets available`
            // toast.error(message, { position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION });
        }
    }

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });

    //get the terms and conditions from redux
    const checked: any = useSelector((state: any) => {
        return state.TermsandConditionsReducer.TermsandConditions;
    });
   

    /*Below class name based on English Arabic*/
    const CheckHeaderImg = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "check-header-img" : "check-header-img-arab";
    const CheckHeaderImgTwo = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "check-header-img-two" : "check-header-img-two-arab";
    const TicketPassTypeImg = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ticket-pass-type-img" : "ticket-pass-type-img-arab";
    const TicketPassTypeLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ticket-pass-type-eng" : "ticket-pass-type-arab";
    const ModalHeadStyleImg = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "modal-head-style-img-eng" : "modal-head-style-img-arab";
    const ModelIncDecSpan = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "modal-head-style-img-eng" : "modal-head-style-img-arab";
    const ModelActionButtons = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "model-action-buttons-eng" : "model-action-buttons-arab";
    const TicketTypeEditeMargin = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ticket-type-edite-margin" : "ticket-type-edite-margin-arab";
    const SumTicketAvailableImg = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "sum-ticket-available-img" : "sum-ticket-available-img-arab";
    const SumTicketPassType = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "sum-ticket-pass-type-eng" : "sum-ticket-pass-type-arab";
    const BackImgLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "BackImgLang-Eng" : "BackImgLang-Arab";

    return (
        <>
            <React.Fragment >

                <Container component="main" className="Themepark_checkout_page" maxWidth="md" sx={{ mb: 4 }} >
                    <div className="background_circle_1"></div>
                    <div className="background_circle_2"></div  >
                    <div className="ThemePark__Back__card" onClick={() => navigate(-1)}>
                        {/* <img src={back_img} alt="" className="back-img" /> */}
                        <img src={back_img} alt="" className={`${BackImgLang} back-img`} />
                        <img src={themeParkIcon} alt="themeParkIcon" />
                        <div className="TutorImg__text">{t("Theme Park")}</div>
                    </div>
                    <div className="CheckOut__conatiner mt-3">
                        <div className="Ticket_conatiner">
                            <div className="tcart-header">
                                <div>
                                    <img src={TbookHeadIcon} alt="" className={CheckHeaderImg} />
                                    <div className="notranslate " translate="no">{
                                    language === "English" ? details?.themepark_name : details?.arabic_themepark_name || details?.themepark_name
                                    // details?.themepark_name
                                    }</div>
                                </div>
                                <div>
                                    <img src={BlueMapIcon} alt="" className={CheckHeaderImgTwo} />
                                    <div className="location" title={details?.address}>{details?.city !== null ? details?.city : details?.address}</div>
                                </div>
                            </div>

                            <div className="TicketBottom__wapper marginTop__ticket">
                                <div className="lable">{t("Expiry Date")}</div>
                                <div className="lable"> {moment(DynamicDate).format('DD-MM-YYYY')} </div>
                                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        className="Ticket_datePicker"
                                        format="dd-MM-yyyy"
                                        disabled
                                        value={DynamicDate}
                                        onChange={(date) => { setDynamicDate(date) }}
                                    />
                                </LocalizationProvider> */}
                            </div>
                            {/* For Selecte Ticket Type */}
                            <div className="selected-text-style">{t("Select Ticket Type")}</div>
                            <div translate={'no'}>
                                {ticketArr?.map((item: any, index: number) => {
                                    if ((ticketId && item.id == ticketId) || !ticketId) {
                                        return (
                                            <div key={item.id} className={item?.tickets_select && item?.tickets_qty > 0 ? "ticket-available ticket-available_select" : "ticket-available"} onClick={() => {
                                                OpenTicketChooseModel(item);
                                                setTicketIndex(index)
                                            }}>

                                                <p className={`${TicketPassTypeLang} ticket-pass-type`}>
                                                    <img src={OneDayPassIcon} alt="" className={TicketPassTypeImg} />
                                                    <span>{item?.ticket_type}</span>
                                                </p>
                                                {item?.tickets_qty > 0 ? <p className="ticket-type-edite" translate={'no'}>
                                                    <span className={TicketTypeEditeMargin}>{item?.tickets_qty} {t("Ticket")}</span>
                                                    <img src={EditeIcon} alt="" />
                                                </p> : ""}
                                            </div>
                                        )
                                    }


                                })}
                            </div>

                            {/* <button className="BookBtn " onClick={booking ? confirmBooking : () => { }} disabled={!booking} >Confirm Booking</button> */}
                            <button className="BookBtn " onClick={confirmBooking}  >{t("Confirm Booking")}</button>
                            {/* <button onClick={OpenUserLoginModel} style={{ background: "red", fontSize: "10px" }}>Demo Button For User Login</button> */}
                        </div>
                    </div>

                </Container>

                <div className={`${orderSummary || open ? 'header_wrapper__blur' : ''}`}>

                    <div className="ticket-modal-container ">
                        <Dialog
                            className="ticket-booking-model"
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={CloseTicketChooseModel}
                            aria-describedby="alert-dialog-slide-description"
                            dir={
                                language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
                                    ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
                                    : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
                            }
                        >
                            <DialogTitle className="modal-head-style">
                                <div>
                                    <img src={OneDayPassIcon} alt="" className={ModalHeadStyleImg} />
                                    <span>{openTicketData?.ticket_type}</span>
                                </div>
                                <div>
                                    {t("AED")} {openTicketData?.tick_discount_amount ? openTicketData?.tick_discount_amount :openTicketData?.ticket_price}
                                </div>
                            </DialogTitle>
                            <DialogContent className="dialog-content">
                                <DialogContentText id="alert-dialog-slide-description">
                                    {openTicketData?.description}
                                </DialogContentText>

                                <DialogContentText className="model-date-picketr" translate={'no'}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <img src={DatePicIcon} alt="" className="model-date-icon" />
                                        <div className="date" translate={'no'}>{moment(DynamicDate).format('MMM, DD YYYY')}</div>

                                        {/* <DatePicker
                                            className="model-Ticket_datePicker"
                                            format="dd-MM-yyyy"
                                            minDate={nextDate}
                                            maxDate={new Date(TicketList?.expiry_on)}
                                            value={DynamicDate}
                                            onChange={(date) => { setDynamicDate(date) }}
                                        /> */}
                                    </LocalizationProvider>
                                </DialogContentText>

                                <DialogContentText className="model-inc-dec" translate={'no'}>
                                    <p>{t("Ticket")}</p>
                                    <p translate={'no'}>
                                        <img src={TicketDecIcon} alt="" onClick={handleDecrement} />
                                        <span className={ModelIncDecSpan} translate={'no'}> {openTicketData?.tickets_qty} </span>
                                        <img src={TicketIncIcon} alt="" onClick={handleIncrement} />
                                    </p>
                                </DialogContentText>

                            </DialogContent>
                            <DialogActions className="model-action-buttons">
                                <Button onClick={CloseTicketChooseModel}>{t("Cancel")}</Button>
                                {/* <Button className={ModelActionButtons} onClick={() => {
                                    const updatedArray = ticketArr.map((ticket: any) => {
                                        if (ticket.id === openTicketData.id) {
                                            ticket.tickets_qty = openTicketData.tickets_qty;
                                            ticket.tickets_select = true;
                                        }
                                        return ticket;
                                    })
                                    setTicketArr(updatedArray)
                                    CloseTicketChooseModel();
                                }}>{t("Confirm")}</Button> */}
                                <Button className={ModelActionButtons} onClick={handleConfirm}>{t("Confirm")}</Button>
                            </DialogActions>
                        </Dialog>

                    </div>

                    {/* Order summary Model */}
                    <div className="summary-ticket-modal-container">
                        <Dialog
                            className="summary-modal-for-ticket-order"
                            open={orderSummary}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                            dir={
                                language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
                                    ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
                                    : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
                            }
                        >
                            <div className="summary-modal-head-style">
                                {t("Order Summary")}
                            </div>
                            <hr className="summary-modal-hr" />
                            <DialogContent className="summary-dialog-content">
                                <DialogContentText className="summary-model-inc-dec-one">
                                    <div className="order_list_container">
                                        {ticketArr?.map((item: any) => {
                                            if (item?.tickets_qty > 0 && item?.tickets_select) {
                                                return (
                                                    <>
                                                        <div key={item.id} className="order_list_wapper">
                                                            <div className={"sum-ticket-available sum-ticket-available-select"} >
                                                                <img className={SumTicketAvailableImg} src={OneDayPassIconWhite} alt="" />
                                                                <p className={`${SumTicketPassType} sum-ticket-pass-type`}>{item?.ticket_type}</p>
                                                                <p className="sum-ticket-type-edite" translate={'no'}>
                                                                    <span>{item?.tickets_qty === 1 ? item?.tickets_qty + 'Ticket' : item?.tickets_qty + 'Tickets'} </span>
                                                                </p>
                                                            </div>
                                                            <div className="price_text">{t("AED")} {item?.tick_discount_amount ? item?.tick_discount_amount : item?.ticket_price}</div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        })}
                                    </div>
                                </DialogContentText>
                                <DialogContentText className="summary-model-inc-dec-two">
                                    <div>
                                        <span className="more_ticket_text">{t("Add more tickets")}</span>
                                        <AddCircleOutlineSharpIcon className="plus_icon" onClick={handleClose} />
                                    </div>
                                </DialogContentText>
                                <hr className="summary-modal-hr" />
                                <DialogContentText key={`total-amount-${totalAmount}`} className="summary-model-inc-dec-three">
                                    <div>
                                        <span className="total_ticket_text">{t("Total")}</span>
                                        <span className="more_ticket_text_three">{t("AED")} {totalAmount}</span>
                                    </div>
                                    <div>
                                        <span className="total_ticket_text">{t("VAT 5%")}</span>
                                        <span className="more_ticket_text_three">+{t("AED")} {(+totalAmount*0.05).toFixed(0)}</span>
                                    </div>
                                <hr className="summary-modal-hr" />
                                <div>
                                    <span className="total_ticket_text">{t("To Pay")}</span>
                                    <span className="more_ticket_text_three">{t("AED")} {+(+totalAmount*0.05).toFixed(0) + +totalAmount}</span>
                                </div>
                                </DialogContentText>



                                <DialogContentText className="summary-model-inc-dec-four">
                                    <div>
                                        <input type="checkbox" className="checkbox" checked={checked} onChange={handleChecked} />
                                        <div className="confirmation_text">
                                            <label>{t("By completing your purchase, you agree to these")} <span onClick={handleOpenDialog}>{t("Terms of Services")}</span></label>
                                        </div>
                                    </div>
                                </DialogContentText>
                                <TaCDialog 
                                TaCopen={TaCopen}
                                handleCloseDialog={handleCloseDialog}
                                />

                            </DialogContent>
                            <div className="procees-button-wrap">
                                <button className={checked ? "proceed_buttons" : "disable_btn proceed_buttons"} onClick={() => {
                                    if (checked) {
                                        ThemeParkBookingApi()
                                    }
                                }}>{t("Proceed to Payment")}
                                </button>
                            </div>
                        </Dialog>
                    </div>
                </div>
                <div>
                    {/* <ThemeParkGuestUserLogin openUserLogin={openUserLogin} CloseUserLoginModel={CloseUserLoginModel} /> */}

                    {/* <ThemeParkGuestUserLogin  openGuestSignupDialog={openUserLogin} handleGuestSignUpClose={CloseUserLoginModel}/> */}
                </div>

            </React.Fragment >
        </>
    )
}

export default ThemeParkCheckOut;