import { useEffect, useState } from "react";
import TutorImg from "../../../Assets/Tutor/TutorImg.svg";
import FilterIcon from "../../../Assets/Tutor/FilterIcon.svg";
import search from "../../../Assets/Tutor/searchIconGray.svg";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import shareIcon from "../../../Assets/Tutor/share.svg";
import LikeIcon from "../../../Assets/Tutor/LikeIcon.svg";
import locationIcon from "../../../Assets/Tutor/location.svg";
import experienceIcon from "../../../Assets/Tutor/experience.svg";
import BadgeIcon from "../../../Assets/Tutor/BadgeIcon.svg";
import right from "../../../Assets/Tutor/right.svg";
import StarIcon from "@mui/icons-material/Star";
import "./TutorsMapsView.scss";
import TutorMaps from "../../../Components/TutorMaps/TutorMaps";
import { useLocation, useNavigate } from "react-router-dom";
import TutorsModelDilogView from "../TutorModelDilogView/TutorModelDilogView";
import {
  Dropdown,
  DropdownMenuItem,
  DropdownNestedMenuItem,
} from "../Dropdown";
import { ArrowRight } from "@mui/icons-material";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import {
  API_END_POINTS,
  AUTHENTICATION,
  FILTER_CONSTANT,
  GLOBAL_CONSTANTS,
  HOMEPAGE,
  ROUTES,
  RadiusMapKm,
} from "../../../Constants/Constants";
import {
  GetSessionStorageData,
  SetSessionStorageData,
  getCurrentLocation,
  isObjectEmptyValue,
  nullUndefinedEmptyCheck,
} from "../../../Utilities/Helpers/Helper";
import { store } from "../../../Redux/Store/Store";
import { setInitTutorFilter, setLoader } from "../../../Redux/Reducer/Reducer";
import { Autocomplete, Box, Rating, Slider, TextField } from "@mui/material";
import axios from "axios";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import TutorMapRadiusIcon from "../../../Assets/Tutor/tutor_km.svg";
import { useSelector } from "react-redux";
import TutorFilterPopup from "../TutorFilterPopup/TutorFilterPop";
import AppFilter from "../../../Components/AppFilter.tsx/AppFilter";
import { useTranslation } from "react-i18next";

const TutorsMapsView = () => {
  const tutor_search = GetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.TUTOR);
  const route = useLocation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(tutor_search ? tutor_search : '');
  const [triggerVal, setTriggerVal] = useState(false);
  const [dailogTutorData, setDailogTutorData] = useState([]);
  const [tutorList, setTutorList] = useState<any>(route?.state?.tutor_list);
  const [searchTextList, setSearchTextList] = useState([]);
  const [currentLat, setCurrentLat] = useState<any>("");
  const [currentLang, setCurrentLang] = useState<any>("");
  const [FilterData, setFilterData] = useState<any>([]);
  const [MapZoom, setMapZoom] = useState(12);
  const { t } = useTranslation();

  const tutorfilter: any = useSelector((state: any) => {
    return state.filterReducer.tutorfilter
  })

  const init_tutorfilter: any = useSelector((state: any) => {
    return state.initFilterReducer.initTutorfilter
  })

  // Filter

  const init_filter = {
    courses: [],
    curriculum: [],
    price: [0, 0],
    mode_of_teaching: [],
    experience: [0, 0],
    rating: 0,
    gender: [],
    grade: [0, 0],
    radius: 10,
  }
  const [FilterPopOpen, setFilterPopOpen] = useState(false);
  const [initFilter, setInitFilter] = useState<any>(isObjectEmptyValue(init_tutorfilter) ? init_tutorfilter : init_filter);
  const [FilterApplyData, setFilterApplyData] = useState<any>(init_filter);
  const [initSort, setSort] = useState<any>({
    price: '',
    rating: ''
  });

  const onOpenFilterPop = () => {
    setFilterPopOpen(true);
  };

  const onCloseFilterPop = () => {
    setFilterPopOpen(false);
  };


  // Render the  Map view Page size
  const containerStyle = {
    width: "100vw",
    height: "100vh",
  };

  const getOneTutor = (data: any) => {
    setDailogTutorData(data);
    setTriggerVal(true);
  };
  const getAllTutor = (data: any) => {
    setTutorList(data);
  };

  const errorCallback = (error: any) => {
    // console.log("errorCallback", error);
  };

  useEffect(() => {
    getFilterData();

  }, []);

  /**Tutor List  Post Api Payload*/
  const payload = (latitude: any, longitude: any, value: string, filter: any) => {
    var payload;
    var priceValue: any = [];
    var experienceValue: any = [];
    var gradeValue: any = [];

    if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
      priceValue = []
    } else {
      priceValue = {
        min: filter.price[0],
        max: filter.price[1],
      }
    }
    if (initFilter.experience[0] === filter.experience[0] && initFilter.experience[1] === filter.experience[1]) {
      experienceValue = []
    } else {
      experienceValue = {
        min: filter.experience[0],
        max: filter.experience[1],
      }
    }
    if (initFilter.grade[0] === filter.grade[0] && initFilter.grade[1] === filter.grade[1]) {
      gradeValue = []
    } else {
      gradeValue = {
        min: filter.grade[0],
        max: filter.grade[1],
      }
    }
    return payload = {
      latitude: latitude?.toString(),
      longitude: longitude?.toString(),
      radius: filter.radius,
      search: value ? value : "",
      price: priceValue,
      experience: experienceValue,
      grade: gradeValue,
      courses: filter.courses,
      mode_of_teaching: filter.mode_of_teaching,
      gender: filter.gender,
      curriculum: filter.curriculum,
      rating: filter.rating,
    };
  };

  /**Tutor get List Api*/
  const TutorListApi = (latitude: any, longitude: any, value: string, filter: any) => {
    var pay = payload(latitude, longitude, value, filter);

    axiosInstance
      .post(`${API_END_POINTS.TUTORLIST}`, pay)
      .then((Response: any) => {
        setTutorList(Response?.data?.result?.data);
      })
      .catch(() => { });
  };

  /**Tutor Search Keyword Api*/
  const searchTutorKeyword = (search: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    var payload = {
      search_text: search,
      course_tag: FilterApplyData?.courses ? FilterApplyData?.courses.toString() : ""
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_BACKEND_API_URL +
        API_END_POINTS.TUTOR_KEY_WORD_SEARCH
        }`,
        payload
        // {
        //     headers: {
        //         Authorization: "Bearer " + accessToken,
        //     },
        // }
      )
      .then((Response: any) => {
        setSearchTextList(Response?.data.result?.data);
      })
      .catch(() => { });
  };

  /**Get Filter Categories List*/
  const getFilterData = () => {
    axiosInstance
      .get(`${API_END_POINTS.TUTORFILTERLIST}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          let data = Response?.data?.result?.data;
          setFilterData(data);
          if (data) {
            let init = {
              courses: [],
              curriculum: [],
              price: [data.price?.min, data.price?.max],
              mode_of_teaching: [],
              experience: [data.experience?.min, data.experience?.max],
              rating: 0,
              gender: [],
              grade: [data.grade?.min, data.grade?.max],
              radius: 10,
            }
            store.dispatch(setInitTutorFilter(init));
            SetSessionStorageData(FILTER_CONSTANT.INIT_FILTER.TUTOR, JSON.stringify(init));
            setInitFilter(init);
            setFilterApplyData(isObjectEmptyValue(tutorfilter) ? tutorfilter : init);
          }
        }
      })
      .catch(() => { });
  };

  /**Header search location*/
  const HeaderLoction: any = useSelector((state: any) => {
    return state.locationReducer.location;
  });

  useEffect(() => {
    if (nullUndefinedEmptyCheck(HeaderLoction?.lat && HeaderLoction?.lng)) {
      setCurrentLat(HeaderLoction?.lat);
      setCurrentLang(HeaderLoction?.lng);
      if (tutorfilter && isObjectEmptyValue(tutorfilter)) {
        TutorListApi(route?.state?.lat, route?.state?.lang, searchValue, tutorfilter);
      } else {
        TutorListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, FilterApplyData);
      }
    }
  }, [HeaderLoction]);

  /**Filter Apply api call Function*/
  const FilterApply = (filter: any) => {
    setFilterApplyData(filter)
    SetSessionStorageData(FILTER_CONSTANT.STATE_FILTER.TUTOR, JSON.stringify(filter));
    TutorListApi(currentLat, currentLang, searchValue, filter);
  };


  /**Autocomplete Onchange Function*/
  const onInputChange = (value: any) => {
    store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    if (nullUndefinedEmptyCheck(value)) {
      setSearchValue(value);
      SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.TUTOR, value);
      if (value.length>2) {
        searchTutorKeyword(value);
        TutorListApi(currentLat, currentLang, searchValue, FilterApplyData);}
    } 
  };

  /**Autocomplete Submit Function*/
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //  prevent page refresh
    event.preventDefault();
    TutorListApi(currentLat, currentLang, searchValue, FilterApplyData);
  };


  const handleChangeRadiusMapvalue = (value: any) => {
    if (value === 15) {
      setMapZoom(12);
    } else if (value === 50) {
      setMapZoom(10);
    } else if (value === 100) {
      setMapZoom(9);
    } else {
      setMapZoom(12);
    }
    setFilterApplyData({ ...FilterApplyData, radius: FilterApplyData.radius == value ? 10 : value })
  };

  const navigateDashboard = () => {
    navigate(ROUTES.TUTORS, {
      state: {
        tutor_list: tutorList,
        search_text: searchValue,
        redius: FilterApplyData.radius,
        lat: currentLat,
        lang: currentLang,
        filter: FilterApplyData,
      },
    });
  };

  const getLatAndLong = (lat: string, lang: string) => {
    setCurrentLat(lat);
    setCurrentLang(lang);
  };

  const handleProceedSort = (checkedPrice: any, checkedRating: any) => {
    if (checkedPrice == 'high') {
      const sortedData = [...tutorList].sort((a: any, b: any) => b?.least_price - a?.least_price);
      setTutorList(sortedData);
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedPrice == 'low') {
      const sortedData = [...tutorList].sort((a: any, b: any) => a?.least_price - b?.least_price);
      setTutorList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedRating == 'high') {
      const sortedData = [...tutorList].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
      setTutorList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedRating == 'low') {
      const sortedData = [...tutorList].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
      setTutorList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    }
  }

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const Search_icon_top = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "search_button_eng" : "search_button_arb";


  return (
    <>

      <div className="TutorsMapsView__container">
        <div className="">
          <TutorMaps
            zoom={MapZoom}
            radius={FilterApplyData.radius * 1000}
            lat={currentLat}
            lang={currentLang}
            containerStyle={containerStyle}
            disable={false}
            zoomControl={true}
            clickableIcons={true}
            draggable={true}
            getOneTutor={getOneTutor}
            tutorList={getAllTutor}
            filter={isObjectEmptyValue(tutorfilter) ? tutorfilter : FilterApplyData}
            initFilter={initFilter}
            searchValue={searchValue}
            initSort={initSort}
          />
        </div>

        <div className="TutorCard_position">
          <div
            className="TutorCard_Img_wapper"
            onClick={() => navigateDashboard()}
          >
            <img draggable="false" src={TutorImg} alt="TutorImg" />
            {/* <h5>{HOMEPAGE.SERVICE_TYPE.TUTOR}</h5> */}
            <h5>{t("Tutors")}</h5>
          </div>

          <div className="search_field_wrapper">
            <div className="search__autocomplete">
              <form onSubmit={handleSubmit}>
                <Autocomplete
                  freeSolo
                  popupIcon
                  id="free-solo-2-demo"
                  disableClearable={false}
                  clearIcon={false}
                  value={searchValue}
                  onChange={(e, value) => {
                    const trimedvalue = value? value.trim():''
                    setSearchValue(trimedvalue);
                    SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.TUTOR, trimedvalue);
                    TutorListApi(currentLat, currentLang, trimedvalue, FilterApplyData);
                  }}
                  options={searchTextList?.map((option: any) =>
                    language === GLOBAL_CONSTANTS.LANGUAGES.ARABIC ? option?.arabic_tag_name : option?.tag)}
                  renderOption={(props, option) => (
                    <Box component='li' sx={[{ px:0.5, py:0.7 }]} {...props} className="notranslate" translate="no">
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={searchValue}
                      placeholder={t("Search for tutor")}
                      onChange={(inputChangeEvent: any) => {
                        onInputChange(inputChangeEvent.target.value.trim());
                      }}
                      onKeyDown={(e: any) => {
                        if (e.key === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                          e.preventDefault();
                          const trimedvalue = searchValue.trim()
                          if (trimedvalue) {
                            TutorListApi(currentLat, currentLang, trimedvalue, FilterApplyData);
                          }
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        type: "text",
                      }}
                    />
                  )}
                />
                <img
                  draggable="false"
                  src={search}
                  alt="search"
                  className={Search_icon_top}
                  onClick={() =>
                    TutorListApi(currentLat, currentLang, searchValue, FilterApplyData)
                  }
                />
              </form>
            </div>
            <AppFilter
              FilterPopOpen={FilterPopOpen}
              onOpenPop={onOpenFilterPop}
              onClosePop={onCloseFilterPop}
              FilterData={FilterData}
              FilterApply={FilterApply}
              FilterApplyData={FilterApplyData}
              initFilter={initFilter}
              initSort={initSort}
              handleApplySort={handleProceedSort}
              type="tutor"
              color="#178fff"
            />
          </div>

          <div className="TutorCard__RadiusMapKm">
            {RadiusMapKm.map((item, i) => {
              return (
                <>
                  <div
                    className={
                      FilterApplyData.radius === item.value
                        ? "RadiusMapKm__wapper__active"
                        : "RadiusMapKm__wapper"
                    }
                    key={i}
                    onClick={() => handleChangeRadiusMapvalue(item.value)}
                  >
                    <img src={TutorMapRadiusIcon} alt="" />
                    <span>{item.lable}</span>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <TutorsModelDilogView
          triggerModel={triggerVal}
          Detailes={dailogTutorData}
          tutorList={tutorList}
          close={() => setTriggerVal(false)}
          getLatAndLong={getLatAndLong}
        />
      </div>
    </>
  );
};

export default TutorsMapsView;
