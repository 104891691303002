import TutorHubChat__Img from "../../../Assets/TutorHub/TutorHubChat.svg";
import chatSendIcon from "../../../Assets/TutorHub/chatSendIcon.svg";
import React, { useEffect, useRef, useState } from "react";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, S3DataGetApi, SOCKET } from "../../../Constants/Constants";
import { GetSessionStorageData, nullUndefinedEmptyCheck, uploadFileToS3 } from "../../../Utilities/Helpers/Helper";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import { useLocation, useNavigate } from "react-router-dom";
import "./SchoolChatExpert.scss";
import { Container } from "@mui/material";
import moment from "moment";
import schoolIocn from "../../../Assets/School/schoolIocn.svg";
import back_img from "../../../Assets/School/backImg.svg";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Pdf_Img from "../../../Assets/Global/drive.svg";
import DownloadImg from "../../../Assets/TutorHub/DownloadImg.svg";
import { setLoader } from "../../../Redux/Reducer/Reducer";
import { store } from "../../../Redux/Store/Store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const SchoolChatExpert = () => {
    const { t } = useTranslation();
    const route = useLocation();
    const navigate = useNavigate();
    const DataKeys: any = useSelector((state: any) => state.s3DataKeyReducer.datakey);
    const [textMessage, setTextMessage] = useState("");
    const [activeIndex, setActiveIndex] = useState(GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT);
    const [chatHistory, setChatHistory] = useState<any>([]);
    const [userId, setUserId] = useState<any>(GetSessionStorageData(AUTHENTICATION.SHARED.USER_ID));
    const [socketInstance, setSocketInstance] = useState<any>();
    const ImageInputRef = useRef<HTMLInputElement>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState<any>(0);
    const chatContainerRef: any = useRef(null);
    //
    const [newMessage, setNewMessage] = useState(false);


    useEffect(() => {
        setUserId(GetSessionStorageData(AUTHENTICATION.SHARED.USER_ID));
        getChatHistory();
    }, []);


    useEffect(() => {
        /* Scroll to the bottom of the chat container when new messages arrive */
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [textMessage]);

    useEffect(() => {
        if (socketInstance) {
            socketInstance.close();
        };
        let socket = new WebSocket(`${process.env.REACT_APP_CHAT_WEB_SOCKET + route?.state?.roomData?.room_name}/`);
        // let socket = new WebSocket("ws://3.110.56.54:8001/ws/chat/" + route?.state?.roomData?.room_name + '/');
        setSocketInstance(socket)
    }, [route?.state?.roomData]);

    useEffect(() => {
        if (chatContainerRef?.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
            const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - 200;

            if (isScrolledToBottom || newMessage || currentPage === 1) {
                chatContainerRef.current.scrollTop = scrollHeight - 200;
                setNewMessage(false);
            } else {
                chatContainerRef.current.scrollTop = 100;
            }
        }
    }, [chatHistory]);

    useEffect(() => {
        if (socketInstance) {
            socketInstance.onopen = (event: any) => {
            };

            /* Listen to events for socket server */
            socketInstance.onmessage = (event: any) => {
                setChatHistory((prevChatHistory: any) => [...prevChatHistory, JSON.parse(event.data)]);
            };

            /* Closes socket on killing component */
            return () => {
                socketInstance.close();
            }
        }
    }, [socketInstance]);

    useEffect(() => {
        getChatHistory();
    }, [currentPage]);

    useEffect(() => {
        /** Add event listener to detect scroll **/
        const handleScroll = () => {
            const chatContainer = chatContainerRef.current;
            if (chatContainer.scrollTop === 0 && currentPage < totalPages) {
                /* Trigger pagination when user scrolls to the top and there are more pages to load */
                setCurrentPage(prevPage => prevPage + 1);
            }
        };

        chatContainerRef.current.addEventListener("scroll", handleScroll);

        return () => {
            /* Remove event listener when component unmounts */
            chatContainerRef.current?.removeEventListener("scroll", handleScroll);
        };
    }, [currentPage, totalPages]);

    /* Sending message using socket */
    const onSendMessage = () => {
        setNewMessage(true)
        socketInstance.send(JSON.stringify({
            sender: userId,
            receiver: route?.state?.is_notification ? route?.state?.is_data?.sent_by : route?.state?.roomData?.expert,
            message: textMessage,
            room_id: route?.state?.roomData?.room_id,
            image: null,
            document: null,
        }));
        setTextMessage("");
        // Scroll to the bottom of the chat container after sending the message
        // if (chatContainerRef.current && chatHistory) {
        //     chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        // }
    };


    // useEffect(() => {
    //     /* After sending a message, scroll to the bottom */
    //     if (chatContainerRef.current) {
    //         chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    //     }
    // }, [currentPage]);

    // useEffect(() => {
    //     /* Initialize chat history and scroll to bottom on component mount */
    //     setUserId(GetSessionStorageData(AUTHENTICATION.SHARED.USER_ID));
    //     getChatHistory();
    //     if (chatContainerRef.current) {
    //         chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    //     }
    // }, []);


    const getChatHistory = () => {
        var payload = {
            page: currentPage,
            limit: 15
        }
        axiosInstance.post(`${API_END_POINTS.UNIVERSITY_CHAT_HISTORY + route?.state?.roomData?.room_id}`, payload)
            // .then((response) => {
            //     if (response.data.status) {
            //         // setChatHistory(response?.data?.result?.data?.reverse());
            //         /* Update chat history state with new data */
            //         // setChatHistory(prevChatHistory => [
            //             setChatHistory((prevChatHistory: any[]) => [
            //             ...prevChatHistory,
            //             ...response?.data?.result?.data?.reverse()
            //         ]);  
            //           /* Update total pages state if available */
            //         if (response?.data?.result?.total_pages) {
            //             setTotalPages(response.data.result.total_pages);
            //         }
            //     }
            // })

            // .then((response) => {
            //     if (response.data.status) {
            //         setChatHistory((prevChatHistory: any[]) => [
            //             ...response?.data?.result?.data?.reverse(),
            //             ...prevChatHistory
            //         ]);
            //             setTotalPages(response.data.result.total_page);
            //     }
            // });

            .then((response) => {
                if (response.data.status) {
                    setChatHistory((prevChatHistory: any[]) => {
                        const newData = response?.data?.result?.data?.reverse() || [];
                        const filteredData = newData.filter((item: any) => !prevChatHistory.some(prevItem => prevItem.id === item.id));
                        return [...filteredData, ...prevChatHistory];
                    });
                    setTotalPages(response.data.result.total_page);
                }
            });
    }

    const onSendMessageDoc = (imgMsg: any, dataType: any) => {
        setNewMessage(true)
        if (imgMsg.trim() !== '') {
            socketInstance.send(JSON.stringify({
                sender: userId,
                receiver: route?.state?.is_notification ? route?.state?.is_data?.sent_by : route?.state?.roomData?.expert,
                message: null,
                image: dataType === 'image' ? imgMsg : null,
                document: dataType === 'document' ? imgMsg : null,
                room_id: route?.state?.roomData?.room_id
            }));
            setTextMessage("");
        }
    };

    const onUploadEventImage = async (event: any) => {
        setNewMessage(true)
        try {
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
            const uploadedImage = await S3DataGetApi(event);
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
            if (event.target.files[0].type.split("/")[0] === "image") {
                onSendMessageDoc(uploadedImage, 'image');
            } else {
                onSendMessageDoc(uploadedImage, 'document');
            }
        } catch (error) { }
    };

    //To extract file name from url
    const checkString = (url: string) => {
        const urlParts = url.split('/');
        const fileNameWithExtension = urlParts[urlParts.length - 1];
        const lastDotIndex = fileNameWithExtension.lastIndexOf('.');

        const fileNameWithoutExtension = lastDotIndex !== -1 ? fileNameWithExtension?.slice(0, lastDotIndex) : fileNameWithExtension;

        return fileNameWithoutExtension;
    }

    //To extract file type from url
    const checkTypeString = (url: string) => {
        const lastDotIndex = url.lastIndexOf('.');
        const fileFormat = url.slice(lastDotIndex);

        return fileFormat.replace(/\./g, '');
    }

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });
    /*Below class name based on English Arabic*/
    const TextTittleLanguage = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "text-tittle-english" : "text-tittle-arab";
    const Back_Image_Lang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "back-img-english" : "back-img-arab";
    const UnversityIconLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "unversity-icon-english" : "unversity-icon-arab";
    const Type_Box_Bontainer = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "type_box_container_english" : "type_box_container_arab";
    const AttachFileIconArab = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "AttachFileIcon_english" : "AttachFileIcon_arab";
    const SendIcon_Disable_Arab = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "sendIcon_disable_english" : "sendIcon_disable_arab";
    const sendIcon_Arab = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "sendIcon_english" : "sendIcon_arab";
    const Type_Box_Input = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "type_box_input_english" : "type_box_input_arab";
    const ChatEndLanguage = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "chat_end_english" : "chat_end_arab";
    const SchoolUserChatLanguage = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "school_user_chat_english" : "school_user_chat_arab";

    return (
        <>
            <React.Fragment>
                <Container className="overall-chat-university-container" component="main" maxWidth="md" sx={{ mb: 4 }}>
                    <div className="mt-2 university-chat-wrap" translate="no">
                        <div className="chat_with_university_tittle-div" onClick={() => navigate(-1)}>
                            <img src={back_img} alt="" className={`${Back_Image_Lang} back-img`} />
                            <img src={schoolIocn} alt="" className={`${UnversityIconLang} unversity-icon`} />
                            <label className={`${TextTittleLanguage} text-tittle`}>{t("Schools")}</label>
                        </div>
                        <div className="UniversityChatExpert__container mt-2">
                            <div className="Header__wapper">
                                <img src={TutorHubChat__Img} alt="" />
                                <div className="title">{t("Chat with")} {route?.state?.is_notification ? route?.state?.roomData?.sender_name : route?.state?.roomData?.expert_name}</div>
                            </div>

                            {/* <div className="chat__container" ref={chatContainerRef} style={{ height: "400px", overflowY: "auto" }}> */}
                            <div className="chat__container" ref={chatContainerRef} >

                                {chatHistory?.map((item: any, i: any) => {
                                    return (
                                        <>
                                            {/* <div className="chat__center">
                                            <div className="show__time">
                                                May 16, 2023
                                            </div>
                                        </div> */}

                                            {item?.sender != userId ? (
                                                <>
                                                    {nullUndefinedEmptyCheck(item?.message) && (
                                                        <div className="chat__start">
                                                            <div className="tutor__chat">
                                                                {item?.message}
                                                            </div>
                                                            <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                        </div>
                                                    )}
                                                    {item.image && <div className="chat__start">
                                                        <img className="chat-image" src={item.image} onClick={() => {
                                                            window.open(item.image, "_blank");
                                                        }} />
                                                        <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                    </div>}

                                                    {item.document && (
                                                        <div className="chat__start">
                                                            <div className="Pdf__container">
                                                                <div className="left__contant">
                                                                    <div className="tutor__img__wapper">
                                                                        <img src={Pdf_Img} alt="" />
                                                                    </div>
                                                                    <div className="d-flex flex-column">
                                                                        <div className="Black__text">{checkString(item.document)}</div>
                                                                        <div className="name__text">{checkTypeString(item.document)}</div>
                                                                    </div>
                                                                </div>
                                                                <img
                                                                    src={DownloadImg}
                                                                    className="DownloadImg"
                                                                    onClick={() => {
                                                                        window.open(item.document, "_blank");
                                                                    }}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {nullUndefinedEmptyCheck(item?.message) && (
                                                        // <div className="chat__end">
                                                        <div className={`${ChatEndLanguage} chat__end`}>
                                                            <div className={`${SchoolUserChatLanguage} school_user__chat`}>
                                                                {item?.message}
                                                            </div>
                                                            <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                        </div>
                                                    )}

                                                    {item.image && <div className={`${ChatEndLanguage} chat__end`}>
                                                        <img className="chat-image" src={item.image} onClick={() => {
                                                            window.open(item.image, "_blank");
                                                        }} />
                                                        <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                    </div>}

                                                    {item.document && (
                                                        <div className={`${ChatEndLanguage} chat__end`}>
                                                            <div className="Pdf__container">
                                                                <div className="left__contant">
                                                                    <div className="tutor__img__wapper">
                                                                        <img src={Pdf_Img} alt="" />
                                                                    </div>
                                                                    <div className="d-flex flex-column">
                                                                        <div className="Black__text">{checkString(item.document)}</div>
                                                                        <div className="name__text">{checkTypeString(item.document)}</div>
                                                                    </div>
                                                                </div>
                                                                <img
                                                                    src={DownloadImg}
                                                                    className="DownloadImg"
                                                                    onClick={() => {
                                                                        window.open(item.document, "_blank");
                                                                    }}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )
                                })}
                            </div >

                            <div className={`${Type_Box_Bontainer} type__box__container`}>
                                <input type="text" placeholder="Type Your Message" value={textMessage} onChange={(e: any) => { setTextMessage(e.target.value); }}
                                    onKeyPress={(e: any) => {
                                        if (e.code === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                                            if (textMessage.trim() === '') {
                                                e.preventDefault();
                                            } else {
                                                onSendMessage();
                                                e.preventDefault();
                                            }
                                        }
                                    }}
                                    className={`${Type_Box_Input} type__box__input`} />
                                <input
                                    ref={ImageInputRef}
                                    className="file_input"
                                    type="file"
                                    accept="image/png, image/jpeg, image/jpg, image/svg+xml, application/pdf, application/msword, 
                                    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                     application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={onUploadEventImage}
                                />
                                <AttachFileIcon className={`${AttachFileIconArab} AttachFileIcon`} onClick={() => ImageInputRef.current?.click()} />
                                {nullUndefinedEmptyCheck(textMessage) && textMessage.trim() !== '' ?
                                    (<img src={chatSendIcon} alt="" onClick={onSendMessage} className={`${sendIcon_Arab} sendIcon`} />) :
                                    (<img src={chatSendIcon} alt="" className={`${SendIcon_Disable_Arab} sendIcon__disable`} />)}
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        </>
    )
}

export default SchoolChatExpert;