import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import "./TutorHub.scss";
import search from "../../Assets/Tutor/searchIconGray.svg";
import TutorImg from "../../Assets/Tutor/TutorImg.svg";
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import messageIcon from "../../Assets/TutorHub/messageIcon.svg";
import video_call_icon from "../../Assets/TutorHub/video_call_icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES,
} from "../../Constants/Constants";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { SetSessionStorageData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import Badge from "@mui/material/Badge";
import activitiesIcon from "../../Assets/Activities/activitiesIcon.svg";
import spacemen from "../../Assets/Global/spacemen.svg";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";

const TutorHub = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [TutorHubList, setTutorHubList] = useState<any>([]);
  console.log("🚀 ~ TutorHub ~ TutorHubList:", TutorHubList)
  const [ActivityHubList, setActivityHubList] = useState<any>([]);
  const [ActiveList, setActiveList] = useState<any>(
    GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT
  );
  const [sortValue, setSortValue] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.STRING_ALL
  );
  const [searchValue, setSearchValue] = useState("");
  const [isTodayTutor, setIsTodayTutor] = useState(false);
  const [isTodayActivity, setIsTodayActivity] = useState(false);

  const navigateDetailes = (value: any, state: any) => {
    navigate(ROUTES.TUTOR_HUB_DETAILES, {
      state: { Detailes_list: value, type: ActiveList, activeState: state },
    });
    SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, value?.session_creator_name);
  };

  /**Loader state*/
  const Loader: any = useSelector((state: any) => {
    return state.loaderReducer.loader;
  });

  useEffect(() => {
    getTutorHubData("", searchValue);
    getActivityHubData("", searchValue);
    SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
  }, [isTodayTutor, isTodayActivity]);

  /**Get Tutor Hub List*/
  const getTutorHubData = (value: any, search: any) => {
    axiosInstance
      .get(
        `${API_END_POINTS.TUTOR_HUB +
        value +
        GLOBAL_CONSTANTS.INITIAL_VALUES.AND_SEARCH +
        search +
        GLOBAL_CONSTANTS.INITIAL_VALUES.AND_ISTODAY +
        isTodayTutor
        }`
      )
      .then((Response: any) => {
        if (Response?.data?.status) {
          setTutorHubList(Response?.data?.result?.data);
        }
      })
      .catch(() => { });
  };

  /**Get Activity Hub List*/
  const getActivityHubData = (value: any, search: any) => {
    axiosInstance
      .get(
        `${API_END_POINTS.Activity_HUB +
        value +
        GLOBAL_CONSTANTS.INITIAL_VALUES.AND_SEARCH +
        search +
        GLOBAL_CONSTANTS.INITIAL_VALUES.AND_ISTODAY +
        isTodayActivity
        }`
      )
      .then((Response: any) => {
        if (Response?.data?.status) {
          setActivityHubList(Response?.data?.result?.data);
        }
      })
      .catch(() => { });
  };

  const handleOpenNewTab = (url: any) => {
    // Open a new tab/window
    window.open(url, "_blank");
  };

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value === GLOBAL_CONSTANTS.INITIAL_VALUES.STRING_ALL) {
      setSortValue(event.target.value);
      if (ActiveList === GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT) {
        getTutorHubData("", searchValue);
      } else {
        getActivityHubData("", searchValue);
      }
    } else {
      setSortValue(event.target.value);
      if (ActiveList === GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT) {
        getTutorHubData(event.target.value, searchValue);
      } else {
        getActivityHubData(event.target.value, searchValue);
      }
    }
  };

  const handleSwitch = (val: any) => {
    setActiveList(val);
    getTutorHubData("", "");
    getActivityHubData("", "");
    setSortValue(GLOBAL_CONSTANTS.INITIAL_VALUES.STRING_ALL);
    setSearchValue("");
  };

  const handleInputChange = () => {
    if (ActiveList === GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT) {
      getTutorHubData(
        sortValue == GLOBAL_CONSTANTS.INITIAL_VALUES.STRING_ALL
          ? ""
          : sortValue,
        searchValue
      );
    } else {
      getActivityHubData(
        sortValue == GLOBAL_CONSTANTS.INITIAL_VALUES.STRING_ALL
          ? ""
          : sortValue,
        searchValue
      );
    }
  };

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /*Below class name based on English Arabic*/
  const SearchIconLanguage = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "searchIcon-eng" : "searchIcon-arab";
  const SearchInputLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "searchInput-eng" : "searchInput-arab";
  /*Below class name based on English Arabic*/
  const CreatedByLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ms-lang" : "me-arab";
  const RightBlackTextLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "RightBlackTextLang_Eng" : "RightBlackTextLang_Arab";
  const ParentTagLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ParentTagLang_Eng" : "ParentTagLang_Arab";

  return (
    <>
      <div className="Tutor_hup_container_wraper" >
        <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
          <div className="Tutor_hup__container">
            <div className="header__section">
              <div className="left__contant">
                <div
                  className={ActiveList === 1 ? "tutor__active" : "title"}
                  onClick={() => handleSwitch(1)}
                >
                  {t("Tutor Bookings")}
                </div>
                <div
                  className={ActiveList === 2 ? "Activity__active" : "title"}
                  onClick={() => handleSwitch(2)}
                >
                  {t("Activity Bookings")}
                </div>
              </div>
              <div className="left__contant_two">
                <div className={`${ParentTagLang} ParentTag`}>
                  <input
                    type="text"
                    className={`${SearchInputLang} searchInput`}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyPress={(e: any) => {
                      if (e.code === "Enter") {
                        if (searchValue.trim() === "") {
                          e.preventDefault();
                          handleInputChange();
                        } else {
                          handleInputChange();
                          e.preventDefault();
                        }
                      }
                    }}
                    placeholder={t("search")}
                  />
                  <img
                    src={search}
                    alt=""
                    className={`${SearchIconLanguage} searchIcon`}
                    onClick={handleInputChange}
                  />
                </div>
                <FormControl className="two_form_control">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={sortValue}
                    label="Sort"
                    onChange={handleChange}
                    className="form_control_select"
                  >
                    <MenuItem className="menu_itemses" value={"all"}>{t("All")}</MenuItem>
                    <MenuItem className="menu_itemses" value={"new"}>{t("New")}</MenuItem>
                    {/* <MenuItem className="menu_itemses" value={"in_progress"}>In Progress</MenuItem> */}
                    <MenuItem className="menu_itemses" value={"completed"}>{t("Completed")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="tutor_hub__card__container row">
              {ActiveList === GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT && (
                <>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div
                      className={
                        isTodayTutor ? "is_today__tutor__active" : "  is_today"
                      }
                      onClick={() => setIsTodayTutor(!isTodayTutor)}
                    >
                      {t("Today")}
                    </div>
                  </div>
                  {TutorHubList?.map((data: any) => {
                    return (
                      <>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                          <div className="card_wapper" onClick={() =>
                            navigateDetailes(data, GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT)
                          }>
                            <div className="Profile_contant">
                              <div className="tutor__contant">
                                <img
                                  src={
                                    nullUndefinedEmptyCheck(
                                      data?.session_creator_profile
                                    )
                                      ? data?.session_creator_profile
                                      : tutor_img
                                  } onError={(e: any) => { e.target.src = tutor_img; }}
                                  className="profile__Img"
                                  alt=""
                                />
                                <div className="d-flex flex-column">
                                  <div className="Profile__name__text notranslate">
                                    {language === "English" ? data?.session_creator_name : data?.arabic_session_creator_name || data?.session_creator_name}
                                  </div>
                                  <div className="name__text">{data?.type}</div>
                                </div>
                              </div>

                              <div className="right_options" onClick={(e) => e.stopPropagation()}>
                                <div
                                  className="message__wapeer"
                                  onClick={() =>
                                    navigateDetailes(
                                      data,
                                      GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT
                                    )
                                  }
                                >
                                  <div className="badgeIcon badeg__pos"></div>
                                  <img src={messageIcon} alt="" />
                                </div>
                                {data?.is_online && (
                                  <div
                                    className="video_call__wapeer"
                                    onClick={() =>
                                      navigateDetailes(
                                        data,
                                        GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT
                                      )
                                    }
                                  >
                                    <div className="badgeIcon badeg__pos"></div>
                                    <img src={video_call_icon} alt="" />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="tutor__contant">
                              <div className="tutor__img__wapper">
                                <img src={TutorImg} alt="" />
                              </div>
                              <div className="d-flex flex-column">
                                <div title={data?.session_name[0]} className="Black__text">
                                  {data?.session_name[0]}
                                </div>
                                <div className="name__text notranslate">
                                  {language === "English" ? data?.session_creator_name : data?.arabic_session_creator_name || data?.session_creator_name}
                                </div>
                              </div>
                            </div>

                            <div className="row__contant">
                              <div className="left__light__text">
                                {t("No. of Sessions")}
                              </div>
                              <div className={`${RightBlackTextLang} right__black__text`}>
                                {data?.completed_slots_count}/
                                {data?.total_slots_count}
                              </div>
                            </div>
                            <div className="row__contant">
                              <div className="left__light__text">{t(data?.is_accepted ? "Date" : "Updated Date")} </div>
                              <div className={`${RightBlackTextLang} right__black__text`}>
                                {data?.date}
                              </div>
                            </div>
                            <div className="row__contant">
                              <div className="left__light__text">
                                {t(data?.is_accepted ? "Booked Time" : "Updated Time")}
                              </div>
                              <div className="d-flex flex-column">
                                {data?.time?.map((item: any) => {
                                  return (
                                    <div className={`${RightBlackTextLang} right__black__text`}>
                                      {item}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="row__contant">
                              <button
                                className={
                                  data?.status == "completed"
                                    ? "CompletedBtn"
                                    : "inprogressBtn"
                                }
                              >
                                {data?.status}
                              </button>
                              {/* <div className="d-flex align-items-center user-text-icon"> */}
                              <div className={`d-flex align-items-center ${language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "user-text-icon" : ""}`}>
                                <img
                                  src={
                                    nullUndefinedEmptyCheck(data?.profile)
                                      ? data?.profile
                                      : tutor_img
                                  } onError={(e: any) => { e.target.src = tutor_img; }}
                                  className="child_profile_img"
                                  alt=""
                                />
                                {/* <div className="createdBy ms-2"> */}
                                <div className={`createdBy ms-2 ${CreatedByLang}`}>
                                  {data?.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {TutorHubList?.length === 0 && !Loader && (
                    <>
                      <div className="No__data__contant">
                        <img src={spacemen} alt="" />
                        <div className="text">{t("No Data Found")}</div>
                      </div>
                    </>
                  )}
                </>
              )}

              {ActiveList === GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT && (
                <>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div
                      className={
                        isTodayActivity
                          ? "is_today__Activity__active"
                          : "  is_today"
                      }
                      onClick={() => setIsTodayActivity(!isTodayActivity)}
                    >
                      {t("Today")}
                    </div>
                  </div>
                  {ActivityHubList?.map((data: any) => {
                    return (
                      <>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                          <div className="card_wapper" onClick={() =>
                            navigateDetailes(data, GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT
                            )}>
                            <div className="Profile_contant">
                              <div className="tutor__contant">
                                <img
                                  src={
                                    nullUndefinedEmptyCheck(
                                      data?.session_creator_profile
                                    )
                                      ? data?.session_creator_profile
                                      : tutor_img
                                  } onError={(e: any) => { e.target.src = tutor_img; }}
                                  className="profile__Img"
                                  alt=""
                                />
                                <div className="d-flex flex-column">
                                  <div className="Profile__name__text notranslate">
                                    {`${language === "English" ? data?.session_creator_name : data?.arabic_session_creator_name || data?.session_creator_name}
${data?.camp_name || data?.arabic_camp_name ? ` - ${language === "English" ? data?.camp_name : data?.arabic_camp_name}` : ""}`}

                                  </div>
                                  <div className="name__text">{data?.type}</div>
                                </div>
                              </div>
                              <div className="right_options" onClick={(e) => e.stopPropagation()}>
                                {/* <div
                                  className="message__wapeer"
                                  onClick={() =>
                                    navigateDetailes(
                                      data,
                                      GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT
                                    )
                                  }
                                >
                                  <div className="badgeIcon badeg__pos"></div>
                                  <img src={messageIcon} alt="" />
                                </div> */}
                                {data?.is_online && (
                                  <div
                                    className="video_call__wapeer"
                                    onClick={() =>
                                      handleOpenNewTab(data?.meet_link)
                                    }
                                  >
                                    <div className="badgeIcon badeg__pos"></div>
                                    <img src={video_call_icon} alt="" />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="tutor__contant">
                              <div className="activity__img__wapper">
                                <img src={activitiesIcon} alt="" />
                              </div>
                              <div className="d-flex flex-column">
                                <div title={data?.session_name} className="Black__text">
                                  {data?.session_name}
                                </div>
                                <div className="name__text notranslate">
                                  {language === "English" ? data?.session_creator_name : data?.arabic_session_creator_name || data?.session_creator_name}
                                </div>
                              </div>
                            </div>

                            <div
                              className="row__contant">
                              <div className="left__light__text">
                                {t("No. of Sessions")}
                              </div>
                              <div className={`${RightBlackTextLang} right__black__text`}>
                                {data?.completed_slots_count}/
                                {data?.total_slots_count}
                              </div>
                            </div>
                            <div className="row__contant">
                              <div className="left__light__text">{t(data?.is_accepted ? "Date" : "Updated Date")} </div>
                              <div className={`${RightBlackTextLang} right__black__text`}>
                                {data?.date}
                              </div>
                            </div>
                            <div className="row__contant">
                              <div className="left__light__text">
                                {t(data?.is_accepted ? "Booked Time" : "Updated Time")}
                              </div>
                              <div className="d-flex flex-column">
                                {data?.time?.map((item: any) => {
                                  return (
                                    <div className={`${RightBlackTextLang} right__black__text`}>
                                      {item}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="row__contant">
                              <button
                                className={
                                  data?.status == "completed"
                                    ? "CompletedBtn"
                                    : "inprogressBtn"
                                }
                              >
                                {data?.status}
                              </button>
                              {/* <div className="d-flex align-items-center user-text-icon"> */}
                              <div className={`d-flex align-items-center ${language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "user-text-icon" : ""}`}>
                                <img
                                  src={
                                    nullUndefinedEmptyCheck(data?.profile)
                                      ? data?.profile
                                      : tutor_img
                                  } onError={(e: any) => { e.target.src = tutor_img; }}
                                  className="child_profile_img"
                                  alt=""
                                />
                                {/* <div className="createdBy ms-2"> */}
                                <div className={`createdBy ms-2 ${CreatedByLang}`}>
                                  {data?.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {ActivityHubList?.length === 0 && !Loader && (
                    <>
                      <div className="No__data__contant">
                        <img src={spacemen} alt="" />
                        <div className="text">{t("No Data Found")}</div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default TutorHub;
